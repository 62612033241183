import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Table, Select, DatePicker, notification} from 'antd';
import Survey from '../../../../http/Survey';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar} from 'recharts';
import DefaultContainer from "../../../../components/DefaultContainer";

const {RangePicker} = DatePicker;
const {Option} = Select;

const SurveyComments = () => {
    const {branchId} = useParams();
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [dateRange, setDateRange] = useState([]);
    const [questionKeys, setQuestionKeys] = useState([]);
    const [selectedQuestionKey, setSelectedQuestionKey] = useState('');
    const [branch, setBranch] = useState(null);
    const [statistics, setStatistics] = useState([]);
    const [monthlyAvgScores, setMonthlyAvgScores] = useState([]);
    const [scoreDistribution, setScoreDistribution] = useState([]);
    const [bestWorstMonths, setBestWorstMonths] = useState([]);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        fetchQuestions(branchId);
        fetchComments(branchId, dateRange, pagination.current, pagination.pageSize, selectedQuestionKey, sortField, sortOrder);
        fetchStatistics(branchId, dateRange);
    }, [branchId]);

    const fetchQuestions = (branchId) => {
        Survey.GetQuestions(branchId, (response) => {
            const {questionKeys, branch} = response.data.data;
            const keysArray = Object.values(questionKeys); // Convert object to array
            setQuestionKeys(keysArray);
            setBranch(branch);
        }, (error) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load questions',
            });
        });
    };

    const fetchComments = (branchId, dateRange, page, pageSize, questionKey, sortField, sortOrder) => {
        setLoading(true);
        // Map sortOrder from 'ascend'/'descend' to 'asc'/'desc'
        const mappedSortOrder = sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : '';
        Survey.getSurveyComments(branchId, dateRange, page, pageSize, questionKey, sortField, mappedSortOrder,
            (response) => {
                const {comments, pagination} = response.data.data;
                setComments(comments);
                setPagination(pagination);
                setLoading(false);
            },
            (error) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load comments',
                });
                setLoading(false);
            }
        );
    };

    const fetchStatistics = (branchId, dateRange) => {
        Survey.getSurveyStatistics(branchId, dateRange,
            (response) => {
                const {statistics, monthlyAvgScores, scoreDistribution, bestWorstMonths} = response.data.data;
                setStatistics(statistics);
                setMonthlyAvgScores(monthlyAvgScores);
                setScoreDistribution(scoreDistribution);
                setBestWorstMonths(bestWorstMonths);
            },
            (error) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load statistics',
                });
            }
        );
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination); // Update pagination state
        const sortField = sorter.field || '';
        const sortOrder = sorter.order || '';
        setSortField(sortField);
        setSortOrder(sortOrder);
        fetchComments(branchId, dateRange, pagination.current, pagination.pageSize, selectedQuestionKey, sortField, sortOrder);
    };

    const handleDateRangeChange = (dates) => {
        const range = dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : [];
        setDateRange(range);
        fetchComments(branchId, range, 1, pagination.pageSize, selectedQuestionKey, sortField, sortOrder);
        fetchStatistics(branchId, range);
    };

    const handleQuestionChange = (value) => {
        setSelectedQuestionKey(value);
        fetchComments(branchId, dateRange, 1, pagination.pageSize, value, sortField, sortOrder);
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            sorter: true,
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            sorter: true,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            sorter: true,
        },
        {
            title: 'Submitted At',
            dataIndex: 'submitted_at',
            key: 'submitted_at',
            sorter: true,
        },
    ];

    const calculateTotalsAndAverage = (key) => {
        const keyStatistics = statistics.find(stat => stat.key === key);
        const totalInputs = keyStatistics ? keyStatistics.total_responses : 0;
        const averageScore = keyStatistics ? parseFloat(keyStatistics.avg_score).toFixed(2) : 0;

        return {totalInputs, averageScore};
    };

    const renderChartsForKey = (key) => {
        const keyStatistics = statistics.filter(stat => stat.key === key);
        const keyMonthlyAvgScores = monthlyAvgScores.filter(score => score.key === key);
        const keyScoreDistribution = scoreDistribution.filter(dist => dist.key === key);
        const keyBestWorstMonths = bestWorstMonths[key];
        const {totalInputs, averageScore} = calculateTotalsAndAverage(key);

        return (
            <div key={key} className={"bg-gray-50 mb-4 p-5 border border-gray-200"}>
                <h2 className="font-bold mb-4 uppercase patty text-3xl">{key}</h2>
                <div className={"flex flex-col 3xl:flex-row justify-between items-center"}>
                    <div style={{marginBottom: 10}}>
                        <div>Total Inputs: <b>{totalInputs}</b></div>
                        <div>Average Score: <b>{averageScore}</b></div>
                    </div>

                    <div className={"flex flex-row"}>
                        <div style={{marginBottom: 20}} className={"w-full flex flex-col items-center"}>
                            <div className={"font-bold"}>Average Scores by Month</div>
                            <LineChart
                                width={600}
                                height={300}
                                data={keyMonthlyAvgScores.map(month => ({
                                    ...month,
                                    avg_score: parseFloat(month.avg_score) // Ensure avg_score is a number
                                }))}
                                margin={{top: 5, bottom: 5}}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="month"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="avg_score" stroke="#8884d8" activeDot={{r: 8}}/>
                            </LineChart>
                        </div>

                        <div style={{marginBottom: 20}} className={"w-full flex flex-col items-center"}>
                            <h3 className={"font-bold"}>Score Distribution</h3>
                            <BarChart
                                width={600}
                                height={300}
                                data={keyScoreDistribution}
                                margin={{top: 5, bottom: 5}}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="score"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="count" fill="#8884d8"/>
                            </BarChart>
                        </div>
                    </div>

                    {keyBestWorstMonths && (
                        <div>
                            <div className={"flex flex-row justify-start"}>
                                <div className={"mr-8"}>
                                    <div className={"font-bold"}>Best Months</div>
                                    <Table
                                        columns={[
                                            {title: 'Month', dataIndex: 'month', key: 'month'},
                                            {title: 'Average Score', dataIndex: 'avg_score', key: 'avg_score'}
                                        ]}
                                        dataSource={keyBestWorstMonths.best}
                                        pagination={false}
                                        rowKey="month"
                                    />
                                </div>
                                <div>
                                    <div className={"font-bold"}>Worst Months</div>
                                    <Table
                                        columns={[
                                            {title: 'Month', dataIndex: 'month', key: 'month'},
                                            {title: 'Average Score', dataIndex: 'avg_score', key: 'avg_score'}
                                        ]}
                                        dataSource={keyBestWorstMonths.worst}
                                        pagination={false}
                                        rowKey="month"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <DefaultContainer>
            {branch &&
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">Survey Reports - <span>{branch.name}</span></h1>
            }

            {questionKeys.map(renderChartsForKey)}

            <div style={{marginBottom: 16}}>
                <RangePicker onChange={handleDateRangeChange}/>
                <Select
                    style={{width: 200, marginLeft: 16}}
                    placeholder="Select Question"
                    onChange={handleQuestionChange}
                >
                    <Option value="">All Questions</Option>
                    {questionKeys.map(key => (
                        <Option key={key} value={key}>{key}</Option>
                    ))}
                </Select>
            </div>

            <Table
                columns={columns}
                dataSource={comments}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                rowKey="id"
            />
        </DefaultContainer>
    );
};

export default SurveyComments;
