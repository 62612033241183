import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle, faRemove, faTrash} from "@fortawesome/free-solid-svg-icons";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import { Button, Form, notification, Popconfirm, Tag } from 'antd';
import Departments from "../../../../http/Departments";
import DepartmentModal from "./DepartmentModal";
import DepartmentRoles from "../../../../http/DepartmentRoles";
import RoleModal from "./RoleModal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ManageDepartments = () => {
    const [form] = Form.useForm();
    const [roleForm] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [editDepartment, setEditDepartment] = useState(null);
    const [editRole, setEditRole] = useState(null);
    const [departmentModalVisible, setDepartmentModalVisible] = useState(false);
    const [roleModalVisible, setRoleModalVisible] = useState(false);
    const [validationFields, setValidationFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const [currentDepartment, setCurrentDepartment] = useState(null);

    const reload = (forceShowLoading = false) => {
        if (!departments || forceShowLoading)
            setLoading(true);
        Departments.Index((response) => {
            setLoading(false);
            setDepartments(response.data.data);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    const onDepartmentModalCancel = () => {
        setDepartmentModalVisible(false);
        setEditDepartment(null);
        setValidationFields(null);
        form.resetFields();
    };

    const onRoleModalCancel = () => {
        setRoleModalVisible(false);
        setEditRole(null);
        setValidationFields(null);
        roleForm.resetFields();
    };

    const onDepartmentEdit = (department) => {
        setEditDepartment(department);
        setValidationFields(null);
        setDepartmentModalVisible(true);
    };

    const onRoleEdit = (role, department) => {
        setCurrentDepartment(department);
        setEditRole(role);
        setValidationFields(null);
        setRoleModalVisible(true);
    };

    const onDepartmentCreate = () => {
        setEditDepartment(null);
        setDepartmentModalVisible(true);
    };

    const onRoleCreate = (department) => {
        setCurrentDepartment(department);
        setEditRole(null);
        setRoleModalVisible(true);
    };

    const onSaveDepartment = (values, isEdit) => {
        setSaving(true);
        if (isEdit) {
            Departments.Update(editDepartment.id, values, (response) => {
                reload();
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Department ${values.name} has been updated!`,
                });
                setSaving(false);
                setDepartmentModalVisible(false);
                setValidationFields(null);
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        } else {
            Departments.Store(values, (response) => {
                reload();
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Department ${values.name} has been created!`,
                });
                setSaving(false);
                setDepartmentModalVisible(false);
                setValidationFields(null);
                form.resetFields();
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        }
    };

    const onSaveRole = (values, isEdit) => {
        setSaving(true);
        if (isEdit) {
            DepartmentRoles.Update(currentDepartment.id, editRole.id, values, (response) => {
                reload();
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Role ${values.name} has been updated!`,
                });
                setSaving(false);
                setRoleModalVisible(false);
                setValidationFields(null);
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        } else {
            DepartmentRoles.Store(currentDepartment.id, values, (response) => {
                reload();
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Role ${values.name} has been created!`,
                });
                setSaving(false);
                setRoleModalVisible(false);
                setValidationFields(null);
                roleForm.resetFields();
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        }
    };

    const handleDeleteDepartment = (departmentId) => {
        Departments.Delete(departmentId, () => {
            reload();
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: 'Department has been deleted!',
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: 'Failed to delete department.',
            });
        });
    };

    const handleDeleteRole = (departmentId, roleId) => {
        DepartmentRoles.Delete(departmentId, roleId, () => {
            reload();
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: 'Role has been deleted!',
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: 'Failed to delete role.',
            });
        });
    };

    const onDragEnd = (result, department) => {
        if (!result.destination) return;
        const reorderedRoles = Array.from(department.roles);
        const [movedRole] = reorderedRoles.splice(result.source.index, 1);
        reorderedRoles.splice(result.destination.index, 0, movedRole);
        // Update the roles order in the state
        setDepartments(prevDepartments =>
            prevDepartments.map(dep => dep.id === department.id ? { ...dep, roles: reorderedRoles } : dep)
        );
        // Call API to update roles order
        DepartmentRoles.Reorder(department.id, reorderedRoles.map((role, index) => ({ id: role.id, order: index })), () => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: 'Roles reordered successfully!',
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: 'Failed to reorder roles.',
            });
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className={`w-full`}
        >
            {departmentModalVisible &&
                <DepartmentModal
                    visible={departmentModalVisible}
                    onCancel={onDepartmentModalCancel}
                    validationFields={validationFields}
                    editDepartment={editDepartment}
                    onSave={onSaveDepartment}
                    saving={saving}
                    form={form}
                />
            }

            {roleModalVisible &&
                <RoleModal
                    visible={roleModalVisible}
                    onCancel={onRoleModalCancel}
                    validationFields={validationFields}
                    editRole={editRole}
                    onSave={onSaveRole}
                    saving={saving}
                    form={roleForm}
                />
            }

            <div className="flex flex-col mx-auto w-full">
                <div className={"text-xl font-bold uppercase mb-4 flex justify-between items-center"}>
                    <span>Departments</span>
                    <Button type="primary" icon={<FontAwesomeIcon icon={faPlusCircle} />} onClick={onDepartmentCreate}>
                        Add Department
                    </Button>
                </div>
                <div className="w-full flex flex-col">
                    <div className="rounded-lg flex-1 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {departments && departments.length > 0 ?
                                <div className={"w-full"}>
                                    {departments.map(department => (
                                        <div key={department.id} className={"mb-3 border border-gray-300 rounded-xl shadow-md mb-2 w-full px-2 py-1 pb-2 pl-3 bg-gray-50"}>
                                            <div className={"flex justify-between items-center mb-2"}>
                                                <span className={"uppercase font-bold flex-grow text-2xl patty"}>{department.name}</span>
                                                <div className={"flex space-x-2"}>
                                                    <FontAwesomeIcon icon={faEdit} className="cursor-pointer" aria-hidden="true" onClick={() => onDepartmentEdit(department)} />
                                                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteDepartment(department.id)} placement={"topRight"}>
                                                        <FontAwesomeIcon icon={faRemove} className="cursor-pointer text-red-600" aria-hidden="true" />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <DragDropContext onDragEnd={(result) => onDragEnd(result, department)}>
                                                    <Droppable droppableId={String(department.id)}>
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                {department.roles && department.roles.length > 0 ? department.roles.map((role, index) => (
                                                                    <Draggable key={role.id} draggableId={String(role.id)} index={index}>
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className={"flex flex-row items-center mb-2"}
                                                                            >
                                                                                <div key={role.id}>
                                                                                    {role.name}
                                                                                </div>
                                                                                <FontAwesomeIcon icon={faEdit} className="cursor-pointer ml-2" aria-hidden="true" onClick={() => onRoleEdit(role, department)} />
                                                                                <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteRole(department.id, role.id)}>
                                                                                    <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-2" aria-hidden="true" />
                                                                                </Popconfirm>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )) : <div className={"font-normal text-xs"}>There are no roles for this department.</div>}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <div className={"flex justify-end border-t border-gray-200 pt-2 mt-2"}>
                                                    <Button size={"small"} type="dashed" onClick={() => onRoleCreate(department)}>Add Role</Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className={"w-full text-center text-gray-400 pb-1"}>There are no departments on this branch.</div>
                            }
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ManageDepartments;
