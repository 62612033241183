const actions = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  HIDE_HEADER: 'HIDE_HEADER',
  SHOW_HEADER: 'SHOW_HEADER',
  HIDE_DASHBOARD_MENU: 'HIDE_DASHBOARD_MENU',
  SHOW_DASHBOARD_MENU: 'SHOW_DASHBOARD_MENU',
  SET_UNDER_CONSTRUCTION: 'SET_UNDER_CONSTRUCTION',
  DASHBOARD_SWITCH_VISIBILITY: 'DASHBOARD_SWITCH_VISIBILITY',

  setLanguage: (lang) => {
    return {
      type: actions.SET_LANGUAGE,
      lang
    };
  },

  hideHeader: () => {
    return {
      type: actions.HIDE_HEADER,
    };
  },

  showHeader: (fixed) => {
    return {
      type: actions.SHOW_HEADER,
      header_fixed: fixed
    };
  },

  hideDashboardMenu: () => {
    return {
      type: actions.HIDE_DASHBOARD_MENU,
    };
  },

  showDashboardMenu: () => {
    return {
      type: actions.SHOW_DASHBOARD_MENU,
    };
  },

  setUnderConstruction: (under_construction, be_back_string) => {
    return {
      type: actions.SET_UNDER_CONSTRUCTION,
      under_construction,
      be_back_string
    };
  },

  dashboardSwitchVisibility: () => {
    return {
      type: actions.DASHBOARD_SWITCH_VISIBILITY,
    };
  }


};

export default actions;
