import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from "./Headers";

const Forecast = {
    getForecasts: (branchId, successCb, failCb) => {
        axios.get(`/forecasts/${branchId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    updateForecast: (forecastId, data, successCb, failCb) => {
        axios.put(`/forecasts/${forecastId}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    updateAdjustmentCoefficient: (data, successCb, failCb) => {
        axios.put(`/forecasts/coefficient`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Forecast;
