import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Irani = {

    ValidateIndex: (criteria, successCb, failCb) => {
        axios.post('/irani/validate/index', criteria, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ApprovedBrandsIndex: (approved_type, criteria, successCb, failCb) => {
        axios.post('/irani/approved_brands/index', {approved_type, ...criteria}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateStatus: (venue_id, status, successCb, failCb) => {
        axios.post(`/irani/${venue_id}/update_status/${status}`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    ReportsIndex: (successCb, failCb) => {
        axios.get('/irani/reports/index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingCreate: (reportSetting, successCb, failCb) => {
        axios.post(`/irani/reports/create_setting`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingUpdate: (id, reportSetting, successCb, failCb) => {
        axios.post(`/irani/reports/${id}`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingDelete: (id, successCb, failCb) => {
        axios.delete(`/irani/reports/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingGenerate: (id, successCb, failCb) => {
        axios.post(`/irani/reports/${id}/generate_report`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },




    ContextReportsIndex: (successCb, failCb) => {
        axios.get('/irani/context/reports/index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ContextReportSettingCreate: (reportSetting, successCb, failCb) => {
        axios.post(`/irani/context/reports/create_setting`, {context_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ContextReportSettingUpdate: (id, reportSetting, successCb, failCb) => {
        axios.post(`/irani/context/reports/${id}`, {context_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ContextReportSettingDelete: (id, successCb, failCb) => {
        axios.delete(`/irani/context/reports/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ContextReportSettingGenerate: (id, successCb, failCb) => {
        axios.post(`/irani/context/reports/${id}/generate_report`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },



    ReviewReportsIndex: (successCb, failCb) => {
        axios.get('/irani/review/reports/index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReviewReportSettingCreate: (reportSetting, successCb, failCb) => {
        axios.post(`/irani/review/reports/create_setting`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReviewReportSettingUpdate: (id, reportSetting, successCb, failCb) => {
        axios.post(`/irani/review/reports/${id}`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReviewReportSettingDelete: (id, successCb, failCb) => {
        axios.delete(`/irani/review/reports/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReviewReportSettingGenerate: (id, successCb, failCb) => {
        axios.post(`/irani/review/reports/${id}/generate_report`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Irani;
