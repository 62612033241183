import React from 'react';

const UpdatedColumn = ({column, value}) => {
    return (
        <div className={"text-center bg-gray-100 py-1 px-1 rounded-lg mt-1 md:text-left flex flex-row items-center border border-gray-300"}>
            <div className={"px-2 mr-2 py-0 bg-gray-800 text-white rounded-md"}><b className={"uppercase"}>{column}</b></div>
            <div>{value}</div>
        </div>
    );
};

export default UpdatedColumn;