export function getOption(food, item_id) {
    for (let okey in food.modified_options) {
        let option = food.modified_options[okey];
        for (let ikey in option.items) {
            let item = option.items[ikey];
            if(item.id == item_id) {
                return option;
            }
        }
    }
}

export function isItemSelected(selectedItems, item_id) {
    return selectedItems.indexOf(item_id) !== -1;
}

export function removeFromSelectedItems(selectedItems, item_id) {

    selectedItems.splice(selectedItems.indexOf(item_id), 1)
    return selectedItems;
}

export function getItem(option, item_id) {
    const foundItem = option.items.find(item => item.id === item_id);
    return foundItem;
}

export function selectItem(food, selectedItems, item_id) {
    let option = getOption(food, item_id);

    if (selectedItems.indexOf(item_id) === -1) {
        // we are selecting an item that has not selected before.
        if(option.multiselect) {
            const max = option.max_modifiers;

            let totalSelected = 0;
            for(const ikey in option.items) {
                const item = option.items[ikey];
                if(isItemSelected(selectedItems, item.id)) {
                    totalSelected += item.total_amount;
                }
            }

            const item = getItem(option, item_id);
            if(totalSelected < max && totalSelected + item.total_amount <= max) {
                //console.log("selectedItems", selectedItems);
                //console.log("option", option);
                selectedItems.push(item_id);
            }
        }
        else {
            for(const ikey in option.items) {
                const item = option.items[ikey];
                if(isItemSelected(selectedItems, item.id))
                    selectedItems = removeFromSelectedItems(selectedItems, item.id);
            }
            selectedItems.push(item_id);
        }
    }
    else {
        if(!option.required) {
            selectedItems = removeFromSelectedItems(selectedItems, item_id);
        }
        else {
            if(option.multiselect) {
                let otherItemsSelected = false;
                for(const ikey in option.items) {
                    const item = option.items[ikey];
                    if(item.id != item_id && isItemSelected(selectedItems, item.id)) {
                        otherItemsSelected = true;
                        break;
                    }
                }
                if(otherItemsSelected) {
                    selectedItems = removeFromSelectedItems(selectedItems, item_id);
                }
            }
            else {
                // do nothing
            }
        }
    }
    return selectedItems;
}

export function getDefaultItemsAsSelected(food) {
    let selectedItems = [];
    for (let okey in food.modified_options) {
        let option = food.modified_options[okey];
        for (let ikey in option.items) {
            let item = option.items[ikey];
            if(item.default) {
                selectedItems = selectItem(food, selectedItems, item.id);
            }
        }
    }
    return selectedItems;
}

export function hasMainDisabledDefaultOption(food, options) {
    for (let okey in food.modified_options) {
        let option = food.modified_options[okey];

        const foundOption = options.find(opt => opt.id === option.id);
        const isInStock = foundOption && foundOption.in_stock === 1;

        if(isInStock) {
            let default_found = false;
            for (let ikey in option.items) {
                let item = option.items[ikey];
                if (item.default) {
                    default_found = true;
                }
            }
            if(!default_found)
                return true;
        }

        if(!isInStock) {
            let first = true;
            for (let ikey in option.items) {
                let item = option.items[ikey];
                if (item.default) {
                    if(!first) return true;
                }
                first = false;
            }
        }
    }
    return false;
}

export function hasAllOptionsSelected(food, options, selectedItems) {
    for (let okey in food.modified_options) {
        let option = food.modified_options[okey];

        let an_item_is_selected = false;
        for (let ikey in option.items) {
            let item = option.items[ikey];
            if(isItemSelected(selectedItems, item.id)) {
                an_item_is_selected = true;
                break
            }
        }
        if(!an_item_is_selected) return option.name;
    }
    return true;
}

export function calculatePrice(food, selectedItems) {
    let total_price = food.price;
    for(const okey in food.modified_options) {
        const option = food.modified_options[okey];
        for(const ikey in option.items) {
            const item = option.items[ikey];
            if(isItemSelected(selectedItems, item.id)) {
                total_price += item.price;
            }
        }
    }
    return total_price;
}

export const convertCartItemToFood = (menu, cart_item) => {
    const food_id = cart_item[0];

    for (const key in menu) {
        const food = menu[key];
        if (food.id == food_id) {
            return food;
        }
    }
}

export const getCartItemByKey = (cart_items, key) => {
    const cart_item = cart_items.filter((item) => {
            return item[3] == key;
        }
    )
    return cart_item[0];
}
