import React, { useState, useEffect } from 'react';
import jsonData from './data.json';
import GoogleMapComponent from "./GoogleMapComponent";

const GoogleIndex = () => {
    const [latLngInput, setLatLngInput] = useState('40.714224,-73.961452');
    const [geocodingResult, setGeocodingResult] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(jsonData);
    }, []);

    return (
        <div className={"w-full h-screen"}>
            {data &&
                <GoogleMapComponent
                    containerElement={<div style={{height: '100%'}}/>}
                    mapElement={<div style={{height: '100%'}}/>}
                    center={{lat: 45.5591827, lng: -73.7118733}}
                    zoom={10}
                    clusters={data.oneDecimalLessAllUsersPaths}
                />
            }
        </div>
    );
};

export default GoogleIndex;
