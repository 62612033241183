import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {Form, Input, Button, notification, Tag} from 'antd';
import {useHistory, useParams} from 'react-router-dom';  // For navigation and parameters
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import OptionApi from "../../../../../http/OptionApi";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {RiEdit2Line, RiImageLine} from "@remixicon/react";
import DefaultContainer from "../../../../../components/DefaultContainer";

const EditOption = () => {
    const [form] = Form.useForm();
    const [option, setOption] = useState(null);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [defaultItemIds, setDefaultItemIds] = useState([]);  // Stores the IDs of pre-selected default items

    const history = useHistory();
    const {id, optionId} = useParams();  // Destructure the food and option IDs from the URL

    // Load the option details and items
    const loadOptionDetails = () => {
        setLoading(true);

        OptionApi.Edit(id, optionId, (response) => {
            const {option} = response.data.data;
            setOption(option);
            setItems(option?.items);
            setDefaultItemIds(JSON.parse(option?.pivot?.pre_selected));  // Store the default item IDs

            form.setFieldsValue({
                name: option.name,
                name_fr: option.name_fr,
            });

            setLoading(false);
        }, (err) => {
            notification.error({message: 'Error!', description: err.response?.data.message});
            setLoading(false);
        });
    };

    useEffect(() => {
        loadOptionDetails();
    }, [optionId]);

    const saveOption = (values) => {
        setSaving(true);
        const data = {
            name: values.name,
            name_fr: values.name_fr,
        };

        OptionApi.Update(id, optionId, data,
            () => {
                notification.success({message: 'Success!', description: 'Option updated successfully!'});
                setSaving(false);
            },
            (err) => {
                notification.error({message: 'Error!', description: err.response?.data.message});
                setSaving(false);
            }
        );
    };

    const handleItemReorder = (result) => {
        if (!result.destination) return;
        const reorderedItems = Array.from(items);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);
        setItems(reorderedItems);

        // Automatically save the reordered items
        const reorderedData = reorderedItems.map((item, index) => ({
            id: item.id,
            order: index
        }));

        OptionApi.UpdateItemOrder(optionId, reorderedData,
            () => {
                notification.success({message: 'Success!', description: 'Item order updated successfully!'});
            },
            (err) => {
                notification.error({message: 'Error!', description: 'Failed to update item order.'});
            }
        );
    };

    const markAsDefault = (itemId) => {
        // Update the default item in the pivot table
        OptionApi.SetDefaultItem(id, optionId, itemId,
            () => {
                notification.success({message: 'Success!', description: 'Item marked as default!'});
                setDefaultItemIds([itemId]);  // Update the local state to reflect the default item
            },
            (err) => {
                notification.error({message: 'Error!', description: 'Failed to mark item as default.'});
            }
        );
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <h1 className="text-xl font-bold">Edit Option</h1>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <div>
                        <Button type={"link"} onClick={() => history.push(`/dashboard/storefront/foods/${id}/edit`)} type="default" className="mb-4">
                            Back to Food
                        </Button>
                    </div>

                    <Form
                        form={form}
                        onFinish={saveOption}
                        className="mt-4"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                    >
                        <Form.Item
                            name="name"
                            label="Option Name"
                            rules={[{required: true, message: 'Please enter the option name'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="name_fr"
                            label="Option Name (French)"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 6, span: 18}}>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save Option
                            </Button>
                        </Form.Item>
                    </Form>
                </DefaultContainer>

                {items && items?.length > 0 &&
                    <>
                        <h2 className="text-lg font-bold mt-8">Reorder and Edit Items</h2>
                        <DragDropContext onDragEnd={handleItemReorder}>
                            <Droppable droppableId="items">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="food-item p-2 mb-2 bg-white shadow-md border border-gray-200 rounded-lg flex items-center justify-between"
                                                    >

                                                        <div className="flex items-center">
                                                            <div className="w-12 h-12 bg-gray-200 flex-shrink-0">
                                                                {item.image ? (
                                                                    <img src={item.image} alt={item.name} className="w-full h-full object-cover"/>
                                                                ) : (
                                                                    <RiImageLine className="w-full h-full"/>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <span className="pl-2 text-sm">{item.name} {item.name_fr ? <i>({item.name_fr})</i> : null}</span>
                                                                    <span className="pl-2 text-sm"><Tag color={"green"}>${item.price}</Tag></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"flex flex-row items-center"}>
                                                            {defaultItemIds.includes(item.id) ? (
                                                                <Tag color="blue">Default</Tag>
                                                            ) : (
                                                                <Button type={"link"} onClick={() => markAsDefault(item.id)}>Mark as default</Button>
                                                            )}

                                                            <Button
                                                                type="link"
                                                                onClick={() => history.push(`/dashboard/storefront/foods/${id}/options/${optionId}/items/${item.id}/edit`)}
                                                            >
                                                                <RiEdit2Line/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                }
            </SkeletonWrapper>
        </motion.div>
    );
};

export default EditOption;
