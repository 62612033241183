const actions = {
  MENU_INDEX_BEGIN: 'MENU_INDEX_BEGIN',
  MENU_INDEX_SUCCESS: 'MENU_INDEX_SUCCESS',
  MENU_INDEX_ERR: 'MENU_INDEX_ERR',
  UPDATE_MENU: 'UPDATE_MENU',

  menuIndexBegin: () => {
    return {
      type: actions.MENU_INDEX_BEGIN,
    };
  },

  menuIndexSuccess: (branches, menu, options, items, images, translations, public_path) => {
    return {
      type: actions.MENU_INDEX_SUCCESS,
      branches,
      menu,
      options,
      items,
      images,
      translations,
      public_path
    };
  },

  menuIndexErr: (error) => {
    return {
      type: actions.MENU_INDEX_ERR,
      error,
    };
  },

  updateMenu: (categories, foods, options, items) => {
    return {
      type: actions.UPDATE_MENU,
      categories,
      foods,
      options,
      items
    };
  },


};

export default actions;
