import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const UserTraining = {
    Index: (branchId, params, successCb, failCb) => {
        axios.get(`/branch/${branchId}/user-trainings`, { ...getAuthenticationHeaders(), params }).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Create: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/user-trainings/create`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (branchId, data, successCb, failCb) => {
        axios.post(`/branch/${branchId}/user-trainings`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (branchId, userTrainingId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/user-trainings/${userTrainingId}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (branchId, userTrainingId, data, successCb, failCb) => {
        axios.put(`/branch/${branchId}/user-trainings/${userTrainingId}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Destroy: (branchId, userTrainingId, successCb, failCb) => {
        axios.delete(`/branch/${branchId}/user-trainings/${userTrainingId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SearchEmployees: (query, successCb, failCb) => {
        axios.get(`/employees/search`, { ...getAuthenticationHeaders(), params: { query } }).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    AssignUser: (branchId, userId, successCb, failCb) => {
        axios.post(`/branch/${branchId}/user-trainings`, { user_id: userId }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default UserTraining ;