import React from 'react';
import {PieChart, Pie, Sector, Cell, ResponsiveContainer, Label, Tooltip, Text} from 'recharts';

const IraniContextPieChart = ({title, data}) => {
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        return data[index]["name"] + " %"+(percent*100).toFixed(1);
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className={"rounded-lg bg-opacity-70 bg-black patty text-white text-lg px-3 py-1"}>
                    <div>{data.name}</div>
                    <div>Occurrence: {data.p + data.n}</div>
                    <div className={"-mt-2"}>Positive: {data.p} (%{(data.p/(data.p+data.n)*100).toFixed(1)})</div>
                </div>
            );
        }

        return null;
    };


    return (
        <div className={"flex-1 border border-gray-400 bg-gray-100 rounded-md m-1 pb-2 overflow-hidden"}>
            <div className={"patty text-xl w-full text-center py-0 bg-gray-800 text-white uppercase mb-1"}>
                {title}
            </div>
            <div className={"flex flex-row items-center justify-center "}>
                <ResponsiveContainer width='100%' height={250}>
                    <PieChart>
                        <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={80} className={"patty text-lg"} fill="#8884d8" label={renderCustomizedLabel} />
                        <Tooltip content={CustomTooltip} />
                    </PieChart>
                </ResponsiveContainer>

            </div>

        </div>
    );
};

export default IraniContextPieChart;