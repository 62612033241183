import React from 'react';
import {useSelector} from "react-redux";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Can = ({abilities=null, roles=null, children, info= null}) =>
{
    const user_abilities_names = useSelector(state => state.auth.user.abilities_names);
    const user_roles_names = useSelector(state => state.auth.user.roles_names);
    const noPermission = <div className="py-1 px-2 bg-red-500 text-white rounded-md flex items-center"><FontAwesomeIcon icon={faWarning} size="lg" className="mr-1"/> You do not have permission to access this section</div>;

    if(!user_abilities_names) return null;
    if(!user_roles_names) return info?noPermission:null;

    if(abilities && abilities.length > 0 && abilities.some(ai => user_abilities_names.includes(ai)))
        return children;

    if(roles && roles.length > 0 && roles.some(ai => user_roles_names.includes(ai)))
        return children;

    return info?noPermission:null;
};

export default Can;
