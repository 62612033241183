import {createBrowserHistory} from 'history';
import {Redirect} from 'react-router-dom';
import React from 'react';

const push = (path, forceRedirectIfNeeded = true) => {
    let currentPath = window.location.pathname;

    window.sendMessageToSwift('pushing to: ' + path)

    if(currentPath != "/server-down") {
        localStorage.setItem("crashedURL", currentPath);
        window.sendMessageToSwift('setting crashedURL to: ' + currentPath)
    }
    else {
        window.sendMessageToSwift('dont set crashedURL because currentPath is /server-down')
    }

    currentPath = currentPath.replace(/\/$/, '');
    path = path.replace(/\/$/, '');
    let forceRefresh = false;
    if (currentPath != path) {
        forceRefresh = true;
    }
    const history = createBrowserHistory({forceRefresh: forceRefresh && forceRedirectIfNeeded});
    history.push(path);
};

export const redirectWithFlash = (path, state) => {
    return <Redirect to={{
        pathname: path,
        state,
    }}/>;
};

export const abort = (history) => {
    if (history) {
        history.push('/404');
    } else {
        push('/404', false);
    }
}

export default push;