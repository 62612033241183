import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, notification, Alert } from 'antd';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import OrderService from "../../../../../http/OrderService";
import usePermission from "../../../../../layouts/usePermission";
import MenuSkeleton from "../../../../../components/skeletons/MenuSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";

const ConfirmVendorOrder = () => {
    const { uuid } = useParams();
    const history = useHistory();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn); // Check if the user is logged in
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [confirming, setConfirming] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null); // New state for success message
    const isVendor = usePermission(null, ["vendor"]);

    useEffect(() => {
        fetchOrderDetails();
    }, [uuid]);

    const fetchOrderDetails = () => {
        setLoading(true);
        setError(null); // Reset any existing errors
        setSuccessMessage(null); // Reset any existing success message

        OrderService.getVendorOrderDetails(
            uuid,
            (response) => {
                setOrder(response.data.data.order);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                setError(err.response?.data?.message || 'Failed to load order details.');
            }
        );
    };

    const confirmOrder = () => {
        setConfirming(true);
        setError(null); // Reset any existing errors

        OrderService.confirmVendorOrder(
            uuid,
            (response) => {
                setConfirming(false);
                setSuccessMessage(response.data.message); // Set the success message from the response
                notification.success({
                    message: 'Success',
                    description: 'Order confirmed successfully.',
                });
                // Optionally, you can also update the order status in the state to reflect the change
                setOrder({...order, status: 'shipped'});
            },
            (err) => {
                setConfirming(false);
                setError('Failed to confirm the order.');
            }
        );
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            style={{ paddingBottom: "5vw" }}
        >
            <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                <div className="container flex flex-col mx-auto p-4 mt-5 md:mt-0 max-w-[1000px]">
                    <div className="bg-white rounded-lg shadow-md p-6 mt-5">
                        <h1 className="text-4xl font-bold text-black patty">Confirm Order</h1>
                        {error ? (
                            <>
                                <Alert
                                    message="Error"
                                    description={error}
                                    type="error"
                                    showIcon
                                    className="mb-4"
                                />
                                <div className="pt-3">
                                    <p>If you would like to manage your orders or take additional actions, you can:</p>
                                    {isLoggedIn ? (
                                        <Button type="primary" onClick={() => isVendor ? history.push('/dashboard/inventory/vendor-orders') : history.push('/dashboard')}>
                                            Go to Your Panel
                                        </Button>
                                    ) : (
                                        <Button type="primary" onClick={() => history.push('/login')}>
                                            Login to Continue
                                        </Button>
                                    )}
                                </div>
                            </>
                        ) : (
                            successMessage ? (
                                <>
                                    <Alert
                                        message="Success"
                                        description={successMessage}
                                        type="success"
                                        showIcon
                                        className="mb-4"
                                    />
                                    <div className="pt-3">
                                        <p>If you would like to manage your orders or take additional actions, you can:</p>
                                        {isLoggedIn ? (
                                            <Button type="primary" onClick={() => isVendor ? history.push('/dashboard/inventory/vendor-orders') : history.push('/dashboard')}>
                                                Go to Your Panel
                                            </Button>
                                        ) : (
                                            <Button type="primary" onClick={() => history.push('/login')}>
                                                Login to Continue
                                            </Button>
                                        )}
                                    </div>
                                </>
                            ) : (
                                order ? (
                                    <>
                                        <div className="mb-4 text-lg">
                                            <div className="text-base"><strong>Inventory:</strong> {order.branch_inventory_group.name}</div>
                                            <div className="text-base pb-3"><strong>Quantity:</strong> {order.ordered_amount} {order.branch_inventory_group.unit_type}</div>
                                            <div className="text-base"><strong>Vendor Name:</strong> {order.inventory_vendor.name}</div>
                                            <div className="text-base"><strong>Order Date:</strong> {order.ordered_at}</div>
                                        </div>
                                        <Button type="primary" size="large" onClick={confirmOrder} loading={confirming}>
                                            Confirm Order
                                        </Button>
                                    </>
                                ) : (
                                    <Alert
                                        message="Error"
                                        description="No order found with this UUID."
                                        type="error"
                                        showIcon
                                    />
                                )
                            )
                        )}
                    </div>
                </div>
            </SkeletonWrapper>
        </motion.div>
    );
};

export default ConfirmVendorOrder;
