import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusCircle, faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import {Button, notification, Tag} from "antd";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TrainingNodeCreate from "./TrainingNodeCreate";
import Training from "../../../../http/Training";

const TrainingNode = ({ node, reload, isLastChild }) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(node.name.toString());
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [children, setChildren] = useState(node.children);

    useEffect(() => {
        setName(node.name);
        setSaving(false);
        setEditing(false);
        setChildren(node.children);
    }, [node]);

    const onSave = () => {
        setSaving(true);
        Training.Update(node.id, {name},(response) => {
            reload();
        }, (err) => {
            reload();
        });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newChildren = Array.from(children);
        const [removed] = newChildren.splice(result.source.index, 1);
        newChildren.splice(result.destination.index, 0, removed);

        setChildren(newChildren);

        const data = newChildren.reduce((acc, child, index) => {
            acc[child.id] = index;
            return acc;
        }, {});

        Training.UpdateOrder(data,
            (response) => { // Success callback
                notification['success']({
                    message: 'Order Updated!'
                });
            },
            (err) => { // Fail callback
                console.error('Order update failed:', err);
            }
        );

    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                className={`w-full flex flex-col border-l-4 border-black pl-5 pt-1 relative`}
            >

            {isLastChild?
                <div className={"absolute bg-white"} style={{left: "-7px", height: "13px", bottom: "0px", width: "10px"}}></div>
            :null}

                <div className={"absolute border-t-4 border-black"} style={{left: "0px", top: "17px", height: "4px", width: "20px"}}></div>


                    <div className={"flex flex-row items-center px-2 bg-gray-100 border border-gray-400 shadow-md"}>
                        {!editing ?
                            <>
                                <span className={"pr-2 whitespace-nowrap text-lg patty"}>
                                    {node.name}
                                </span>
                                {node.id > 1 &&
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="text-sm cursor-pointer"
                                                         onClick={() => {
                                                             setEditing(true)
                                                         }}/>
                                        <FontAwesomeIcon icon={faTrash} className="text-sm cursor-pointer ml-2"
                                                         onClick={() => {
                                                             setDeleting(true)
                                                         }}/>
                                    </>
                                }
                                <TrainingNodeCreate node={node} reload={reload}/>
                            </>
                            :
                            <div>
                                <input type={"text"} name={'name'} value={name}
                                       className={"w-64 border border-gray-400 px-2 uppercase font-bold"} placeholder={"Traning Name..."}
                                       onChange={(e) => setName(e.target.value)}/>
                                <Button type={"primary"} loading={saving}
                                        className={"ml-2 bg-gray-800 text-white px-5 py-0 rounded-sm "}
                                        onClick={onSave}>Save</Button>
                            </div>
                        }
                    </div>

                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={"pl-2 pb-0 mb-0"}
                        >
                            {children.map((child, index) => (
                                <Draggable key={child.id} draggableId={child.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className=""
                                        >
                                            <TrainingNode node={child} reload={reload} isLastChild={index === children.length - 1} />
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>



            </motion.div>
        </DragDropContext>

    );
};
export default TrainingNode;
