import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const BranchHour = {
    Manage: (branchId, successCb, failCb) => {
        axios.get(`/branches/${branchId}/hours/manage`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateAll: (branchId, data, successCb, failCb) => {
        axios.put(`/branches/${branchId}/hours/manage`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateStorefrontStatus: (branch_id, status, successCb, failCb) => {
        axios.put(`/branch/${branch_id}/storefront-status`, { status }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default BranchHour;
