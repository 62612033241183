import React from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";

const DashboardIndex = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                    <T upper>Dashboard</T>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-sm flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center py-6">
                        Welcome to your dashboard!
                    </div>
                </div>
            </div>
        </motion.div>

    );
};
export default DashboardIndex;
