import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const OptionApi = {

    Edit: (food_id, option_id, successCb, failCb) => {
        axios.get(`/food/${food_id}/option/${option_id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Update: (food_id, option_id, data, successCb, failCb) => {
        axios.put(`/food/${food_id}/option/${option_id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    UpdateItemOrder: (optionId, data, successCb, failCb) => {
        axios.post(`/option/${optionId}/items/reorder`, { items: data }, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    SetDefaultItem: (foodId, optionId, itemId, successCb, failCb) => {
        axios.post(`/food/${foodId}/option/${optionId}/set-default`, { item_id: itemId }, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default OptionApi;
