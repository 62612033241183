import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { notification, Pagination, Table as AntTable, Select, Button, Space } from 'antd';
import Employees from '../../../../../../http/Employees'; // Adjust the path as needed
import SkeletonWrapper from '../../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../../components/DefaultContainer';
import CouponComponent from "../../../Coupons/Generate/CouponComponent"; // Adjust the path as needed

const { Option } = Select;

const EmployeeCouponReports = ({ employee }) => {
    // Existing states
    const [coupons, setCoupons] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorter, setSorter] = useState({
        field: 'created_at',
        order: 'desc',
    });
    const [initialLoading, setInitialLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);

    // New filter states
    const [filterType, setFilterType] = useState(null); // 'percent' or 'balance'
    const [filterHasBalance, setFilterHasBalance] = useState(null); // true or false

    /**
     * Determine if the current fetch is the initial load.
     */
    const isInitialLoad = (current, pageSize, field, order, type, hasBalance) => {
        return (
            current === 1 &&
            pageSize === 10 &&
            field === 'created_at' &&
            order === 'desc' &&
            !type &&
            hasBalance === null
        );
    };

    /**
     * Fetch coupon reports from the backend.
     */
    const fetchCoupons = useCallback(() => {
        if (
            isInitialLoad(
                pagination.current,
                pagination.pageSize,
                sorter.field,
                sorter.order,
                filterType,
                filterHasBalance
            )
        ) {
            setInitialLoading(true);
        } else {
            setTableLoading(true);
        }

        // Prepare filter parameters
        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            sort_by: sorter.field,
            sort_direction: sorter.order,
        };

        if (filterType) {
            params.filter_type = filterType;
        }

        if (filterHasBalance !== null) {
            params.filter_has_balance = filterHasBalance;
        }

        Employees.couponReport(
            employee.id,
            params,
            (response) => {
                const fetchedCoupons = response.data.data.coupons.data;
                const totalCoupons = response.data.data.coupons.total;

                // Ensure coupons is always an array
                setCoupons(Array.isArray(fetchedCoupons) ? fetchedCoupons : []);
                setPagination((prev) => ({
                    ...prev,
                    total: typeof totalCoupons === 'number' ? totalCoupons : 0,
                }));

                if (
                    isInitialLoad(
                        pagination.current,
                        pagination.pageSize,
                        sorter.field,
                        sorter.order,
                        filterType,
                        filterHasBalance
                    )
                ) {
                    setInitialLoading(false);
                } else {
                    setTableLoading(false);
                }
            },
            (error) => {
                notification.error({
                    message: 'Error Fetching Coupons',
                    description:
                        error.response?.data?.message ||
                        'An error occurred while fetching coupon reports.',
                });
                if (
                    isInitialLoad(
                        pagination.current,
                        pagination.pageSize,
                        sorter.field,
                        sorter.order,
                        filterType,
                        filterHasBalance
                    )
                ) {
                    setInitialLoading(false);
                } else {
                    setTableLoading(false);
                }
            }
        );
    }, [
        employee.id,
        pagination.current,
        pagination.pageSize,
        sorter.field,
        sorter.order,
        filterType,
        filterHasBalance,
    ]);

    // Fetch coupons on component mount and whenever dependencies change
    useEffect(() => {
        fetchCoupons();
    }, [fetchCoupons]);

    /**
     * Handle table change events (pagination, sorting).
     *
     * @param {object} paginationConfig - Pagination configuration.
     * @param {object} filters - Filters applied.
     * @param {object} sorterConfig - Sorting configuration.
     */
    const handleTableChange = (paginationConfig, filters, sorterConfig) => {
        setPagination({
            ...pagination,
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
        });

        if (sorterConfig.order) {
            setSorter({
                field: sorterConfig.field,
                order: sorterConfig.order === 'ascend' ? 'asc' : 'desc',
            });
        } else {
            // Reset to default sorting if no sort order
            setSorter({
                field: 'created_at',
                order: 'desc',
            });
        }
    };

    /**
     * Handle page change.
     *
     * @param {number} page - Current page number.
     * @param {number} pageSize - Number of items per page.
     */
    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize: pageSize,
        }));
    };

    /**
     * Handle page size change.
     *
     * @param {number} current - Current page number.
     * @param {number} size - New page size.
     */
    const handlePageSizeChange = (current, size) => {
        setPagination((prev) => ({
            ...prev,
            current: 1, // Reset to first page when changing page size
            pageSize: size,
        }));
    };

    /**
     * Handle filter changes.
     */
    const handleFilterTypeChange = (value) => {
        setFilterType(value);
        setPagination((prev) => ({
            ...prev,
            current: 1, // Reset to first page when filters change
        }));
    };

    const handleFilterHasBalanceChange = (value) => {
        setFilterHasBalance(value);
        setPagination((prev) => ({
            ...prev,
            current: 1, // Reset to first page when filters change
        }));
    };

    /**
     * Clear all filters.
     */
    const clearFilters = () => {
        setFilterType(null);
        setFilterHasBalance(null);
        setPagination((prev) => ({
            ...prev,
            current: 1,
            pageSize: prev.pageSize, // Keep the same pageSize
        }));
    };

    /**
     * Define columns for Ant Design's Table.
     */
    const columns = useMemo(
        () => [
            {
                title: 'Coupon',
                render: (text, record) => <CouponComponent coupon={record} />,
            }
        ],
        [sorter]
    );

    return (
        <div>
            {/* Show skeleton only during initial load */}
            <SkeletonWrapper loading={initialLoading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <h2>Employee Coupon Reports</h2>

                    {/* Filtering Controls */}
                    <div className="filters-container" style={{ marginBottom: '20px' }}>
                        <Space size="middle">
                            <Select
                                allowClear
                                placeholder="Filter by Type"
                                style={{ width: 200 }}
                                value={filterType}
                                onChange={handleFilterTypeChange}
                            >
                                <Option value="percent">Percent</Option>
                                <Option value="balance">Balance</Option>
                            </Select>

                            <Select
                                allowClear
                                placeholder="Filter by Balance Status"
                                style={{ width: 200 }}
                                value={filterHasBalance}
                                onChange={handleFilterHasBalanceChange}
                            >
                                <Option value={true}>Has Balance</Option>
                                <Option value={false}>No Balance</Option>
                            </Select>

                            <Button onClick={clearFilters}>Clear Filters</Button>
                        </Space>
                    </div>

                    <AntTable
                        columns={columns}
                        dataSource={coupons}
                        pagination={false} // Disable internal pagination
                        rowKey="id"
                        onChange={handleTableChange}
                        loading={tableLoading} // Show table loading spinner during pagination/sorting
                        bordered
                        scroll={{ x: 'max-content' }} // Handle horizontal overflow
                    />
                    <div className="pagination-container">
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePageSizeChange}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100']}
                            showTotal={(total) => `Total ${total} coupons`}
                            showQuickJumper
                            style={{ marginTop: '20px', textAlign: 'right' }}
                        />
                    </div>
                </DefaultContainer>
            </SkeletonWrapper>
        </div>
    );
};

export default EmployeeCouponReports;
