import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faUserClock} from "@fortawesome/free-solid-svg-icons";

const InstaUser = ({username}) => {
    return (
        <div className="px-1 py-0.5 bg-blue-50 m-1 border border-blue-200 text-black text-xs rounded-md shadow font-bold">
            <FontAwesomeIcon icon={faUserClock} aria-hidden="true" className={"mr-1"} />
            {username}
        </div>
    );
};

export default InstaUser;