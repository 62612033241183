import React from 'react';
import {Link} from "react-router-dom";
import T from "../../../components/T";
import Can from "../../../layouts/Can";

const UserMenu = ({menu}) => {

    return (
        <div>

            <div className="flex flex-col py-5 hidden lg:block">
                <Link to={"/orders"} className={`uppercase py-2 my-1 px-20 rounded-l-xl hover:text-black hover:bg-white cursor-pointer block text-black ${menu == 'orders'? "bg-white font-extrabold text-base shadow-lg":"text-sm"}`}>
                    <T cap>Orders</T>
                </Link>
                <Link to={"/profile"} className={`uppercase py-2 my-1 px-20 rounded-l-xl hover:text-black hover:bg-white cursor-pointer block text-black ${menu == 'profile'? "bg-white font-extrabold text-base shadow-lg":"text-sm"}`}>
                    <T cap>Profile</T>
                </Link>

                <Link to={"/logout"} className="uppercase py-2 my-1 px-20 rounded-l-xl hover:text-black hover:bg-white text-sm cursor-pointer block text-black">
                    <T cap>Logout</T>
                </Link>
            </div>

            <div className="flex flex-row py-2 block lg:hidden">
                <Link to={"/orders"} className={`uppercase py-2 my-1 hover:bg-white hover:text-black cursor-pointer flex-1 text-center block rounded-l-xl text-black ${menu == 'orders'?"bg-white font-extrabold text-base  shadow-2xl":"bg-gray-200 text-sm"}`}>
                    <T cap>Orders</T>
                </Link>
                <Link to={"/profile"} className={`uppercase py-2 my-1 hover:bg-white hover:text-black cursor-pointer flex-1 text-center block text-black ${menu == 'profile'?"bg-white font-extrabold text-base shadow-2xl":"bg-gray-200 text-sm"}`}>
                    <T cap>Profile</T>
                </Link>

                <Link to={"/logout"} className={`uppercase py-2 my-1 hover:bg-white hover:text-black cursor-pointer flex-1 text-center rounded-r-xl  text-black block bg-gray-200 text-sm`}>
                    <T cap>Logout</T>
                </Link>
            </div>

        </div>
    );
};

export default UserMenu;