import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Task = {

    Store: (data, successCb, failCb) => {
        axios.post(`/task`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (task_id, data, successCb, failCb) => {
        axios.put(`/task/${task_id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (task_id, successCb, failCb) => {
        axios.delete(`/task/${task_id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Task;
