import actions from './actions';
import { updateAuthProfile } from '../authentication/actionCreator';
import Profile from '../../http/Profile';
import moment from 'moment';
import { notification } from 'antd';
import push from "../../history";

const {
  profilesEditReset,
  profilesEditBegin,
  profilesEditSuccess,
  profilesEditErr,

  profilesUpdateBegin,
  profilesUpdateSuccess,
  profilesUpdateErr,

  passwordEditReset,
  passwordUpdateBegin,
  passwordUpdateSuccess,
  passwordUpdateErr,

  ordersIndexBegin,
  ordersIndexSuccess,
  ordersIndexErr,

  requestsIndexBegin,
  requestsIndexSuccess,
  requestsIndexErr,

} = actions;


const resetEditProfile = () => {
  return async dispatch => {
    dispatch(profilesEditReset());
  };
};

const editProfile = () => {
  return async dispatch => {
    dispatch(profilesEditBegin());
    Profile.Edit("",(response) => {
      console.log(response.data);
      dispatch(profilesEditSuccess(response.data.data.profile))
    }, (err) => {
      console.log("ERROR", err.response?.data.message);
      dispatch(profilesEditErr(err.response?.data.message))
    });
  };
};

const resetEditPassword = () => {
  return async dispatch => {
    dispatch(passwordEditReset());
  };
};

const updatePassword = ({old_password, password, password_confirmation}) => {
  return async dispatch => {
    dispatch(passwordUpdateBegin());
    Profile.UpdatePassword(
      {old_password, password, password_confirmation},
      (response) => {
        dispatch(passwordUpdateSuccess());
        notification['success']({
          message: 'Password Changed.',
          description:
            `Your password has been updated successfully.`,
        });

      },
      (err) => {
        console.log(err);
        dispatch(passwordUpdateErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
      }
    );
  };
};

const updateProfile = ({name, phone, biography, skill, password, password_confirmation}) => {
  return async dispatch => {
    dispatch(profilesUpdateBegin());
    Profile.Update(
      {name, phone, biography, skill, password, password_confirmation},
      (response) => {
        const profile = response.data.data.profile;
        console.log("profile", profile);
        dispatch(profilesUpdateSuccess(profile));
        dispatch(updateAuthProfile(profile));
        notification['success']({
          message: 'Profile updated Successfully',
          description:
            `Your profile has been updated successfully.`,
        });

      },
      (err) => {
        console.log(err);
        dispatch(profilesUpdateErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
      }
    );
  };
};

const ListOrders = (page=0) => {
  return async dispatch => {
    dispatch(ordersIndexBegin());
    Profile.Orders(page, (response) => {
      dispatch(ordersIndexSuccess(response.data.data.orders))
    }, (err) => {
      if (!err.response) {
        push('/server-down', true)
      } else {
        dispatch(ordersIndexErr(err?.response?.data?.message ?? "No internet connection!"))
      }
    });
  };
};

const ListRequests = () => {
  return async dispatch => {
    dispatch(requestsIndexBegin());
    Profile.Requests( (response) => {
      dispatch(requestsIndexSuccess(response.data.data.requests))
    }, (err) => {
      if (!err.response) {
        push('/server-down', true)
      } else {
        dispatch(requestsIndexErr(err?.response?.data?.message ?? "No internet connection!"))
      }
    });
  };
};


export { resetEditProfile, editProfile, updateProfile, resetEditPassword, updatePassword, ListOrders, ListRequests};
