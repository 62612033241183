import React from 'react';
import {motion} from "framer-motion";
import Styled from "styled-components";
import {Collapse} from 'antd';
import {roundToTwo} from "../../../services/global";
import CheckoutItem from "./CheckoutItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMedal, faStar, faStop} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import T from "../../../components/T";
import {Translate} from "../../../helpers/Translate";

const CollapseWrapper = Styled.div`
    .ant-collapse-header {
        font-size:17px;
        font-weight:bold;
    }
`;

const OptionsWrapper = Styled.div`
    span {        
        margin-right:8px;
        color:black;
        font-weight:500;
    }
    
    i {
        padding-left:0px;
        color:gray;
        font-size:13px;
    }
`;

const {Panel} = Collapse;

const OrderRow = ({order, onEditOrder, onPay}) => {

    return (

        <div
            className={`${order.marked ? "bg-green-100" : "bg-white"} shadow-lg flex flex-col border border-gray-300 bg-gray-50 overflow-hidden w-full text-lg rounded-xl mb-2 p-2`}
        >
            <div className="flex flex-col p-2">
                <div className="font-bold flex flex-col sm:flex-row sm:justify-between items-center text-xl lg:text-2xl mb-2">
                    <div className="mb-2 lg:mb-0 uppercase">
                        {order.first_name}
                    </div>

                    <div className="flex flex-col sm:flex-row-reverse items-center">
                        <div className="flex flex-row items-center">
                            <div className=" h-8 bg-gray-200 px-3 flex flex-row justify-center items-center rounded-lg text-sm">
                                {order.payed_at_hr}
                            </div>

                            <div className="h-8 flex flex-row justify-center items-center uppercase ml-2 bg-green-100 rounded-lg text-sm font-bold px-3">
                                {Translate(order.type)}
                            </div>
                        </div>

                        <div className="hidden">
                            {order.points > 0? <div className={"text-sm flex flex-row items-center px-3 mx-2 py-1 h-8 bg-red-brand text-white rounded-lg"}><FontAwesomeIcon icon={faMedal} className="block text-sm mr-1" aria-hidden="true"/> {order.points}</div> : null}
                        </div>

                        <div className="my-2 sm:mr-3 sm:my-0 text-lg uppercase">
                            {order.payment_status != "success" ?
                                <div className="flex flex-row flex-1 justify-center lg:justify-end text-red-800 font-bold items-center">
                                    <FontAwesomeIcon icon={faStop} className="mr-1 text-xl" aria-hidden="true"/>
                                    <T cap>Not Paid</T>
                                </div>
                                :
                                <div className="flex flex-row flex-1 justify-center lg:justify-end text-green-800 font-bold items-center">
                                    <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl" aria-hidden="true"/>
                                    <T cap>Paid</T>
                                </div>
                            }
                        </div>


                    </div>

                </div>

                <Link to={`/order/${order.uuid}`} className="font-bold ml-0 py-2 bg-black text-white px-5 rounded-2xl text-base flex items-center justify-center">
                    <T upper>VIEW ORDER</T>
                </Link>
            </div>

            <div className="flex flex-col">
                <CollapseWrapper>
                    <Collapse accordion ghost expandIconPosition="right">
                        <Panel header={<>{order.items.reduce(function (sum, current) {
                            return sum + current.quantity;
                        }, 0)} <T>ITEMS</T>: ${roundToTwo(order.total)}</>} key="1" className="uppercase">
                            {order.items.map((item, index) => {
                                return <OptionsWrapper><CheckoutItem item={item}/></OptionsWrapper>
                            })}
                        </Panel>
                    </Collapse>
                </CollapseWrapper>
            </div>

        </div>
    );
};

export default OrderRow;
