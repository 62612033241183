import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import Bloggers from "../../../../http/Bloggers";
import Table from "ant-responsive-table";
import {Button, Pagination} from "antd";
import {SpinnerCircular} from "spinners-react";
import InviteActions from "./InviteActions";
import InviteeStatus from "./InviteeStatus";

const InviteesBloggers = () => {
    const dispatch = useDispatch();
    const [bloggers, setBloggers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perpage, setPerPage] = useState(10);

    useEffect(() => {
        fetchAcceptedBloggers(page, perpage);
    }, []);

    const fetchAcceptedBloggers = (p, pp) => {
        setLoading(true);
        Bloggers.FetchInvitedBloggers(p, pp, (response) => {
            setBloggers(response.data.data.bloggers)
            setLoading(false);
        }, (err) => {
            setBloggers(null);
            setLoading(false);
        });
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            sorter: (a, b) => a.username.localeCompare(b.username),
            key: 'username',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Biography',
            dataIndex: 'biography',
            key: 'biography',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Followers',
            dataIndex: 'follower_count',
            key: 'follower_count',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Followings',
            dataIndex: 'following_count',
            key: 'following_count',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Montreal',
            dataIndex: 'been_to_montreal',
            key: 'been_to_montreal',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Marker',
            dataIndex: 'marker',
            key: 'marker',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Inviter',
            dataIndex: 'inviter',
            key: 'inviter',
            showOnResponse: true,
            showOnDesktop: true
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true
        },];

    const BloggersDataSource = [];
    if (bloggers) {
        bloggers.data.map(blogger => {
            const {pk, username, biography, follower_count, following_count, category, been_to_montreal, marker, inviter} = blogger;

            return BloggersDataSource.push({
                key: pk,
                pk,
                username,
                biography: <div className="text-xs">{biography}</div>,
                follower_count,
                following_count,
                category,
                been_to_montreal: <div>{(been_to_montreal / 33 * 100).toFixed(0)}%</div>,
                marker: marker && <div className="flex flex-col items-center justify-center text-xs">
                            <div className="w-10 h-10 rounded-full border border-gray-200 flex flex-col items-center justify-center bg-gray-50">
                                <img src={marker.avatar.url} className="max-w-10 max-h-10 contain rounded-full" />
                            </div>
                            <div>
                                {marker.name}
                            </div>
                            <div className="whitespace-nowrap font-bold">{blogger.marked_at.split(" ")[0]}</div>

                        </div>,
                inviter: inviter && <div className="flex flex-col items-center justify-center text-xs">
                            <div className="w-10 h-10 rounded-full border border-gray-200 flex flex-col items-center justify-center bg-gray-50">
                                <img src={inviter.avatar.url} className="max-w-10 max-h-10 contain rounded-full" />
                            </div>
                            <div>
                                {inviter.name}
                            </div>
                            <div className="whitespace-nowrap font-bold">{blogger.invited_at.split(" ")[0]}</div>
                        </div>,
                status: (
                    <InviteeStatus blogger={blogger}/>
                ),
            });
        });
    }

    const onShowSizeChange = (current, size) => {
        console.log("perpage Changed", size);
        setPerPage(size)
        fetchAcceptedBloggers(1, size);
    }

    const onChange = (p, size) => {
        if(p != page) {
            setPage(p);
            fetchAcceptedBloggers(p, size);
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-3 items-center pr-2">
                    <div className="flex flex-row items-center"><T upper>INVITED BLOGGERS</T>
                        {loading?
                            <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="mr-4 h-5 ml-2"/>
                        :null}
                    </div>
                    <Can roles={["manage_bloggers"]}>
                        <FontAwesomeIcon icon={faPlus} className="rounded bg-black text-xl text-white px-2.5 py-2"/>
                    </Can>
                </div>
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="rounded-2xl flex-1 border shadow-xl p-3 flex flex-col items-center justify-center py-6 w-full">
                        {bloggers?
                            <div className="w-full">
                                <Table antTableProps={{dataSource: BloggersDataSource, columns: columns, pagination: false, showHeader: true}} mobileBreakPoint={1200} width={"100%"} style={{width: "100%"}}/>
                                <div className="mt-4 ml-4 flex flex-row items-center">
                                    <Pagination
                                        total={bloggers.total}
                                        showSizeChanger
                                        showTotal={total => `Total ${total} items`}
                                        defaultPageSize={bloggers.per_page}
                                        current={bloggers.current_page}
                                        onShowSizeChange={onShowSizeChange}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        :
                            <>Loading</>
                        }
                    </div>
                </div>
            </div>
        </motion.div>
    );
};
export default InviteesBloggers;
