import React from 'react';
import Avatar from "./components/Avatar";
import {faCrown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserWithAvatar = ({name, avatar, role, noborder}) => {
    return (
        <div className={`flex flex-row items-center whitespace-nowrap px-1 ${noborder?'':'m-1 bg-blue-50 rounded-lg border-blue-200 border'}`}>
            <Avatar url={avatar}/>
            {role == "accountable"?<FontAwesomeIcon icon={faCrown} className="text-sm mr-1" aria-hidden="true"/>:null}
            {name}
        </div>
    );
};

export default UserWithAvatar;