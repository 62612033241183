import React, {useEffect, useState} from 'react';
import T from "../../../../components/T";
import {motion} from "framer-motion";
import BigBang from "../../../../http/BigBang";
import {Button, Checkbox, notification, Select, Slider} from "antd";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import {faArrowRight, faArrowRightToCity, faCheck, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BigBangReport from "./BigBangReport";
import Parameter from "./Parameter";

const defaults = {
    'venues': 1,
    'poi': 2,
    'reviews': 1,
    'last_year_reviews': 2,
    'busy_score': 3,
    'poi_busy_score': 4,
    'poi_reviews': 2,
    'poi_last_year_reviews': 4,
    'm100': 15,
    'm200': 5,
    'm300': 2,
    'm500': 1,
    'min_distance': 150,
    'min_sample': 5,
    'zscore': 2,
};

const BigBangReports = () => {

    const [locations, setLocations] = useState(null);
    const [mcdonalds, set_mcdonalds] = useState(null);
    const [starbucks, set_starbucks] = useState(null);
    const [shakeshacks, set_shakeshacks] = useState(null);
    const [fiveguys, set_fiveguys] = useState(null);
    const [burgerkings, set_burgerkings] = useState(null);

    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedState, setSelectedState] = useState(0);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [creating, setCreating] = useState(false);
    const [settings, setSettings] = useState([]);
    const [editSetting, setEditSetting] = useState(null);

    const [venues, set_venues] = useState(defaults['venues']);
    const [poi, set_poi] = useState(defaults['poi']);
    const [reviews, set_reviews] = useState(defaults['reviews']);
    const [last_year_reviews, set_last_year_reviews] = useState(defaults['last_year_reviews']);
    const [busy_score, set_busy_score] = useState(defaults['busy_score']);
    const [poi_busy_score, set_poi_busy_score] = useState(defaults['poi_busy_score']);
    const [poi_reviews, set_poi_reviews] = useState(defaults['poi_reviews']);
    const [poi_last_year_reviews, set_poi_last_year_reviews] = useState(defaults['poi_last_year_reviews']);

    const [m100, set_m100] = useState(defaults['m100']);
    const [m200, set_m200] = useState(defaults['m200']);
    const [m300, set_m300] = useState(defaults['m300']);
    const [m500, set_m500] = useState(defaults['m500']);

    const [allPOIs, setAllPOIs] = useState(true);
    const [allmcdonalds, setAllmcdonalds] = useState(false);
    const [allstarbucks, setAllstarbucks] = useState(false);
    const [allfiveguys, setAllfiveguys] = useState(false);
    const [allshakeshacks, setAllshakeshacks] = useState(false);
    const [only24, set_only24] = useState(false);

    const reload = (data) => {
        resetVars();
        setLocations(data.locations);
        setSettings(data.settings);
        setSettingsLoaded(true);
        set_mcdonalds(data.mcdonalds);
        set_starbucks(data.starbucks);
        set_shakeshacks(data.shakeshacks);
        set_fiveguys(data.fiveguys);
        set_burgerkings(data.burgerkings);
    }

    useEffect(() => {
        BigBang.ReportsIndex((response) => {
            const data = response.data.data;
            reload(data);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }, []);

    const countryChange = (country_id) => {
        setSelectedCountry(country_id);
        setSelectedState(0);
        setSelectedCity(0);
    }

    const stateChange = (state_id) => {
        setSelectedState(state_id);
        setSelectedCity(0);
    }

    const cityChange = (city_id) => {
        setSelectedCity(city_id);
    }

    const addLocation = () => {
        if (selectedLocations.find(location => location.country_id == selectedCountry && location.state_id == selectedState && location.city_id == selectedCity)) return false;

        let loc = {};
        if (selectedCountry == 0) {
            loc['country_id'] = 0;
            loc['country'] = "All Countries";
        } else {
            loc['country_id'] = selectedCountry;
            const country = locations.find(c => c.country_id == selectedCountry);
            loc['country'] = country["country"];

            let state = null;
            if (selectedState == 0) {
                loc['state_id'] = 0;
                loc['state'] = "All States";
            } else {
                loc['state_id'] = selectedState;
                state = country.states.find(c => c.state_id == selectedState);
                loc['state'] = state['state'];
            }

            if (selectedCity == 0) {
                loc['city_id'] = 0;
                loc['city'] = "All Cities";
            } else {
                loc['city_id'] = selectedCity;
                const city = state.cities.find(c => c.city_id == selectedCity);
                loc['city'] = city['city'];
            }
        }

        setSelectedLocations([...selectedLocations, loc]);
    }

    const deleteLocation = (location) => {
        const updatedLocations = selectedLocations.filter(loc => loc.country_id !== location.country_id || loc.city_id !== location.city_id);
        setSelectedLocations(updatedLocations);
    }
    const resetVars = () => {
        setCreating(false);
        setSelectedLocations([]);
        setSelectedCountry(0);
        setSelectedState(0);
        setSelectedCity(0);
        setEditSetting(null);
        set_venues(defaults['venues']);
        set_poi(defaults['poi']);
        set_reviews(defaults['reviews']);
        set_last_year_reviews(defaults['last_year_reviews']);
        set_busy_score(defaults['busy_score']);
        set_poi_busy_score(defaults['poi_busy_score']);
        set_poi_reviews(defaults['poi_reviews']);
        set_poi_last_year_reviews(defaults['poi_last_year_reviews']);
        set_m100(defaults['m100']);
        set_m200(defaults['m200']);
        set_m300(defaults['m300']);
        set_m500(defaults['m500']);
        setAllPOIs(true);
        setAllmcdonalds(false);
        setAllstarbucks(false);
        setAllfiveguys(false);
        setAllshakeshacks(false);
        set_only24(false);
    }

    const edit = (setting) => {
        setSelectedLocations(setting.locations);
        set_venues(setting.score_parameters.venues);
        set_poi(setting.score_parameters.poi);
        set_reviews(setting.score_parameters.reviews);
        set_last_year_reviews(setting.score_parameters.last_year_reviews);
        set_busy_score(setting.score_parameters.busy_score);
        set_poi_busy_score(setting.score_parameters.poi_busy_score);
        set_poi_reviews(setting.score_parameters.poi_reviews);
        set_poi_last_year_reviews(setting.score_parameters.poi_last_year_reviews);
        set_m100(setting.score_parameters.m100);
        set_m200(setting.score_parameters.m200);
        set_m300(setting.score_parameters.m300);
        set_m500(setting.score_parameters.m500);
        setAllPOIs(setting.score_parameters.allPOIs);
        setAllmcdonalds(setting.score_parameters.allmcdonalds);
        setAllstarbucks(setting.score_parameters.allstarbucks);
        setAllfiveguys(setting.score_parameters.allfiveguys);
        setAllshakeshacks(setting.score_parameters.allshakeshacks);
        set_only24(setting.score_parameters.only24);
        setEditSetting(setting);
    }

    const cancelEdit = () => {
        resetVars();
    }

    const getReportSetting = () => {
        return {
            'locations': selectedLocations,
            venues,
            poi,
            reviews,
            last_year_reviews,
            busy_score,
            poi_busy_score,
            poi_reviews,
            poi_last_year_reviews,
            m100,
            m200,
            m300,
            m500,
            allPOIs,
            allmcdonalds,
            allstarbucks,
            allfiveguys,
            allshakeshacks,
            only24
        }
    }

    const updateReportSettings = () => {
        setCreating(true);

        BigBang.ReportSettingUpdate(editSetting.id, getReportSetting(), (response) => {
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    const createReportSettings = () => {
        setCreating(true);

        BigBang.ReportSettingCreate(getReportSetting(), (response) => {
            resetVars();
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            resetVars();
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    console.log("selectedLocations", selectedLocations);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                    <T upper>BIGBANG Reports</T>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <SkeletonWrapper loading={!settingsLoaded} skeleton={GenericSkeleton}>
                        {settingsLoaded &&
                            <div className={"flex flex-col w-full"}>
                                <div className={"rounded-sm flex-1 border-black border shadow-xl bg-white flex flex-col items-start justify-center"}>
                                    <div className={"text-base bg-gray-800 text-white py-1 w-full px-2"}>
                                        CREATE A REPORT SETTINGS
                                    </div>

                                    <div className={"px-2 pb-2 flex flex-col w-full"}>
                                        <div className={"pt-2 px-1"}>
                                            <div className={"flex flex-col"}>
                                                <div className={"flex flex-row items-center uppercase"}>
                                                    <div className={"flex flex-row items-center"}>
                                                        <div className={"font-bold mr-2"}>Country:</div>
                                                        <Select
                                                            key={'country'}
                                                            className={"w-48"}
                                                            showSearch={true}
                                                            onChange={countryChange}
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            defaultValue={0}
                                                            disabled={creating}
                                                            options={[{value: 0, label: "All Countries"}, ...locations.map((country) => {
                                                                return ({value: country["country_id"], label: country["country"]})
                                                            })]}
                                                        />
                                                    </div>

                                                    {selectedCountry > 0 &&
                                                        <div className={"flex flex-row items-center ml-4"}>
                                                            <div className={"font-bold mr-2"}>State:</div>
                                                            <Select
                                                                key={'state'}
                                                                className={"w-48"}
                                                                showSearch={true}
                                                                onChange={stateChange}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                defaultValue={0}
                                                                value={selectedState}
                                                                disabled={selectedCountry == 0}
                                                                options={selectedCountry > 0 ?
                                                                    [{value: 0, label: "All States"}, ...locations.filter(country => country.country_id == selectedCountry)[0].states.map((state) => {
                                                                        return ({value: state["state_id"], label: state["state"]})
                                                                    })] : [{value: 0, label: "All States"}]
                                                                }
                                                            />
                                                        </div>
                                                    }

                                                    {selectedState > 0 &&
                                                        <div className={"flex flex-row items-center ml-4"}>
                                                            <div className={"font-bold mr-2"}>City:</div>
                                                            <Select
                                                                key={'city'}
                                                                className={"w-48"}
                                                                showSearch={true}
                                                                onChange={cityChange}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                defaultValue={0}
                                                                value={selectedCity}
                                                                disabled={selectedState == 0}
                                                                options={selectedCountry > 0 && selectedState > 0 ?
                                                                    [{value: 0, label: "All Cities"}, ...locations.filter(country => country.country_id == selectedCountry)[0].states.filter(state => state.state_id == selectedState)[0].cities.map((city) => {
                                                                        return ({value: city["city_id"], label: city["city"]})
                                                                    })] : [{value: 0, label: "All Cities"}]
                                                                }
                                                            />
                                                        </div>
                                                    }

                                                    <div className={"ml-1"}>
                                                        <Button
                                                            type={"primary"}
                                                            onClick={addLocation}
                                                            disabled={creating}
                                                        >
                                                            ADD LOCATION
                                                        </Button>
                                                    </div>
                                                </div>

                                                {selectedLocations.length > 0 &&
                                                    <div className={"mt-2 flex flex-col"}>
                                                        {selectedLocations.map(location => {
                                                            return (
                                                                <div className={"m-1 p-1 bg-blue-100 flex flex-row items-center font-bold"}>
                                                                    <FontAwesomeIcon icon={faTrash} className="text-base mr-3 cursor-pointer ml-1 text-black" aria-hidden="true" onClick={() => deleteLocation(location)}/>
                                                                    {location.country}
                                                                    {location.state &&
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faArrowRight} className="text-base mx-3 cursor-pointer text-black" aria-hidden="true"/>
                                                                            {location.state}
                                                                        </span>
                                                                    }
                                                                    {location.city &&
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faArrowRight} className="text-base mx-3 cursor-pointer text-black" aria-hidden="true"/>
                                                                            {location.city}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {selectedLocations.length > 0 &&
                                            <div className={"p-2"}>
                                                <div className={"pr-3 font-bold"}>
                                                    TARGET
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <Checkbox onChange={
                                                        (e) => {
                                                            setAllPOIs(e.target.checked);
                                                            setAllmcdonalds(false);
                                                            setAllstarbucks(false);
                                                            setAllshakeshacks(false);
                                                            setAllfiveguys(false);
                                                        }}
                                                              checked={allPOIs}
                                                              className={"mr-4"}
                                                    >
                                                        ALL POIs
                                                    </Checkbox>
                                                    <Checkbox onChange={(e) => {
                                                        setAllmcdonalds(e.target.checked);
                                                        setAllPOIs(false);
                                                    }} checked={allmcdonalds} disabled={allPOIs} className={"mr-4"}>
                                                        McDonald's
                                                    </Checkbox>
                                                    <Checkbox onChange={(e) => {
                                                        setAllstarbucks(e.target.checked);
                                                        setAllPOIs(false);
                                                    }} checked={allstarbucks} disabled={allPOIs} className={"mr-4"}>
                                                        Starbucks
                                                    </Checkbox>
                                                    <Checkbox onChange={(e) => {
                                                        setAllshakeshacks(e.target.checked);
                                                        setAllPOIs(false);
                                                    }} checked={allshakeshacks} disabled={allPOIs} className={"mr-4"}>
                                                        Shake Shack
                                                    </Checkbox>
                                                    <Checkbox onChange={(e) => {
                                                        setAllfiveguys(e.target.checked);
                                                        setAllPOIs(false);
                                                    }} checked={allfiveguys} disabled={allPOIs} className={"mr-4"}>
                                                        FiveGuys
                                                    </Checkbox>
                                                </div>
                                                <div className={"flex pt-2 mt-2 border-t border-gray-200"}>
                                                    <Checkbox onChange={(e) => {
                                                        set_only24(e.target.checked);

                                                    }} checked={only24} className={"mr-4"}>
                                                        Only 24 Hours
                                                    </Checkbox>
                                                </div>
                                            </div>
                                        }

                                        {selectedLocations.length > 0 &&
                                            <div className={"mt-2 mx-1 border-t border-black pt-2 flex flex-col"}>

                                                <Parameter label={"Venues"} maxValue={10} value={venues} onSet={set_venues}/>
                                                <Parameter label={"POIs"} maxValue={10} value={poi} onSet={set_poi}/>
                                                <Parameter label={"reviews"} maxValue={10} value={reviews} onSet={set_reviews}/>
                                                <Parameter label={"last year reviews"} maxValue={10} value={last_year_reviews} onSet={set_last_year_reviews}/>
                                                <Parameter label={"busy score"} maxValue={10} value={busy_score} onSet={set_busy_score}/>
                                                <Parameter label={"poi busy score"} maxValue={10} value={poi_busy_score} onSet={set_poi_busy_score}/>
                                                <Parameter label={"poi reviews"} maxValue={10} value={poi_reviews} onSet={set_poi_reviews}/>
                                                <Parameter label={"poi last year reviews"} maxValue={10} value={poi_last_year_reviews} onSet={set_poi_last_year_reviews}/>
                                                <div className={"border-t border-gray-200 my-2 w-full"}/>
                                                <Parameter label={"100 meters"} maxValue={20} value={m100} onSet={set_m100}/>
                                                <Parameter label={"200 meters"} maxValue={20} value={m200} onSet={set_m200}/>
                                                <Parameter label={"300 meters"} maxValue={20} value={m300} onSet={set_m300}/>
                                                <Parameter label={"500 meters"} maxValue={20} value={m500} onSet={set_m500}/>
                                                {/*
                                                <div className={"border-t border-gray-200 my-2 w-full"} />
                                                <Parameter label={"min distance (meters)"} maxValue={500} minValue={1} value={min_distance} onSet={set_min_distance} />
                                                <Parameter label={"min cluster nodes"} maxValue={50} minValue={1} value={min_sample} onSet={set_min_sample} />
                                                <div className={"border-t border-gray-200 my-2 w-full"} />
                                                <Parameter label={"ZSCORE MARGIN"} maxValue={50} minValue={0} step={.1} value={min_sample} onSet={set_min_sample} />
                                                */}
                                            </div>
                                        }

                                        {selectedLocations.length > 0 &&
                                            <div className={"mt-2 mx-1 border-t border-black pt-2"}>
                                                {editSetting ?
                                                    <>
                                                        <Button
                                                            type={"primary"}
                                                            onClick={updateReportSettings}
                                                            loading={creating}
                                                        >
                                                            SAVE SETTINGS
                                                        </Button>
                                                        <Button
                                                            type={""}
                                                            onClick={cancelEdit}
                                                            className={"ml-2"}
                                                            loading={creating}
                                                        >
                                                            CANCEL
                                                        </Button>
                                                    </>
                                                    :
                                                    <Button
                                                        type={"primary"}
                                                        onClick={createReportSettings}
                                                        loading={creating}
                                                    >
                                                        CREATE REPORT SETTINGS
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </div>

                                </div>
                                {(settings && settings.length > 0) &&
                                    <div className={"flex flex-col w-full mt-3"}>
                                        {settings.map(setting => {
                                            return (<BigBangReport setting={setting} mcdonalds={mcdonalds} starbucks={starbucks} shakeshacks={shakeshacks} fiveguys={fiveguys} burgerkings={burgerkings} edit={edit} reload={reload}/>)
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </SkeletonWrapper>
                </div>
            </div>
        </motion.div>
    );
};

export default BigBangReports;