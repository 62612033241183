import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const BranchInventoryGroup = {
    Index: (successCb, failCb) => {
        axios.get(`/branch_inventory_groups`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/branch_inventory_groups/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/branch_inventory_groups/store`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.post(`/branch_inventory_groups/${id}/update`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (id, successCb, failCb) => {
        axios.delete(`/branch_inventory_groups/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    FetchInitialData: (successCb, failCb) => {
        axios.get(`/branch_inventories/fetch_initial_data`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    GetBranchInventoryGroups: (branchId, confirmation, successCb, failCb) => {
        axios.get(`/branch_inventory_groups/${branchId}/${confirmation}/end_of_day`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    SubmitEndOfDay: (branchId, data, confirmation, successCb, failCb) => {
        axios.post(`/branch_inventory_groups/${branchId}/${confirmation}/end_of_day`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    GetDailyRecords: (branchId, page = 1, perPage = 10, successCb, failCb) => {
        axios.get(`/branch_inventory_groups/${branchId}/daily-records`, {
            params: {
                page: page,
                perPage: perPage
            },
            ...getAuthenticationHeaders()
        })
        .then(response => {
            successCb(response);
        })
        .catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Sort: (groups, successCb, failCb) => {
        axios.post(`/branch_inventory_groups/sort`, { groups }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default BranchInventoryGroup;
