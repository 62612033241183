import actions from './actions';

const {
  SET_BRANCH,
} = actions;

const initState = {
  branch: JSON.parse(localStorage.getItem('branch'))
};

const DashboardReducer = (state = initState, action) => {
  const { type, branch } = action;
  switch (type)
  {

    case SET_BRANCH:
      localStorage.setItem('branch',  JSON.stringify(branch));
      return {
        ...state,
        branch
      };

    default:
      return state;

  }
};

export default DashboardReducer;
