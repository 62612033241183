import React, { useEffect } from 'react';
import { Card, Form, Input, Select, Switch, InputNumber, Space, Button, Row, Col, Upload, Tag, Popconfirm } from 'antd';
import { PlusOutlined, MinusCircleOutlined, UploadOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteFilled } from '@ant-design/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import FormItem from "../../../../components/Form/FormItem";

const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;

const DraggableField = ({ field, index, moveField, updateFieldState, removeField, error, isFirst, isLast }) => {

    const handleFieldChange = (key, value) => {
        updateFieldState(index, key, value);
    };

    const handleOptionChange = (optionIndex, value) => {
        const options = [...field.options];
        options[optionIndex] = value;
        updateFieldState(index, 'options', options);
    };

    const addOption = () => {
        const options = [...field.options, ''];
        updateFieldState(index, 'options', options);
    };

    const removeOption = (optionIndex) => {
        const options = field.options.filter((_, i) => i !== optionIndex);
        updateFieldState(index, 'options', options);
    };

    const handleTypeChange = (value) => {
        let updatedField = { ...field, type: value };
        if (['select', 'radio', 'checkbox'].includes(value) && !field.options) {
            updatedField.options = [''];
        }
        updateFieldState(index, 'type', value);
        if (['select', 'radio', 'checkbox'].includes(value)) {
            updateFieldState(index, 'options', updatedField.options);
        }
    };

    const renderFieldContent = () => {
        const commonFields = (
            <>
                <Row gutter={8}>
                    <Col span={12}>
                        <StyledFormItem
                            label="Field Label"
                            validateStatus={error.label ? 'error' : ''}
                            help={error.label ? error.label[0] : ''}
                        >
                            <Input
                                value={field.label}
                                onChange={(e) => handleFieldChange('label', e.target.value)}
                            />
                        </StyledFormItem>
                    </Col>
                    <Col span={12}>
                        <StyledFormItem
                            label="Field Description"
                            validateStatus={error.description ? 'error' : ''}
                            help={error.description ? error.description[0] : ''}
                        >
                            <Input.TextArea
                                value={field.description}
                                onChange={(e) => handleFieldChange('description', e.target.value)}
                            />
                        </StyledFormItem>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <StyledFormItem
                            label="Field Type"
                            validateStatus={error.type ? 'error' : ''}
                            help={error.type ? error.type[0] : ''}
                        >
                            <Select
                                value={field.type}
                                onChange={(value) => handleTypeChange(value)}
                            >
                                <Select.Option value="text">Text</Select.Option>
                                <Select.Option value="email">Email</Select.Option>
                                <Select.Option value="textarea">Textarea</Select.Option>
                                <Select.Option value="select">Select</Select.Option>
                                <Select.Option value="radio">Radio</Select.Option>
                                <Select.Option value="checkbox">Checkbox</Select.Option>
                                <Select.Option value="file">File</Select.Option>
                                <Select.Option value="divider">Divider</Select.Option>
                                <Select.Option value="title">Title</Select.Option>
                                <Select.Option value="description">Description</Select.Option>
                                <Select.Option value="image">Image</Select.Option>
                            </Select>
                        </StyledFormItem>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <StyledFormItem
                            label="Required"
                            validateStatus={error.required ? 'error' : ''}
                            help={error.required ? error.required[0] : ''}
                        >
                            <Switch
                                checked={field.required}
                                onChange={(checked) => handleFieldChange('required', checked)}
                            />
                        </StyledFormItem>
                    </Col>
                </Row>
            </>
        );

        switch (field.type) {
            case 'text':
            case 'email':
            case 'textarea':
            case 'select':
            case 'radio':
            case 'checkbox':
                return (
                    <>
                        {commonFields}
                        {(field.type === 'text' || field.type === 'textarea') && (
                            <Row gutter={8}>
                                <Col span={6}>
                                    <StyledFormItem
                                        label="Min Length"
                                        validateStatus={error.min ? 'error' : ''}
                                        help={error.min ? error.min[0] : ''}
                                    >
                                        <InputNumber
                                            value={field.min}
                                            onChange={(value) => handleFieldChange('min', value)}
                                            style={{ width: '100%' }}
                                        />
                                    </StyledFormItem>
                                </Col>
                                <Col span={6}>
                                    <StyledFormItem
                                        label="Max Length"
                                        validateStatus={error.max ? 'error' : ''}
                                        help={error.max ? error.max[0] : ''}
                                    >
                                        <InputNumber
                                            value={field.max}
                                            onChange={(value) => handleFieldChange('max', value)}
                                            style={{ width: '100%' }}
                                        />
                                    </StyledFormItem>
                                </Col>
                            </Row>
                        )}
                        {(field.type === 'select' || field.type === 'radio' || field.type === 'checkbox') && (
                            <StyledFormItem
                                label="Options"
                                validateStatus={error.options ? 'error' : ''}
                                help={error.options ? error.options[0] : ''}
                            >
                                <Space direction="vertical">
                                    {field.options && field.options.map((option, optionIndex) => (
                                        <Space key={optionIndex} align="baseline">
                                            <Input
                                                value={option}
                                                onChange={(e) => handleOptionChange(optionIndex, e.target.value)}
                                                style={{ width: '80%' }}
                                            />
                                            <MinusCircleOutlined onClick={() => removeOption(optionIndex)} />
                                        </Space>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={addOption}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Option
                                    </Button>
                                </Space>
                            </StyledFormItem>
                        )}
                    </>
                );
            case 'divider':
                return (
                    <div className="w-full text-center">
                        Divider
                    </div>
                );
            case 'title':
                return (
                    <StyledFormItem
                        label="Title Text"
                        validateStatus={error.label ? 'error' : ''}
                        help={error.label ? error.label[0] : ''}
                    >
                        <Input
                            value={field.label}
                            onChange={(e) => handleFieldChange('label', e.target.value)}
                        />
                    </StyledFormItem>
                );
            case 'description':
                return (
                    <StyledFormItem
                        label="Description Text"
                        validateStatus={error.label ? 'error' : ''}
                        help={error.label ? error.label[0] : ''}
                    >
                        <Input.TextArea
                            value={field.label}
                            onChange={(e) => handleFieldChange('label', e.target.value)}
                        />
                    </StyledFormItem>
                );
            case 'image':
            case 'file':
                return (
                    <>
                        {commonFields}
                        {field.type === 'image' && (
                            <StyledFormItem
                                label="Header Image"
                                validateStatus={error.image ? 'error' : ''}
                                help={error.image ? error.image[0] : ''}
                            >
                                <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                        handleFieldChange('image', file);
                                        handleFieldChange('image_path', URL.createObjectURL(file));
                                        return false;
                                    }}
                                >
                                    {field.image_path ? <img src={field.image_path} alt="header" style={{ width: '100%' }} /> : <UploadOutlined />}
                                </Upload>
                            </StyledFormItem>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <motion.div layout>
            <Card
                title={<div className={"patty text-3xl"}>{field.label}</div>}
                extra={
                    <div className={"flex flex-row items-center"}>
                        {!isFirst && <ArrowUpOutlined onClick={() => moveField(index, index - 1)} className={"mr-2"} />}
                        {!isLast && <ArrowDownOutlined onClick={() => moveField(index, index + 1)}  />}
                        <Popconfirm
                            title="Are you sure you want to delete this field?"
                            onConfirm={() => removeField(index)}
                            okText="Yes"
                            cancelText="No"
                            placement={"topLeft"}
                        >
                            <Button type="link" danger icon={<DeleteFilled />} />
                        </Popconfirm>
                    </div>
                }
                style={{ width: '100%', marginBottom: '10px' }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    {renderFieldContent()}
                </Space>
            </Card>
        </motion.div>
    );
};

export default DraggableField;
