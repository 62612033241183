import actions from './actions';

const {
    RESET_FOOD,
    SET_FOOD,
    SELECT_ITEM,
} = actions;

const initState = {
    food: null,
    selectedItems: [],
    price: 0
};

const MenuReducer = (state = initState, action) => {
    const {type, food, selectedItems, price} = action;
    switch (type)
    {
        case RESET_FOOD:
            return {
                ...state,
                food: null,
                selectedItems: [],
                price: 0
            };

        case SET_FOOD:
            return {
                ...state,
                food,
                selectedItems,
                price
            };
        case SELECT_ITEM:
            return {
                ...state,
                selectedItems,
                price
            };
        default:
            return state;
    }
};
export default MenuReducer;
