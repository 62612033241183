import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard, faClipboardCheck} from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";

const Clipboard = ({text, paste}) => {
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const copyToClipboard = () => {
        copy(text);
        setCopiedToClipboard(true);
        if(paste) paste(text);

        setTimeout(() => {setCopiedToClipboard(false)}, 3000);
    }

    return (
        <div className="text-2xl">
            {copiedToClipboard ?
                <FontAwesomeIcon icon={faClipboardCheck} className=" ml-2 text-black cursor-pointer" aria-hidden="true" onClick={copyToClipboard}/>
                :
                <FontAwesomeIcon icon={faClipboard} className=" ml-2 text-black cursor-pointer" aria-hidden="true" onClick={copyToClipboard}/>
            }
        </div>
    );
};

export default Clipboard;