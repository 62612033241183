import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Space, Radio, Checkbox, Select, Upload, Tooltip } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FormHandler from '../../../../http/FormHandler';
import { notification } from 'antd';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';

const { TextArea } = Input;

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 2px !important;
`;

const FormRenderer = () => {
    const { id } = useParams();
    const [form, setForm] = useState({ id: null, name: '', description: '', fields: [] });
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        if (id) {
            // Fetch the form details
            FormHandler.getForm(id, (response) => {
                setForm(response.data.data);
                setLoading(false);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleFieldChange = (fieldId, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [`fields_${fieldId}`]: value,
        }));
    };

    const handleCheckboxChange = (fieldId, checkedValues, options) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [`fields_${fieldId}`]: {
                selected: checkedValues,
                options: options,
            },
        }));
    };

    const handleFileChange = (fieldId, file) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [`fields_${fieldId}`]: file,
        }));
    };

    const handleSubmit = () => {
        const formData = new FormData();
        Object.entries(formValues).forEach(([key, value]) => {
            if (value instanceof File) {
                formData.append(key, value);
            } else if (Array.isArray(value)) {
                value.forEach((val, index) => {
                    formData.append(`${key}[${index}]`, val);
                });
            } else if (typeof value === 'object' && value !== null) {
                formData.append(`${key}[selected]`, JSON.stringify(value.selected));
                formData.append(`${key}[options]`, JSON.stringify(value.options));
            } else {
                formData.append(key, value);
            }
        });

        setSubmitting(true);
        FormHandler.submitForm(form.id, formData, (response) => {
            notification['success']({
                message: 'Success!',
                description: 'Form submitted successfully.',
            });
            setErrors({});
            setSubmitting(false);
            setFormSubmitted(true);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setErrors(err.response?.data.errors || {});
            setSubmitting(false);
        });
    };

    const renderField = (field) => {
        const formItemLayout = {
            labelCol: { span: 6 }, // Adjust the width of the label here
            wrapperCol: { span: 18 }, // Adjust the width of the input here
        };

        const labelWithTooltip = (
            <div className="flex flex-row items-center">
                {field.label}
                {field.description && (
                    <Tooltip title={field.description} className="cursor-pointer">
                        <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4 }} />
                    </Tooltip>
                )}
            </div>
        );

        switch (field.type) {
            case 'text':
            case 'email': // Add email case
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <Input
                            value={formValues[`fields_${field.id}`] || ''}
                            onChange={(e) => handleFieldChange(field.id, e.target.value)}
                        />
                    </StyledFormItem>
                );
            case 'textarea':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <TextArea
                            value={formValues[`fields_${field.id}`] || ''}
                            onChange={(e) => handleFieldChange(field.id, e.target.value)}
                        />
                    </StyledFormItem>
                );
            case 'select':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <Select
                            value={formValues[`fields_${field.id}`] || ''}
                            onChange={(value) => handleFieldChange(field.id, value)}
                        >
                            {field.options.map((option, index) => (
                                <Select.Option key={index} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </StyledFormItem>
                );
            case 'radio':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <Radio.Group
                            value={formValues[`fields_${field.id}`] || ''}
                            onChange={(e) => handleFieldChange(field.id, e.target.value)}
                        >
                            {field.options.map((option, index) => (
                                <Radio key={index} value={option}>
                                    {option}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </StyledFormItem>
                );
            case 'checkbox':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <Checkbox.Group
                            value={formValues[`fields_${field.id}`]?.selected || []}
                            onChange={(checkedValues) => handleCheckboxChange(field.id, checkedValues, field.options)}
                        >
                            {field.options.map((option, index) => (
                                <Checkbox key={index} value={option}>
                                    {option}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </StyledFormItem>
                );
            case 'file':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                        validateStatus={errors[`fields_${field.id}`] ? 'error' : ''}
                        help={errors[`fields_${field.id}`] && errors[`fields_${field.id}`][0]}
                    >
                        <Upload
                            beforeUpload={(file) => {
                                handleFileChange(field.id, file);
                                return false;
                            }}
                            multiple={false} // Ensure multiple file selection is not allowed
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </StyledFormItem>
                );
            case 'image':
                return (
                    <div key={field.id} className="image-field w-full flex flex-row items-center justify-center">
                        {field.image_path && <img src={field.image_path} alt={field.label} style={{ maxHeight: 100 }} />}
                    </div>
                );
            case 'divider':
                return <div key={field.id} className="divider border-t border-gray-300 -mx-3" />;
            case 'title':
                return (
                    <div key={field.id} className="font-bold text-2xl patty pb-0 mb-0">
                        {field.label}
                    </div>
                );
            case 'description':
                return (
                    <div key={field.id} className="description">
                        {field.label}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-4">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <SpinnerCircular size={100} thickness={50} speed={40} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)" />
                </div>
            ) : formSubmitted ? (
                <div className="container mx-auto border border-gray-400 rounded-lg p-4 bg-white text-center" style={{ maxWidth: '1000px' }}>
                    <h2 className="text-4xl font-bold pb-3 mb-0 pt-3">Thank You!</h2>
                    <p>Your form has been submitted successfully.</p>
                </div>
            ) : (
                <div className="container mx-auto border border-gray-400 rounded-lg p-4 bg-white" style={{ maxWidth: '1000px' }}>
                    <Form>
                        <StyledFormItem>
                            <h2 className="patty text-4xl font-bold pb-0 mb-0">{form.name}</h2>
                            <p>{form.description}</p>
                        </StyledFormItem>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {form.fields.map((field) => renderField(field))}
                        </Space>
                        <Button type="primary" onClick={handleSubmit} className="w-full mt-2" loading={submitting}>
                            Submit
                        </Button>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default FormRenderer;
