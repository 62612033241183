import Styled from "styled-components";

export const AuthWrapper = Styled.div`
    .ant-form-item-explain, .ant-form-item-control-input-content  {
        text-align:left;
    }
    .ant-form-item-label {
        padding-bottom:3px;
    }
    .ant-form-item-label label{
        font-size:15px;
        font-weight:bold;
        margin-top:0px;
    }      
    .ant-form-item-explain-error {
        text-align:left;
    }
    
    
`;

