import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Employees = {

    Index: (successCb, failCb) => {
        axios.get(`/employees`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/employees/${id}/edit`,getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/employees/store`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateInformation: (user_id, data, successCb, failCb) => {
        axios.post(`/employees/${user_id}/information`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateDepartments: (user_id, data, successCb, failCb) => {
        axios.post(`/employees/${user_id}/departments`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdatePermissions: (user_id, data, successCb, failCb) => {
        axios.post(`/employees/${user_id}/permissions`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SendPasswordReset: (user_id, successCb, failCb) => {
        axios.post(`/employees/${user_id}/send_password_reset`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SendInvitation: (user_id, successCb, failCb) => {
        axios.post(`/employees/${user_id}/send_invitation`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SendVerification: (user_id, successCb, failCb) => {
        axios.post(`/employees/${user_id}/send_verification`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Reinvite: (successCb, failCb) => {
        axios.post(`/employees/reinvite`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    couponReport: (employeeId, params, onSuccess, onError) => {
        axios.post(`/employees/${employeeId}/coupon-reports`, params, getAuthenticationHeaders())
            .then(response => {
                if (onSuccess) onSuccess(response);
            })
            .catch(error => {
                if (onError) onError(error);
            });
    },

};

export default Employees;