import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faBullseye, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import Deliverable from "../../../../../../../../http/Deliverable";
import {notification} from "antd";
import {SpinnerCircular} from "spinners-react";

const GoalComponent = ({deliverable, reload, canEdit}) => {

    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [goal, setGoal] = useState(null);

    const editGoal = () => {
        setGoal(deliverable.goal);
        setEditing(true);
    }

    const save = () => {
        setSaving(true);
        Deliverable.UpdateRequest(deliverable.id, {goal}, (r) => {
            reload();
            setSaving(false);
            setEditing(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.request?.data.message,
            });
            setSaving(false);
            setEditing(false);
        });
        reload();
    }

    return (
        editing ?
            <div className={"flex flex-row items-center mt-0.5 px-1 py-0.5"}>
                <input name={"response"} className={"flex-1 px-1 py-0.5 border border-gray-300"} value={goal} onChange={(e) => setGoal(e.target.value)} disabled={saving}/>
                {saving ?
                    <SpinnerCircular size={18} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"ml-1"}/>
                    :
                    <FontAwesomeIcon icon={faFloppyDisk} aria-hidden="true" className={"ml-1 text-base cursor-pointer"} onClick={() => save(deliverable)}/>
                }
            </div>
        :
            <div>
                <FontAwesomeIcon icon={faBullseye} aria-hidden="true" className={"mr-1"}/>
                <b>{goal?goal:deliverable.goal}</b>
                {canEdit && <FontAwesomeIcon icon={faEdit} aria-hidden="true" className={"ml-1 text-xs cursor-pointer"} onClick={() => editGoal(deliverable)}/>}
                <i>(By <b>{deliverable.goal_by.name}</b> {moment(deliverable.created_at).fromNow()})</i>
            </div>
    );
};

export default GoalComponent;