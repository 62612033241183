import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import store from './redux/store';
import axios from 'axios';
import {Provider} from 'react-redux';
import ScrollToTop from "./components/ScrollToTop";
import 'antd/dist/antd.min.css';
import './App.css';
import './App.less';
import Base from "./Base";
import {GlobalProvider} from "./components/GlobalContext";
import Pusher from 'pusher-js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.0.140';
axios.defaults.baseURL = window.location.hostname === '192.168.0.140' ? 'http://192.168.0.140:8000/api' : (isLocalhost ? 'http://127.0.0.1:8000/api' : 'https://pattyslapsapi.com/api');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

const App = () => {

    return (
        <DndProvider backend={HTML5Backend}>
            <GlobalProvider>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop>
                            <Base/>
                        </ScrollToTop>
                    </Router>
                </Provider>
            </GlobalProvider>
        </DndProvider>
    );
}

export default App;
