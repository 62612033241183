import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Survey = {
    GetQuestions: (branchId, successCb, failCb) => {
        axios.get(`/survey/${branchId}/questions`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Submit: (branch_guid, answers, comments, phone, email, checkout_id, successCb, failCb) => {
        let data = { branch_guid, answers, comments, phone, email };
        if (checkout_id && checkout_id > 0) {
            data[checkout_id] = checkout_id;
        }

        axios.post('/survey/submit', data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Search: (branchId, keyword, successCb, failCb) => {
        axios.get(`/branch/${branchId}/checkouts/search/${keyword}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    getSurveyComments: (branchId, dateRange = [], page = 1, pageSize = 10, questionKey = '', sortField = '', sortOrder = '', successCallback, errorCallback) => {
        let url = `/survey/${branchId}/comments?page=${page}&pageSize=${pageSize}`;
        if (dateRange.length > 0) {
            url += `&dateRange[]=${dateRange[0]}&dateRange[]=${dateRange[1]}`;
        }
        if (questionKey) {
            url += `&questionKey=${questionKey}`;
        }
        if (sortField) {
            url += `&sortField=${sortField}&sortOrder=${sortOrder}`;
        }
        axios.get(url, getAuthenticationHeaders())
            .then(successCallback)
            .catch(errorCallback);
    },

    getSurveyStatistics: (branchId, dateRange = [], successCallback, errorCallback) => {
        let url = `/survey/${branchId}/statistics`;
        if (dateRange.length > 0) {
            url += `?dateRange[]=${dateRange[0]}&dateRange[]=${dateRange[1]}`;
        }
        axios.get(url, getAuthenticationHeaders())
            .then(successCallback)
            .catch(errorCallback);
    }
};

export default Survey;
