import React from 'react';

const TypeTag = ({type}) => {
    return (
        <div className={`uppercase font-bold w-full text-center bg-gray-100 rounded-lg px-2 ${type == 'issue' && 'bg-red-200'} ${type == 'idea' && 'bg-green-200 text-black'} `}>
            {type}
        </div>
    );
};

export default TypeTag;