import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Button, Space } from 'antd';
import { SpinnerCircular } from 'spinners-react';
import FormHandler from '../../../../http/FormHandler';
import { notification } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import FormItem from '../../../../components/Form/FormItem';
import DraggableField from './DraggableField';
import { AnimatePresence, motion } from 'framer-motion';

const MemoDraggableField = React.memo(DraggableField);

const FormBuilder = () => {
    const { id } = useParams();
    const history = useHistory();
    const [form, setForm] = useState({ id: null, name: '', description: '' });
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (id) {
            // Fetch the form details if editing an existing form
            FormHandler.getForm(id, (response) => {
                setForm(response.data.data);
                setFields(response.data.data.fields);
                setLoading(false);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [id]);

    const addField = () => {
        const newField = {
            id: Date.now().toString(),
            label: '',
            type: 'text',
            options: [],
            required: false,
            min: null,
            max: null,
            order: fields.length,
        };
        setFields([...fields, newField]);
    };

    const moveField = (fromIndex, toIndex) => {
        const updatedFields = [...fields];
        const [movedField] = updatedFields.splice(fromIndex, 1);
        updatedFields.splice(toIndex, 0, movedField);

        // Update order property based on the updated position
        updatedFields.forEach((field, index) => {
            field.order = index;
        });

        setFields(updatedFields);
    };

    const updateFieldState = (index, key, value) => {
        const newFields = [...fields];
        newFields[index] = { ...newFields[index], [key]: value };
        setFields(newFields);
    };

    const removeField = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };

    const saveForm = () => {
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);

        fields.forEach((field, index) => {
            formData.append(`fields[${index}][id]`, field.id || '');
            formData.append(`fields[${index}][form_id]`, field.form_id || form.id || '');
            formData.append(`fields[${index}][label]`, field.label || '');
            formData.append(`fields[${index}][description]`, field.description || '');
            formData.append(`fields[${index}][type]`, field.type);
            formData.append(`fields[${index}][required]`, field.required ? '1' : '0');
            formData.append(`fields[${index}][min]`, field.min !== null ? field.min : '');
            formData.append(`fields[${index}][max]`, field.max !== null ? field.max : '');

            // Make sure to append the correct order
            formData.append(`fields[${index}][order]`, index);

            formData.append(`fields[${index}][options]`, JSON.stringify(field.options || []));
            if (field.type === 'image' && field.image) {
                formData.append(`fields[${index}][image]`, field.image);
            }
        });

        if (form.id) {
            setSaving(true);
            FormHandler.updateForm(form.id, formData, (response) => {
                notification['success']({
                    message: 'Success!',
                    description: 'Form updated successfully.',
                });
                setErrors({});
                setSaving(false);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                if (err.response?.data.errors) {
                    setErrors(err.response.data.errors);
                }
                setSaving(false);
            });
        } else {
            setSaving(true);
            FormHandler.createForm(formData, (response) => {
                setSaving(false);
                notification['success']({
                    message: 'Success!',
                    description: 'Form saved successfully.',
                });
                setForm((prevForm) => ({ ...prevForm, id: response.data.data.id }));
                setErrors({});
                history.push(`/dashboard/forms/edit-form/${response.data.data.id}`);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                if (err.response?.data.errors) {
                    setErrors(err.response.data.errors);
                }
                setSaving(false);
            });
        }
    };

    return (
        <div className="p-4 w-full rounded-md border-2 border-gray-500 bg-gray-100">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <SpinnerCircular size={100} thickness={50} speed={40} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)" />
                </div>
            ) : (
                <div>
                    <Form>
                        <FormItem
                            label="Form Name"
                            name="name"
                            errors={errors}
                            initialValue={form.name}
                            style={{ marginBottom: "10px", paddingBottom: "0px" }}
                        >
                            <Input
                                value={form.name}
                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                            />
                        </FormItem>
                        <FormItem
                            label="Form Description"
                            name="description"
                            errors={errors}
                            initialValue={form.description}
                            style={{ marginBottom: "0px", paddingBottom: "0px" }}
                        >
                            <Input.TextArea
                                value={form.description}
                                onChange={(e) => setForm({ ...form, description: e.target.value })}
                            />
                        </FormItem>
                        <Space direction="vertical" style={{ width: "100%", paddingTop: "12px" }}>
                            <AnimatePresence>
                                {fields.map((field, index) => (
                                    <motion.div
                                        key={field.id}
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <MemoDraggableField
                                            field={field}
                                            index={index}
                                            moveField={moveField}
                                            updateFieldState={updateFieldState}
                                            removeField={removeField}
                                            error={errors[`fields.${index}`] || {}}
                                            isFirst={index === 0}
                                            isLast={index === fields.length - 1}
                                        />
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                        </Space>
                        <Button type="dashed" onClick={addField} className="w-full mt-2">Add Field</Button>
                        <Button type="primary" onClick={saveForm} className="w-full mt-2" loading={saving}>Save Form</Button>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default FormBuilder;
