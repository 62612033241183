import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from "../../../../components/Form/FormItem";

const RoleModal = ({ visible, onCancel, onSave, editRole, saving, form, validationFields }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(editRole ? { name: editRole.name } : { name: '' });
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, editRole, form]);

    return (
        <Modal
            visible={visible}
            title={editRole ? "Edit Role" : "Add Role"}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={saving} onClick={() => form.submit()}>
                    {editRole ? "Update" : "Create"}
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={editRole ? { name: editRole.name } : { name: '' }}
                onFinish={values => onSave(values, !!editRole)}
            >
                <FormItem
                    name="name"
                    label="Role Name"
                    rules={[{ required: true, message: 'Please input the role name!' }]}
                    errors={validationFields}
                >
                    <Input ref={inputRef} />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default RoleModal;
