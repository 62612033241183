import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import {Button, notification, Popconfirm} from 'antd';
import CategoryApi from "../../../../../http/CategoryApi";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {RiProductHuntFill, RiProductHuntLine, RiImageLine, RiEdit2Line, RiDeleteBin6Line} from "@remixicon/react";
import {useHistory} from "react-router-dom";

const ManageCategories = () => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const reload = () => {
        setLoading(true);
        CategoryApi.Index(
            (response) => {
                setLoading(false);
                setCategories(response.data.data.categories); // Corrected according to your API structure
            },
            (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        reload();
    }, []);

    const onCategoryEdit = (category) => {
        history.push(`/dashboard/storefront/categories/${category.id}/edit`)
    };

    const handleDeleteCategory = (categoryId) => {
        CategoryApi.Delete(
            categoryId,
            () => {
                reload();
                notification['success']({
                    message: 'Success!',
                    description: 'Category deleted successfully!',
                });
            },
            (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            }
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedCategories = Array.from(categories);
        const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
        reorderedCategories.splice(result.destination.index, 0, movedCategory);
        setCategories(reorderedCategories);

        // Automatically call reorder after dragging
        saveOrder(reorderedCategories);
    };

    const saveOrder = (reorderedCategories) => {
        setSaving(true);
        CategoryApi.UpdateOrder(
            reorderedCategories.map((category, index) => ({id: category.id, order: index})),
            () => {
                notification['success']({
                    message: 'Success!',
                    description: 'Categories reordered successfully!',
                });
                setSaving(false);
            },
            (err) => {
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to save order.',
                });
                setSaving(false);
            }
        );
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold mb-4 flex justify-between items-center">
                    <span>Manage Categories</span>
                    <Button type="primary" icon={<FontAwesomeIcon icon={faPlusCircle}/>} className={"!hidden"}>
                        Add Category
                    </Button>
                </div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="categories">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="w-full">
                                    {categories.map((category, index) => (
                                        <Draggable key={category.id} draggableId={String(category.id)} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="category-card p-4 mb-2 bg-white shadow flex"
                                                >
                                                    {/* Category Image */}
                                                    <div className="category-image-box w-16 h-16 bg-gray-200 mr-4 flex-shrink-0 flex flex-col items-center justify-center">
                                                        {category.image ?
                                                            <img src={category.image} alt={category.name} className="w-full h-full object-cover"/>
                                                        :
                                                            <RiImageLine/>
                                                        }
                                                    </div>

                                                    {/* Category Content */}
                                                    <div className="flex-grow">
                                                        <h3 className="text-lg font-bold">{category.name}</h3>

                                                        <div className="mt-2">
                                                            {category.foods && category.foods.length > 0 ? (
                                                                category.foods.map(food => (
                                                                    <div key={food.id} className="flex items-center mb-1">
                                                                        {/* Food Image */}
                                                                        <div className={"w-12 flex flex-row items-center justify-center h-8"}>
                                                                            {food.image ?
                                                                                <img
                                                                                    src={food.image}
                                                                                    alt={food.name}
                                                                                    className="h-8 max-w-12 mr-2"
                                                                                />
                                                                                :
                                                                                <RiImageLine/>
                                                                            }
                                                                        </div>
                                                                        <span>{food.name}</span>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <span>No foods in this category</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Actions */}

                                                    <div className="ml-4 flex flex-row items-center justify-center">
                                                        <div className="ml-3">
                                                            <RiEdit2Line
                                                                onClick={() => onCategoryEdit(category)}
                                                                className="cursor-pointer"
                                                            />
                                                        </div>
                                                        {false &&
                                                            <Popconfirm
                                                                title="Sure to delete?"
                                                                placement={"right"}
                                                                onConfirm={() => handleDeleteCategory(category.id)}
                                                            >
                                                                <RiDeleteBin6Line className="cursor-pointer ml-2 text-red-600"/>
                                                            </Popconfirm>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </SkeletonWrapper>
                {saving && <p className="mt-4 text-center text-gray-500">Saving changes...</p>}
            </div>
        </motion.div>
    );
};

export default ManageCategories;
