import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectItem} from "../../redux/food/actionCreator";
import {getOption} from "../../services/food";
import T from "../../components/T";

const Item = ({item}) => {

    const dispatch = useDispatch();
    const food = useSelector(state => state.food.food);
    const selectedItems = useSelector(state => state.food.selectedItems);
    const options = useSelector(state => state.menu.index_options);
    const items = useSelector(state => state.menu.index_items);
    const [inStock, setInStock] = useState(false);
    const [enabled, setEnabled] = useState(true);


    useEffect(() => {
        const option = getOption(food, item.id);
        const foundOption = options.find(opt => opt.id === option.id);
        let isInStock = foundOption && foundOption.in_stock === 1;

        if(isInStock) {
            const foundSauceOption = options.find(opt => opt.name === "Sauces (Default " + item.name + ")");
            if(foundSauceOption) {
                isInStock = foundSauceOption.in_stock === 1;
            }
        }

        const foundItem = items.find(it => it.id === item.id);
        const _isInStock = foundItem && foundItem.in_stock === 1;
        const _enabled = foundItem && foundItem.status === 1;

        setInStock(isInStock && _isInStock);
        setEnabled(_enabled);

    }, [options, items]);

    const selectCurrentItem = () => {
        if(inStock)
            dispatch(selectItem(food, selectedItems, item.id));
    }

    return (
        enabled &&
        <div className={`${!inStock?'opacity-20':''} flex-1 border-2 border-red-500 cursor-pointer flex flex-row items-center justify-center text-xs md:text-base ${selectedItems.includes(item.id) ? 'bg-red-500 text-white' : ' text-black font-normal'}`}
             style={{padding: ".3vw", margin: ".3vw"}}
             onClick={selectCurrentItem}
        >
            <T cap_if_all_lowercase fr={item.name_fr}>{item.name}</T>
        </div>
    );
};

export default Item;
