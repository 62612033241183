import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from '../helpers/Translate';

const capitalizeFrenchWords = (str) => {
    const lowercaseWords = ['de', 'du', 'des', 'la', 'le', 'les', 'un', 'une', 'et', 'à', 'd\'', 'l\''];
    const specialCharacters = ['(', '[', '{', '"', '\''];

    return str.split(/(\s+|[\(\[\{\"\']+)/).map((word, index, words) => {
        if (specialCharacters.includes(word)) {
            return word;
        }

        const prevWord = index > 0 ? words[index - 1] : '';
        const prevChar = prevWord.slice(-1);

        if (specialCharacters.includes(prevChar) || index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }

        if (lowercaseWords.includes(word.toLowerCase())) {
            return word.toLowerCase();
        }

        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join('');
};

const T = ({ children, cap = false, upper = false, cap_if_all_lowercase = false, fr=null }) => {
    const text = React.Children.toArray(children).join('');

    var t = Translate(text, fr);

    if (cap) {
        return t.charAt(0).toUpperCase() + t.slice(1);
    }

    if (upper) {
        return t.toUpperCase();
    }

    if (cap_if_all_lowercase && t === t.toLowerCase()) {
        return capitalizeFrenchWords(t);
    }

    return t;
};

export default T;
