import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Button, notification } from 'antd';
import TemperatureZone from '../../../../../http/TemperatureZone';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';

const ListTemperatureZones = () => {
    const { branchId } = useParams();
    const [zones, setZones] = useState([]);
    const [branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadZones();
    }, [branchId]);

    const loadZones = () => {
        setLoading(true);
        TemperatureZone.Index(branchId, (response) => {
            setZones(response.data.data.zones);
            setBranch(response.data.data.branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    };

    const handleDelete = (zoneId) => {
        TemperatureZone.Destroy(branchId, zoneId, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Temperature Zone deleted successfully.',
            });
            loadZones();
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    };

    const columns = [
        {
            title: 'Zone Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Link to={`/dashboard/operations/setup/inventory/temperature-zone/${branch.id}/${record.id}`}>
                        <Button type="link">Edit</Button>
                    </Link>
                    <Button type="link" danger onClick={() => handleDelete(record.id)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            {branch &&
                <DefaultContainer>
                    <h2>Temperature Zones for {branch ? branch.name : 'Loading...'}</h2>
                    <Link to={`/dashboard/operations/setup/inventory/temperature-zones/${branch.id}/create`}>
                        <Button type="primary" style={{marginBottom: '20px'}}>Add Temperature Zone</Button>
                    </Link>
                    <Table
                        columns={columns}
                        dataSource={zones}
                        rowKey="id"
                        loading={loading}
                    />
                </DefaultContainer>
            }
        </SkeletonWrapper>
    );
};

export default ListTemperatureZones;