const actions = {
  CHECKOUT_RESET: 'CHECKOUT_RESET',

  CHECKOUT_STORE_RESET: 'CHECKOUT_STORE_RESET',
  CHECKOUT_STORE_BEGIN: 'CHECKOUT_STORE_BEGIN',
  CHECKOUT_STORE_SUCCESS: 'CHECKOUT_STORE_SUCCESS',
  CHECKOUT_STORE_ERROR: 'CHECKOUT_STORE_ERR',

  APPLY_COUPON_BEGIN: 'APPLY_COUPON_BEGIN',
  APPLY_COUPON_SUCCESS: 'APPLY_COUPON_SUCCESS',
  APPLY_COUPON_ERROR: 'APPLY_COUPON_ERR',

  CLEAR_COUPON_BEGIN: 'CLEAR_COUPON_BEGIN',
  CLEAR_COUPON_SUCCESS: 'CLEAR_COUPON_SUCCESS',
  CLEAR_COUPON_ERROR: 'CLEAR_COUPON_ERR',

  CREATE_PAYMENT_BEGIN: 'CREATE_PAYMENT_BEGIN',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_ERROR: 'CREATE_PAYMENT_ERR',

  CHECKOUT_VALIDATE_RESET: 'CHECKOUT_VALIDATE_RESET',
  CHECKOUT_VALIDATE_BEGIN: 'CHECKOUT_VALIDATE_BEGIN',
  CHECKOUT_VALIDATE_SUCCESS: 'CHECKOUT_VALIDATE_SUCCESS',
  CHECKOUT_VALIDATE_ERROR: 'CHECKOUT_VALIDATE_ERR',

  RESET_ACTIVE_ORDERS: 'RESET_ACTIVE_ORDERS',
  ACTIVE_ORDERS_INDEX_BEGIN: 'ACTIVE_ORDERS_INDEX_BEGIN',
  ACTIVE_ORDERS_INDEX_SUCCESS: 'ACTIVE_ORDERS_INDEX_SUCCESS',
  ACTIVE_ORDERS_INDEX_ERR: 'ACTIVE_ORDERS_INDEX_ERR',

  RESET_OFFLINE_ORDERS: 'RESET_OFFLINE_ORDERS',
  OFFLINE_ORDERS_INDEX_BEGIN: 'OFFLINE_ORDERS_INDEX_BEGIN',
  OFFLINE_ORDERS_INDEX_SUCCESS: 'OFFLINE_ORDERS_INDEX_SUCCESS',
  OFFLINE_ORDERS_INDEX_ERR: 'OFFLINE_ORDERS_INDEX_ERR',

  checkoutReset: () => {
    return {
      type: actions.CHECKOUT_RESET,
    };
  },

  checkoutStoreReset: () => {
    return {
      type: actions.CHECKOUT_STORE_RESET,
    };
  },


  checkoutStoreBegin: () => {
    return {
      type: actions.CHECKOUT_STORE_BEGIN,
    };
  },

  checkoutStoreSuccess: (checkout) => {
    return {
      type: actions.CHECKOUT_STORE_SUCCESS,
      checkout,
    };
  },

  checkoutStoreError: (errors) => {
    return {
      type: actions.CHECKOUT_STORE_ERROR,
      errors,
    };
  },



  applyCouponBegin: () => {
    return {
      type: actions.APPLY_COUPON_BEGIN,
    };
  },
  applyCouponSuccess: (message, checkout) => {
    return {
      type: actions.APPLY_COUPON_SUCCESS,
      message,
      checkout,
    };
  },
  applyCouponError: (message, errors) => {
    return {
      type: actions.APPLY_COUPON_ERROR,
      message,
      errors,
    };
  },



  clearCouponBegin: () => {
    return {
      type: actions.CLEAR_COUPON_BEGIN,
    };
  },
  clearCouponSuccess: (message, checkout) => {
    return {
      type: actions.CLEAR_COUPON_SUCCESS,
      message,
      checkout,
    };
  },
  clearCouponError: (message, errors) => {
    return {
      type: actions.CLEAR_COUPON_ERROR,
      message,
      errors,
    };
  },



  createPaymentBegin: () => {
    return {
      type: actions.CREATE_PAYMENT_BEGIN,
    };
  },

  createPaymentSuccess: (checkout) => {
    return {
      type: actions.CREATE_PAYMENT_SUCCESS,
      checkout,
    };
  },

  createPaymentError: (message, errors) => {
    return {
      type: actions.CREATE_PAYMENT_ERROR,
      message,
      errors,
    };
  },

  checkoutValidateReset: () => {
    return {
      type: actions.CHECKOUT_VALIDATE_RESET,
    };
  },

  checkoutValidateBegin: () => {
    return {
      type: actions.CHECKOUT_VALIDATE_BEGIN,
    };
  },

  checkoutValidateSuccess: (checkout, total_active_orders, message) => {
    return {
      type: actions.CHECKOUT_VALIDATE_SUCCESS,
      checkout,
      total_active_orders,
      message,
    };
  },

  checkoutValidateError: (error, fields) => {
    return {
      type: actions.CHECKOUT_VALIDATE_ERROR,
      error,
      fields,
    };
  },


  resetActiveOrders: () => {
    return {
      type: actions.RESET_ACTIVE_ORDERS,
    };
  },

  activeOrdersIndexBegin: () => {
    return {
      type: actions.ACTIVE_ORDERS_INDEX_BEGIN,
    };
  },

  activeOrdersIndexSuccess: (active_orders) => {
    return {
      type: actions.ACTIVE_ORDERS_INDEX_SUCCESS,
      active_orders
    };
  },

  activeOrdersIndexErr: (error, fields) => {
    return {
      type: actions.ACTIVE_ORDERS_INDEX_ERR,
      error,
      fields,
    };
  },


  resetOfflineOrders: () => {
    return {
      type: actions.RESET_OFFLINE_ORDERS,
    };
  },

  offlineOrdersIndexBegin: () => {
    return {
      type: actions.OFFLINE_ORDERS_INDEX_BEGIN,
    };
  },

  offlineOrdersIndexSuccess: (offline_orders, total_pending_points) => {
    return {
      type: actions.OFFLINE_ORDERS_INDEX_SUCCESS,
      offline_orders,
      total_pending_points
    };
  },

  offlineOrdersIndexErr: (error, fields) => {
    return {
      type: actions.OFFLINE_ORDERS_INDEX_ERR,
      error,
      fields,
    };
  },

};

export default actions;
