import React, {useEffect, useState} from 'react';
import {faEdit, faBullseye, faFloppyDisk, faForward, faPaperPlane, faStepForward, faUserClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import Division from "../../../../../../../http/Division";
import {notification} from "antd";
import Deliverable from "../../../../../../../http/Deliverable";
import {SpinnerCircular} from "spinners-react";
import Goal from "./Deliverable/Goal";
import Result from "./Deliverable/Result";
import {useSelector} from "react-redux";

const Deliverables = ({task_id, canEdit, deliverables, reload, readonly}) => {

    const [request, setRequest] = useState("");
    const [requesting, setRequesting] = useState(false);

    const [response, setResponse] = useState("");
    const [responding, setResponding] = useState(false);

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        setResponding(false);
        setResponse('');
        setRequesting(false);
        setRequest('');
    }, [deliverables]);

    const respond = (deliverable) => {
        if(response.length == 0) {
            notification['error']({
                message: 'Error!',
                description: "Please write a result.",
            });
            return false;
        }
        setResponding(true);
        Deliverable.Respond(deliverable.id, {result:response}, (r) => {
            reload();
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setResponding(false);
        });
    }

    const _request = () => {
        if(request.length == 0) {
            notification['error']({
                message: 'Error!',
                description: "Please write a goal.",
            });
            return false;
        }
        setRequesting(true);
        Deliverable.Request({task_id, goal:request}, (r) => {
            reload();
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.request?.data.message,
            });
            setRequesting(false);
        });
    }

    let _deliverables = deliverables;
    if(readonly && _deliverables.length > 1) {
        _deliverables = [_deliverables[_deliverables.length - 1]];
    }

    const editGoal = () => {

    }

    const editResult = () => {

    }

    console.log("deliverables:", deliverables);

    return (
        <div className={"flex flex-col text-xs"}>



            {_deliverables.map(deliverable => {return (
                <div className={"flex flex-col rounded-lg bg-gray-100 px-1 py-0.5 border border-gray-300 mb-1"}>
                    <Goal deliverable={deliverable} reload={reload} canEdit={canEdit} />
                    {deliverable.result?
                        <Result deliverable={deliverable} reload={reload} canEdit={canEdit} />
                    :
                        !readonly && canEdit &&
                        <div className={"flex flex-row items-center mt-0.5 px-1 py-0.5"}>
                            <input name={"response"} className={"flex-1 px-1 py-0.5 border border-gray-300"} value={response} onChange={(e) => setResponse(e.target.value)} disabled={responding} />
                            {responding?
                                <SpinnerCircular size={18} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"ml-1"}/>
                            :
                                <FontAwesomeIcon icon={faFloppyDisk} aria-hidden="true" className={"ml-1 text-base cursor-pointer"} onClick={() => respond(deliverable)}/>
                            }
                        </div>
                    }
                </div>
            )})}

            {(deliverables.length == 0 || (deliverables.length>0 && deliverables[deliverables.length-1].result != "")) && !readonly && canEdit ?
                <div className={"flex flex-col rounded-lg bg-gray-100 px-1 py-0.5 border border-gray-300 mb-1"}>
                    <div>
                        <div className={"flex flex-row items-center mt-0.5 px-1 py-0.5"}>
                            <FontAwesomeIcon icon={faBullseye} aria-hidden="true" className={"mr-1 cursor-pointer"} /> Notes:
                            <input name={"response"} className={"flex-1 px-1 py-0.5 border border-gray-300 ml-1"} value={request} onChange={(e) => setRequest(e.target.value)} disabled={requesting} />
                            {requesting?
                                <SpinnerCircular size={18} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"ml-1"}/>
                                :
                                <FontAwesomeIcon icon={faFloppyDisk} aria-hidden="true" className={"ml-1 text-base cursor-pointer"} onClick={_request}/>
                            }
                        </div>
                    </div>
                </div>
            : null}
        </div>
    );
};

export default Deliverables;