import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import GooglePlaces from "../../../../../http/GooglePlaces";
import {faMedal, faPlusCircle, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Input, notification, Select, Switch} from 'antd';
import OurReview from "./OurReview";
import {SpinnerCircular} from "spinners-react";
import {DatePicker} from 'antd';
import moment from 'moment';
import BaseReview from "../BaseReviews/BaseReview";
import {SmallDashOutlined} from "@ant-design/icons";

const {Option} = Select;

const OurReviewsIndex = () => {

    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState(null);
    const [selectedPlaceId, setSelectedPlaceId] = useState(() => {
        const storedValue = localStorage.getItem('selectedPlaceId');
        const parsedValue = parseInt(storedValue, 10);
        return !isNaN(parsedValue) ? parsedValue : null;
    });
    const [ourReviews, setOurReviews] = useState(null);
    const [ourReviewsLoading, setOurReviewsLoading] = useState(false);
    const [ourReviewsSmallLoading, setOurReviewsSmallLoading] = useState(false);

    const [totalReviews, setTotalReviews] = useState(5);
    const [generateText, setGenerateText] = useState(0);
    const [dateTime, setDateTime] = useState(null);
    const [rating, setRating] = useState(5);
    const [generating, setGenerating] = useState(false);

    const reload = () => {
        if (!places) setLoading(true);
        GooglePlaces.Index((response) => {
            setLoading(false);
            setPlaces(response.data.data.places);
            // After loading, check if the stored ID is still valid
            /*
            const validId = response.data.data.places.some(place => place.id === selectedPlaceId);
            if (!validId) {
                console.log("id is not valid!", parseInt(selectedPlaceId));
                setSelectedPlaceId(null);
                localStorage.removeItem('selectedPlaceId');
            }

             */
        }, (err) => {
            setLoading(false);
            console.error(err);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        // Restore selection from localStorage on component mount
        const storedSelection = localStorage.getItem('selectedPlaceId');
        if (storedSelection) {
            setSelectedPlaceId(storedSelection);
        }
    }, [places]);

    useEffect(() => {
        if (selectedPlaceId > 0) {
            console.log("Getting the reviews for google with id", selectedPlaceId);
            reloadGoogleOurReviews();
        } else {
            setOurReviews(null);
        }
    }, [selectedPlaceId]);


    const groupReviewsByDate = (reviews) => {
        if(!reviews) return null;
        const grouped = new Map();

        reviews.forEach((review) => {
            // Format the date as YYYY-MM-DD
            const date = review.should_post_at.split(' ')[0];
            if (!grouped.has(date)) {
                grouped.set(date, []);
            }
            grouped.get(date).push(review);
        });

        return grouped;
    };

    const [groupedReviews, setGroupedReviews] = useState(() => groupReviewsByDate(ourReviews));


    useEffect(() => {
        // Regroup reviews if they change
        setGroupedReviews(groupReviewsByDate(ourReviews));
    }, [ourReviews]);


    const handleChange = (event) => {
        const {value} = event.target;
        setSelectedPlaceId(value);
        localStorage.setItem('selectedPlaceId', value);
    };

    const reloadGoogleOurReviews = () => {
        if(!ourReviews)
            setOurReviewsLoading(true);
        else
            setOurReviewsSmallLoading(true);
        //setOurReviews(null)

        GooglePlaces.OurReviews(selectedPlaceId, (response) => {
            setOurReviewsLoading(false);
            setOurReviewsSmallLoading(false);
            setOurReviews(response.data.data.our_reviews);
        }, (err) => {
            setOurReviewsLoading(false);
            setOurReviewsSmallLoading(false);
            console.error(err);
        });
    }

    const disablePastDate = (current) => {
        return current && current < moment().startOf('day');
    };


    const onGenerate = () => {
        setGenerating(true);

        GooglePlaces.Generate(selectedPlaceId, totalReviews, generateText, dateTime, rating, (response) => {
            notification['success']({
                description: 'Reviews generated successfully.'
            });
            setOurReviews(response.data.data.our_reviews);
            setGenerating(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setGenerating(false);
        });
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full`}
        >

            <div className="flex flex-col mx-auto w-full">
                <div className="text-3xl font-bold text-black pl-1 flex flex-row mb-2 items-center patty uppercase">
                    Our Google Reviews
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            <div className={"flex flex-col justify-start w-full"}>
                                {places != null &&
                                    <div className={"w-full flex flex-col items-start"}>
                                        {places.length == 0 ?
                                            <div className={"text-gray-400"}>
                                                There are no google places.
                                            </div>
                                            :
                                            <div className={"flex flex-row items-center"}>
                                                <select value={selectedPlaceId} onChange={handleChange} disabled={loading} className={"border border-gray-500 px-2 py-2"}>
                                                    <option value="">Select a place</option>
                                                    {places.map(place => (
                                                        <option key={place.id} value={place.id}>{place.name} --- {place.place_id}</option>
                                                    ))}
                                                </select>

                                                {selectedPlaceId > 0 &&
                                                    <FontAwesomeIcon icon={faRefresh} className="block text-xl cursor-pointer ml-3" aria-hidden="true" onClick={reloadGoogleOurReviews}/>
                                                }

                                                {ourReviewsSmallLoading &&
                                                    <SpinnerCircular size={20} thickness={200} speed={80} color="rgba(255, 0, 0, .4)" className={"ml-2"} secondaryColor="rgba(0, 0, 0, 0.14)"/>
                                                }

                                            </div>
                                        }
                                    </div>
                                }
                                <div className={"mt-2"}>

                                    {selectedPlaceId > 0 &&
                                        <div className={"bg-gray-100 rounded-md border border-gray-300 shadow px-2 py-2 flex flex-row items-center"}>
                                            <div className={" flex flex-row items-center"}>
                                                <div className={"mr-2"}>
                                                    Reviews To Generate:
                                                </div>
                                                <Input disabled={generating} type={"number"} style={{width: 65}} defaultValue={5} onChange={e => setTotalReviews(e.target.value)}/>
                                            </div>
                                            <div className={"mx-2 bg-gray-200 rounded-lg px-2 py-1 flex flex-row items-center"}>
                                                <span className="mx-2">Generate Texts:</span>
                                                <Switch disabled={generating} onChange={checked => setGenerateText(checked)}/>
                                            </div>
                                            <DatePicker disabled={generating} disabledDate={disablePastDate} onChange={(date, dateString) => {setDateTime(dateString);}}/>
                                            <div className={"ml-2 bg-gray-200 rounded-lg px-2 py-0.5 flex flex-row items-center"}>
                                                <span className={"pr-1"}>Rating:</span>
                                                <Select defaultValue={rating} onChange={value => setRating(value)}>
                                                    {[1, 2, 3, 4, 5].map(num => (
                                                        <Option key={num} value={num}>{num}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <Button loading={generating} type={"primary"} className={"ml-2"} onClick={onGenerate}>Generate</Button>
                                        </div>
                                    }

                                    <div className={"mt-2"}>
                                        {ourReviews && ourReviews.length > 0 && groupedReviews?
                                            <div>
                                                {Array.from(groupedReviews.entries()).map(([date, reviews]) => (
                                                    <div key={date} className={"border-2 border-gray-800 shadow-lg mb-3 rounded-lg"}>
                                                        <div className={"mb-2 border-b-2 border-gray-800 rounded-t-md bg-gray-900 text-white text-left patty text-xl py-0 pl-2"}>{date} ({reviews.length} reviews)</div>
                                                        <div className={"flex flex-col pb-1 px-2"}>
                                                            {reviews.map((review) => (
                                                                <OurReview key={review.id} review={review} setOurReviews={setOurReviews} reload={reloadGoogleOurReviews} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className={"text-gray-400"}>
                                                {selectedPlaceId > 0 ?
                                                    <>
                                                        {ourReviewsLoading ?
                                                            <>
                                                                <div className="py-24 w-full flex flex-col items-center justify-center opacity-70">
                                                                    <SpinnerCircular size={200} thickness={100} speed={80} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)"/>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                There are no reviews generated for this place.
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>Please select a place.</>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>

    );
};

export default OurReviewsIndex;