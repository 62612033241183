import React from 'react';
import {withGoogleMap, GoogleMap, Polygon, Marker, OverlayView} from 'react-google-maps';

const colors = {
    'normies': '#CCCCCC',
    'hipsters': '#FFC924',
    'rich': '#2BDE73',
    'uni': '#1C5182',
    'tourists': '#FF4742',
    'suits': '#42A5FF'
}

const GoogleMapComponent = withGoogleMap(({clusters, zoom, center}) => (

    <GoogleMap
        defaultZoom={10}
        defaultCenter={{lat: 45.5591827, lng: -73.7118733}} // Set the initial center of the map
        zoom={zoom} // Use the passed zoom prop
        center={center} // Use the passed center prop
    >
        {clusters.map((cluster, index) => (
            <React.Fragment key={index}>

                <Polygon
                    key={index}
                    path={[
                        {lng: parseFloat(cluster.longitude), lat: parseFloat(cluster.latitude)},
                        {lng: parseFloat(cluster.longitude), lat: parseFloat(cluster.latitude)+.01},
                        {lng: parseFloat(cluster.longitude)+.01, lat: parseFloat(cluster.latitude)+.01},
                        {lng: parseFloat(cluster.longitude)+.01, lat: parseFloat(cluster.latitude)},
                        {lng: parseFloat(cluster.longitude), lat: parseFloat(cluster.latitude)}
                    ]}
                    options={{
                        fillColor: colors[cluster.category],
                        fillOpacity: .05,
                        strokeWeight: 0,
                    }}
                />

            </React.Fragment>
        ))}

    </GoogleMap>
));

export default GoogleMapComponent;
