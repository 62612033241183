import React, {useEffect, useState} from 'react';
import T from "../../../../../components/T";
import {motion} from "framer-motion";
import Irani from "../../../../../http/Irani";
import {Button, notification, Select} from "antd";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import {faArrowRight, faArrowRightToCity, faCheck, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IraniContextReport from "./IraniContextReport";

const IraniContextReports = () => {

    const [locations, setLocations] = useState(null);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [creating, setCreating] = useState(false);
    const [settings, setSettings] = useState([]);
    const [editSetting, setEditSetting] = useState(null);

    const reload = (data) => {
        resetVars();
        setLocations(data.locations);
        setSettings(data.settings);
        setSettingsLoaded(true);
    }

    useEffect(() => {
        Irani.ContextReportsIndex((response) => {
            const data = response.data.data;
            reload(data);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }, []);

    const countryChange = (country_id) => {
        setSelectedCountry(country_id);
        setSelectedCity(0);
    }

    const cityChange = (city_id) => {
        setSelectedCity(city_id);
    }

    const addLocation = () => {
        if (selectedLocations.find(location => location.country_id == selectedCountry && location.city_id == selectedCity)) return false;

        let loc = {};
        if (selectedCountry == 0) {
            loc['country_id'] = 0;
            loc['country'] = "All Countries";
        } else {
            loc['country_id'] = selectedCountry;
            const country = locations.find(c => c.country_id == selectedCountry);
            loc['country'] = country["country"];

            if (selectedCity == 0) {
                loc['city_id'] = 0;
                loc['city'] = "All Cities";
            } else {
                loc['city_id'] = selectedCity;
                const city = country.cities.find(c => c.city_id == selectedCity);
                loc['city'] = city['city'];
            }
        }

        setSelectedLocations([...selectedLocations, loc]);
    }

    const deleteLocation = (location) => {
        const updatedLocations = selectedLocations.filter(loc => loc.country_id !== location.country_id || loc.city_id !== location.city_id);
        setSelectedLocations(updatedLocations);
    }
    const resetVars = () => {
        setCreating(false);
        setSelectedLocations([]);
        setSelectedCountry(0);
        setSelectedCity(null);
        setEditSetting(null);
    }

    const edit = (setting) => {
        setSelectedLocations(setting.locations);
        setEditSetting(setting);
    }

    const cancelEdit = () => {
        resetVars();
    }

    const updateReportSettings = () => {
        setCreating(true);

        let reportSetting = {'locations': selectedLocations};

        Irani.ContextReportSettingUpdate(editSetting.id, reportSetting, (response) => {
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    const createReportSettings = () => {
        setCreating(true);

        let reportSetting = {locations: selectedLocations};

        Irani.ContextReportSettingCreate(reportSetting, (response) => {
            resetVars();
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            resetVars();
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    console.log("selectedLocations", selectedLocations);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                    <T upper>Irani Context Reports</T>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <SkeletonWrapper loading={!settingsLoaded} skeleton={GenericSkeleton}>
                        {settingsLoaded &&
                            <div className={"flex flex-col w-full"}>
                                <div className={"rounded-sm flex-1 border-black border shadow-xl bg-white flex flex-col items-start justify-center"}>
                                    <div className={"text-base bg-gray-800 text-white py-1 w-full px-2"}>
                                        CREATE A CONTEXT REPORT SETTINGS
                                    </div>

                                    <div className={"px-2 pb-2 flex flex-col w-full"}>
                                        <div className={"pt-2 px-1"}>
                                            <div className={"flex flex-col"}>
                                                <div className={"flex flex-row items-center uppercase"}>
                                                    <div className={"flex flex-row items-center"}>
                                                        <div className={"font-bold mr-2"}>Country:</div>
                                                        <Select
                                                            key={'country'}
                                                            className={"w-48"}
                                                            showSearch={true}
                                                            onChange={countryChange}
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            defaultValue={0}
                                                            disabled={creating}
                                                            options={[{value: 0, label: "All Countries"}, ...locations.map((country) => {
                                                                return ({value: country["country_id"], label: country["country"]})
                                                            })]}
                                                        />
                                                    </div>

                                                    {selectedCountry > 0 &&
                                                        <div className={"flex flex-row items-center ml-4"}>
                                                            <div className={"font-bold mr-2"}>City:</div>
                                                            <Select
                                                                key={'city'}
                                                                className={"w-48"}
                                                                showSearch={true}
                                                                onChange={cityChange}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                defaultValue={0}
                                                                value={selectedCity}
                                                                options={[{value: 0, label: "All Cities"}, ...locations.filter(country => country.country_id == selectedCountry)[0].cities.map((city) => {
                                                                    return ({value: city["city_id"], label: city["city"]})
                                                                })]}
                                                            />
                                                        </div>
                                                    }

                                                    <div className={"ml-1"}>
                                                        <Button
                                                            type={"primary"}
                                                            onClick={addLocation}
                                                            disabled={creating}
                                                        >
                                                            ADD LOCATION
                                                        </Button>
                                                    </div>
                                                </div>

                                                {selectedLocations.length > 0 &&
                                                    <div className={"mt-2 flex flex-col"}>
                                                        {selectedLocations.map(location => {
                                                            return (
                                                                <div className={"m-1 p-1 bg-blue-100 flex flex-row items-center font-bold"}>
                                                                    <FontAwesomeIcon icon={faTrash} className="text-base mr-3 cursor-pointer ml-1 text-black" aria-hidden="true" onClick={() => deleteLocation(location)}/>
                                                                    {location.country}
                                                                    {location.city &&
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faArrowRight} className="text-base mx-3 cursor-pointer text-black" aria-hidden="true"/>
                                                                            {location.city}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {selectedLocations.length > 0 &&
                                            <div className={"mt-2 mx-1 border-t border-black pt-2"}>
                                                {editSetting ?
                                                    <>
                                                        <Button
                                                            type={"primary"}
                                                            onClick={updateReportSettings}
                                                            loading={creating}
                                                        >
                                                            SAVE SETTINGS
                                                        </Button>
                                                        <Button
                                                            type={""}
                                                            onClick={cancelEdit}
                                                            className={"ml-2"}
                                                            loading={creating}
                                                        >
                                                            CANCEL
                                                        </Button>
                                                    </>
                                                    :
                                                    <Button
                                                        type={"primary"}
                                                        onClick={createReportSettings}
                                                        loading={creating}
                                                    >
                                                        CREATE REPORT SETTINGS
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                {(settings && settings.length > 0) &&
                                    <div className={"flex flex-col w-full mt-3"}>
                                        {settings.map(setting => {
                                            return (<IraniContextReport setting={setting} edit={edit} reload={reload}/>)
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </SkeletonWrapper>
                </div>
            </div>
        </motion.div>
    );
};

export default IraniContextReports;