import React, {useState} from 'react';
import Coefficients from "../../../../http/Coefficients";
import {Button, notification} from "antd";

const RecalculateAllCoefficients = () => {
    const [recalculating, setRecalculating] = useState(false);

    const handleRecalculate = () => {
        setRecalculating(true);
        Coefficients.recalculateCoefficients(0, (response) => {
            notification.success({
                message: 'Success',
                description: response.data?.data?.message,
            });
            setRecalculating(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to recalculate coefficients',
            });
            setRecalculating(false);
        });
    };

    return (
        <div className={"w-full my-2"}>
            <Button type="primary" onClick={handleRecalculate} loading={recalculating}>
                Recalculate All Coefficients for All Branches
            </Button>
        </div>
    );
};

export default RecalculateAllCoefficients;