import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputNumber, Button, notification } from 'antd';
import BranchHour from '../../../../http/BranchHour';
import DefaultContainer from '../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import styled from 'styled-components';

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 2px;
`;

const ManageBranchHours = () => {
    const { branchId } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [branch, setBranch] = useState(null);
    const [statusLoading, setStatusLoading] = useState(false); // Storefront status loading

    useEffect(() => {
        loadBranchHours();
    }, [branchId]);

    const loadBranchHours = () => {
        setLoading(true);
        BranchHour.Manage(branchId, (response) => {
            const { branch, hours } = response.data.data;
            const hoursByDay = {};
            hours.forEach(hour => {
                hoursByDay[hour.day_of_week] = hour;
            });
            const initialValues = daysOfWeek.map(day => ({
                day_of_week: day,
                start_hour: hoursByDay[day]?.start_hour || 0,
                end_hour: hoursByDay[day]?.end_hour || 24,
            }));

            form.setFieldsValue({ hours: initialValues });
            setBranch(branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load branch hours.',
            });
            setLoading(false);
        });
    };

    const onFinish = (values) => {
        setSaving(true);
        BranchHour.UpdateAll(branchId, values, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Branch hours updated successfully.',
            });
            setSaving(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to update branch hours.',
            });
            setSaving(false);
        });
    };

    // Set storefront status directly (open or close)
    const setStorefrontStatus = (status) => {
        setStatusLoading(true);
        BranchHour.UpdateStorefrontStatus(branchId, status, (response) => {
            setBranch(prev => ({ ...prev, storefront_status: status }));
            notification.success({
                message: 'Success!',
                description: `Storefront has been ${status === 1 ? 'opened' : 'closed'} successfully.`,
            });
            setStatusLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: `Failed to update storefront status.`,
            });
            setStatusLoading(false);
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2 className="text-2xl font-bold mb-4">Manage Branch Hours for {branch ? branch.name : 'Loading...'}</h2>

                {/* Storefront status buttons */}
                {branch && (
                    <div className="mb-5">
                        <h3 className="text-xl font-semibold">Storefront Status: {branch.storefront_status === 1 ? 'OPEN' : 'CLOSED'}</h3>
                        <div className="mt-3">
                            <Button
                                type="primary"
                                className="mr-3"
                                loading={statusLoading && branch.storefront_status === 0}
                                onClick={() => setStorefrontStatus(1)}
                                disabled={branch.storefront_status === 1}
                            >
                                Open Storefront
                            </Button>
                            <Button
                                danger
                                loading={statusLoading && branch.storefront_status === 1}
                                onClick={() => setStorefrontStatus(0)}
                                disabled={branch.storefront_status === 0}
                            >
                                Close Storefront
                            </Button>
                        </div>
                    </div>
                )}

                <Form form={form} onFinish={onFinish} layout="vertical" initialValues={{ hours: [] }}>
                    {daysOfWeek.map((day, index) => (
                        <div key={day} className="bg-white p-2 mb-3 rounded-md shadow-sm border border-gray-200">
                            <h3 className="text-lg font-semibold mr-3">{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                            <StyledFormItem
                                name={['hours', index, 'day_of_week']}
                                initialValue={day}
                                hidden
                            >
                                <InputNumber />
                            </StyledFormItem>
                            <div className={"flex flex-row items-center gap-3"}>
                                <StyledFormItem
                                    name={['hours', index, 'start_hour']}
                                    label="Start Hour"
                                    rules={[{ required: true, message: 'Please enter the start hour' }]}
                                >
                                    <InputNumber min={0} max={23} placeholder="Enter start hour (0-23)" />
                                </StyledFormItem>
                                <StyledFormItem
                                    name={['hours', index, 'end_hour']}
                                    label="End Hour"
                                    rules={[{ required: true, message: 'Please enter the end hour' }]}
                                >
                                    <InputNumber min={24} max={29} placeholder="Enter end hour (24-29)" />
                                </StyledFormItem>
                            </div>
                        </div>
                    ))}

                    <StyledFormItem>
                        <Button type="primary" htmlType="submit" loading={saving}>Update Branch Hours</Button>
                    </StyledFormItem>
                </Form>


            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default ManageBranchHours;
