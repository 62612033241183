import React from "react";
import {useHistory} from "react-router-dom";

export default function CheckoutItem({item}) {

    const history = useHistory();

    const goToEdit = () => {
        history.push(`/cart/${item.checkout_id}`, {"pathname": '/cart'})
    }

    return (
        <>
            <div className="mb-2 bg-white flex flex-row items-top pb-4 pb-1">
                <div className="bg-gray-200 rounded-md w-8 h-8 flex items-center justify-center">
                    {item.quantity}x
                </div>
                <div className="flex-1 flex flex-col pl-4 ">
                    <div className="font-bold text-lg">{item.name}</div>
                    <div dangerouslySetInnerHTML={{__html: item.formatted}} className="pl-2 border-l border-gray-100"></div>
                </div>
                <div className="pl-5">
                    <span className="text-xl">${item.price}</span>
                </div>
            </div>
        </>
    )
}