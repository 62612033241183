import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const BigBang = {


    ReportsIndex: (successCb, failCb) => {
        axios.get('/bigbang/reports/index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingCreate: (reportSetting, successCb, failCb) => {
        axios.post(`/bigbang/reports/create_setting`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingUpdate: (id, reportSetting, successCb, failCb) => {
        axios.post(`/bigbang/reports/${id}`, {report_setting: reportSetting}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingDelete: (id, successCb, failCb) => {
        axios.delete(`/bigbang/reports/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingGenerateScores: (id, successCb, failCb) => {
        axios.post(`/bigbang/reports/${id}/generate_scores`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ReportSettingGenerateClusters: (id, data, successCb, failCb) => {
        axios.post(`/bigbang/reports/${id}/generate_clusters`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


};

export default BigBang;
