import React, {useState} from 'react';
import GoogleMapComponent from "./GoogleMapComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPin} from "@fortawesome/free-solid-svg-icons";
import {Checkbox, Tag} from "antd";

const BigBangIndex = ({clusters, mcdonalds, shakeshacks, starbucks, fiveguys, burgerkings, setViewDynamic}) => {

    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({lat: 45.5591827, lng: -73.7118733});
    const [selectedBoundary, setSelectedBoundary] = useState(null);

    const [showmcdonalds, set_showmcdonalds] = useState(true);
    const [showstarbucks, set_showstarbucks] = useState(true);
    const [showshakeshacks, set_showshakeshacks] = useState(true);
    const [showfiveguys, set_showfiveguys] = useState(true);
    const [showburgerkings, set_showburgerkings] = useState(true);

    const maxScore = clusters.reduce((max, current) => Math.max(max, current.score), -Infinity);

    const setCenterZoom = (cluster) => {
        setCenter({lat: cluster["center"][1], lng: cluster["center"][0]});
        setZoom(16);
        setSelectedBoundary(cluster.shape);
    }

    console.log("mcdonalds", mcdonalds);

    return (
        <div
            className={"fixed w-full h-screen bg-white left-0 top-0 flex flex-row"}
            style={{zIndex: 1000000}}
        >
            <div
                className={"cursor-pointer fixed right-14 top-4 bg-black rounded-full flex flex-row items-center justify-center text-white font-bold w-8 h-8 text-lg"}
                onClick={() => setViewDynamic(false)}
                style={{zIndex: 1000001}}
            >
                X
            </div>
            <div className={"w-96 border-2 border-gray-700 overflow-y-scroll relative"}>
                <div className={"flex flex-wrap items-start justify-start pb-1 mb-1 border-b-2 border-black w-full bg-yellow-50 font-bold pt-1"}>
                    <div className={"w-1/2"}>
                        <div className={"ml-2"}>
                            <Checkbox onChange={(e) => {
                                set_showmcdonalds(e.target.checked);
                            }} checked={showmcdonalds}>
                                McDonald's
                            </Checkbox>
                        </div>
                    </div>
                    <div className={"w-1/2"}>
                        <div className={"ml-2"}>
                            <Checkbox onChange={(e) => {
                                set_showstarbucks(e.target.checked);
                            }} checked={showstarbucks}>
                                Starbucks
                            </Checkbox>
                        </div>
                    </div>
                    <div className={"w-1/2"}>
                        <div className={"ml-2"}>
                            <Checkbox onChange={(e) => {
                                set_showshakeshacks(e.target.checked);
                            }} checked={showshakeshacks}>
                                Shake Shack
                            </Checkbox>
                        </div>
                    </div>
                    <div className={"w-1/2"}>
                        <div className={"ml-2"}>
                            <Checkbox onChange={(e) => {
                                set_showfiveguys(e.target.checked);
                            }} checked={showfiveguys}>
                                FiveGuys
                            </Checkbox>
                        </div>
                    </div>
                    <div className={"w-1/2"}>
                        <div className={"ml-2"}>
                            <Checkbox onChange={(e) => {
                                set_showburgerkings(e.target.checked);
                            }} checked={showburgerkings}>
                                BurgerKings
                            </Checkbox>
                        </div>
                    </div>
                </div>
                {clusters.map((cluster, index) => {
                    return (
                        <div className={`p-1 cursor-pointer hover:bg-green-100 flex flex-row items-center text-sm ${index % 2 == 1 ? 'bg-gray-100' : ''}`}
                             onClick={() => setCenterZoom(cluster)}
                        >
                            <div className={"w-6 h-6 mr-2 rounded-full bg-black text-white patty flex flex-row items-center justify-center"}>{cluster.rank}</div>
                            <div className={"flex flex-col w-full"}>
                                <div>
                                    {cluster.country} {cluster.state ? <> - {cluster.state}</> : null} {cluster.city ? <> - {cluster.city}</> : null} <b>({cluster.area.toFixed(1)} KM2)</b>
                                </div>
                                <div>
                                    Density Score: {(cluster.area / (cluster.mcdonalds + cluster.starbucks + 5 * cluster.fiveguys + 5 * cluster.shakeshack) * 100).toFixed(2)}
                                </div>
                                <div>
                                    <Tag style={{marginTop: "2px"}} color={cluster.mcdonalds > 1 ? 'green' : cluster.mcdonalds == 1 ? 'yellow' : 'red'}>McDonald's: {cluster.mcdonalds} (<b>{cluster.mcdonalds_mean_last_year_reviews.toFixed(0)}</b> Reviews)</Tag>
                                    <Tag style={{marginTop: "2px"}} color={cluster.starbucks > 1 ? 'green' : cluster.starbucks == 1 ? 'yellow' : 'red'}>Starbucks: {cluster.starbucks} (<b>{cluster.starbucks_mean_last_year_reviews.toFixed(0)}</b> Reviews)</Tag>
                                    <Tag style={{marginTop: "2px"}} color={cluster.fiveguys > 1 ? 'green' : cluster.fiveguys == 1 ? 'yellow' : 'red'}>FiveGuys: {cluster.fiveguys} (<b>{cluster.fiveguys_mean_last_year_reviews.toFixed(0)}</b> Reviews)</Tag>
                                    <Tag style={{marginTop: "2px"}} color={cluster.shakeshack > 1 ? 'green' : cluster.shakeshack == 1 ? 'yellow' : 'red'}>ShakeShacks: {cluster.shakeshack} (<b>{cluster.shakeshack_mean_last_year_reviews.toFixed(0)}</b> Reviews)</Tag>
                                    <Tag style={{marginTop: "2px"}}>POIs: {cluster.pois} ({cluster.pois_mean_last_year_reviews.toFixed(0)} Reviews)</Tag>
                                </div>
                                <div className={"mt-1"}>
                                    <div style={{height: "4px", width: `${cluster.score / maxScore * 100}%`}} className={"bg-green-800"}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{width: '100%', height: "100%"}}>
                <GoogleMapComponent
                    clusters={clusters}
                    containerElement={<div style={{height: '100%'}}/>}
                    mapElement={<div style={{height: '100%'}}/>}
                    zoom={zoom}
                    center={center}
                    selectedBoundary={selectedBoundary}
                    mcdonalds={mcdonalds}
                    starbucks={starbucks}
                    shakeshacks={shakeshacks}
                    fiveguys={fiveguys}
                    burgerkings={burgerkings}
                    showmcdonalds={showmcdonalds}
                    showstarbucks={showstarbucks}
                    showshakeshacks={showshakeshacks}
                    showfiveguys={showfiveguys}
                    showburgerkings={showburgerkings}
                />
            </div>
        </div>
    );
};

export default BigBangIndex;