import React, {useState} from 'react';
import {Button, notification} from "antd";
import Bloggers from "../../../../http/Bloggers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faMailForward} from "@fortawesome/free-solid-svg-icons";
import Clipboard from "../../../../components/Clipboard";

const InviteActions = ({blogger}) => {

    const [inviting, setInviting] = useState(false);
    const [invited, setInvited] = useState(false);
    const [invitationLetter, setInvitationLetter] = useState(null);

    const invite = () => {
        setInviting(true);
        Bloggers.Invite(blogger.pk,(response) => {
            setInviting(false);
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `${blogger.username} has been invited!`,
            });
            setInvited(true);
            setInvitationLetter(response.data.data.blogger.invitation_letter);
        }, (err) => {
            setInviting(false);
        });
    }

    return (
        <div className='table-actions'>
            <div className="flex flex-row pl-2 items-center justify-start xl:justify-end">
                {!invited ?
                    <Button type={"primary"} onClick={invite} loading={inviting}>
                        Invite
                    </Button>
                :
                    <div className="flex flex-row items-center text-sm font-bold bg-gray-100 rounded-lg px-2 py-1 text-gray-800">
                        Letter:
                        <Clipboard text={invitationLetter} />
                    </div>
                }
            </div>
        </div>
    );
};

export default InviteActions;