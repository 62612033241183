import React, { useState, useEffect } from 'react';
import { Input, Button, notification } from 'antd';
import DefaultContainer from '../../../../../components/DefaultContainer';
import EmailTemplateService from '../../../../../http/EmailTemplateService';
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";

const { TextArea } = Input;

const BatchVendorEmailTemplateEditor = () => {
    const [template, setTemplate] = useState({
        title: '',
        body: ''
    });
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        fetchTemplate();
    }, []);

    const fetchTemplate = () => {
        setLoading(true);
        EmailTemplateService.getBatchVendorTemplate(
            (response) => {
                setTemplate(response.data.data.template);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error',
                    description: err.response?.data?.message || 'Failed to load the email template.',
                });
            }
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTemplate({
            ...template,
            [name]: value
        });
    };

    const handleSubmit = () => {
        setSaving(true);
        EmailTemplateService.updateBatchVendorTemplate(template,
            (response) => {
                setSaving(false);
                notification['success']({
                    message: 'Success',
                    description: 'Template updated successfully.',
                });
            },
            (err) => {
                setSaving(false);
                notification['error']({
                    message: 'Error',
                    description: err.response?.data?.message || 'Failed to update the template.',
                });
            }
        );
    };

    return (
        <DefaultContainer>
            <h2 className="text-2xl mb-4">Edit Batch Vendor Email Template</h2>

            {/* Tags Section */}
            <div className="mb-4 p-4 pb-2 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-bold mb-2">Available Tags</h3>
                <ul className="list-disc pl-5">
                    <li><code>[Vendor Name]</code>: The name of the vendor</li>
                    <li><code>[Date]</code>: The date of the orders</li>
                    <li><code>[Order Details]</code>: Details of all orders including group name, quantity, and unit type</li>
                    <li><code>[Confirmation Links]</code>: Links for confirming each order</li>
                </ul>
            </div>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                {template &&
                    <>
                        {/* Template Title */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Title</label>
                            <Input
                                name="title"
                                value={template.title}
                                onChange={handleInputChange}
                                placeholder="Email Title"
                                disabled={saving}
                            />
                        </div>

                        {/* Template Body */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Body</label>
                            <TextArea
                                name="body"
                                value={template.body}
                                onChange={handleInputChange}
                                placeholder="Email Body"
                                rows={10}
                                disabled={saving}
                            />
                        </div>

                        <Button type="primary" onClick={handleSubmit} loading={saving}>
                            Save Template
                        </Button>
                    </>
                }
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default BatchVendorEmailTemplateEditor;