import React, {useEffect} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import FormItem from "../../components/Form/FormItem";
import {Button, Form, Input, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {login, register, ResetAuthErrors, SetRedirectSource} from "../../redux/authentication/actionCreator";
import {AuthWrapper} from "../../styles/AuthWrapper";
import FormError from "../../components/Form/FormError";
import {ResetActiveOrders} from "../../redux/checkout/actionCreator";

const About = () => {

    return (
        <section className="bg-white">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white uppercase">
                    About Us
                </h2>

                <div>
                    <p>
                        <b>Welcome to Patty Slaps,</b>
                        <br/>
                        where every bite is an explosion of flavor and a celebration of craftsmanship! We're not just another burger joint – we're a culinary experience that bridges the gap between fine dining and fast food, all while embracing art, community, and technology.
                        Our journey began with a simple yet ambitious idea: to redefine the burger experience in Canada. Fueled by a passion for quality and taste, we embarked on a mission to craft the perfect burger that tantalizes the senses and leaves a lasting impression. From the moment you step into our vibrant and welcoming space, you're in for a treat that goes beyond the ordinary.
                    </p>

                    <p>
                        <b>Crafted with Care</b>
                        <br/>
                        At Patty Slaps, we take pride in our culinary artistry. Our ingredients are meticulously sourced and handpicked to ensure only the freshest and finest elements grace your plate. Our blend of chuck and brisket forms the foundation of our delectable patties, and every element – from our locally-supplied pretzel buns to our house-made sauces – is a testament to our commitment to excellence.
                    </p>

                    <p>
                        <b>More Than a Meal</b>
                        <br/>
                        Beyond crafting exceptional burgers, we're dedicated to creating an atmosphere that fosters community and connection. Our social tables are a testament to our belief that good food brings people together. Whether you're enjoying our handcrafted creations with friends, family, or fellow burger enthusiasts, you're in for an experience that transcends the plate.
                    </p>

                    <p>
                        <b>Innovation Meets Tradition</b>
                        <br/>
                        We're not just about food; we're about innovation. Our in-house artists pour their creativity into every graphic and animation, giving our brand a unique visual identity. Our cutting-edge technology, from our convenient self-serve kiosks to our user-friendly app, ensures that your experience is seamless and hassle-free. And we're not stopping here – stay tuned for exciting seasonal offerings and creative collaborations that push the boundaries of burger perfection.
                        Ready to take your taste buds on a journey? Come visit us at Patty Slaps and savor the flavors that redefine the burger game. Your adventure begins with every bite.
                    </p>
                </div>
            </div>
        </section>

    );
};

export default About;
