import React from 'react';

const PriorityTag = ({priority}) => {
    return (
        <div className={`uppercase font-bold w-full text-center bg-gray-100 rounded-lg ${priority == 'low' && 'bg-yellow-200'} ${priority == 'medium' && 'bg-green-200 text-black'} ${priority == 'high' && 'bg-red-700 text-white'}`}>
            {priority}
        </div>
    );
};

export default PriorityTag;