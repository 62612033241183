import React, {useEffect} from 'react';
import Can from "../../../../layouts/Can";
import UserMenu from "../../Profile/UserMenu";
import {motion} from "framer-motion";
import T from "../../../../components/T";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ListRequests} from "../../../../redux/profile/actionCreator";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../../components/skeletons/MenuSkeleton";
import RequestRow from "./RequestRow";
import {Pagination} from "antd";

const Requests = () =>
{
    const dispatch = useDispatch();

    const loading = useSelector(state => state.profile.requests_loading);
    const error = useSelector(state => state.profile.requests_error);
    const success = useSelector(state => state.profile.requests_success);
    const requests = useSelector(state => state.profile.requests);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            console.log("listing requests...");
            dispatch(ListRequests());
        }
    }, []);


    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                    <T upper>MY REQUESTS</T>
                </div>
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center">
                        <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                            {requests ?
                                <>
                                    {requests.length == 0 ?
                                        <div className="text-base text-gray-800 font-normal py-8"><T cap>You do not have any requests.</T></div>
                                        :
                                        <>
                                            <div className="w-full">
                                                {requests.map(request => {
                                                    return (<RequestRow request={request}/>);
                                                })}
                                            </div>
                                        </>
                                    }
                                </>
                                : null}
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>


    );
};

export default Requests;