import actions from './actions';

const {
    setBranch,

} = actions;

const SetBranch = (branch) => {
    return async dispatch => {
        dispatch(setBranch(branch));
    };
};

export {SetBranch};
