import React from 'react';

const FormContainer = ({children}) => {
    return (
        <div className="p-4 pb-0 border border-gray-300 rounded-md mb-3 ml-0 max-w-2xl bg-white" >
            {children}
        </div>
    );
};

export default FormContainer;