import React, {useEffect, useState} from 'react';
import T from "../../../../../components/T";
import {motion} from "framer-motion";
import Irani from "../../../../../http/Irani";
import {Button, notification, Select} from "antd";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import {faArrowRight, faArrowRightToCity, faCheck, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IraniReviewReport from "./IraniReviewReport";

const IraniReviewReports = () => {

    const [locations, setLocations] = useState(null);
    const [venues, setVenues] = useState(null);
    const [contexts, setContexts] = useState(null);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [selectedContext, setSelectedContext] = useState(null);
    const [selectedContexts, setSelectedContexts] = useState([]);
    const [reportType, setReportType] = useState('location');
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [creating, setCreating] = useState(false);
    const [settings, setSettings] = useState([]);
    const [editSetting, setEditSetting] = useState(null);

    const reload= (data) => {
        resetVars();
        setLocations(data.locations);
        setVenues(data.venues);
        setContexts(data.contexts);
        setSettings(data.settings);
        setSettingsLoaded(true);
    }

    useEffect(() => {
        Irani.ReviewReportsIndex((response) => {
            const data = response.data.data;
            reload(data);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }, []);


    const _setReportType = (type) => {
        if(creating) return null;
        setReportType(type)
    }

    const addContext = () => {
        if (selectedContexts.find(context => context.id == selectedContext)) return false;
        const c = contexts.find(context => context.id == selectedContext);
        setSelectedContexts([...selectedContexts, c]);
    }

    const contextChange = (context_id) => {
        setSelectedContext(context_id);
    }

    const deleteContext = (context_id) => {
        const updatedContexts = selectedContexts.filter(context => context.id !== context_id);
        setSelectedContexts(updatedContexts);
    }

    const countryChange = (country_id) => {
        setSelectedCountry(country_id);
        setSelectedCity(0);
    }

    const venueChange = (venue_id) => {
        setSelectedVenue(venue_id);
    }

    const cityChange = (city_id) => {
        setSelectedCity(city_id);
    }

    const addLocation = () => {
        if (selectedLocations.find(location => location.country_id == selectedCountry && location.city_id == selectedCity)) return false;

        let loc = {};
        if (selectedCountry == 0) {
            loc['country_id'] = 0;
            loc['country'] = "All Countries";
        } else {
            loc['country_id'] = selectedCountry;
            const country = locations.find(c => c.country_id == selectedCountry);
            loc['country'] = country["country"];

            if (selectedCity == 0) {
                loc['city_id'] = 0;
                loc['city'] = "All Cities";
            } else {
                loc['city_id'] = selectedCity;
                const city = country.cities.find(c => c.city_id == selectedCity);
                loc['city'] = city['city'];
            }
        }

        setSelectedLocations([...selectedLocations, loc]);
    }

    const deleteLocation = (location) => {
        const updatedLocations = selectedLocations.filter(loc => loc.country_id !== location.country_id || loc.city_id !== location.city_id);
        setSelectedLocations(updatedLocations);
    }

    const addVenue = (venue) => {
        if (selectedVenues.find(venue => venue.id == selectedVenue)) return false;
        const c = venues.find(venue => venue.id == selectedVenue);
        setSelectedVenues([...selectedVenues, c]);
    }

    const deleteVenue = (venue) => {
        const updatedVenues = selectedVenues.filter(v => v.id !== venue.id);
        setSelectedVenues(updatedVenues);
    }

    const resetVars = () => {
        setCreating(false);
        setSelectedContexts([]);
        setSelectedLocations([]);
        setSelectedVenues([]);
        setSelectedContext(null);
        setSelectedCountry(0);
        setSelectedCity(null);
        setSelectedVenue(null);
        setEditSetting(null);
    }

    const edit = (setting) => {
        setSelectedContexts(setting.contexts);
        setReportType(setting.report_type);
        setSelectedLocations(setting.locations);
        setSelectedVenues(setting.venues);
        setEditSetting(setting);
    }

    const cancelEdit = () => {
        resetVars();
    }

    const updateReportSettings = () => {
        setCreating(true);

        let reportSetting = {contexts: selectedContexts, report_type: reportType, locations:[], venues:[]};
        if(reportType == 'location')
            reportSetting['locations'] = selectedLocations;
        else
            reportSetting['venues'] = selectedVenues;

        Irani.ReviewReportSettingUpdate(editSetting.id, reportSetting, (response) => {
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    const createReportSettings = () => {
        setCreating(true);

        let reportSetting = {contexts: selectedContexts, report_type: reportType, locations:[], venues:[]};
        if(reportType == 'location')
            reportSetting['locations'] = selectedLocations;
        else
            reportSetting['venues'] = selectedVenues;

        Irani.ReviewReportSettingCreate(reportSetting, (response) => {
            resetVars();
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            resetVars();
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });

    }

    console.log("selectedLocations", selectedLocations);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                    <T upper>Irani Review Reports</T>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <SkeletonWrapper loading={!settingsLoaded} skeleton={GenericSkeleton}>
                        {settingsLoaded &&
                            <div className={"flex flex-col w-full"}>
                                <div className={"rounded-sm flex-1 border-black border shadow-xl bg-white flex flex-col items-start justify-center"}>
                                <div className={"text-base bg-gray-800 text-white py-1 w-full px-2"}>
                                    CREATE A REVIEW REPORT SETTINGS
                                </div>
                                <div className="w-full p-2 flex flex-col">
                                    <div className={"flex flex-row w-full"}>
                                        <div className={"font-bold text-lg mr-2"}>
                                            CONTEXTS:
                                        </div>

                                        <Select
                                            key={'context'}
                                            className={"w-full"}
                                            showSearch={true}
                                            style={{width: "100% important"}}
                                            onChange={contextChange}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={creating}
                                            value={selectedContext}
                                            options={contexts.map((context) => {
                                                return ({value: context["id"], label: context["category_full_name"]})
                                            })}
                                        />

                                        <div className={"ml-1"}>
                                            <Button
                                                disabled={!selectedContext || creating}
                                                type={"primary"}
                                                onClick={addContext}
                                            >
                                                ADD
                                            </Button>
                                        </div>
                                    </div>
                                    {selectedContexts.length > 0 &&
                                        <div className={"mt-2 flex flex-col"}>
                                            {selectedContexts.map(context => {
                                                return (
                                                    <div className={"m-1 p-1 bg-blue-100 flex flex-row items-center font-bold"}>
                                                        <FontAwesomeIcon icon={faTrash} className="text-base mr-3 cursor-pointer ml-1 text-black" aria-hidden="true" onClick={() => deleteContext(context.id)}/>
                                                        {context.category_full_name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>

                                {selectedContexts.length > 0 &&
                                    <div className={"px-2 pb-2 flex flex-col w-full"}>

                                        <div className={" w-full border-t border-black pt-2"}>
                                            <div className={"w-full bg-gray-100 flex flex-row items-center border border-black"}>
                                                <div className={`w-1/2 py-1 flex flex-row items-center justify-center border-r border-black cursor-pointer ${reportType == 'location' ? "bg-gray-800 text-white font-bold" : ""}`} onClick={() => _setReportType('location')}>
                                                    {reportType == 'location' ? <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl " aria-hidden="true"/> : ""}
                                                    BY LOCATION
                                                </div>
                                                <div className={`w-1/2 py-1 flex flex-row items-center justify-center cursor-pointer ${reportType == 'venue' ? "bg-gray-800 text-white font-bold" : ""}`} onClick={() => _setReportType('venue')}>
                                                    {reportType == 'venue' ? <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl " aria-hidden="true"/> : ""}
                                                    BY VENUES
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"pt-2 px-1"}>
                                            {reportType == 'location' &&
                                                <div className={"flex flex-col"}>
                                                    <div className={"flex flex-row items-center uppercase"}>
                                                        <div className={"flex flex-row items-center"}>
                                                            <div className={"font-bold mr-2"}>Country:</div>
                                                            <Select
                                                                key={'country'}
                                                                className={"w-48"}
                                                                showSearch={true}
                                                                onChange={countryChange}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                defaultValue={0}
                                                                disabled={creating}
                                                                options={[{value: 0, label: "All Countries"}, ...locations.map((country) => {
                                                                    return ({value: country["country_id"], label: country["country"]})
                                                                })]}
                                                            />
                                                        </div>

                                                        {selectedCountry > 0 &&
                                                            <div className={"flex flex-row items-center ml-4"}>
                                                                <div className={"font-bold mr-2"}>City:</div>
                                                                <Select
                                                                    key={'city'}
                                                                    className={"w-48"}
                                                                    showSearch={true}
                                                                    onChange={cityChange}
                                                                    filterOption={(input, option) =>
                                                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    defaultValue={0}
                                                                    value={selectedCity}
                                                                    options={[{value: 0, label: "All Cities"}, ...locations.filter(country => country.country_id == selectedCountry)[0].cities.map((city) => {
                                                                        return ({value: city["city_id"], label: city["city"]})
                                                                    })]}
                                                                />
                                                            </div>
                                                        }

                                                        <div className={"ml-1"}>
                                                            <Button
                                                                type={"primary"}
                                                                onClick={addLocation}
                                                                disabled={creating}
                                                            >
                                                                ADD LOCATION
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    {selectedLocations.length > 0 &&
                                                        <div className={"mt-2 flex flex-col"}>
                                                            {selectedLocations.map(location => {
                                                                return (
                                                                    <div className={"m-1 p-1 bg-blue-100 flex flex-row items-center font-bold"}>
                                                                        <FontAwesomeIcon icon={faTrash} className="text-base mr-3 cursor-pointer ml-1 text-black" aria-hidden="true" onClick={() => deleteLocation(location)}/>
                                                                        {location.country}
                                                                        {location.city &&
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faArrowRight} className="text-base mx-3 cursor-pointer text-black" aria-hidden="true"/>
                                                                                {location.city}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {reportType == 'venue' &&
                                                <div className={"flex flex-col w-full"}>
                                                    <div className={"flex flex-col items-center uppercase w-full"}>
                                                        <div className={"font-bold mr-2 text-left w-full"}>Venues:</div>
                                                        <div className={"flex flex-row items-center w-full"}>
                                                            <Select
                                                                key={'venues'}
                                                                className={"w-full"}
                                                                showSearch={true}
                                                                onChange={venueChange}
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }                                            disabled={creating}
                                                                disabled={creating}
                                                                options={venues.map((venue) => {
                                                                    return ({value: venue["id"], key: venue["id"], label: venue["name"] + " -> " + venue["address"]})
                                                                })}
                                                            />

                                                            <div className={"ml-1"}>
                                                                <Button
                                                                    type={"primary"}
                                                                    onClick={addVenue}
                                                                    disabled={creating}
                                                                >
                                                                    ADD VENUE
                                                                </Button>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    {selectedVenues.length > 0 &&
                                                        <div className={"mt-2 flex flex-col"}>
                                                            {selectedVenues.map(venue => {
                                                                return (
                                                                    <div className={"m-1 p-1 bg-blue-100 flex flex-row items-center font-bold"}>
                                                                        <FontAwesomeIcon icon={faTrash} className="text-base mr-3 cursor-pointer ml-1 text-black" aria-hidden="true" onClick={() => deleteVenue(venue)}/>
                                                                        {venue.name} <FontAwesomeIcon icon={faArrowRight} className="text-base mx-3 cursor-pointer text-black" aria-hidden="true"/> {venue.address}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {((reportType == "location" && selectedLocations.length > 0) || (reportType == "venue" && selectedVenues.length > 0)) &&
                                            <div className={"mt-2 mx-1 border-t border-black pt-2"}>
                                                {editSetting?
                                                    <>
                                                        <Button
                                                            type={"primary"}
                                                            onClick={updateReportSettings}
                                                            loading={creating}
                                                        >
                                                            SAVE SETTINGS
                                                        </Button>
                                                        <Button
                                                            type={""}
                                                            onClick={cancelEdit}
                                                            className={"ml-2"}
                                                            loading={creating}
                                                        >
                                                            CANCEL
                                                        </Button>
                                                    </>
                                                :
                                                    <Button
                                                        type={"primary"}
                                                        onClick={createReportSettings}
                                                        loading={creating}
                                                    >
                                                        CREATE REPORT SETTINGS
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                                {(settings && settings.length > 0) &&
                                    <div className={"flex flex-col w-full mt-3"}>
                                        {settings.map(setting => {return (<IraniReviewReport setting={setting} edit={edit} reload={reload} />)})}
                                    </div>
                                }
                            </div>
                        }
                    </SkeletonWrapper>
                </div>
            </div>
        </motion.div>
    );
};

export default IraniReviewReports;