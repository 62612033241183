import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faClockRotateLeft, faSigning, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const StatusTag = ({status}) => {
    return (
        <div className={`uppercase whitespace-nowrap rounded-md px-2 font-bold w-full text-center ${status == 'not started' && 'bg-red-200'} ${status == 'in progress' && 'bg-yellow-200'} ${status == 'completed' && 'bg-green-200'}`}>
            {status == 'not started' && <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1"/>}
            {status == 'in progress' && <FontAwesomeIcon icon={faClockRotateLeft} className="mr-1"/>}
            {status == 'completed' && <FontAwesomeIcon icon={faCheckDouble} className="mr-1"/>}
            {status}
        </div>
    );
};

export default StatusTag;