import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoltLightning, faChartLine, faClipboard, faClipboardCheck, faComments, faExclamationTriangle, faInfoCircle, faLocationPin, faMapPin, faPlayCircle, faPlus, faRocket, faSkull, faThumbsUp, faTimes} from "@fortawesome/free-solid-svg-icons";
import Profile from "../../../../http/Profile";
import Bloggers from "../../../../http/Bloggers";
import {SpinnerCircular} from "spinners-react";
import axios from "axios";
import copy from "copy-to-clipboard";
import StatsInfo from "./stats_info";
import {Button, notification, Progress} from "antd";

const MarkBloggers = () => {

    const [bloggers, setBloggers] = useState([]);
    const [data, setData] = useState({});
    const [bloggerIndex, setBloggerIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [copiedToClipboard, setCopliedToClipboard] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [accepting, setAccepting] = useState(false);
    const [rejecting, setRejecting] = useState(false);


    useEffect(() => {
        fetchFreeBloggers();
    }, []);

    const fetchFreeBloggers = () => {
        setLoading(true);
        Bloggers.FetchFreeBloggers((response) => {
            setLoading(false);
            setBloggerIndex(0);
            console.log(response.data.data);
            setBloggers(response.data.data.bloggers);
            const counts = response.data.data.counts;
            setData(counts);
            console.log("counts", counts);
            if (counts["processed"] && counts["processed"] > 0) {
                setPercentCompleted((counts["processed"] / (counts["processing"] + counts["not processed"] + counts["processed"]) * 100).toFixed(0))
            } else setPercentCompleted(0);

            setCopliedToClipboard(false);
        }, (err) => {
            setLoading(false);
            console.log("ERROR", err.response?.data.message);
        });
    }

    const accept = (blogger) => {
        setAccepting(true);
        Bloggers.Accept(blogger.pk,(response) => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `${blogger.username} has been accepted!`,
            });
            setAccepting(false);
            fetchFreeBloggers();
        }, (err) => {
            setAccepting(false);
            console.log("ERROR", err.response?.data.message);
        });
    }

    const reject = (blogger) => {
        setRejecting(true);
        Bloggers.Reject(blogger.pk,(response) => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `${blogger.username} has been rejected!`,
            });
            setRejecting(false);
            fetchFreeBloggers();
        }, (err) => {
            setRejecting(false);
            console.log("ERROR", err.response?.data.message);
        });
    }

    function formatNumber(number) {
        if (number >= 1000) {
            const rounded = Math.round(number / 100) / 10;
            return `${rounded}k`;
        }
        return number.toString();
    }

    const copyPkToClipboard = () => {
        const pk = bloggers[bloggerIndex].pk;
        copy(pk);
        setCopliedToClipboard(true);
    }

    const generateIcons = (value) => {
        if (value < .5) return null;

        let iconCount = Math.ceil(value) - 1; // Get the integer part of the value
        const icons = [];

        for (let i = 0; i < Math.min(iconCount, 4); i++) {
            icons.push(
                <FontAwesomeIcon icon={faRocket} className="text-sm mr-1 text-blue-800 cursor-pointer" aria-hidden="true"/>
            );
        }

        return icons;
    }


    return (
        <motion.div
            initial={{opacity: 0, scale: 1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full relative"
        >
            <div className="text-xl font-bold pl-1 flex flex-row justify-between mb-3 items-center pr-2 fixed top-20 rounded-xl right-3 text-white" style={{zIndex: 1000}}>
                <Button danger type={"primary"} style={{borderRadius: "5px", border: "2px solid black"}} loading={rejecting} disabled={accepting || loading} className={"uppercase bg-red-brand px-8 rounded-lg py-1 cursor-pointer border-2 border-black shadow-lg flex flex-row items-center"} onClick={() => reject(bloggers[bloggerIndex])}>
                    reject
                </Button>
                <Button type={"primary"} style={{borderRadius: "5px", border: "2px solid black"}} loading={accepting} disabled={rejecting || loading} className={"uppercase bg-green-700 px-8 rounded-lg py-1 ml-3 cursor-pointer border-2 border-black shadow-lg flex flex-row items-center"} onClick={() => accept(bloggers[bloggerIndex])}>
                    accept
                </Button>
            </div>

            <div className="flex flex-col mx-auto w-full pt-12">
                <div className="p-2 bg-white rounded-lg mb-2 border border-gray-200 shadow flex flex-col">
                    <div className="text-xs font-bold flex flex-row items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="text-sm text-gray-800 mr-1 "/>
                        {data && data["processed"]?
                            <>
                                {data["processed"]} bloggers marked out of {data["processed"] + data["processing"] + data["not processed"]} bloggers.
                            </>
                        :<>Loading...</>}
                    </div>
                    <Progress percent={percentCompleted} showInfo={true}/>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    {loading ?
                        <div className="p-10 flex flex-row items-center text-base uppercase justify-center bg-white border border-gray-200 w-full">
                            <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="h-5 mr-2"/>
                            Loading
                        </div>
                        :
                        bloggers.length == 0 ?
                            <div>No Bloggers found to process.</div>
                            :
                            <>
                                {bloggers && bloggers.length > 0 && bloggers[bloggerIndex] &&

                                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-1 sm:p-3 sm:pt-3 flex flex-col items-center justify-center py-2 w-full">
                                        <div className="flex flex-row w-full">
                                            <div className="flex-1 w-full">
                                                <div className="flex flex-wrap sm:flex-row items-center justify-start w-full mb-0">

                                                    <StatsInfo title="SCORE" data={bloggers[bloggerIndex].calculated_score} />
                                                    <StatsInfo title="POST" data={bloggers[bloggerIndex].media_count.toLocaleString()}/>
                                                    <StatsInfo title="FOLLOWERS" data={bloggers[bloggerIndex].follower_count.toLocaleString()}/>
                                                    <StatsInfo title="FOLLOWINGS" data={bloggers[bloggerIndex].following_count.toLocaleString()}/>
                                                    <StatsInfo title="CATEGORY" data={bloggers[bloggerIndex].category == ""?'NONE':bloggers[bloggerIndex].category}/>
                                                    {/*
                                                    <StatsInfo title="CLIPS" data={bloggers[bloggerIndex].total_clips_count ? bloggers[bloggerIndex].total_clips_count.toLocaleString() : 0}/>
                                                    <StatsInfo title="IGTVS" data={bloggers[bloggerIndex].total_igtv_videos ? bloggers[bloggerIndex].total_igtv_videos.toLocaleString() : 0}/>
                                                    */}

                                                </div>
                                                <div className="flex flex-wrap sm:flex-row items-center justify-start w-full mb-0">

                                                    <StatsInfo title="COOL" data={`%${Math.round(bloggers[bloggerIndex].cool*100)}`} isPercent={true} />
                                                    <StatsInfo title="GOOD" data={`%${Math.round(bloggers[bloggerIndex].good*100)}`} isPercent={true}/>
                                                    <StatsInfo title="FANCY" data={`%${Math.round(bloggers[bloggerIndex].fancy*100)}`} isPercent={true}/>
                                                    <StatsInfo title="KHAZ" data={`%${Math.round(bloggers[bloggerIndex].khaz*100)}`} isPercent={true} />

                                                </div>
                                                <div className="flex flex-wrap sm:flex-row items-center justify-start w-full mb-4">

                                                    <StatsInfo title="FOOD SCORE" data={`%${bloggers[bloggerIndex].blogger_score}`} isPercent/>
                                                    <StatsInfo title="MONTREAL?" data={`%${(bloggers[bloggerIndex].been_to_montreal / 33 * 100).toFixed(0)}`} isPercent={true}/>
                                                    <StatsInfo title="AVERAGE LIKES" data={
                                                        <div className="flex flex-row justify-between w-full">
                                                            <div>
                                                                {bloggers[bloggerIndex].likes_average.toFixed(0)}
                                                            </div>
                                                            <div>
                                                                {generateIcons(bloggers[bloggerIndex].likes_engagement_zscore)}
                                                                {bloggers[bloggerIndex].likes_engagement_zscore.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    }
                                                    />


                                                    <StatsInfo title="AVERAGE COMMENTS" data={
                                                        <div className="flex flex-row justify-between w-full">
                                                            <div>
                                                                {bloggers[bloggerIndex].comments_average.toFixed(0)}
                                                            </div>
                                                            <div>
                                                                {generateIcons(bloggers[bloggerIndex].comments_engagement_zscore)}
                                                                {bloggers[bloggerIndex].comments_engagement_zscore.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    }
                                                    />

                                                    <StatsInfo title="LAST POST" data={
                                                        <>
                                                            {bloggers[bloggerIndex].last_post_taken_at_days > 600 ?
                                                                <FontAwesomeIcon icon={faSkull} className="text-2xl mr-2 text-red-800 cursor-pointer" aria-hidden="true"/>
                                                                : bloggers[bloggerIndex].last_post_taken_at_days > 300 ?
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-2xl mr-2 text-red-800 cursor-pointer" aria-hidden="true"/>
                                                                    : null}
                                                            {bloggers[bloggerIndex].last_post_taken_at_days.toLocaleString()} Days
                                                        </>
                                                    }/>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <div className="text-2xl mb-1 font-bold flex flex-col sm:flex-row justify-between w-full">
                                                <a href={"https://instagram.com/" + bloggers[bloggerIndex].username} target={"_blank"} className="text-black">
                                                    {bloggers[bloggerIndex].username}
                                                </a>
                                                <div className="text-base">
                                                    {bloggers[bloggerIndex].pk}
                                                    {copiedToClipboard ?
                                                        <FontAwesomeIcon icon={faClipboardCheck} className=" ml-2 text-green-800 cursor-pointer" aria-hidden="true" onClick={copyPkToClipboard}/>
                                                        :
                                                        <FontAwesomeIcon icon={faClipboard} className=" ml-2 text-green-800 cursor-pointer" aria-hidden="true" onClick={copyPkToClipboard}/>
                                                    }
                                                </div>
                                            </div>

                                            <div className="text-base mb-4">
                                                {bloggers[bloggerIndex].biography}
                                            </div>
                                        </div>

                                        <div className="w-full flex flex-wrap">
                                            {bloggers[bloggerIndex]['latest_posts'][0].map(post => {
                                                if (post.thumbnail_url == null) return null;
                                                return (
                                                    <div className="w-full py-1 px-0 md:w-1/3 md:p-1 md:pb-2 2xl:w-1/6 ">
                                                        <div className=" border rounded-lg bg-gray-50 shadow-lg">
                                                            <img src={"https://pattykiosk.com/api/image-proxy/" + post.thumbnail_url} className="rounded-t-lg"/>
                                                            {post.location && post.location.city ?
                                                                <>
                                                                    <div className="p-2">
                                                                        <FontAwesomeIcon icon={faMapPin} className="text-2xl" aria-hidden="true"/>
                                                                        <b>{post.location.address} - {post.location.city}</b>
                                                                    </div>
                                                                    <hr/>
                                                                </>
                                                                : null}
                                                            <div className="flex flex-row justify-between w-full p-2">
                                                                <div className="font-bold">
                                                                    {post.taken_at.split('T')[0]}
                                                                </div>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faComments} className="text-sm mr-0.5" aria-hidden="true"/>
                                                                    {formatNumber(post.comment_count)}
                                                                </div>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faThumbsUp} className="text-sm mr-0.5" aria-hidden="true"/>
                                                                    {formatNumber(post.like_count)}
                                                                </div>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faPlayCircle} className="text-sm mr-0.5" aria-hidden="true"/>
                                                                    {formatNumber(post.play_count)}
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <div className="p-2">
                                                                {post.caption_text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </>

                    }
                </div>
            </div>
        </motion.div>
    );
};
export default MarkBloggers;
