import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import {Button, notification, Tag} from 'antd';
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import CategoryApi from "../../../../../http/CategoryApi";
import { Link } from 'react-router-dom';  // For navigating to the edit page
import { RiEdit2Line } from "@remixicon/react";
import DefaultContainer from "../../../../../components/DefaultContainer";

const ManageFoods = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadCategories = () => {
        setLoading(true);
        CategoryApi.Index(
            (response) => {
                setLoading(false);
                setCategories(response.data.data.categories.filter(category => category.foods && category.foods.length > 0));  // Only show categories with foods
            },
            (err) => {
                notification.error({ message: 'Error!', description: err.response?.data.message });
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <h1 className="text-xl font-bold">Manage Foods</h1>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                {categories.length === 0 ? (
                    <p>No categories with foods available.</p>
                ) : (
                    categories.map((category) => (
                        <div key={category.id} className="mb-4"> {/* Reduced margin to make it more compact */}
                            <h2 className="text-md font-semibold mb-2">{category.name} {category.name_fr?`(${category.name_fr})`:null}</h2> {/* Smaller font size */}
                            <div className="space-y-1"> {/* Reduced spacing between foods */}
                                {category.foods.map((food) => (
                                    <div
                                        key={food.id}
                                        className="food-item p-1 pr-4 bg-white shadow-md border border-gray-200 rounded-md flex items-center justify-between"  // Compact design
                                    >
                                        <div className="flex items-center">
                                            <div className="w-10 h-10 bg-gray-200 flex-shrink-0"> {/* Reduced image size */}
                                                {food.image ? (
                                                    <img src={food.image} alt={food.name} className="w-full h-full object-cover" />
                                                ) : (
                                                    <RiEdit2Line className="w-full h-full" />
                                                )}
                                            </div>
                                            <span className="ml-3 text-sm">{food.name} {food.name_fr?`(${food.name_fr})`:null}</span>
                                            <span className="ml-3 text-sm">
                                                <Tag color={"green"}>${food.price}</Tag>
                                            </span>
                                            <Tag className="ml-3 text-sm">Delivery: ${food.delivery_price}</Tag>

                                        </div>
                                        <Link to={`/dashboard/storefront/foods/${food.id}/edit`} className="ml-auto">
                                            <Button type="ghost" icon={<RiEdit2Line />} size="small" className={"!p-1 !h-auto !w-auto"} />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </SkeletonWrapper>
        </motion.div>
    );
};

export default ManageFoods;
