import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const DepartmentRoles = {
    Store: (departmentId, roleData, successCb, failCb) => {
        axios.post(`/departments/${departmentId}/roles`, roleData, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (departmentId, roleId, roleData, successCb, failCb) => {
        axios.post(`/departments/${departmentId}/roles/${roleId}`, roleData, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (departmentId, roleId, successCb, failCb) => {
        axios.delete(`/departments/${departmentId}/roles/${roleId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Reorder: (departmentId, rolesOrder, successCb, failCb) => {
        axios.post(`/departments/${departmentId}/roles/reorder`, { roles: rolesOrder }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default DepartmentRoles;
