import React from 'react';
import {useSelector} from "react-redux";
import T from "./T";

const FoodImage = ({name, no_style=false, name_fr=null}) => {
    const lang = useSelector(state => state.settings.lang);
    //if(name == 'Fries') name = 'Mystery Fries';
    return (
        no_style ? <T fr={name_fr}>{name}</T> :
        <div className="text-center uppercase patty relative text-3xl tracking-very-tight leading-6 xs:leading-6 sm:leading-9 md:leading-10 xs:text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-5xl 2xl:text-6xl -mt-3 sm:-mt-4 md:-mt-5 lg:-mt-10">
            <T fr={name_fr}>{name}</T>
        </div>
    );
};

export default FoodImage;
