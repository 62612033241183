import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {closeStoreCartBubble, closeUpdateCartBubble, removeFromCart} from "../redux/cart/actionCreator";
import FadeIn from 'react-fade-in';
import {calculatePrice, convertCartItemToFood} from "../services/food";
import {Link, useHistory, useLocation} from "react-router-dom";
import BubbleViewBagButton from "./BubbleViewBagButton";
import T from "../components/T";

const CartItemUpdateBubble = () => {
    const loading = useSelector(state => state.cart.update_loading);
    const done = useSelector(state => state.cart.update_done);
    const cart_item = useSelector(state => state.cart.update_cart_item);
    const cart_items = useSelector(state => state.cart.cart_items);

    const bubble = useSelector(state => state.cart.update_bubble);
    const menu = useSelector(state => state.menu.index_menu);

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const cartRoute = location.pathname === "/cart";

    const handleClose = () => {
        dispatch(closeUpdateCartBubble());
        dispatch(closeUpdateCartBubble());
    }

    let food = null;
    let total_price = 0;
    let key = null;
    let quantity = 0;

    if (menu && cart_item) {
        key = cart_item[3];
        let price = 0;
        food = convertCartItemToFood(menu, cart_item);
        const selectedItems = cart_item[1];
        quantity = cart_item[2];
        price = calculatePrice(food, selectedItems);
        total_price = (price * quantity).toFixed(2);
    }

    const removeItem = () => {
        console.log("current cart items:", cart_items, "and key is: ", key);
        dispatch(closeStoreCartBubble());
        dispatch(closeUpdateCartBubble());
        dispatch(removeFromCart(cart_items, key));
    }


    const goToEdit = () => {
        history.push(`/cart/${key}`, {"pathname": history.location.pathname})
    }

    return (
        <>
            {(bubble > 0) && menu && food ?
                <FadeIn transitionDuration={200} className="absolute right-0 md:right-5 top-28 md:top-20 w-full md:w-96 px-2 md:px-0">
                    <div id="shopping-bag" className={loading || done ? '' : 'hidden'}>

                        <div className={food ? "rounded-xl border-4 bg-gray-100 z-40 border-gray-700 p-6 shadow-2xl" : 'hidden'}>
                            <div className="flex justify-between">
                                <h1 className="text-xl font-bold text-green-500 uppercase"><T>ITEM UPDATED ON YOUR BAG</T></h1>

                                <button onClick={handleClose}>
                                    <FontAwesomeIcon icon={faTimes} className="text-2xl" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="flex justify-between pt-3 items-center">
                                <img src={food.image} className="w-24"/>
                                <div className="flex flex-col flex-1 pl-5">
                                    <div className="text-lg font-bold"><span className="text-base font-normal capitalize">{quantity}x</span> <T>{food.name}</T></div>
                                    <div className="buttons capitalize">
                                        {food.modified_options.length > 0?
                                            <button onClick={goToEdit} className="capitalize">
                                                <T>Edit</T>
                                            </button>
                                        :null}
                                        <button onClick={removeItem} className="ml-3 capitalize">
                                            <T>Remove</T>
                                        </button>
                                    </div>
                                </div>
                                <div className="font-bold">${total_price}</div>
                            </div>
                            <BubbleViewBagButton cart_items={cart_items} cartRoute={cartRoute} />
                        </div>

                        <div className="w-96 rounded-xl h-20 absolute border  bg-gray-100 -right-10 top-10 z-40 shadow-lg border-gray-200 p-6 flex items-center justify-center hidden ">
                            <div className="flex justify-between flex-1">
                                <h1 className="text-xl font-bold text-red-600">ERROR ADDING ITEM TO YOUR BAG<p className="text-sm">Please try again.</p></h1>
                                <FontAwesomeIcon icon={faTimes} className="text-2xl" aria-hidden="true"/>
                            </div>
                        </div>

                        <div className={loading ? "w-96 rounded-xl h-60 absolute border  bg-gray-100 -right-10 top-10 z-40 shadow-lg border-gray-200 p-6 flex items-center justify-center" : 'hidden'}>
                            <img src="images/tenor.gif" className="w-7"/>
                        </div>
                    </div>
                </FadeIn>
                : null}
        </>
    );
}

export default CartItemUpdateBubble;
