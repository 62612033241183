export const CreateCustomFormData = (data, method=null) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key])
      formData.append(key, data[key]);
  }
  if(method) {
    formData.append('_method', method);
  }
  console.log('form data:', formData);
  return formData;
}