import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const TrainingTopic = {
    Index: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/training-topics`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Create: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/training-topics/create`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (branchId, topicId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/training-topics/${topicId}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (branchId, data, successCb, failCb) => {
        axios.post(`/branch/${branchId}/training-topics`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (branchId, topicId, data, successCb, failCb) => {
        axios.put(`/branch/${branchId}/training-topics/${topicId}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Destroy: (branchId, topicId, successCb, failCb) => {
        axios.delete(`/branch/${branchId}/training-topics/${topicId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateOrder: (branchId, data, successCb, failCb) => {
        axios.put(`/branch/${branchId}/training-topics/update-order`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default TrainingTopic;