import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {closeDestroyCartBubble} from "../redux/cart/actionCreator";
import FadeIn from 'react-fade-in';
import {Link, useHistory, useLocation} from "react-router-dom";
import BubbleViewBagButton from "./BubbleViewBagButton";
import T from "../components/T";

const CartItemDestroyBubble = () => {
    const loading = useSelector(state => state.cart.destroy_loading);
    const done = useSelector(state => state.cart.destroy_done);

    const bubble = useSelector(state => state.cart.destroy_bubble);
    const menu = useSelector(state => state.menu.index_menu);
    const cart_items = useSelector(state => state.cart.cart_items);

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const cartRoute = location.pathname === "/cart";

    const handleClose = () => {
        dispatch(closeDestroyCartBubble());
    }

    return (
        <>
            {(bubble > 0) && menu ?
                <FadeIn transitionDuration={200} className="absolute right-0 md:right-5 top-28 md:top-20 w-full md:w-96 px-2 md:px-0">
                    <div id="shopping-bag" className={loading || done ? '' : 'hidden'}>

                        <div className={"rounded-xl border bg-gray-100 z-40 shadow-lg border-gray-700 p-6"}>
                            <div className="flex justify-between">
                                <div className="text-xl font-bold text-red-500 uppercase"><T>ITEM REMOVED FROM YOUR BAG</T></div>

                                <button onClick={handleClose}>
                                    <FontAwesomeIcon icon={faTimes} className="text-2xl" aria-hidden="true"/>
                                </button>
                            </div>
                            <BubbleViewBagButton cart_items={cart_items} cartRoute={cartRoute} />

                        </div>
                    </div>
                </FadeIn>
                : null}
        </>
    );
};

export default CartItemDestroyBubble;
