import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Modal, notification, InputNumber } from 'antd';
import { useParams } from 'react-router-dom';
import ModelPerformance from '../../../../http/ModelPerformance';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const { Search } = Input;

const ModelPerformanceComponent = () => {
    const [performances, setPerformances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [retraining, setRetraining] = useState(false);
    const [branch, setBranch] = useState(null);
    const [discardDays, setDiscardDays] = useState(45); // default value
    const [trainingDays, setTrainingDays] = useState(400); // default value
    const [modalVisible, setModalVisible] = useState(false);
    const [currentBranchId, setCurrentBranchId] = useState(null);
    const { branchId } = useParams();

    useEffect(() => {
        fetchModelPerformances();
    }, [branchId]);

    const fetchModelPerformances = () => {
        setLoading(true);
        ModelPerformance.getModelPerformances((response) => {
            setPerformances(response.data.data.performances);
            setBranch(response.data.data.branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load model performances',
            });
            setLoading(false);
        });
    };

    const handleRetrain = (branchId) => {
        setCurrentBranchId(branchId);
        setModalVisible(true);
    };

    const handleRetrainOk = () => {
        setRetraining(true);
        ModelPerformance.retrainModel(currentBranchId, { discard_days: discardDays, training_days: trainingDays }, (response) => {
            notification.success({
                message: 'Success',
                description: response.data.message,
            });
            fetchModelPerformances(); // Refresh the coefficients
            setRetraining(false);
            setModalVisible(false);
            setCurrentBranchId(null);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to retrain model',
            });
            setRetraining(false);
            setModalVisible(false);
            setCurrentBranchId(null);
        });
    };

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: ['branch', 'name'],
            key: 'branch_name',
        },
        {
            title: 'Trained At',
            dataIndex: 'trained_at',
            key: 'trained_at',
        },
        {
            title: 'Data Entries',
            dataIndex: 'data_entries',
            key: 'data_entries',
        },
        {
            title: 'Discard Days',
            dataIndex: 'discard_days',
            key: 'discard_days',
        },
        {
            title: 'Train Days',
            dataIndex: 'train_days',
            key: 'train_days',
        },
        {
            title: 'MAE',
            dataIndex: 'mean_absolute_error',
            key: 'mean_absolute_error',
        },
        {
            title: 'Random MAE',
            dataIndex: 'random_mean_absolute_error',
            key: 'random_mean_absolute_error',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Button onClick={() => handleRetrain(record.branch_id)} loading={retraining}>
                    Retrain Model
                </Button>
            ),
        },
    ];

    return (
        <DefaultContainer>
            <div className={"patty text-2xl font-bold mb-4"}>Model Performances {branch && <>- Branch {branch.name}</>}</div>
            <Button type="primary" onClick={() => handleRetrain(0)} loading={retraining} style={{ marginBottom: '20px' }}>
                Retrain All Models
            </Button>
            <Modal
                title="TRAIN SETTINGS"
                visible={modalVisible}
                onOk={handleRetrainOk}
                onCancel={() => setModalVisible(false)}
                okText="Retrain"
                cancelText="Cancel"
                confirmLoading={retraining}
            >
                <div className={"font-bold mb-2 uppercase"}>Enter total days to discard from the opening:</div>
                <InputNumber
                    min={0}
                    max={365}
                    value={discardDays}
                    onChange={(value) => setDiscardDays(value)}
                    style={{ width: '100%' }}
                    disabled={retraining}
                />
                <div className={"font-bold mb-2 uppercase mt-5"}>Enter max number of days for training:</div>
                <InputNumber
                    min={30}
                    max={3650}
                    value={trainingDays}
                    onChange={(value) => setTrainingDays(value)}
                    style={{ width: '100%' }}
                    disabled={retraining}
                />
            </Modal>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={performances}
                    loading={loading}
                    rowKey="id"
                />
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default ModelPerformanceComponent;
