import React from 'react';
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import T from "../T";

const BackToMenu = ({loading= false, disabled=false}) => {
    const history = useHistory();

    const handleBack = () => {
        if(history.length > 2) {
            history.goBack();
        }
        else {
            history.push("/menu")
        }
    }

    return (
        <button
                className=" bg-red-brand hover:bg-red-700 text-white rounded-md focus:outline-none focus:shadow-outline flex flex-row flex-1 items-center -ml-9 -mt-1 md:mt-3 md:-ml-5"
                style={{display: "flex", height: "auto", padding: "5px 10px", zIndex: "100"}}
                onClick={handleBack}
        >
            <div className="text-white hover:text-white flex-1 text-left flex flex-row items-center text-sm md:text-lg uppercase">
                <FontAwesomeIcon icon={faArrowLeft} aria-hidden="true" className={"mr-2"} />
                <T>BACK</T>
            </div>
        </button>
    );
};

export default BackToMenu;
