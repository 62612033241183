import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const TrainingEvaluation = {
    Index: (userTrainingId, successCb, failCb) => {
        axios.get(`/user-trainings/${userTrainingId}/evaluations`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (userTrainingId, data, successCb, failCb) => {
        axios.post(`/user-trainings/${userTrainingId}/evaluation`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Create: (userTrainingId, successCb, failCb) => {
        axios.get(`/user-trainings/${userTrainingId}/evaluation`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default TrainingEvaluation;
