import React, {useEffect, useRef, useState} from 'react';
import {motion} from "framer-motion";
import UserModal from "../Employees/UserModal";
import T from "../../../../components/T";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import BranchSelector from "../BranchSelector";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import {Tag} from "antd";
import {useSelector} from "react-redux";
import Employees from "../../../../http/Employees";
import Training from "../../../../http/Training";
import TraningNode from "./TrainingNode";
import TrainingNodeCreate from "./TrainingNodeCreate";
import TrainingNode from "./TrainingNode";

const ManageTraining = () => {
    const [trainings, setTrainings] = useState(null);;
    const [loading, setLoading] = useState(true);
    const [createMasterVisible, setCreateMasterVisible] = useState(false);

    const reload = () => {
        if(!trainings) setLoading(true);
        Training.Index((response) => {
            setLoading(false);
            setTrainings(response.data.data.trainings);
        }, (err) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        reload();
    }, []);

    const onCreateMaster = () => {
        setCreateMasterVisible(true);
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full`}
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-3xl font-bold text-black pl-1 flex flex-row mb-2 items-center patty uppercase">
                    Training Checklists
                </div>
                    <div className="w-full flex flex-col lg:flex-row">
                        <div
                            className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                                {trainings &&
                                    <div className={"w-full flex flex-col items-start"}>
                                        {trainings.length == 0 ?
                                            <div className={"text-gray-400"}>
                                                There are not training checklists.
                                            </div>
                                            :
                                            <>
                                                <TrainingNode node={trainings} reload={reload} />
                                            </>
                                        }
                                    </div>
                                }


                            </SkeletonWrapper>
                        </div>
                    </div>
            </div>
        </motion.div>


    );
};
export default ManageTraining;
