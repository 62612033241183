import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Irani = {

    ValidateIndex: (criteria, successCb, failCb) => {
        axios.post('/usa/validate/index', criteria, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    ApprovedBrandsIndex: (approved_type, criteria, successCb, failCb) => {
        axios.post('/usa/approved_brands/index', {approved_type, ...criteria}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateStatus: (venue_id, status, successCb, failCb) => {
        axios.post(`/usa/${venue_id}/update_status/${status}`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


};

export default Irani;
