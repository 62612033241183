import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import {l} from "../../helpers/log";
import {motion} from "framer-motion";
import {ShowHeader} from "../../redux/settings/actionCreator";
import BackToMenu from "../../components/Buttons/BackToMenu";
import FoodImage from "../../components/FoodImage";
import FoodName from "../../components/FoodName";
import CartBadge from "./CartBadge";
import Back from "../../components/Buttons/Back";
import ConditionalLink from "./ConditionalLink";
import OutOfStock from "./OutOfStock";
import T from "../../components/T";


const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96]
};

export default function Category() {
    const {slug} = useParams();
    const categories = useSelector(state => state.menu.index_categories);
    const loading = useSelector(state => state.menu.index_loading);
    const [category, setCategory] = useState(null);
    const dispatch = useDispatch();
    const cart_items = useSelector(state => state.cart.cart_items);

    useEffect(() => {
            dispatch(ShowHeader());
        },
        []);

    useEffect(() => {
            if (categories && slug) {
                l("slug:", slug);
                l("categories:", categories);
                const c = categories.filter(category => category.slug == slug)[0];
                l("found category", c);
                setCategory(c);
            }
            return () => {
                setCategory(null)
            };
        },
        [slug, categories]);


    l("category", category);
    l("category.foods", category?.foods);

    return (
        (category && category.status == 1) &&
        <motion.div className="border-red-700 flex flex-col items-center relative bg-white w-full h-full min-h-screen"
                    initial={{opacity: 0, y: -400}}
                    animate={{opacity: 1, y: 0}}
        >
            <div className={"sm:container mx-auto "}>
            {category ?
                <>
                    <motion.div className="flex flex-row items-start justify-start absolute" style={{top: "20px", left: "50px"}}
                         initial={{opacity: 0, x: -400}}
                         animate={{opacity: 1, x: 0, transition: {delay: 1}}}
                    >
                        <Back />
                    </motion.div>

                    <div style={{paddingTop: '2vw'}} className="font-bold w-full text-center text-4xl sm:text-5xl md:text-6xl lg:text-8xl mt-2 uppercase"><T>{category.name}</T></div>
                    <div className="flex flex-wrap items-center justify-center w-full">
                        {category.foods.map(food => {
                            if(food.status == 0) return null;
                            if(food.price == 0) return null;
                            let purchased = 0;
                            if(cart_items && cart_items.length > 0) {
                                cart_items.map(item => {
                                    if(item[0] == food.id)
                                        purchased += item[2];
                                });

                            }

                            return (
                                <ConditionalLink to={`/food/${food.slug}`} className="w-1/2 xl:w-1/4" enabled={food.in_stock}>
                                    <motion.div
                                        initial={{x: "0", ...transition}}
                                        animate={{x: 0, ...transition}}
                                        exit={{x: "0",}}
                                        whileTap={{
                                            scale: .8,
                                        }}
                                        key={`food-${food.slug}`}
                                    >
                                        <div className="p-2 flex flex-col items-center justify-center text-black relative">
                                            <CartBadge purchased={purchased} />
                                            <OutOfStock in_stock={food.in_stock} />
                                            <FoodImage image={food.image} />
                                            <FoodName name={food.name} name_fr={food.name_fr} />
                                        </div>
                                    </motion.div>
                                </ConditionalLink>
                            );
                        })}
                    </div>
                </>
                : null}
            </div>
        </motion.div>
    )
}