import React from 'react';
import {motion} from "framer-motion";
import Styled from "styled-components";
import {Collapse} from 'antd';
import {roundToTwo} from "../../../services/global";
import CheckoutItem from "./CheckoutItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faStar, faStop} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {SpinnerCircular, SpinnerCircularSplit} from "spinners-react";
import T from "../../../components/T";

const CollapseWrapper = Styled.div`
    .ant-collapse-header {
        font-size:17px;
        font-weight:bold;
    }
`;

const OptionsWrapper = Styled.div`
    span {        
        margin-right:8px;
        color:black;
        font-weight:500;
    }
    
    i {
        padding-left:0px;
        color:gray;
        font-size:13px;
    }
`;

const {Panel} = Collapse;

const ActiveOrderRow = ({order, loading}) => {

    return (

        <div
            className={`${order.marked ? "bg-green-100" : "bg-white"} shadow-lg flex flex-col border border-gray-300 bg-gray-50 overflow-hidden w-full text-lg rounded-xl mb-3 p-2`}
        >
            <div className="flex flex-col p-2">
                <div className="font-bold flex flex-col items-center text-xl lg:text-2xl mb-2 ">
                    <div className="flex flex-row w-full">
                        <div className="flex-1">
                            {order.first_name}
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="uppercase ml-2 px-2 bg-green-100 rounded-lg text-sm flex items-center justify-center font-bold py-1">
                                {order.type}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row justify-end mt-2">
                        <div className="bg-gray-200 px-3 py-1 rounded-xl text-sm">
                            {order.payed_at_hr}
                        </div>
                    </div>

                    <div className="flex flex-row w-full items-center pt-2">
                        <div className="text-sm flex-1">
                            {loading ?
                                <div className="flex flex-row items-center text-xs font-normal">
                                    <SpinnerCircularSplit size={15} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.14)" className="mr-1  h-5"/>
                                </div>
                            :
                                <>
                                    {order.fulfillment_status == "created" ?
                                        <div className="flex flex-row pr-1">
                                            <SpinnerCircular size={15} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.14)" className="mr-1 h-5"/>
                                            Accepting order...
                                        </div>
                                        : null}

                                    {order.fulfillment_status == "reserved" || order.fulfillment_status == "proposed" ?
                                        <div className="flex flex-col items-center justify-center w-full flex items-start justify-start">
                                            <div className="flex flex-row pr-0 text-sm flex items-start justify-start w-full">
                                                <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.14)" className="mr-1 h-5"/>
                                                <T cap>Order is being prepared</T>
                                            </div>
                                        </div>
                                        : null}
                                    {order.fulfillment_status == "prepared" ?
                                        <div className="flex flex-row pr-1 flex items-center w-full flex items-start justify-start">
                                            <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl mb-1" aria-hidden="true"/>
                                            <T cap>Your order is ready</T>
                                        </div>
                                        : null}
                                    {order.fulfillment_status == "completed" ?
                                        <div className="flex flex-row pr-1 flex items-center w-full flex items-start justify-start">
                                            <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl " aria-hidden="true"/>
                                            <T cap>Order has been picked up</T>
                                        </div>
                                        : null}

                                    {order.fulfillment_status == "canceled" || order.fulfillment_status == "failed" ?
                                        <div className="flex flex-row pr-1 flex items-center">
                                            <FontAwesomeIcon icon={faCheck} className="mr-1 text-xl" aria-hidden="true"/>
                                            <T cap>Your Order has been canceled! Please ask the manager about this incident.</T>
                                        </div>
                                        : null}
                                </>
                            }
                        </div>
                        <div className="text-lg">
                            <T cap>Total</T>: ${roundToTwo(order.total)}
                        </div>
                    </div>


                </div>
                <Link to={`/order/${order.uuid}`} className="font-bold ml-0|]
                =lg:py-2 bg-black text-white px-5 py-3 rounded-lg text-base flex items-center justify-center">
                    <T upper>VIEW ORDER</T>
                </Link>
            </div>
        </div>
    );
};

export default ActiveOrderRow;
