// ManageAbilities.js

import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Input, message } from 'antd';
import AbilityGroup from './AbilityGroup';
import Abilities from '../../../../http/Abilities';
import DefaultContainer from "../../../../components/DefaultContainer";

const ManageAbilities = () => {
    const [abilityGroups, setAbilityGroups] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAbilityGroups();
    }, []);

    const fetchAbilityGroups = () => {
        setLoading(true);
        Abilities.Index(
            (response) => {
                setAbilityGroups(response.data.data.ability_groups);
                setLoading(false);
            },
            (err) => {
                message.error('Failed to load ability groups');
                setLoading(false);
            }
        );
    };

    const addNewGroup = () => {
        if (!newGroupName.trim()) return;

        setLoading(true);
        const newGroup = { name: newGroupName, parent_id: null };

        Abilities.StoreGroup(
            newGroup,
            (response) => {
                message.success('Ability group added');
                setAbilityGroups(response.data.data.ability_groups);
                setNewGroupName('');
                setLoading(false);
            },
            (err) => {
                message.error('Failed to add ability group');
                setLoading(false);
            }
        );
    };

    const addNestedGroup = (parentId, newNestedGroupName) => {
        setLoading(true);
        const newGroup = { name: newNestedGroupName, parent_id: parentId };

        Abilities.StoreGroup(
            newGroup,
            (response) => {
                message.success('Nested ability group added');
                setAbilityGroups(response.data.data.ability_groups);
                setLoading(false);
            },
            (err) => {
                message.error('Failed to add nested ability group');
                setLoading(false);
            }
        );
    };

    const addNewAbility = (groupId, abilityName) => {
        if (!abilityName.trim()) return;

        setLoading(true);
        const newAbility = { label: abilityName, group_id: groupId };

        Abilities.Store(
            newAbility,
            (response) => {
                message.success('Ability added');
                fetchAbilityGroups();
                setLoading(false);
            },
            (err) => {
                message.error('Failed to add ability');
                setLoading(false);
            }
        );
    };

    const deleteAbilityGroup = (groupId) => {
        setLoading(true);
        Abilities.DeleteGroup(
            groupId,
            (response) => {
                message.success('Ability group deleted');
                fetchAbilityGroups();
                setLoading(false);
            },
            (err) => {
                message.error('Failed to delete ability group');
                setLoading(false);
            }
        );
    };

    // Function to move groups within a parent group or at the root level
    const moveGroupInParent = (parentGroupId, fromIndex, toIndex) => {
        const parentGroup = findGroupById(abilityGroups, parentGroupId);

        if (parentGroup) {
            if (toIndex < 0 || toIndex >= parentGroup.children.length) return;
            const updatedGroups = [...parentGroup.children];
            const [movedGroup] = updatedGroups.splice(fromIndex, 1);
            updatedGroups.splice(toIndex, 0, movedGroup);
            parentGroup.children = updatedGroups;
            setAbilityGroups([...abilityGroups]);

            // Prepare data for API call
            const groupOrders = updatedGroups.map((group, i) => ({
                id: group.id,
                order: i,
            }));

            Abilities.UpdateGroupOrder(
                {
                    groups: groupOrders,
                    parent_id: parentGroupId,
                },
                (response) => {
                    message.success('Group order updated');
                },
                (err) => {
                    message.error('Failed to update group order');
                }
            );
        } else {
            if (toIndex < 0 || toIndex >= abilityGroups.length) return;
            const updatedGroups = [...abilityGroups];
            const [movedGroup] = updatedGroups.splice(fromIndex, 1);
            updatedGroups.splice(toIndex, 0, movedGroup);
            setAbilityGroups(updatedGroups);

            // Prepare data for API call
            const groupOrders = updatedGroups.map((group, i) => ({
                id: group.id,
                order: i,
            }));

            Abilities.UpdateGroupOrder(
                {
                    groups: groupOrders,
                    parent_id: null,
                },
                (response) => {
                    message.success('Group order updated');
                },
                (err) => {
                    message.error('Failed to update group order');
                }
            );
        }
    };

    // Recursive function to find a group by its ID
    const findGroupById = (groups, groupId) => {
        for (const group of groups) {
            if (group.id === groupId) return group;
            if (group.children && group.children.length) {
                const foundGroup = findGroupById(group.children, groupId);
                if (foundGroup) return foundGroup;
            }
        }
        return null;
    };

    // Function to handle dragging and dropping abilities between groups
    const handleDropAbility = (item, targetGroupId) => {
        const sourceGroupId = item.groupId;
        const abilityId = item.id;

        // Find source and target groups
        const sourceGroup = findGroupById(abilityGroups, sourceGroupId);
        const targetGroup = findGroupById(abilityGroups, targetGroupId);

        if (!sourceGroup || !targetGroup) return;

        // Remove ability from source group
        const movedAbility = sourceGroup.abilities.find(
            (ability) => ability.id === abilityId
        );
        if (!movedAbility) {
            console.error('Ability not found in source group.');
            return;
        }

        sourceGroup.abilities = sourceGroup.abilities.filter(
            (ability) => ability.id !== abilityId
        );

        // Add ability to target group
        targetGroup.abilities = [...(targetGroup.abilities || []), movedAbility];

        // Update state
        setAbilityGroups([...abilityGroups]);

        // Send API request to update the backend
        Abilities.UpdateOrder(
            {
                group_id: targetGroupId,
                abilities: targetGroup.abilities.map((ability, i) => ({
                    id: ability.id,
                    order: i,
                })),
            },
            (response) => {
                message.success('Ability moved and order updated');
            },
            (err) => {
                message.error('Failed to update ability order');
            }
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <DefaultContainer className="manage-abilities mx-auto max-w-8xl py-2">
                <div className="text-xl font-bold text-black mb-4">
                    Manage Abilities
                </div>
                <div className="mb-4 flex items-center">
                    <Input
                        placeholder="New Group Name"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        style={{ width: '300px', marginRight: '10px' }}
                        disabled={loading}
                    />
                    <Button
                        type="primary"
                        onClick={addNewGroup}
                        loading={loading}
                        disabled={loading}
                    >
                        Add Group
                    </Button>
                </div>

                <div className="ability-groups">
                    {abilityGroups.map((group, index) => (
                        <AbilityGroup
                            key={group.id}
                            group={group}
                            index={index}
                            setAbilityGroups={setAbilityGroups}
                            addNestedGroup={addNestedGroup}
                            addNewAbility={addNewAbility}
                            deleteAbilityGroup={deleteAbilityGroup}
                            moveGroupUp={() =>
                                moveGroupInParent(null, index, index - 1)
                            }
                            moveGroupDown={() =>
                                moveGroupInParent(null, index, index + 1)
                            }
                            isFirst={index === 0}
                            isLast={index === abilityGroups.length - 1}
                            moveGroupInParent={moveGroupInParent}
                            parentGroupId={null}
                            handleDropAbility={handleDropAbility}
                            fetchAbilityGroups={fetchAbilityGroups}
                        />
                    ))}
                </div>
            </DefaultContainer>
        </DndProvider>
    );
};

export default ManageAbilities;
