import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {ListActiveOrders, ListOfflineOrders} from "../../../redux/checkout/actionCreator";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import ActiveOrderRow from "./ActiveOrderRow";
import {SpinnerCircular} from "spinners-react";
import Pullable from "react-pullable";
import {SetRedirectSource} from "../../../redux/authentication/actionCreator";
import {motion} from 'framer-motion';
import T from "../../../components/T";
import {faMedal} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActiveOrders = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.checkout.offline_orders_loading);
    const error = useSelector(state => state.checkout.offline_orders_error);
    const success = useSelector(state => state.checkout.offline_orders_success);
    const active_orders = useSelector(state => state.checkout.offline_orders);
    const total_pending_points = useSelector(state => state.checkout.offline_total_pending_points);

    const handleRefresh = () => {
        dispatch(ListOfflineOrders());
    }

    useEffect(() => {
        dispatch(ListOfflineOrders());
        dispatch(SetRedirectSource("/orders/offline"))
    }, []);

    return (
        <Pullable
            onRefresh={handleRefresh}
            spinnerSize={34}
            refreshDuration={500}
        >
            <motion.div className=""
                        initial={{opacity: 0, scale: 1, y: -100}}
                        animate={{opacity: 1, scale: 1, y: 0}}
            >
                <div className="row">
                    <div className="md:container flex flex-col mx-auto pb-10 pt-5 px-2">
                        <h1 className="text-xl font-bold text-black pl-3 flex flex-row mb-2 justify-between">
                            <div>
                                <T upper>OFFLINE ORDERS</T>
                                {loading && active_orders ?
                                    <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="ml-2  h-5"/>
                                    : null}
                            </div>
                        </h1>

                        {total_pending_points > 0 &&
                            <div className="w-full flex flex-col lg:flex-row mb-2">
                                <div className="rounded-xl bg-blue-50 flex-1 border-4 border-black shadow-xl p-2 flex flex-col md:flex-row items-center justify-center text-base">
                                    <div className="flex flex-row">
                                        You have
                                        <div className="flex flex-row items-center font-bold mx-1">
                                            <FontAwesomeIcon icon={faMedal} className="block text-sm" aria-hidden="true"/>
                                            {total_pending_points}
                                        </div>
                                        unclaimed points.
                                    </div>
                                    <div className="flex flex-row md:ml-1">
                                        Please <Link className={"mx-1 underline"} to={"/login"}>login</Link> or <Link className={"mx-1 underline"} to={"/register"}>register</Link>
                                    </div>
                                    to claim your loyalty points.
                                </div>
                            </div>
                        }

                        <div className="w-full flex flex-col lg:flex-row">
                            <div className="rounded-2xl flex-1 border shadow-xl bg-white p-2 flex flex-col items-center justify-center">
                                <SkeletonWrapper loading={loading && !active_orders} skeleton={MenuSkeleton}>
                                    {active_orders && active_orders.length > 0 ?
                                        <div className="w-full">
                                            {active_orders.map(order => {
                                                return (<ActiveOrderRow order={order} loading={loading}/>);
                                            })}
                                        </div>
                                        :
                                        <div className="text-sm text-gray-600 font-normal py-8">You do not have any offline orders.</div>
                                    }
                                </SkeletonWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </Pullable>
    );
};

export default ActiveOrders;
