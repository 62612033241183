import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CheckVersion} from "./redux/settings/actionCreator";
import {useHistory, useLocation} from "react-router-dom";
import {Button} from "antd";

const ApiUnderConstruction = () =>
{
    const dispatch = useDispatch();
    const be_back_string = useSelector(state => state.settings.be_back_string);
    const history = useHistory();
    const location = useLocation();
    const [reloading, setReloading] = useState(false);


    const reload = () => {
        setReloading(true);
        setTimeout(() => {setReloading(false)}, 2000);
    }


    return (
        <div className="w-full h-screen brand-background-red flex flex-col items-center justify-center" style={{overflow: "hidden"}}>
            <div className="text-center text-3xl text-white flex flex-col  bg-black p-5 rounded-2xl mb-5">
                <div className="text-2xl">We will be back</div>
                <div className="text-3xl mb-3 mt-2">{be_back_string}</div>
                <div className="mb-2">
                    <Button type="primary" loading={reloading} onClick={reload}>Reload</Button>
                </div>
            </div>
            <div className="p-5 px-20">
                <img src="/images/hello.png" />
            </div>
            <div className="text-4xl -mt-6 uppercase text-black text-center font-bold" style={{transform: "rotate(-14deg)"}}>
                Under <br/> Constructions
            </div>
        </div>
    );
};

export default ApiUnderConstruction;