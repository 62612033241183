import actions from './actions';
import Auth from '../../http/Auth';
import push from '../../history';
import store from "../store";
import {notification} from "antd";
import Profile from "../../http/Profile";

const {
    redirectSuccess,
    setRedirectSource,
    loginBegin,
    loginSuccess,
    loginErr,
    registerBegin,
    registerSuccess,
    registerErr,
    logoutBegin,
    logoutSuccess,
    logoutErr,
    updateAuthProfileBegin,
    updateAuthProfileSuccess,
    updateAuthProfileError,
    sendForgotPasswordRst,
    sendForgotPasswordBegin,
    sendForgotPasswordSuccess,
    sendForgotPasswordErr,
    resetPasswordRst,
    resetPasswordBegin,
    resetPasswordSuccess,
    resetPasswordErr,
    resetAuthErrors,
    updateTotalUnapprovedCoupons
} = actions;

const login = ({email, password}, history) => {
    return async dispatch => {
        dispatch(loginBegin());

        Auth.login({email, password}, (response) => {

            localStorage.setItem('token', response.data.api_token);
            localStorage.setItem('user', JSON.stringify(response.data.data.user));
            localStorage.setItem('isLoggedIn', "true");

            dispatch(loginSuccess(response.data.data.user, response.data.api_token))

            notification.open({
                message: 'Logged In',
                description:
                    'You have successfully logged in.',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                placement: "bottomRight",
                type: 'success'
            });

            let redirect_source = store.getState().auth.redirect_source;
            console.log("redirect_source: ", redirect_source);
            if(redirect_source) {
                dispatch(redirect(redirect_source, history));
            }
            else {
                dispatch(redirect("/menu", history));
            }
        }, (err) => {
            dispatch(loginErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
        });

    };
};

const SetRedirectSource = (source) => {
    return async dispatch => {
        dispatch(setRedirectSource(source));
    };
};

const redirect = (path, history = null) => {
    return async dispatch => {
        dispatch(redirectSuccess());
        if (history) {
            history.push(path);
        } else {
            push(path, true);
        }
    };
};

const register = ({name, email, password, password_confirmation, terms}, history) => {
    return async dispatch => {
        dispatch(registerBegin());

        Auth.register({name, email, password, password_confirmation, terms}, (response) => {
            if (response.data.data.user) {
                localStorage.setItem('token', response.data.api_token);
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
                localStorage.setItem('isLoggedIn', "true");
                dispatch(registerSuccess(response.data.data.user, response.data.api_token));

                notification.open({
                    message: 'Registration',
                    description:
                        'You have successfully registered.',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    placement: "bottomRight",
                    type: 'success'
                });

                let redirect_source = store.getState().auth.redirect_source;
                console.log("redirect_source: ", redirect_source);
                if(redirect_source) {
                    dispatch(redirect(redirect_source, history));
                }
                else {
                    dispatch(redirect("/menu", history));
                }
            } else {
                dispatch(registerErr("An internal error occurred, please try again later", null));
            }
        }, (err) => {
            dispatch(registerErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors));
        });

    };
};

const logOut = () => {
    return async dispatch => {
        try {
            Auth.logout((response) => {}, (err) => {});
            dispatch(logoutBegin());
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('isLoggedIn');
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};


const updateAuthProfile = (cursor = "") => {
    return async dispatch => {
        dispatch(updateAuthProfileBegin());
        Profile.Edit(cursor, (response) => {
            const profile = response.data.data.profile;
            localStorage.setItem('user', JSON.stringify(profile));
            dispatch(updateAuthProfileSuccess(profile));
        }, (err) => {
            console.log("ERROR", err.response?.data.message);
            updateAuthProfileError(err.response?.data.message);
            notification['error']({message: 'Profile reload Failed!', description: `We cannot refresh your profile information, please contact the administrator.`,});
            push("/logout");
        });
    };
};

const partiallyUpdateAuthProfile = (user) => {
    return async dispatch => {
        const localUser = JSON.parse(localStorage.getItem('user'));
        if(!localUser) return;
        for (const prop in user) {
            localUser[prop] = user[prop];
        }
        dispatch(updateAuthProfileSuccess(localUser));
        localStorage.setItem('user', JSON.stringify(localUser));
    };
};



const sendForgotPasswordReset = () => {
    return async dispatch => {
        dispatch(sendForgotPasswordRst());
    }
}


const sendForgotPassword = ({email}) => {
    return async dispatch => {
        dispatch(sendForgotPasswordBegin());
        Auth.sendForgotPassword({email}, (response) => {
            console.log("sendforgotpassword response:", response);
            dispatch(sendForgotPasswordSuccess())
        }, (err) => {
            console.log("sendforgotpassword err:", err);
            if(err.response) {
                dispatch(sendForgotPasswordErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
            }
            else {
                dispatch(sendForgotPasswordErr("Internal Error, Please try again later.", null))
            }
        });
    };
}

const resetPasswordReset = () => {
    return async dispatch => {
        dispatch(resetPasswordRst());
    }
}

const resetPassword = ({email, token, password, password_confirmation}) => {
    return async dispatch => {
        dispatch(resetPasswordBegin());
        Auth.ResetPassword({email, token, password, password_confirmation}, (response) => {
            console.log("sendforgotpassword response:", response);
            dispatch(resetPasswordSuccess())
        }, (err) => {
            console.log("sendforgotpassword err:", err);
            if(err.response) {
                dispatch(resetPasswordErr(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
            }
            else {
                dispatch(resetPasswordErr("Internal Error, Please try again later.", null))
            }
        });
    };
}

const ResetAuthErrors = () => {
    return async dispatch => {
        dispatch(resetAuthErrors());
    }
}

const UpdateTotalUnapprovedCoupons = (total_unapproved_coupons) => {
    return async dispatch => {
        dispatch(updateTotalUnapprovedCoupons(total_unapproved_coupons));
    }
}

export {login, register, logOut, SetRedirectSource, updateAuthProfile, sendForgotPassword, sendForgotPasswordReset, resetPassword, resetPasswordReset, ResetAuthErrors, partiallyUpdateAuthProfile, UpdateTotalUnapprovedCoupons};