import React, {useEffect, useState} from 'react';
import CouponGeneratePreset from "../../../../../http/CouponGeneratePreset";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import {motion} from "framer-motion";
import CouponGeneratePresetComponent from "./CouponGeneratePresetComponent";
import CouponComponent from "./CouponComponent";

const GenerateIndex = () => {
    const [loading, setLoading] = useState(true);
    const [couponGeneratePresets, setCouponGeneratePresets] = useState([]);
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {reload(true)}, [])

    const reload = (forceShowLoading=false) => {
        if(!couponGeneratePresets || forceShowLoading)
            setLoading(true);

        CouponGeneratePreset.GenerateIndex((response) => {
            setLoading(false);
            setCouponGeneratePresets(response.data.data.coupon_generate_presets);
            setCoupons(response.data.data.coupon_history);
        }, (err) => {
            setLoading(false);
        });
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full less-margin  mx-auto max-w-8xl mt-2`}
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex-1 p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            <div className={"text-left w-full"}>
                                <div className={"font-bold uppercase text-base"}>List of coupons you can generate.</div>
                                <hr className={"mt-2 mb-2"} />
                            </div>
                            {couponGeneratePresets && couponGeneratePresets.length > 0?
                                <div className={"w-full"}>
                                    {couponGeneratePresets.map(couponGeneratePreset => {
                                        return (
                                            <CouponGeneratePresetComponent couponGeneratePreset={couponGeneratePreset} reload={reload} />
                                        )
                                    })}
                                </div>
                                :
                                <div className={"w-full text-center text-gray-400 pb-1"}>There are no coupon for you on PattySlaps.</div>
                            }

                            {coupons && coupons.length > 0?
                                <div className={"mt-2 w-full"}>
                                    <hr/>
                                    <div className={"mt-1 w-full"}>
                                        <div className={"pb-1 uppercase"}>
                                            <b>Your coupon history</b>
                                        </div>
                                        <div className={"flex flex-wrap"}>
                                            {coupons.map(coupon => {
                                                return(<CouponComponent coupon={coupon} />);
                                            })}
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default GenerateIndex;