import React, { useState } from 'react';
import ItemComponent from './ItemComponent';

const MasterComponent = () => {
    const [items, setItems] = useState([
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 2, title: 'Item 2', description: 'Description for Item 2' },
        { id: 3, title: 'Item 3', description: 'Description for Item 3' },
        { id: 4, title: 'Item 4', description: 'Description for Item 4' },
    ]);

    // Update item in parent state
    const updateItem = (id, newTitle, newDescription) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, title: newTitle, description: newDescription } : item
        );
        setItems(updatedItems);
    };

    return (
        <div className="p-4">
            {items.map((item) => (
                <ItemComponent
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    onUpdate={updateItem} // Pass update function to child
                />
            ))}
        </div>
    );
};

export default MasterComponent;
