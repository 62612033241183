import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart } from './Headers';

const ItemApi = {

    // Fetch item details by ID
    Edit: (id, successCb, failCb) => {
        axios.get(`/item/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an item by ID
    Update: (id, data, successCb, failCb) => {
        axios.post(`/item/${id}`, data, getAuthenticationHeadersMultiPart())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default ItemApi;
