import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faBeerMugEmpty, faPhone, faPlus, faStop, faTemperatureEmpty} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import Bloggers from "../../../../http/Bloggers";
import Table from "ant-responsive-table";
import {Button, Calendar, Modal, Pagination, Tag} from "antd";
import {SpinnerCircular} from "spinners-react";
import InviteActions from "./InviteActions";
import InviteeStatus from "./InviteeStatus";
import moment from "moment";
import InstaUser from "../../../../components/InstaUser";

const BloggerAttends = () => {
    const [bloggers, setBloggers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [disabledDates, setDisabledDates] = useState([]);
    const [locking, setLocking] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchAttendingBloggers();
    }, []);

    const fetchAttendingBloggers = () => {
        setLoading(true);
        Bloggers.FetchAttendingBloggers((response) => {
            setBloggers(response.data.data.bloggers)
            setDisabledDates(response.data.data.disabled_dates);
            setLoading(false);
        }, (err) => {
            setBloggers(null);
            setLoading(false);
        });
    }

    const dateCellRender = (date) => {
        if (!bloggers) return;

        const currentDateString = date.format("YYYY-MM-DD");
        const attends = bloggers[currentDateString];

        if (attends && attends.length > 0) {
            return <div className="flex flex-col">
                {disabledDates && disabledDates.includes(currentDateString)?<Tag color={"red"}>LOCKED</Tag>:null}
                {attends.map(attend => <InstaUser username={attend.username}/>)}
            </div>
        }
        return (<div className="text-gray-400 uppercase text-xs flex flex-col">
            {disabledDates && disabledDates.includes(currentDateString)?<Tag color={"red"}>LOCKED</Tag>:null}
            <div className="mt-1">
                No Attends
            </div>
        </div>);
    };

    const onSelect = (date, mode) => {
        const dateString = date.format("YYYY-MM-DD");
        setSelectedDate(dateString);
        setIsModalOpen(true);
    };

    const handleLock = () => {
        setLocking(true);
        Bloggers.LockAttendanceDate(selectedDate,(response) => {
            setDisabledDates(response.data.data.disabled_dates);
            setLocking(false);
        }, (err) => {
            setLocking(false);
        });
    }

    const handleUnlock = () => {
        setLocking(true);
        Bloggers.UnlockAttendanceDate(selectedDate,(response) => {
            setDisabledDates(response.data.data.disabled_dates);
            setLocking(false);
        }, (err) => {
            setLocking(false);
        });
    }

    return (
        <>
            <Modal title={`Attendance for ${selectedDate}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                   footer={[ // Provide a custom footer element with only the OK (confirm) button
                       <div className="flex flex-row justify-between">
                           {disabledDates && disabledDates.includes(selectedDate) ?
                               <Button key="ok" type="primary" onClick={handleUnlock} loading={locking}>
                                   UNLOCK
                               </Button>
                           :
                               <Button key="ok" type="primary" onClick={handleLock} danger loading={locking}>
                                   LOCK
                               </Button>
                           }

                           <Button key="ok" type="primary" onClick={handleOk} loading={locking}>
                               OK
                           </Button>
                       </div>
                   ]}
            >

                <div>
                    {bloggers && bloggers[selectedDate] && bloggers[selectedDate].length > 0?
                        <div>{bloggers[selectedDate].map(attend =>
                            <div className={"px-2 py-1 mb-1 bg-gray-100 rounded-lg border-gray-200 text-black flex flex-row justify-between items-center"}>
                                <div className="flex flex-row items-center">
                                    <a href={"https://instagram.com/"+attend.username} className="text-sm" target={"_blank"}>{attend.username} </a>
                                    {attend.will_attend_at_hour?
                                    <div className="px-2 bg-purple-200 rounded-lg ml-2 text-xs py-1 font-bold">
                                        at {attend.will_attend_at_hour}
                                    </div>
                                    :null}
                                </div>
                                {attend.contact_phone?
                                    <div className={"font-bold text-sm px-2 bg-purple-200 rounded-lg py-1 text-xs"}>
                                        <FontAwesomeIcon icon={faPhone} aria-hidden="true" className={"mr-2"}/>
                                        {attend.contact_phone}
                                    </div>
                                :null}
                            </div>
                    )}</div>
                    :<div>No Attendance.</div>}
                </div>
            </Modal>

            <motion.div
                initial={{opacity: 0, scale: 1.1, y: 0}}
                animate={{opacity: 1, scale: 1, y: 0}}
                className="w-full"
            >
                <div className="flex flex-col mx-auto w-full">
                    <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-3 items-center pr-2">
                        <div className="flex flex-row items-center"><T upper>BLOGGERS ATTENDS</T>
                            {loading ?
                                <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="mr-4 h-5 ml-2"/>
                                : null}
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row">
                        <div className="rounded-2xl flex-1 border shadow-xl p-3 flex flex-col items-center justify-center py-6 w-full">
                            <Calendar dateCellRender={dateCellRender} onSelect={onSelect}/>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
};
export default BloggerAttends;
