import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const TaskGroup = {

    Show: (task_group_id, completed, successCb, failCb) => {
        axios.get(`/taskgroup/${task_group_id}${completed?'/completed':''}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


    Store: (data, completed, successCb, failCb) => {
        axios.post(`/taskgroup`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (task_group_id, data, completed, successCb, failCb) => {
        axios.post(`/taskgroup/${task_group_id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (task_group_id, successCb, failCb) => {
        axios.delete(`/taskgroup/${task_group_id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default TaskGroup;
