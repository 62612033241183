import React from 'react';
import {motion} from "framer-motion";

const CartBadge = ({purchased}) => {
    return (
        purchased?
            <motion.div
                initial={{opacity: 0, scale: 5}}
                animate={{opacity: 1, scale: 1, transition: {delay: .4}}}
                key={`added-food-name`}
                className="absolute" style={{right: "15%", top: "15%"}}
            >
                <div className="bg-red-800 text-white flex flex-row items-center justify-center rounded-full shadow-xl text-2xl h-12 w-12 md:text-5xl md:w-16 md:h-16" style={{letterSpacing: "0vw"}}>
                    <div style={{marginTop: "0vw"}}>{purchased}</div>
                </div>
            </motion.div>
        :null
    );
};

export default CartBadge;
