import React, {useEffect, useRef, useState} from 'react';
import {notification} from "antd";
import Division from "../../../../../http/Division";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';
import TaskGroupDetails from "../TaskGroup/TaskGroupDetails";

const DivisionTimeline = () => {
    const [divisions, setDivisions] = useState(null);
    const user = useSelector(state => state.auth.user);

    const reload = () => {
        Division.TimelineIndex((response) => {
            setDivisions(response.data.data.divisions);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }
    useEffect(() => {
        reload();
    }, []);

    return (
        <div className={"flex flex-col w-full h-screen"}>
            <div className={"w-full flex flex-row items-center justify-center text-4xl font-bold uppercase patty bg-white"}>
                Divisions Timeline
            </div>
            <SkeletonWrapper loading={!divisions} skeleton={GenericSkeleton}>
                {divisions &&
                    <div className={"flex flex-col p-1 py-2"}>
                        {divisions.map(division => {
                            if(division.task_groups.length == 0) return null;
                            return (

                                <motion.div
                                    initial="initial"
                                    exit="initial"
                                    whileHover="hovered"
                                    key={`division-${division.id}`}
                                    className={`rounded-lg hover:text-black border-2 border-black shadow hover:border-black px-4 py-4 text-black text-base  m-1 flex flex-col
                                                ${division.users.find(u => u.id === user.id && u.pivot.role === "accountable") ? 'bg-green-300' : (division.users.some(u => u.id === user.id) ? 'bg-green-100' : 'bg-gray-200')}
                                            `}
                                >
                                    <Link to={`/dashboard/division/${division.id}`} className={"text-black hover:text-black"}>
                                        <div className={"font-bold text-4xl uppercase patty border-b border-gray-500 mb-2 pb-2"}>{division.name}</div>
                                    </Link>
                                    <div className={"flex flex-wrap"}>
                                        {division.users.map(u => {
                                            return (
                                                <div className={`px-2 py-0.5 bg-gray-100 border border-gray-400 rounded-md text-sm m-0.5 ${u.pivot.role == "accountable" ? "font-bold" : "text-gray-600"} ${u.id == user.id ? "" : ""} uppercase`}>
                                                    {u.pivot.role == "accountable" &&
                                                        <FontAwesomeIcon icon={faCrown} className="text-sm mr-1" aria-hidden="true"/>
                                                    }
                                                    {u.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={"rounded-lg overflow-hidden border border-gray-400 bg-white mt-1"}>
                                        {division.task_groups.map(task_group => {return (
                                            <div className={"border-t-2 border-black mt-2"}>
                                                <TaskGroupDetails tg={task_group} timeline_reload={reload} />
                                            </div>
                                        )})}
                                    </div>
                                </motion.div>
                            )
                        })}
                    </div>
                }
            </SkeletonWrapper>
        </div>
    );
};

export default DivisionTimeline;