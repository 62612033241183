import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExpand} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectItem} from "../../redux/food/actionCreator";
import {Translate} from "../../helpers/Translate";

const SelectedItemsNames = ({selectedItems, option}) => {

    let names = [];
    const multiselect = option.multiselect == 1;
    let changed = false;
    const lang = useSelector(state => state.settings.lang);

    for(const ikey in option.items) {
        const item = option.items[ikey];

        if(item.default == 0 || multiselect) {
            if (selectedItems.indexOf(item.id) !== -1) {
                if(item.default == 0) changed = true;
                names.push(Translate(item.name));
            }
        }
    }

    if(names.length == 0) return null;
    if(multiselect && !changed) return null;

    return (
        <span>
            {names.join(' - ')}
        </span>
    );
};

export default SelectedItemsNames;
