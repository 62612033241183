import React, { useEffect, useState } from 'react';
import { Modal, Form, Checkbox, Button, notification } from 'antd';
import FormItem from "../../../../../components/Form/FormItem";

const ManageRolesModal = ({ visible, onSave, onCancel, departments, editCouponGeneratePreset, saving, form }) => {
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    useEffect(() => {
        if (editCouponGeneratePreset) {
            const presetRoles = editCouponGeneratePreset.department_roles.map(role => ({
                departmentId: role.department_id,
                roleId: role.id
            }));
            setSelectedDepartments(presetRoles);
            form.setFieldsValue({ roles: presetRoles });
        } else {
            setSelectedDepartments([]);
            form.resetFields();
        }
    }, [editCouponGeneratePreset, form]);

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        const formattedDepartments = selectedDepartments.reduce((acc, { departmentId, roleId }) => {
            const department = acc.find(d => d.id === departmentId);
            if (department) {
                department.roles.push(roleId);
            } else {
                acc.push({ id: departmentId, roles: [roleId] });
            }
            return acc;
        }, []);
        onSave({ departments: formattedDepartments }, editCouponGeneratePreset ? true : false);
    };

    const handleDepartmentRoleChange = (departmentId, roleId) => {
        setSelectedDepartments(prev => {
            const index = prev.findIndex(d => d.departmentId === departmentId && d.roleId === roleId);
            if (index !== -1) {
                return prev.filter(d => d.departmentId !== departmentId || d.roleId !== roleId);
            } else {
                return [...prev, { departmentId, roleId }];
            }
        });
    };

    const handleSelectAll = (departmentId) => {
        setSelectedDepartments(prev => {
            const department = departments.find(dept => dept.id === departmentId);
            const allRoles = department.roles.map(role => role.id);
            const updatedDepartments = prev.filter(d => d.departmentId !== departmentId);
            allRoles.forEach(roleId => updatedDepartments.push({ departmentId, roleId }));
            return updatedDepartments;
        });
    };

    const handleDeselectAll = (departmentId) => {
        setSelectedDepartments(prev => prev.filter(d => d.departmentId !== departmentId));
    };

    return (
        <Modal
            visible={visible}
            title={"Departments"}
            okText={"Save"}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={onSubmit}
            confirmLoading={saving}
            key={'manage-roles'}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ roles: editCouponGeneratePreset?.roles?.map(role => role.id) || [] }}
            >
                <div className="">
                    <ul>
                        {departments.map(department => (
                            <li key={department.id} className={"mb-3"}>
                                <div className="font-bold mb-0">
                                    {department.name}
                                    {department.roles && department.roles.length > 0 &&
                                        <>
                                            <Button type="link" onClick={() => handleSelectAll(department.id)} className={"uppercase"} style={{ fontSize: "10px", marginRight: "0px", paddingRight: "10px" }}>ALL</Button>
                                            <Button type="link" onClick={() => handleDeselectAll(department.id)} className={"uppercase"} style={{ fontSize: "10px", marginLeft: "0px", paddingLeft: "0px" }}>NONE</Button>
                                        </>
                                    }
                                </div>
                                {department.roles && department.roles.length > 0 ?
                                    <ul className="ml-4">
                                        {department.roles.map(role => (
                                            <li key={role.id}>
                                                <Checkbox
                                                    checked={selectedDepartments.some(d => d.departmentId === department.id && d.roleId === role.id)}
                                                    onChange={() => handleDepartmentRoleChange(department.id, role.id)}
                                                >
                                                    {role.name}
                                                </Checkbox>
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    <div className={"text-xs text-gray-400 ml-4 pt-1"}>No Roles</div>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </Form>
        </Modal>
    );
};

export default ManageRolesModal;
