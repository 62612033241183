import React, {useEffect, useState} from 'react';
import {Form, Pagination, Select} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {editProfile, resetEditProfile, updateProfile} from '../../../redux/profile/actionCreator';
import SkeletonWrapper from '../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../components/skeletons/GenericSkeleton';
import ProfileForm from './Form';
import FormError from '../../../components/Form/FormError';
import UserMenu from "./UserMenu";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import OrderRow from "./OrderRow";
import {motion} from "framer-motion";
import T from "../../../components/T";

const {Option} = Select;
const Profile = () => {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();

    const edit_profile = useSelector(state => state.profile.edit_profile);
    const edit_loading = useSelector(state => state.profile.edit_loading);
    const edit_error = useSelector(state => state.profile.edit_error);

    const updated_profile = useSelector(state => state.profile.update_profile);
    const update_loading = useSelector(state => state.profile.update_loading);
    const update_fields = useSelector(state => state.profile.update_fields);
    const update_error = useSelector(state => state.profile.update_error);

    const history = useHistory();
    const [form] = Form.useForm();

    const handleSubmit = () => {

        if (location.state && location.state.created) {
            let state = {...history.location.state};
            delete state.created;
            history.replace({...history.location, state});
        }

        console.log({...form.getFieldsValue(true)});

        dispatch(updateProfile({...form.getFieldsValue(true)}));
        setSubmitted(true);
    };

    useEffect(() => {
        dispatch(resetEditProfile());
        dispatch(editProfile());
        setLoaded(true);
    }, []);

    const error = update_error ? update_error : edit_error;

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="row"
        >
            <div className="md:container flex flex-col mx-auto pb-10 pt-5 px-4">
                <h1 className="text-4xl font-bold text-black"><T upper>MY PROFILE</T></h1>
                <div className="w-full flex flex-col lg:flex-row">

                    <UserMenu menu={"profile"}/>

                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-6 flex flex-col">
                        <SkeletonWrapper loading={edit_loading} skeleton={MenuSkeleton}>
                            {!(edit_profile && loaded) && error ? <FormError error={error}/> : null}
                            {loaded && edit_profile ?
                                <ProfileForm form={form}
                                             validationFields={update_fields}
                                             loading={update_loading}
                                             handleSubmit={handleSubmit}
                                             profile={updated_profile ? updated_profile : edit_profile}
                                             error={error}
                                />
                                : null}
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>

    );
};

export default Profile;
