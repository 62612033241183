import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import GooglePlaces from "../../../../../http/GooglePlaces";
import {faMedal, faPlusCircle, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Select} from 'antd';
import BaseReview from "./BaseReview";
import {SpinnerCircular} from "spinners-react";

const {Option} = Select;

const BaseReviewsIndex = () => {

    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState(null);
    const [selectedPlaceId, setSelectedPlaceId] = useState(() => {
        const storedValue = localStorage.getItem('selectedPlaceId');
        const parsedValue = parseInt(storedValue, 10);
        return !isNaN(parsedValue) ? parsedValue : null;
    });
    const [baseReviews, setBaseReviews] = useState(null);
    const [baseReviewsLoading, setBaseReviewsLoading] = useState(false);
    const [type, set_type] = useState('latest');

    const reload = () => {
        if (!places) setLoading(true);
        GooglePlaces.Index((response) => {
            setLoading(false);
            setPlaces(response.data.data.places);
            // After loading, check if the stored ID is still valid
            /*
            const validId = response.data.data.places.some(place => place.id === selectedPlaceId);
            if (!validId) {
                console.log("id is not valid!", parseInt(selectedPlaceId));
                setSelectedPlaceId(null);
                localStorage.removeItem('selectedPlaceId');
            }

             */
        }, (err) => {
            setLoading(false);
            console.error(err);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        // Restore selection from localStorage on component mount
        const storedSelection = localStorage.getItem('selectedPlaceId');
        if (storedSelection) {
            setSelectedPlaceId(storedSelection);
        }
    }, [places]);

    useEffect(() => {
        if (selectedPlaceId > 0) {
            console.log("Getting the reviews for google with id", selectedPlaceId);
            reloadGoogleBaseReviews();
        } else {
            setBaseReviews(null);
        }
    }, [selectedPlaceId]);

    const handleChange = (event) => {
        const {value} = event.target;
        setSelectedPlaceId(value);
        localStorage.setItem('selectedPlaceId', value);
    };

    const reloadGoogleBaseReviews = () => {
        setBaseReviewsLoading(true);
        setBaseReviews(null)

        GooglePlaces.BaseReviews(selectedPlaceId, (response) => {
            setBaseReviewsLoading(false);
            setBaseReviews(response.data.data.base_reviews);
        }, (err) => {
            setBaseReviewsLoading(false);
            console.error(err);
        });
    }

    const filteredReviews = () => {
        switch (type) {
            case 'latest':
                // Return the first 50 reviews
                return baseReviews.slice(0, 50);
            case 'shadowed':
                // Return reviews where status is 'failed'
                return baseReviews.filter(review => review.status === 'failed');
            case 'all':
                // Return all reviews
                return baseReviews;
            default:
                return baseReviews;
        }
    };


    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full`}
        >

            <div className="flex flex-col mx-auto w-full">
                <div className="text-3xl font-bold text-black pl-1 flex flex-row mb-2 items-center patty uppercase">
                    Google Reviews
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            <div className={"flex flex-col justify-start w-full"}>
                                {places != null &&
                                    <div className={"w-full flex flex-col items-start"}>
                                        {places.length == 0 ?
                                            <div className={"text-gray-400"}>
                                                There are no google places.
                                            </div>
                                            :
                                            <div className={"flex flex-row items-center"}>
                                                <select value={selectedPlaceId} onChange={handleChange} disabled={loading} className={"border border-gray-500 px-2 py-2"}>
                                                    <option value="">Select a place</option>
                                                    {places.map(place => (
                                                        <option key={place.id} value={place.id}>{place.name} ({place.place_id})</option>
                                                    ))}
                                                </select>

                                                {selectedPlaceId > 0 &&
                                                    <FontAwesomeIcon icon={faRefresh} className="block text-xl cursor-pointer ml-3" aria-hidden="true" onClick={reloadGoogleBaseReviews}/>
                                                }

                                            </div>
                                        }
                                    </div>
                                }
                                <div className={"mt-2"}>
                                    {baseReviews ?
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row pb-2 uppercase"}>
                                                <div className={`p-2 py-1 mx-1 ${type == 'latest'?'bg-green-600 text-white font-bold':'bg-gray-200'} cursor-pointer`} onClick={() => set_type('latest')}>Latest</div>
                                                <div className={`p-2 py-1 mx-1 ${type == 'shadowed'?'bg-green-600 text-white font-bold':'bg-gray-200'} cursor-pointer`} onClick={() => set_type('shadowed')}>Shadowed</div>
                                                <div className={`p-2 py-1 mx-1 ${type == 'all'?'bg-green-600 text-white font-bold':'bg-gray-200'} cursor-pointer`} onClick={() => set_type('all')}>All</div>
                                            </div>
                                            {filteredReviews().map(review => {
                                                return (<BaseReview key={review.id} review={review}/>);
                                            })}
                                        </div>
                                        :
                                        <div className={"text-gray-400"}>
                                            {selectedPlaceId > 0 ?
                                                <>
                                                {baseReviewsLoading ?
                                                    <>
                                                        <div className="py-24 w-full flex flex-col items-center justify-center opacity-70">
                                                            <SpinnerCircular size={200} thickness={100} speed={80} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)"/>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        There are no reviews for this place.
                                                    </>
                                                }
                                                </>
                                            :
                                                <>Please select a place.</>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>

    );
};

export default BaseReviewsIndex;