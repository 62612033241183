import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Select, Button, notification, Spin } from 'antd';
import Device from '../../../../http/Device';
import DefaultContainer from '../../../../components/DefaultContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faStore } from "@fortawesome/free-solid-svg-icons";
import {CountdownCircleTimer} from 'react-countdown-circle-timer';

const { Option } = Select;

const DeviceForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [branches, setBranches] = useState([]);
    const [device, setDevice] = useState(null);
    const [deviceCode, setDeviceCode] = useState(null);
    const [deviceCodeId, setDeviceCodeId] = useState(null);
    const [deviceId, setDeviceId] = useState(null);
    const [deviceCodeLoading, setDeviceCodeLoading] = useState(false);
    const [deviceIdLoading, setDeviceIdLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            Device.Edit(id, (response) => {
                const deviceData = response.data.data.device;
                setDevice(deviceData);
                setBranches(response.data.data.branches);
                form.setFieldsValue(deviceData);
                setDeviceId(deviceData.square_device_id);
                setLoading(false);
            }, (err) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load device data.',
                });
                setLoading(false);
            });
        } else {
            Device.Create((response) => {
                setBranches(response.data.data.branches);
            }, (err) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load branches.',
                });
            });
        }
    }, [id]);

    const handleSubmit = (values) => {
        setLoading(true);
        const saveFn = id ? Device.Update : Device.Store;

        saveFn(
            { id, data: values },
            (response) => {
                notification.success({
                    message: 'Success',
                    description: `Device ${id ? 'updated' : 'created'} successfully.`,
                });
                history.push('/dashboard/settings/terminals/devices');
                setLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error',
                    description: err.response?.data?.message || `Failed to ${id ? 'update' : 'create'} the device.`,
                });
                setLoading(false);
            }
        );
    };

    const onGetDeviceCode = () => {
        setDeviceCodeLoading(true);
        Device.GetDeviceCode(device.id, (response) => {
            setDeviceCodeLoading(false);
            setDeviceCode(response.data.data.device_code);
            setDeviceCodeId(response.data.data.device_code_id);
            setDeviceIdLoading(true);
            getDeviceId(response.data.data.device_code_id);
        }, (err) => {
            setDeviceCodeLoading(false);
            notification.error({
                message: 'Error',
                description: 'Failed to get device code.',
            });
        });
    };

    const getDeviceId = (deviceCodeId) => {
        Device.GetDeviceId(deviceCodeId, (response) => {
            setDeviceId(response.data.data);
            form.setFieldsValue({ square_device_id: response.data.data });
            setDeviceIdLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Fetching device ID failed.',
            });
            clearDeviceId();
        });
    };

    const clearDeviceId = () => {
        setDeviceId(null);
        setDeviceCode(null);
        setDeviceCodeId(null);
        setDeviceIdLoading(false);
    };

    const onDeviceCodeTimeout = () => {
        setDeviceCode(null);
        setDeviceCodeId(null);
        setDeviceIdLoading(false);
    };

    return (
        <DefaultContainer className={"mx-auto max-w-8xl py-2 mt-4"} append>
            <h2 className="text-2xl font-bold mb-4">{id ? 'Edit Device' : 'Add Device'}</h2>
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item name="branch_id" label="Branch" rules={[{ required: true, message: 'Please select a branch' }]}>
                        <Select placeholder="Select a branch">
                            {branches.map(branch => (
                                <Option key={branch.id} value={branch.id}>
                                    {branch.name} - {branch.address}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter a device name' }]}>
                        <Input placeholder="Device Name" />
                    </Form.Item>
                    <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a device type' }]}>
                        <Select placeholder="Select a device type" onChange={value => setDevice({ ...device, type: value })}>
                            {/*
                            <Option value="autopay">Autopay</Option>
                            <Option value="moneris">Moneris</Option>
                            */}
                            <Option value="square">Square</Option>
                        </Select>
                    </Form.Item>

                    {/* Logic for Square Fields */}
                    {device?.type === 'square' && device && id && (
                        <>
                            {deviceId ? (
                                <>
                                    <Form.Item name="square_device_id" label="Square Device ID" initialValue={deviceId} style={{marginBottom: "3px"}}>
                                        <Input prefix={<FontAwesomeIcon icon={faStore} />} placeholder="Square Device ID" disabled />
                                    </Form.Item>
                                    <Button type="primary" danger onClick={clearDeviceId}>
                                        Clear Square Device ID
                                    </Button>
                                </>
                            ) : (
                                <div className="flex flex-row items-center">
                                    <div className="flex flex-row items-center">
                                        <div className="mr-3 font-bold">Device Code:</div>
                                        {!deviceCode ? (
                                            <Button loading={deviceCodeLoading} type="primary" onClick={onGetDeviceCode}>
                                                Get a Device Code
                                            </Button>
                                        ) : (
                                            <div className="flex flex-row items-center">
                                                <div className="text-white bg-black px-5 py-2 ml-5" style={{ borderRadius: '1vw' }}>
                                                    {deviceCode}
                                                </div>
                                                <div className="py-2 pl-4 text-sm">
                                                    <FontAwesomeIcon icon={faArrowLeft} /> Use This Code On Your Terminal
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {deviceIdLoading && (
                                        <div className="flex flex-row items-center justify-center ml-5">
                                            <CountdownCircleTimer
                                                isPlaying
                                                duration={2 * 60}
                                                colors={['#58a200', '#F7B801', '#A30000', '#4d0000']}
                                                colorsTime={[120, 60, 40, 30]}
                                                size={60}
                                                strokeWidth={5}
                                                onComplete={onDeviceCodeTimeout}
                                            >
                                                {({ remainingTime }) => {
                                                    if (remainingTime === 0) {
                                                        return <div className="text-xs text-red-600">Device Code Expired.</div>;
                                                    }
                                                    return (
                                                        <div className="timer">
                                                            <div className="font-bold text-xs"></div>
                                                            <div className="value">{remainingTime} Sec</div>
                                                        </div>
                                                    );
                                                }}
                                            </CountdownCircleTimer>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} className={"mt-4"}>
                            {id ? 'Update Device' : 'Create Device'}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </DefaultContainer>
    );
};

export default DeviceForm;
