import React from 'react';
import {Slider} from "antd";

const Parameter = ({label, value, step, minValue, maxValue, onSet}) => {
    return (
        <div className={"flex flex-row w-full items-center"}>
            <div className={"w-56 font-bold uppercase text-right pr-2 flex flex-row items-center justify-end"}>
                {label} &nbsp; <div className={"text-lg px-2 bg-gray-100 rounded-full border border-gray-200"}>{value}</div>
            </div>
            <div className={"flex-1"}>
                <Slider value={value} min={minValue} max={maxValue} onChange={onSet} step={step} />
            </div>
        </div>
    );
};

export default Parameter;