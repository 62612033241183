import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart } from './Headers';
import { CreateCustomFormData } from './CustomFormData';

const Profile = {
  Edit: (cursor, successCb, failCb) => {
    axios.get(`/profile?cursor=${cursor}`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },
  Update: (data, successCb, failCb) => {
    const formData = CreateCustomFormData(data, "PUT");
    axios.post(`/profile`, formData, getAuthenticationHeaders())
      .then(response => {
        successCb(response);
      }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },
  UpdatePassword: (data, successCb, failCb) => {
    const formData = CreateCustomFormData(data, "PUT");
    axios.post(`/update_password`, formData, getAuthenticationHeaders())
      .then(response => {
        successCb(response);
      }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  Delete: (data, successCb, failCb) => {
    axios.post(`/user/delete`, data, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      failCb(err);
    });
  },

  Orders: (page, successCb, failCb) => {
    axios.get(`/user/orders?page=${page}`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  Requests: (successCb, failCb) => {
    axios.get(`/checkout/request/index`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  ApproveRequest: (request_id, successCb, failCb) => {
    axios.post(`/checkout/request/${request_id}/approve`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  RejectRequest: (request_id, successCb, failCb) => {
    axios.post(`/checkout/request/${request_id}/reject`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  ActiveOrders: (successCb, failCb) => {
    axios.get(`/checkouts/active`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      failCb(err);
    });
  },

  OfflineOrders: (successCb, failCb) => {
    const order_uids = JSON.parse(localStorage.getItem('order_uids')) || [];
    axios.post(`/checkouts/offline`, {order_uids}, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      failCb(err);
    });
  },
};

export default Profile;
