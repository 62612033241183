import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {Form, Input, Button, Upload, Select, notification} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import ItemApi from "../../../../../http/ItemApi";  // Assuming you have an API handler for items
import {RiImageLine} from "@remixicon/react";
import DefaultContainer from "../../../../../components/DefaultContainer";

const {Option} = Select;

const EditItem = () => {
    const [form] = Form.useForm();
    const [item, setItem] = useState(null);
    const [itemTypes, setItemTypes] = useState([]);  // For storing available item types
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [image, setImage] = useState(null);  // For handling the image upload

    const history = useHistory();
    const {id, optionId, itemId} = useParams();  // Destructure the food, option, and item IDs from the URL

    // Load the item details and item types
    const loadItemDetails = () => {
        setLoading(true);

        ItemApi.Edit(itemId, (response) => {
            const {item, item_types} = response.data.data;  // Assuming your API passes item types
            setItem(item);
            setItemTypes(item_types);

            form.setFieldsValue({
                name: item.name,
                name_fr: item.name_fr,
                price: item.price,
                item_type_id: item.item_type_id
            });

            setLoading(false);
        }, (err) => {
            notification.error({message: 'Error!', description: err.response?.data.message});
            setLoading(false);
        });
    };

    useEffect(() => {
        loadItemDetails();
    }, []);

    const saveItem = (values) => {
        setSaving(true);
        const data = new FormData();
        data.append('name', values.name);
        data.append('name_fr', values.name_fr);
        data.append('price', values.price);
        data.append('item_type_id', values.item_type_id);

        if (values.image) {
            data.append('image', values.image.file);  // Append the image file to FormData
        }

        ItemApi.Update(itemId, data,
            () => {
                notification.success({message: 'Success!', description: 'Item updated successfully!'});
                setSaving(false);
                history.push(`/dashboard/storefront/foods/${id}/options/${optionId}/edit`);  // Redirect after save
            },
            (err) => {
                notification.error({message: 'Error!', description: err.response?.data.message});
                setSaving(false);
            }
        );
    };

    const handleImageUpload = (file) => {
        setImage(file);  // Store the uploaded image in the state
        return false;  // Prevent automatic upload by Ant Design
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <h1 className="text-xl font-bold">Edit Item</h1>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <div>
                        <Button onClick={() => history.push(`/dashboard/storefront/foods/${id}/options/${optionId}/edit`)} type="default" className="mb-4">
                            Back to Option
                        </Button>
                    </div>

                    <Form
                        form={form}
                        onFinish={saveItem}
                        className="mt-4"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                    >
                        <Form.Item
                            name="name"
                            label="Item Name"
                            rules={[{required: true, message: 'Please enter the item name'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="name_fr"
                            label="Item Name (French)"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="price"
                            label="Price"
                            rules={[{required: true, message: 'Please enter the price'}]}
                        >
                            <Input type="number" min={0} step="0.01"/>
                        </Form.Item>

                        <Form.Item
                            name="item_type_id"
                            label="Item Type"
                            rules={[{required: true, message: 'Please select the item type'}]}
                        >
                            <Select placeholder="Select Item Type">
                                {itemTypes.map((type) => (
                                    <Option key={type.id} value={type.id}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="image"
                            label="Item Image"
                        >
                            <Upload
                                listType="picture-card"
                                beforeUpload={handleImageUpload}
                                showUploadList={true}
                                maxCount={1}  // Limit to one image upload
                                onRemove={() => setImage(null)}  // Clear image from the state
                            >
                                {item && item.image ? (
                                    <img src={item.image} alt="item" style={{width: '100%'}}/>
                                ) : (
                                    <RiImageLine/>
                                )}
                            </Upload>
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 6, span: 18}}>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save Item
                            </Button>
                        </Form.Item>
                    </Form>
                </DefaultContainer>
            </SkeletonWrapper>
        </motion.div>
    );
};

export default EditItem;
