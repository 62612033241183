import {Button, Col, Divider, Form, Input, Row, Select} from 'antd';
import FormItem from '../../../components/Form/FormItem';
import React, {useState} from 'react';
import FormError from '../../../components/Form/FormError';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft} from "@fortawesome/free-solid-svg-icons";
import {MaskedInput} from "antd-mask-input";
import DeleteAccount from "./DeleteAccount";
import {Translate} from "../../../helpers/Translate";
import T from "../../../components/T";

const {Option} = Select;
const {TextArea} = Input;

const ProfileForm = ({form, handleSubmit, validationFields, loading, profile, error}) => {

    const [deleteRequestActive, setDeleteRequestActive] = useState(false);

    const onDeleteRequest = () => {
        setDeleteRequestActive(true);
    }

    return (
        <>
            {deleteRequestActive ?
                <DeleteAccount />
                :
                <Form form={form} onFinish={handleSubmit} layout='vertical'>

                    <FormError error={error}/>

                    <Row gutter='20'>

                        <Col lg={24} xs={24} className=''>
                            <FormItem name='name'
                                      label={Translate('Name')}
                                      errors={validationFields}
                                      disabled={true}
                                      initialValue={profile?.name}
                            >
                                <Input prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={Translate('Name')} disabled={loading}/>
                            </FormItem>

                            <FormItem name='email'
                                      label={Translate('Email')}
                                      errors={validationFields}
                                      disabled={true}
                                      initialValue={profile?.email}
                            >
                                <Input prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={Translate('Email Address')} disabled={true}/>
                            </FormItem>

                            <FormItem name='phone'
                                      label={Translate('Phone')}
                                      errors={validationFields}
                                      initialValue={profile?.phone}
                            >
                                <MaskedInput
                                    mask={'(000) 000-0000'}
                                    defaultValue={profile?.phone}
                                    disabled={loading}
                                />
                            </FormItem>

                            <Divider/>
                            <div className="text-base font-bold mb-4"><T>Update Password (leave blank if you don't want to change it)</T></div>
                            <FormItem name='password'
                                      label={Translate('Password')}
                                      errors={validationFields}
                                      initialValue=''
                            >
                                <Input.Password prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={Translate('Password')} disabled={loading} autoComplete={"off"} />
                            </FormItem>
                            <FormItem name='password_confirmation'
                                      label={Translate('Confirm Password')}
                                      errors={validationFields}
                                      initialValue=''
                            >
                                <Input.Password prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={Translate('Confirm Password')} disabled={loading} autoComplete={"off"}/>
                            </FormItem>

                        </Col>


                        <Col xs={24} className='pt-2 flex'>
                            <div className="flex flex-row justify-between">
                                <Button htmlType='submit' size='default' type='primary' key='submit' loading={loading} className=''>
                                    <T>Update Profile</T>
                                </Button>

                                <Button htmlType='submit' size='default' type='primary' danger key='submit' loading={loading} className='' onClick={onDeleteRequest}>
                                    <T>Delete Account</T>
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </Form>
            }
        </>
    );
};

export default ProfileForm;