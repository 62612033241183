import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Departments = {

    Index: (successCb, failCb) => {
        axios.get(`/departments`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/departments`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (department_id, data, successCb, failCb) => {
        axios.post(`/departments/${department_id}/update`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (department_id, successCb, failCb) => {
        axios.delete(`/departments/${department_id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default Departments;
