import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const DiscountPreset = {

    Index: (successCb, failCb) => {
        axios.get(`/coupon_generate_preset`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    GenerateIndex: (successCb, failCb) => {
        axios.get(`/coupon_generate_preset/generate`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SyncRoles: (preset_id, data, successCb, failCb) => {
        axios.post(`/coupon_generate_preset/${preset_id}/sync_roles`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SyncDisabledFoods: (preset_id, data, successCb, failCb) => {
        axios.post(`/coupon_generate_preset/${preset_id}/sync_disabled_foods`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/coupon_generate_preset`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (preset_id, data, successCb, failCb) => {
        axios.put(`/coupon_generate_preset/${preset_id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    GetUnapproveCoupons: (successCb, failCb) => {
        axios.get(`/coupons/unapproved_coupons`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default DiscountPreset;