import React, {useEffect} from 'react';

const HiringForm = () => {

    useEffect(() => {
        setTimeout(() => {window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdNSz7kVmUGLEbmqG9IAuiedADWpdE1ebOgxyb011KxkcMAbQ/viewform?usp=sf_link';}, 5000);

    }, []);

    return (
        <div className="brand-h-screen w-full flex flex-col items-center justify-center">
            Redirecting...
        </div>
    );
};

export default HiringForm;