import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import CouponGeneratePreset from "../../../../../http/CouponGeneratePreset";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import {motion} from "framer-motion";
import CouponGeneratePresetComponent from "./CouponGeneratePresetComponent";
import CouponComponent from "./CouponComponent";
import {UpdateTotalUnapprovedCoupons} from "../../../../../redux/authentication/actionCreator";
import {faMinusCircle, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApproveCoupons = () => {
    const [loading, setLoading] = useState(true);
    const [unapprovedCoupons, setUnApprovedCoupons] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        reload(true)
    }, [])

    const customSetUnApprovedCoupons = (coupons) => {
        setUnApprovedCoupons(coupons);
        dispatch(UpdateTotalUnapprovedCoupons(coupons.length))
    }

    const reload = (forceShowLoading = false) => {
        if (!unapprovedCoupons || forceShowLoading)
            setLoading(true);

        CouponGeneratePreset.GetUnapproveCoupons((response) => {
            setLoading(false);
            setUnApprovedCoupons(response.data.data.unapproved_coupons);
            dispatch(UpdateTotalUnapprovedCoupons(response.data.data.unapproved_coupons.length))
        }, (err) => {
            setLoading(false);
        });
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full less-margin`}
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <div className={"text-left w-full"}>
                            <div className={"font-bold uppercase text-base flex flex-row items-center"}>List of coupons to validate. <FontAwesomeIcon icon={faRefresh} className="text-2xl ml-2 cursor-pointer" aria-hidden="true" onClick={reload}/></div>
                        </div>
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {unapprovedCoupons && unapprovedCoupons.length > 0 ?
                                <div className={"mt-2 w-full"}>
                                    <hr/>
                                    <div className={"mt-1 w-full"}>
                                        <div className={"flex flex-wrap"}>
                                            {unapprovedCoupons.map(coupon => {
                                                return (<CouponComponent coupon={coupon} validate customSetUnApprovedCoupons={customSetUnApprovedCoupons}/>);
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : <div>There are no coupons to validate.</div>}
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ApproveCoupons;