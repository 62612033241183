import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import TrainingTopic from '../../../../../http/TrainingTopic';
import DefaultContainer from '../../../../../components/DefaultContainer';

const CreateEditTrainingTopics = () => {
    const [form] = Form.useForm();
    const { branchId, topicId } = useParams();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState(null);
    const history = useHistory();

    console.log("reached the edit");

    useEffect(() => {
        if (topicId) {
            setLoading(true);
            TrainingTopic.Edit(branchId, topicId, (response) => {
                const { topic, branch } = response.data.data;
                form.setFieldsValue({ name: topic.name, group_name: topic.group_name });
                setBranch(branch);
                setLoading(false);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load training topic data.',
                });
                setLoading(false);
            });
        } else {
            TrainingTopic.Create(branchId, (response) => {
                setBranch(response.data.data.branch);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load branch data.',
                });
            });
        }
    }, [branchId, topicId, form]);

    const onFinish = (values) => {
        setSaving(true);
        if (topicId) {
            // Update existing training topic
            TrainingTopic.Update(branchId, topicId, values, (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'Training Topic updated successfully.',
                });
                history.push(`/dashboard/hr/setup/training-topics/${branch.id}/manage`);
                setSaving(false);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to update training topic.',
                });
                setSaving(false);
            });
        } else {
            // Create new training topic
            TrainingTopic.Store(branchId, values, (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'Training Topic created successfully.',
                });
                form.resetFields();
                setSaving(false);
                history.push(`/dashboard/hr/setup/training-topics/${branch.id}/manage`);

            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to create training topic.',
                });
                setSaving(false);
            });
        }
    };

    return (
        <DefaultContainer>
            <h2>{topicId ? 'Edit' : 'Create'} Training Topic for {branch ? branch.name : 'Loading...'}</h2>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="name"
                    label="Training Topic Name"
                    rules={[{ required: true, message: 'Please enter the training topic name' }]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    name="group_name"
                    label="Group Name"
                    rules={[{ required: false, message: 'Please enter the group name' }]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={saving}>{topicId ? 'Update' : 'Create'} Topic</Button>
                </Form.Item>
            </Form>
        </DefaultContainer>
    );
};

export default CreateEditTrainingTopics;