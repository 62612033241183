import { combineReducers } from 'redux';
import MenuReducer from './menu/reducers';
import FoodReducer from './food/reducers';
import CartReducer from './cart/reducers';
import CheckoutReducer from './checkout/reducers';
import AuthReducer from './authentication/reducers';
import ProfileReducer from "./profile/reducers";
import SettingsReducer from "./settings/reducers";
import DashboardReducer from "./dashboard/reducers";

const rootReducers = combineReducers({
  menu: MenuReducer,
  food: FoodReducer,
  cart: CartReducer,
  checkout: CheckoutReducer,
  auth: AuthReducer,
  profile: ProfileReducer,
  settings: SettingsReducer,
  dashboard: DashboardReducer,

});

export default rootReducers;
