import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const CookieConsent = () =>
{
    const [consent, setConsent] = useState(true);
    const lang = useSelector(state => state.settings.lang);
    const location = useLocation();

    useEffect(() => {
        const c = localStorage.getItem("consent");
        if(!c || c != "true")
            setConsent(false);
    }, []);

    const doConsent = () => {
        localStorage.setItem('consent', "true");
        setConsent(true);
    }

    return (
        !consent && !location.pathname.includes("/print/") &&
        <div className={"fixed bottom-0 w-full pb-20 pt-3 bg-red-brand border-t-4 border-black flex flex-row items-center justify-center text-center px-2"} style={{zIndex: 100001}}>
            <div>
                <div className={"text-white text-lg font-bold pb-3"}>
                    {lang == "en"?
                        <div>
                            By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.
                        </div>
                    :
                        <div>
                            En utilisant ce site web, vous acceptez notre utilisation des cookies. Nous utilisons des cookies pour vous offrir une excellente expérience et pour aider notre site web à fonctionner efficacement.
                        </div>
                    }
                </div>
                <button className={"bg-white border-black border-2 px-5 py-1 rounded-lg font-bold uppercase text-xl shadow-md"} onClick={doConsent}>{lang == "en"?"Accept":"Accepter"}</button>
            </div>
        </div>
    );
};

export default CookieConsent;