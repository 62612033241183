import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {Button, message} from 'antd';
import Auth from "../../http/Auth";
import { useSelector } from "react-redux";

const VerifyEmail = () => {
    const { data } = useParams();
    const [verificationStatus, setVerificationStatus] = useState(null);
    const user = useSelector(state => state.auth.user);
    const [sending, setSending] = useState(false);

    const getDecoded = (txt) => {
        return decodeURIComponent(txt || '');
    };

    const resendVerificationEmail = () => {
        setSending(true);
        Auth.ResendVerificationEmail((response) => {
            message.success('Verification email sent successfully!');
            setSending(false);
        }, (err) => {
            message.error('Failed to send verification email. Please try again.');
            console.error('Resend email error:', err);
            setSending(false);
        });
    };

    useEffect(() => {
        if (data) {
            const decoded_data = getDecoded(data);

            console.log("data", data);
            console.log("decoded_data", decoded_data);

            Auth.VerifyEmail(decoded_data, (response) => {
                message.success('Email verified successfully!');
                setVerificationStatus('success');
            }, (err) => {
                message.error('Email verification failed. Please try again.');
                setVerificationStatus('failure');
                console.error('Verification error:', err);
            });
        }
    }, [data]);

    return (
        <div className="auth-contents container mx-auto bg-white mt-4 py-2 px-4 lg:py-4 lg:px-8">
            {!data ? (
                user.email_verified_at ? (
                    <div>
                        <h2 className="font-bold text-xl">Email Already Verified</h2>
                        <p>Your email is already verified.</p>
                    </div>
                ) : (
                    <div>
                        <h2 className="font-bold text-xl">Email Verification Required</h2>
                        <p>Your email is not verified. Please verify your email to continue.</p>
                        <Button onClick={resendVerificationEmail} type={"primary"} className="btn btn-primary" loading={sending}>Resend Verification Email</Button>
                    </div>
                )
            ) : (
                verificationStatus === 'success' ? (
                    <div>
                        <h2 className="font-bold text-xl">Email Verified</h2>
                        <p>Your email has been successfully verified. {!user && <>You can now log in with your email.</>}</p>
                        {!user && <Link to="/login" className="btn btn-primary">Go to Login</Link>}
                    </div>
                ) : verificationStatus === 'failure' ? (
                    <div>
                        <h2 className="font-bold text-xl">Verification Failed</h2>
                        <p>There was an issue verifying your email. Please try again.</p>
                    </div>
                ) : (
                    <div>
                        <h2 className="font-bold text-xl">Verifying...</h2>
                        <p>Please wait while we verify your email.</p>
                    </div>
                )
            )}
        </div>
    );
};

export default VerifyEmail;
