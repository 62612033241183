import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Training = {

    Index: (successCb, failCb) => {
        axios.get(`/training/index`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.post(`/training/${id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (parent_id, data, successCb, failCb) => {
        axios.post(`/training/${parent_id}/create_child`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateOrder: (data, successCb, failCb) => {
        axios.post(`/training/update_order`, {orders: data}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default Training;