import React, {useState} from 'react';
import {Radio, Select, Space} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {SetLanguage} from "../redux/settings/actionCreator";
import {useLocation} from "react-router-dom";

const LanguageBar = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const lang = useSelector(state => state.settings.lang);
    const frs = useSelector(state => state.menu.index_translations);

    const setLang = (lang) => {
        if (frs) {
            dispatch(SetLanguage(lang));
        }
    }

    return (
        <div className={"flex flex-row rounded-lg bg-white border-black border-2 shadow-sm overflow-hidden"} style={{zIndex: 2000, fontSize: "18px"}}>
            <div className={`pl-3 pr-2 border-r border-black cursor-pointer ${lang == 'en' ? 'font-bold' : ''}`} onClick={() => setLang('en')} style={{opacity: lang == 'en' && frs ? 1 : .15}}>
                EN
            </div>
            <div className={`pl-2 pr-3 border-l border-black cursor-pointer ${lang == 'fr' ? 'font-bold' : ''}`} onClick={() => setLang('fr')} style={{opacity: lang == 'fr' && frs ? 1 : .15}}>
                FR
            </div>

        </div>
    );
};

export default LanguageBar;
