import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {calculatePrice, convertCartItemToFood} from "../../services/food";
import SelectedItemsNames from "../Food/SelectedItemsNames";
import {closeStoreCartBubble, closeUpdateCartBubble, removeFromCart, updateCart} from "../../redux/cart/actionCreator";
import {Link, useHistory, useLocation} from "react-router-dom";
import T from "../../components/T";

export default function CartItem({storageCartItem, disabled}) {
    const food_id = storageCartItem[0];
    const selectedItems = storageCartItem[1];
    const quantity = storageCartItem[2];
    const key = storageCartItem[3];

    const location = useLocation();
    const pathname = location.pathname;

    const menu = useSelector(state => state.menu.index_menu);
    const cart_items = useSelector(state => state.cart.cart_items);
    const dispatch = useDispatch();

    const history = useHistory();

    const removeItem = () => {
        if (!disabled) {
            dispatch(closeStoreCartBubble());
            dispatch(closeUpdateCartBubble());
            dispatch(removeFromCart(cart_items, key, false));
        }
    }

    let total_price = 0;
    const food = convertCartItemToFood(menu, storageCartItem);
    if (food) {
        const price = calculatePrice(food, selectedItems);
        total_price = (price * quantity).toFixed(2);
    }

    const incrementQuantity = () => {
        if (!disabled) {
            let newitem = [...storageCartItem];
            newitem[2]++;
            dispatch(updateCart(cart_items, key, newitem, false))
        }
    }

    const goToEdit = () => {
        if (!disabled) {
            history.push(`/cart/${key}`, {"pathname": pathname})
        }
    }

    const decrementQuantity = () => {
        if (!disabled) {
            let newitem = [...storageCartItem];
            if (newitem[2] > 1) {
                newitem[2]--;
                dispatch(updateCart(cart_items, key, newitem, false))
            }
            else {
                dispatch(removeFromCart(cart_items, key, false));
            }
        }
    }

    return (
        <>
            {food ?
                <div className="m-1 mb-2 bg-white p-1 flex flex-col sm:flex-row items-center rounded-lg shadow-md">
                    <div className="w-48 py-2 flex flex-row items-center justify-center">
                        <img src={food.image} className="max-h-32 ml-4"/>
                    </div>
                    <div className="flex flex-row items-center w-full">
                        <div className="flex-1 flex flex-col pl-6 py-6">
                            <div className="font-bold text-lg capitalize"><T>{food.name}</T></div>

                            {food.modified_options.map((option) => {
                                const r = SelectedItemsNames({selectedItems, option});
                                if (!r) return null;
                                return (
                                    <div key={option.name}>
                                        <span className="text-gray-500 font-bold capitalize"><T>{option.name}</T></span>:
                                        <i className="text-gray-500 ml-2">
                                            {r}
                                        </i>
                                    </div>
                                )
                            })}

                            <div className="buttons mt-2">
                                {food.modified_options.length > 0 && food.can_customize?
                                    <button onClick={goToEdit} className="text-blue-400 mr-3 capitalize">
                                        <T>Edit</T>
                                    </button>
                                    : null}
                                <button onClick={removeItem}>
                                    <a className="text-blue-400 capitalize"><T>Remove</T></a>
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            <div className="">
                                <div className="flex items-center text-gray-500">
                                    <button onClick={decrementQuantity}>
                                        <FontAwesomeIcon icon={faMinusCircle} className="text-2xl mr-2" aria-hidden="true"/>
                                    </button>
                                    <span className="text-xl" style={{paddingBottom: 4}}>{quantity}</span>
                                    <button onClick={incrementQuantity}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="text-2xl ml-2" aria-hidden="true"/>
                                    </button>

                                </div>
                            </div>
                            <div className="px-10 pt-2">
                                <span className="text-2xl font-bold">${total_price}</span>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}