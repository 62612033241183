import React from 'react';

const Footer = () => {
    return (
        <div className="hidden">
            <div className="pt-0">
                <footer id="footer" className="relative z-50 border-t border-gray-200 footer" style={{backgroundColor: "#efefef"}}>
                    <div className="py-10 flex flex-col justify-center items-center">
                        <p tabIndex={0} className="focus:outline-none text-xs lg:text-base leading-none text-gray-600 dark:text-gray-50">2022 PattySlaps All Rights Reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Footer;
