import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, notification, Badge, Divider, Tag } from 'antd';
import { RiUserLine, RiFileTextLine, RiAlertLine, RiFlagLine, RiCalendarLine } from '@remixicon/react';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';

const EndOfShiftReportShow = () => {
    const { branchId, reportId } = useParams();
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);

    useEffect(() => {
        BranchInventory.ShowEndOfShiftReport(reportId, (response) => {
            setReport(response.data.data.report);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load end of shift report.',
            });
            setLoading(false);
        });
    }, [branchId, reportId]);

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                {report && (
                    <Card
                        title={
                            <div className="flex items-center space-x-2">
                                <RiFileTextLine className="text-xl" />
                                <span className="text-lg font-semibold">
                                    End of Shift Report - {report.date} ({report.shift})
                                </span>
                            </div>
                        }
                        className="shadow-md rounded-lg border-t-4 border-blue-500"
                    >
                        <div className="flex items-center my-2">
                            <RiUserLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>User:</strong> {report.user.name}
                            </div>
                        </div>
                        <Divider dashed />
                        <div className="my-2 flex items-center " >
                            <RiAlertLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Summary of Operations:</strong> {report.summary}
                            </div>
                        </div>
                        <Divider dashed />
                        <div className="flex items-center my-2">
                            <RiFlagLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Challenges Encountered:</strong> {report.challenges}
                            </div>
                        </div>
                        <Divider dashed />
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Materials Out of Stock:</strong> {report.out_of_stock_materials}
                            </div>
                        </div>
                        <Divider dashed />
                        <div className="flex items-center  my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Unresolved Issues:</strong> {report.unresolved_issues}
                            </div>
                        </div>
                        <Divider dashed />
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Incidents:</strong> {report.incidents ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>}
                            </div>
                            {report.incidents && (
                                <div className="font-medium text-gray-600 mt-4">
                                    <strong>Incident Description:</strong> {report.incident_description}
                                </div>
                            )}
                        </div>
                        <Divider dashed />
                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Notices Given:</strong> {report.notices_given ? <Tag color="orange">Yes</Tag> : <Tag color="green">No</Tag>}
                            </div>
                            {report.notices_given && (
                                <div className="font-medium text-gray-600 mt-4">
                                    <strong>Notice Description:</strong> {report.notice_description}
                                </div>
                            )}
                        </div>
                    </Card>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default EndOfShiftReportShow;