import React from 'react';
import { Alert, Divider } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";

function FormError({ error }) {
  return (
    <>
      {error && error !== 'Validation Exception' ?
        <>
        <Alert
          className='mb-5 font-bold'
          showIcon
          icon={<FontAwesomeIcon icon={faUserTie} className="text-2xl" aria-hidden="true"/>}
          message={error}
          description=''
          type='error'
        />
          <Divider />
        </>
        : null}
    </>
  );
}

export default FormError;