import React, {useEffect, useRef, useState} from 'react';
import {Tree, TreeNode} from 'react-organizational-chart';

import KPI from "../../../../../http/KPI";
import {notification} from "antd";
import Division from "../../../../../http/Division";
import FoodSkeleton from "../../../../../components/skeletons/FoodSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import styled from "styled-components";
import {DecompositionTreeGraph} from '@ant-design/graphs';

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
`;

const convertIdsToString = (node) => {
    node.id = node.id.toString(); // Convert the ID to a string
    node.visible = 1;
    if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
            convertIdsToString(child); // Recursively convert IDs for children
        });
    }
};

const CompanyTree = () => {
    const ref = useRef();
    const [translate, setTranslate] = useState();
    const [divisions, setDivisions] = useState(null);

    const [config, setConfig] = useState(null);

    useEffect(() => {

        Division.Index((response) => {
            setDivisions(response.data.data.divisions);

            let d = response.data.data.divisions;
            convertIdsToString(d);
            console.log(d);

            setConfig({
                data: d,
                height: window.innerHeight - 180,
                nodeCfg: {
                    size: [190, 105],
                    items: {
                        containerStyle: {
                            fill: "#DBEA8D"
                        },
                        padding: 10,
                        style: (cfg, group, type) => {
                            const styles = {
                                icon: {
                                    width: 12,
                                    height: 12
                                },
                                value: {
                                    fill: "#6394ff"
                                },
                                text: {
                                    fill: "#aaa"
                                }
                            };
                            return styles[type];
                        }
                    },
                    nodeStateStyles: {
                        hover: {
                            lineWidth: 2
                        }
                    },
                    title: {
                        containerStyle: (cfg) => {
                            return {
                                fill: cfg.children?.length > 0 ? stroke : "#7caee0"
                            };
                        },
                        style: {
                            fill: "#fff",
                            fontSize: 12
                        }
                    },
                    style: (cfg) => {
                        return {
                            fill: "#fff",
                            radius: 2,
                            stroke: cfg.children?.length > 0 ? stroke : "#7caee0"
                        };
                    }
                },
                edgeCfg: {
                    label: {
                        style: {
                            fill: "#aaa",
                            fontSize: 12,
                            fillOpacity: 1
                        }
                    },
                    style: (edge) => {
                        return {
                            stroke: stroke,
                            strokeOpacity: 0.5
                        };
                    },
                    endArrow: {
                        fill: stroke
                    },
                    edgeStateStyles: {
                        hover: {
                            strokeOpacity: 1
                        }
                    }
                },
                markerCfg: (cfg) => {
                    return {
                        position: "right",
                        show: cfg.children?.length,
                        style: (arg) => {
                            return {
                                stroke: cfg.children?.length > 0 ? stroke : "yellow"
                            };
                        }
                    };
                },
                behaviors: ["drag-canvas", "zoom-canvas", "drag-node"]
            });

        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }, []);

    const stroke = "#3572af";

    const getHGap = () => {
        return 120;
    }

    const getVGap = () => {
        return -15;
    }

    return (
        <div className={"flex flex-col w-full h-screen"}>
            <div className={"w-full flex flex-row items-center justify-center text-4xl font-bold uppercase patty bg-white"}>
                Company Tree
            </div>
            <SkeletonWrapper loading={!divisions} skeleton={GenericSkeleton}>
                {divisions && config &&
                    <DecompositionTreeGraph {...config}  layout={{getHGap: getHGap, getVGap: getVGap    }}/>
                }
            </SkeletonWrapper>
        </div>
    );
};

export default CompanyTree;