import React, {useEffect, useState} from 'react';
import {Modal, Form, Checkbox, Button} from 'antd';

const ManageDisabledFoodsModal = ({visible, onSave, onCancel, foods, editCouponGeneratePreset, saving, form}) => {
    const [selectedFoods, setSelectedFoods] = useState([]);
    const [foodsByCategory, setFoodsByCategory] = useState({});

    useEffect(() => {
        if (editCouponGeneratePreset) {
            setSelectedFoods(editCouponGeneratePreset.disabled_foods || []);
        } else {
            setSelectedFoods([]);
        }
    }, [editCouponGeneratePreset]);

    useEffect(() => {
        const groupedFoods = foods.reduce((acc, food) => {
            const category = food.category.name;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(food);
            return acc;
        }, {});
        setFoodsByCategory(groupedFoods);
    }, [foods]);

    const handleCheckboxChange = (foodId) => {
        setSelectedFoods(prev => {
            if (prev.includes(foodId)) {
                return prev.filter(id => id !== foodId);
            } else {
                return [...prev, foodId];
            }
        });
    };

    const handleCategorySelect = (categoryFoods) => {
        setSelectedFoods(prev => {
            const newSelection = categoryFoods.filter(food => !prev.includes(food.id));
            return [...prev, ...newSelection.map(food => food.id)];
        });
    };

    const handleCategoryDeselect = (categoryFoods) => {
        setSelectedFoods(prev => {
            return prev.filter(id => !categoryFoods.some(food => food.id === id));
        });
    };

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        onSave(selectedFoods);
    };

    return (
        <Modal
            visible={visible}
            title={<b>Manage Disabled Foods</b>}
            okText={"Save Disabled Foods"}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={onSubmit}
            confirmLoading={saving}
            width={900}
        >
            <div>
                <p>
                    Please select the foods you want to disable for this preset. These foods will be disallowed from being used with this coupon preset.
                    Ensure you carefully select only the foods that should be disabled, as allowed foods will remain unselected.
                </p>

            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                {Object.keys(foodsByCategory).map(category => (
                    <div key={category} className="mb-4">
                        <div className="font-bold text-lg mb-2">
                            {category}
                            <Button type="link" onClick={() => handleCategorySelect(foodsByCategory[category])}>Select All</Button>
                            <Button type="link" onClick={() => handleCategoryDeselect(foodsByCategory[category])}>Deselect All</Button>
                        </div>
                        <div className={"flex flex-wrap"}>
                            {foodsByCategory[category].map(food => (
                                <div key={food.id} className={"ml-1 mb-1 px-1"}>
                                    <Checkbox
                                        checked={selectedFoods.includes(food.id)}
                                        onChange={() => handleCheckboxChange(food.id)}
                                    >
                                        {food.name}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Form>
        </Modal>
    );
};

export default ManageDisabledFoodsModal;
