import axios from 'axios';
import {getAuthenticationHeaders} from './Headers';

const Menu = {
    Index: (successCb, failCb) => {

        let branch_guid = localStorage.getItem('branch_guid');

        axios.get(`${branch_guid?'/branch/'+branch_guid:''}/categories`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default Menu;
