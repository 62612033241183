import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import TemperatureZone from '../../../../../http/TemperatureZone';
import DefaultContainer from '../../../../../components/DefaultContainer';

const CreateEditTemperatureZone = () => {
    const [form] = Form.useForm();
    const { branchId, zoneId } = useParams();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState(null);
    const history = useHistory();

    console.log("reached the edit");

    useEffect(() => {
        if (zoneId) {
            setLoading(true);
            TemperatureZone.Edit(branchId, zoneId, (response) => {
                const { zone, branch } = response.data.data;
                form.setFieldsValue({ name: zone.name });
                setBranch(branch);
                setLoading(false);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load temperature zone data.',
                });
                setLoading(false);
            });
        } else {
            TemperatureZone.Create(branchId, (response) => {
                setBranch(response.data.data.branch);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load branch data.',
                });
            });
        }
    }, [branchId, zoneId, form]);

    const onFinish = (values) => {
        setSaving(true);
        if (zoneId) {
            // Update existing temperature zone
            TemperatureZone.Update(branchId, zoneId, values, (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'Temperature Zone updated successfully.',
                });
                history.push(`/dashboard/operations/setup/inventory/temperature-zones/${branch.id}/manage`);
                setSaving(false);
            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to update temperature zone.',
                });
                setSaving(false);
            });
        } else {
            // Create new temperature zone
            TemperatureZone.Store(branchId, values, (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'Temperature Zone created successfully.',
                });
                form.resetFields();
                setSaving(false);
                history.push(`/dashboard/operations/setup/inventory/temperature-zones/${branch.id}/manage`);

            }, (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to create temperature zone.',
                });
                setSaving(false);
            });
        }
    };

    return (
        <DefaultContainer>
            <h2>{zoneId ? 'Edit' : 'Create'} Temperature Zone for {branch ? branch.name : 'Loading...'}</h2>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="name"
                    label="Temperature Zone Name"
                    rules={[{ required: true, message: 'Please enter the temperature zone name' }]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={saving}>{zoneId ? 'Update' : 'Create'} Zone</Button>
                </Form.Item>
            </Form>
        </DefaultContainer>
    );
};

export default CreateEditTemperatureZone;