import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Rate, Spin, notification, Tag} from 'antd';
import TrainingEvaluation from '../../../../../http/TrainingEvaluation';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';

const ShowTrainingEvaluations = () => {
    const {userTrainingId} = useParams();
    const [evaluations, setEvaluations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [averages, setAverages] = useState({
        punctuality: 0,
        understanding_of_role: 0,
        task_execution_efficiency: 0,
        hygiene: 0,
        motivation_self_improvement: 0,
        overall_performance: 0,
        readiness: 0,
    });

    useEffect(() => {
        TrainingEvaluation.Index(userTrainingId, (response) => {
            const {evaluations} = response.data.data;

            if (evaluations && evaluations.length > 0) {
                setEvaluations(evaluations);
                calculateAverages(evaluations);
            }
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load training evaluations.',
            });
            setLoading(false);
        });
    }, [userTrainingId]);

    const calculateAverages = (evaluations) => {
        const fields = [
            'punctuality',
            'understanding_of_role',
            'task_execution_efficiency',
            'hygiene',
            'motivation_self_improvement',
            'overall_performance',
            'readiness',
        ];
        const sums = fields.reduce((acc, field) => {
            acc[field] = 0;
            return acc;
        }, {});

        evaluations.forEach((evaluation) => {
            fields.forEach((field) => {
                sums[field] += evaluation[field];
            });
        });

        const averageValues = fields.reduce((acc, field) => {
            acc[field] = (sums[field] / evaluations.length).toFixed(1);
            return acc;
        }, {});

        setAverages(averageValues);
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <div className="mb-4">
                    <h2 className="text-2xl font-bold mb-4">Training Evaluations</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                        {Object.entries(averages).map(([key, value]) => (
                            <div key={key} className="bg-white p-4 rounded-lg shadow-md border border-gray-200 text-center">
                                <h3 className="font-semibold capitalize">{key.replace(/_/g, ' ')}</h3>
                                <Rate disabled allowHalf defaultValue={parseFloat(value)}/>
                                <p className="mt-1 text-gray-500">Average: {value}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {evaluations.length > 0 ? (
                    evaluations.map((evaluation) => (
                        <div key={evaluation.id} className="mb-4 p-4 rounded-lg shadow-md border border-gray-200 bg-gray-100">
                            <div className="mb-4">
                                <h3 className="text-lg font-semibold">Evaluator: {evaluation.evaluator.name}</h3>
                                <div className={"ml-1"}>
                                    <h4 className="text-md font-medium mt-2">Trained Topics:</h4>
                                    <div className="flex flex-wrap">
                                        {evaluation.trained_topics && evaluation.trained_topics.length > 0 ? (
                                            evaluation.trained_topics.map((topic) => (
                                                <Tag key={topic}>{topic}</Tag>
                                            ))
                                        ) : (
                                            <Tag>No topics found.</Tag>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                {[
                                    {label: 'Punctuality', value: evaluation.punctuality},
                                    {label: 'Understanding of Role', value: evaluation.understanding_of_role},
                                    {label: 'Task Execution Efficiency', value: evaluation.task_execution_efficiency},
                                    {label: 'Hygiene', value: evaluation.hygiene},
                                    {label: 'Motivation & Self-Improvement', value: evaluation.motivation_self_improvement},
                                    {label: 'Overall Performance', value: evaluation.overall_performance},
                                    {label: 'Readiness', value: evaluation.readiness},
                                ].map((item) => (
                                    <div key={item.label} className="flex flex-col items-center bg-white p-2 rounded-lg shadow-sm border border-gray-200">
                                        <h4 className="font-medium">{item.label}</h4>
                                        <Rate disabled defaultValue={item.value}/>
                                        <p className="text-gray-500 mt-1">Score: {item.value}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-4">
                                <h4 className="font-medium">Additional Comments:</h4>
                                <div className={"pl-1"}>{evaluation.comments || 'No additional comments.'}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No evaluations found.</p>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default ShowTrainingEvaluations;
