import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import Bloggers from "../../../../http/Bloggers";
import Table from "ant-responsive-table";
import {Pagination} from "antd";
import {SpinnerCircular} from "spinners-react";
import SettingsForm from "./SettingsForm";

const BloggerSettings = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-3 items-center pr-2">
                    <div className="flex flex-row items-center"><T upper>SETTINGS</T></div>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-2xl flex-1 border shadow-xl p-3 flex flex-col w-full bg-white">
                        <SettingsForm names={['allowed_attends_per_day', 'invite_template', 'override_disabled_attendance_date']} />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};
export default BloggerSettings;
