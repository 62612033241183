import actions from './actions';

const {
  SET_LANGUAGE,
  HIDE_HEADER,
  SHOW_HEADER,
  HIDE_DASHBOARD_MENU,
  SHOW_DASHBOARD_MENU,
  SET_UNDER_CONSTRUCTION,
  DASHBOARD_SWITCH_VISIBILITY
} = actions;

const initState = {
  lang: localStorage.getItem('lang') || "en",
  header_visible: false,
  dashboard_menu_visible: false,
  header_fixed: false,
  under_construction: localStorage.getItem("under_construction") == "true" || false,
  be_back_string: null,
  dashboard_visible: window.innerWidth > 767,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const SettingsReducer = (state = initState, action) => {
  const { type, header_fixed, error, lang, under_construction, be_back_string } = action;
  switch (type)
  {

    case DASHBOARD_SWITCH_VISIBILITY:
      return {
        ...state,
        dashboard_visible: !state.dashboard_visible
      };

    case SET_LANGUAGE:
      localStorage.setItem("lang", lang);
      return {
        ...state,
        lang
      };

    case HIDE_HEADER:
      return {
        ...state,
        header_visible: false,
      };

    case SHOW_HEADER:
      return {
        ...state,
        header_visible: true,
        header_fixed,
      };

    case HIDE_DASHBOARD_MENU:
      return {
        ...state,
        dashboard_menu_visible: false,
      };

    case SHOW_DASHBOARD_MENU:
      return {
        ...state,
        dashboard_menu_visible: true,
      };

    case SET_UNDER_CONSTRUCTION:
      return {
        ...state,
        under_construction,
        be_back_string,
      };

    default:
      return state;

  }
};
export default SettingsReducer;
