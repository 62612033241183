import React, {useState} from 'react';
import {Button, Card, notification} from "antd";
import {faDollarSign, faEdit, faPercentage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Clipboard from "../../../../../components/Clipboard";
import moment from "moment";
import CouponGeneratePreset from "../../../../../http/CouponGeneratePreset";
import {UpdateTotalUnapprovedCoupons} from "../../../../../redux/authentication/actionCreator";
import Coupon from "../../../../../http/Coupon";
import { RiCouponLine, RiUserLine, RiCalendarEventLine, RiShoppingCartLine, RiMoneyDollarCircleLine, RiPercentLine, RiStopCircleLine, RiCheckboxCircleLine, RiCloseCircleLine, RiTimeLine, RiErrorWarningLine, RiCheckLine, RiCloseLine } from "@remixicon/react"; // Import relevant icons

const CouponComponent = ({coupon, header_color="bg-green-200", validate=false, customSetUnApprovedCoupons, paste}) => {

    const [approving, setApproving] = useState(false);
    const [rejecting, setRejecting] = useState(false);

    const approve = () => {
        setApproving(true);
        Coupon.Approve(coupon.id, (response) => {
            customSetUnApprovedCoupons(response.data.data.unapproved_coupons);
            setApproving(false);
            notification['success']({
                description: `Coupon for ${coupon.issuer.name} has been approved.`
            });
        }, (err) => {
            setApproving(false);
        });
    }

    const reject = () => {
        setRejecting(true);
        Coupon.Reject(coupon.id, (response) => {
            customSetUnApprovedCoupons(response.data.data.unapproved_coupons);
            setRejecting(false);
            notification['success']({
                description: `Coupon for ${coupon.issuer.name} has been rejected.`
            });
        }, (err) => {
            setRejecting(false);
        });
    }


    return (

        <div className={`m-2 border-gray-500 ${!validate && (coupon.balance === 0 || coupon.status !== 'active') ? 'opacity-30' : ''}`}>
            <div className={`w-full patty text-4xl flex justify-between px-3 pt-1 border-gray-600 rounded-t-lg border-2 border-b-0 ${header_color}`}>
                <div className="flex items-center">
                    <RiCouponLine className="mr-2" /> {/* Coupon Code Icon */}
                    {coupon.code}
                    <Clipboard text={coupon.code} paste={paste} />
                </div>
                <div className="flex items-center text-4xl pl-4">
                    {coupon.type === "percent" ? <RiPercentLine className="mr-2" /> : <RiMoneyDollarCircleLine className="mr-2" />}
                    {coupon.type === "percent" ? `%${coupon.percent}` : `$${coupon.amount}`}
                </div>
            </div>
            <hr className="border-gray-800" />
            <div className="px-3 py-3 bg-gray-200 border-gray-600 border-2 border-t-0 rounded-b-lg">

                <div className="flex items-center text-lg patty">
                    <RiUserLine className="mr-2" /> {/* Issuer Icon */}
                    <b>By:</b> &nbsp;{coupon?.issuer?.name}
                </div>

                {coupon?.coupon_generate_preset?.discount_preset?.name && (
                    <div className="flex items-center text-lg patty">
                        <RiShoppingCartLine className="mr-2" /> {/* Discount Preset Icon */}
                        <b>FROM:</b> &nbsp;{coupon?.coupon_generate_preset?.discount_preset?.name}
                    </div>
                )}

                {coupon?.full_name && (
                    <div className="flex items-center text-lg patty">
                        <RiUserLine className="mr-2" /> {/* Full Name Icon */}
                        <b>FOR:</b> &nbsp;{coupon?.full_name}
                    </div>
                )}

                <div className="flex items-center text-lg patty">
                    <RiCheckboxCircleLine className="mr-2" /> {/* Balance Icon */}
                    <b>Balance:</b> &nbsp;
                    {coupon.type === "percent" ? (
                        coupon.balance === -1 ? "UNLIMITED" : `${coupon.balance} Time${coupon.balance > 1 ? 's' : ''} Remaining`
                    ) : (
                        `$${coupon.balance}`
                    )}
                </div>

                <div className="flex items-center text-lg patty">
                    <RiCalendarEventLine className="mr-2" /> {/* Expiration Icon */}
                    <b>Expiration:</b> &nbsp;{coupon.expires_at ? moment(coupon.expires_at).format('MM/DD/YYYY') : 'Never Expires'}
                </div>

                {coupon.one_item === 1 && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" /> {/* Single Item Limit Icon */}
                        <b>single item limit</b>
                    </div>
                )}

                {coupon.one_item === 2 && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" /> {/* Two Items Limit Icon */}
                        <b>two items limit</b>
                    </div>
                )}

                {coupon.beer === "none" && (
                    <div className="flex items-center text-lg patty">
                        <RiStopCircleLine className="mr-2 text-red-700" /> {/* No Beers Icon */}
                        <b>no beers are allowed</b>
                    </div>
                )}

                {coupon.allow_can_beer && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" /> {/* Can Beers Allowed Icon */}
                        <b>can beers are allowed</b>
                    </div>
                )}

                {coupon.max_total_price > 0 && (
                    <div className="flex items-center text-lg patty">
                        <RiMoneyDollarCircleLine className="mr-2" /> {/* Max Total Price Icon */}
                        CAN BE APPLIED TO ORDERS LESS THAN <b className="pl-1">${coupon.max_total_price}</b>
                    </div>
                )}

                {coupon.max_coupon_price > 0 && coupon.type === 'percent' && (
                    <div className="flex items-center text-lg patty">
                        <RiMoneyDollarCircleLine className="mr-2" /> {/* Max Discount Icon */}
                        MAX DISCOUNT AMOUNT IS <b className="pl-1">${coupon.max_coupon_price}</b>
                    </div>
                )}

                {(coupon.status === "disabled" || coupon.status === 'requires_confirmation') && (
                    <div className="flex items-center text-lg patty">
                        <RiErrorWarningLine className="mr-2" /> {/* Status Icon */}
                        STATUS: <b className="pl-1">{coupon.status === "disabled" ? 'DISABLED' : 'REQUIRES CONFIRMATION'}</b>
                    </div>
                )}

                {validate && (
                    <div className="w-full flex justify-between border-t pt-2 mt-2 border-gray-800">
                        <Button type="primary" icon={<RiCheckLine />} loading={approving} disabled={rejecting} onClick={approve}>
                            Accept
                        </Button>
                        <Button type="primary" icon={<RiCloseLine />} danger loading={rejecting} disabled={approving} onClick={reject}>
                            Reject
                        </Button>
                    </div>
                )}

            </div>
        </div>


    );
};

export default CouponComponent;