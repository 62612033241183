import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuildingFlag, faCheckCircle, faLocationPin, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, notification, Popconfirm, Tag} from "antd";
import Irani from "../../../../../http/Irani";
import IraniPieChart from "../Reports/IraniPieChart";
import IraniContextPieChart from "./IraniContextPieChart";
import ContextsTable from "./ContextsTable";

function generatePastelColor() {
    const hue = Math.floor(Math.random() * 360); // Random hue
    const saturation = 75 + Math.floor(Math.random() * 25); // Slightly reduced saturation
    const lightness = 50 + Math.floor(Math.random() * 10); // Reduced brightness
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

function generatePastelColors(count) {
    // Generate an array of random pastel colors
    const colors = [];
    for (let i = 0; i < count; i++) {
        colors.push(generatePastelColor());
    }
    return colors;
}

const IraniContextReport = ({setting, edit, reload}) => {

    const [colors, setColors] = useState("#eb3b3b, #c49a93, #eb9c00, #1c381c, #00dbeb, #6a3beb, #380e0e, #eb5e00, #382a0e, #5eeb00, #62c489, #5c81b8, #160038".split(", "));
    const [deleting, setDeleting] = useState(false);
    const [generating, setGenerating] = useState(false);

    const onEdit = () => {
        edit(setting);
    }

    const onDelete = () => {
        setDeleting(true);

        Irani.ContextReportSettingDelete(setting.id, (response) => {
            setDeleting(false);
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            setDeleting(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }

    const onGenerate = () => {
        setGenerating(true);

        Irani.ContextReportSettingGenerate(setting.id, (response) => {
            setGenerating(false);
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            setGenerating(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }


    return (
        <div className={"flex flex-col bg-white border border-gray-400 p-2 w-full mb-2 shadow"}>
            <div className={"flex flex-row w-full"}>
                <div className={"flex flex-col w-full flex-1 justify-center"}>
                    {setting.locations.map(location => {
                        return (
                            <div className={"text-sm font-bold"}>
                                <FontAwesomeIcon icon={faLocationPin} className="mr-1"/>
                                {location.country} {location.city ? <> - {location.city}</> : null}
                            </div>
                        )
                    })}
                </div>
                <div>
                    {!!!setting.report &&
                        <Button disabled={deleting} loading={generating} type={'primary'} onClick={onGenerate}>GENERATE</Button>
                    }
                    <Button disabled={deleting || generating} className={"ml-2"} onClick={onEdit}>EDIT</Button>
                    <Popconfirm
                        title="Delete the setting"
                        description="Are you sure to delete this setting?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={onDelete}
                    >
                        <Button disabled={generating} loading={deleting} className={"ml-2"}>
                            <FontAwesomeIcon icon={faTrash} className="text-sm"/>
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            {!!setting.report &&
                <div>
                    <div className={"pt-1 flex flex-row"}>
                        <IraniContextPieChart title={"Root"}
                          data={
                            setting.report.filter(item => item.level === 1).sort((a, b) => b.occurrence - a.occurrence).slice(0, 8)
                                .map((row, index) => {
                                    return {
                                        name: row.name, p: parseInt(row.positive_occurrence), n: parseInt(row.negative_occurrence), value: row.occurrence, fill: colors[index]}}
                                )
                          }
                        />

                        <IraniContextPieChart title={"Foods"}
                                              data={
                                                  setting.report.filter(item => item.parent_id === 13 && !item.name.toLowerCase().includes('overall')).sort((a, b) => b.occurrence - a.occurrence).slice(0, 8)
                                                      .map((row, index) => {
                                                          return {
                                                              name: row.name, p: parseInt(row.positive_occurrence), n: parseInt(row.negative_occurrence), value: row.occurrence, fill: colors[index]}}
                                                      )
                                              }
                        />

                        <IraniContextPieChart title={"Main Dish"}
                                              data={
                                                  setting.report.filter(item => item.parent_id === 15).sort((a, b) => b.occurrence - a.occurrence).slice(0, 8)
                                                      .map((row, index) => {
                                                          return {
                                                              name: row.name, p: parseInt(row.positive_occurrence), n: parseInt(row.negative_occurrence), value: row.occurrence, fill: colors[index]}}
                                                      )
                                              }
                        />
                    </div>

                    <div>
                        <ContextsTable contexts={setting.report} />
                    </div>
                </div>
            }
        </div>
    );
};

export default IraniContextReport;