import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const DiscountPreset = {

    Index: (successCb, failCb) => {
        axios.get(`/discount_preset`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


    Store: (data, successCb, failCb) => {
        axios.post(`/discount_preset`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (preset_id, data, successCb, failCb) => {
        axios.put(`/discount_preset/${preset_id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default DiscountPreset;