import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, notification, Checkbox, Row, Col, Table } from 'antd';
import InventoryVendor from "../../../../../http/InventoryVendor";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";

const { Option } = Select;

const CreateVendor = () => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const history = useHistory();
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            InventoryVendor.Edit(id, (response) => {
                const vendor = response.data.data.vendor;
                setAllUsers(response.data.data.users); // Fetch all users

                // Set the selected users with default values for user_type and email_notification
                setSelectedUsers(vendor.users.map(user => ({
                    ...user,
                    selected: true, // Ensure the user is marked as selected
                    user_type: user.pivot.type || 'manager', // Set a default if user_type is undefined
                    email_notification: user.pivot.email_notification || false // Default to false if undefined
                })));

                // Set form values for name and type
                form.setFieldsValue({
                    name: vendor.name,
                    type: vendor.type,
                    vendor_users: vendor.users.map(user => ({
                        id: user.id,
                        user_type: user.pivot.type || 'manager', // Default to 'manager'
                        email_notification: user.pivot.email_notification || false // Default to false
                    }))
                });

                setLoading(false);
            }, (err) => {
                setLoading(false);
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load vendor',
                });
                history.push("/dashboard/operations/setup/inventory/vendors/manage");
            });
        } else {
            InventoryVendor.Create((response) => {
                setAllUsers(response.data.data.users); // Fetch all users
                setLoading(false);
            }, (err) => {
                setLoading(false);
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load users',
                });
                history.push("/dashboard/operations/setup/inventory/vendors/manage");
            });
        }
    }, [id]);



    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelected) => {
            const updatedSelected = [...prevSelected];
            const userIndex = updatedSelected.findIndex(user => user.id === userId);

            if (userIndex > -1) {
                // If user already exists in selected list, toggle selection
                updatedSelected[userIndex].selected = !updatedSelected[userIndex].selected;
            } else {
                // Add new selected user
                const userToAdd = allUsers.find(user => user.id === userId);
                updatedSelected.push({ ...userToAdd, selected: true, user_type: 'manager', email_notification: false });
            }

            return updatedSelected;
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);

        // Filter out the selected users and map them with their user_type and email_notification
        const selected = selectedUsers
            .filter(user => user.selected) // Only include users that are selected (checked)
            .map(user => ({
                id: user.id,
                user_type: user.user_type, // Include user_type
                email_notification: user.email_notification // Include email_notification
            }));

        const formData = { ...values, vendor_users: selected };

        if (id) {
            InventoryVendor.Update(id, formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/vendors/manage");
                notification.success({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification.error({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            InventoryVendor.Store(formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/vendors/manage");
                notification.success({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification.error({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }
    };



    const columns = [
        {
            title: 'User',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Checkbox
                    checked={selectedUsers.find(user => user.id === record.id)?.selected || false}
                    onChange={() => handleCheckboxChange(record.id)}
                >
                    {text} ({record.email})
                </Checkbox>
            )
        },
        {
            title: 'User Type',
            dataIndex: 'user_type',
            key: 'user_type',
            render: (text, record) => {
                const selectedUser = selectedUsers.find(user => user.id === record.id);
                return (
                    <Select
                        placeholder="Select user type"
                        disabled={!selectedUser?.selected}
                        value={selectedUser?.user_type || 'manager'}
                        onChange={(value) => {
                            setSelectedUsers(prevSelected =>
                                prevSelected.map(user =>
                                    user.id === record.id ? { ...user, user_type: value } : user
                                )
                            );
                        }}
                    >
                        <Option value="manager">Manager</Option>
                        <Option value="supervisor">Supervisor</Option>
                    </Select>
                );
            }
        },
        {
            title: 'Notify',
            dataIndex: 'email_notification',
            key: 'email_notification',
            render: (text, record) => {
                const selectedUser = selectedUsers.find(user => user.id === record.id);
                return (
                    <Checkbox
                        disabled={!selectedUser?.selected}
                        checked={selectedUser?.email_notification || false}
                        onChange={(e) => {
                            setSelectedUsers(prevSelected =>
                                prevSelected.map(user =>
                                    user.id === record.id ? { ...user, email_notification: e.target.checked } : user
                                )
                            );
                        }}
                    />
                );
            }
        }
    ];


    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" style={{ backgroundColor: "#FAFAFA" }}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Vendor' : 'Add New Vendor'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish} disabled={submitting || loading}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input the vendor name' }]}
                        >
                            <Input placeholder="Enter vendor name" disabled={submitting} />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please select the vendor type' }]}
                        >
                            <Select placeholder="Select vendor type" disabled={submitting}>
                                <Option value="central_kitchen">Central Kitchen</Option>
                                <Option value="vendor">Vendor</Option>
                            </Select>
                        </Form.Item>

                        {/* User selection table */}
                        <Table
                            dataSource={allUsers}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                        />

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full primary-button">
                                {id ? 'Update Vendor' : 'Add Vendor'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateVendor;
