import React from 'react';

const Avatar = ({url}) => {
    return (
        <div className="w-6 h-6 overflow-hidden flex flex-row items-center justify-center contain rounded-full border border-gray-200 mr-1">
            <img src={url} className={"max-w-8 max-h-8"} />
        </div>
    );
};

export default Avatar;