import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from "../../../../components/Form/FormItem";

const DepartmentModal = ({ visible, onCancel, onSave, editDepartment, saving, form, validationFields }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(editDepartment ? { name: editDepartment.name } : { name: '' });
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, editDepartment, form]);

    return (
        <Modal
            visible={visible}
            title={editDepartment ? "Edit Department" : "Create Department"}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={saving} onClick={() => form.submit()}>
                    {editDepartment ? "Update" : "Create"}
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={editDepartment ? { name: editDepartment.name } : { name: '' }}
                onFinish={values => onSave(values, !!editDepartment)}
            >
                <FormItem
                    name="name"
                    label="Department Name"
                    rules={[{ required: true, message: 'Please input the department name!' }]}
                    errors={validationFields}
                >
                    <Input ref={inputRef} />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default DepartmentModal;
