import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Space, Radio, Checkbox, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormHandler from '../../../../http/FormHandler';
import { notification } from 'antd';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';
import moment from 'moment';

const { TextArea } = Input;

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 2px !important;
`;

const FormSubmissionViewer = () => {
    const { formId, submissionId } = useParams();
    const [form, setForm] = useState({ id: null, name: '', description: '', fields: [] });
    const [submission, setSubmission] = useState({});
    const [loading, setLoading] = useState(true);
    const [submittedAt, setSubmittedAt] = useState('');

    useEffect(() => {
        if (formId && submissionId) {
            // Fetch the form and submission details
            FormHandler.getFormSubmission(formId, submissionId, (response) => {
                setForm(response.data.data.form_structure);
                setSubmission(response.data.data.data);
                setSubmittedAt(response.data.data.created_at);
                setLoading(false);
            }, (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [formId, submissionId]);

    const renderField = (field) => {
        const formItemLayout = {
            labelCol: { span: 6 }, // Adjust the width of the label here
            wrapperCol: { span: 18 }, // Adjust the width of the input here
        };

        const labelWithTooltip = (
            <div className="flex flex-row items-center">
                {field.label}
                {field.description && (
                    <Tooltip title={field.description} className="cursor-pointer">
                        <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4 }} />
                    </Tooltip>
                )}
            </div>
        );

        switch (field.type) {
            case 'text':
            case 'textarea':
            case 'select':
            case 'radio':
            case 'checkbox':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                    >
                        {renderInputField(field)}
                    </StyledFormItem>
                );
            case 'file':
                return (
                    <StyledFormItem
                        key={field.id}
                        label={labelWithTooltip}
                        {...formItemLayout}
                    >
                        {submission[`fields_${field.id}`] ? (
                            <a href={submission[`fields_${field.id}`]} target="_blank" rel="noopener noreferrer">View File</a>
                        ) : (
                            <span className="text-gray-400">Not uploaded</span>
                        )}
                    </StyledFormItem>
                );
            case 'image':
                return (
                    <div key={field.id} className="image-field w-full flex flex-row items-center justify-center">
                        {submission[`fields_${field.id}`] && (
                            <img src={submission[`fields_${field.id}`]} alt={field.label} style={{ maxHeight: 100 }} />
                        )}
                    </div>
                );
            case 'divider':
                return <div key={field.id} className="divider border-t border-gray-300 -mx-3" />;
            case 'title':
                return (
                    <div key={field.id} className="font-bold text-2xl patty pb-0 mb-0">
                        {field.label}
                    </div>
                );
            case 'description':
                return (
                    <div key={field.id} className="pt-0 -mt-2">
                        {field.label}
                    </div>
                );
            default:
                return null;
        }
    };

    const renderInputField = (field) => {
        switch (field.type) {
            case 'text':
            case 'email':
                return <Input value={submission[`fields_${field.id}`]} readOnly />;
            case 'textarea':
                return <TextArea value={submission[`fields_${field.id}`]} readOnly />;
            case 'select':
                return (
                    <Select value={submission[`fields_${field.id}`]} readOnly>
                        {field.options.map(option => (
                            <Select.Option key={option} value={option}>{option}</Select.Option>
                        ))}
                    </Select>
                );
            case 'radio':
                return (
                    <Radio.Group value={submission[`fields_${field.id}`]} readOnly>
                        {field.options.map(option => (
                            <Radio key={option} value={option}>{option}</Radio>
                        ))}
                    </Radio.Group>
                );
            case 'checkbox':
                const selectedValues = submission[`fields_${field.id}`]?.selected || [];
                return (
                    <Checkbox.Group value={selectedValues} readOnly>
                        {field.options.map(option => (
                            <Checkbox key={option} value={option}>
                                {option}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-4">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <SpinnerCircular size={100} thickness={50} speed={40} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)" />
                </div>
            ) : (
                <div className="container mx-auto border border-gray-400 rounded-lg p-4 bg-white" style={{ maxWidth: '1000px' }}>
                    <Form>
                        <StyledFormItem>
                            <h2 className="patty text-4xl font-bold pb-0 mb-0">{form.name}</h2>
                            <p>{form.description}</p>
                        </StyledFormItem>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {form.fields.map((field) => renderField(field))}
                        </Space>
                        <StyledFormItem>
                            <div className="divider border-t border-gray-300 -mx-3 pt-3 mt-3" />
                            <div className="patty text-2xl font-bold">Submitted At</div>
                            <p className="pb-0 mb-0">{moment(submittedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                        </StyledFormItem>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default FormSubmissionViewer;
