import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, notification, Popconfirm } from 'antd';
import TrainingTopic from '../../../../../http/TrainingTopic';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ListTrainingTopics = () => {
    const { branchId } = useParams();
    const [topics, setTopics] = useState([]);
    const [branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadTrainingTopics();
    }, [branchId]);

    const loadTrainingTopics = () => {
        setLoading(true);
        TrainingTopic.Index(branchId, (response) => {
            setTopics(response.data.data.topics);
            setBranch(response.data.data.branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load training topics.',
            });
            setLoading(false);
        });
    };

    const handleDelete = (topicId) => {
        TrainingTopic.Destroy(branchId, topicId, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Training Topic deleted successfully.',
            });
            loadTrainingTopics();
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to delete training topic.',
            });
        });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedTopics = Array.from(topics);
        const [movedTopic] = reorderedTopics.splice(result.source.index, 1);
        reorderedTopics.splice(result.destination.index, 0, movedTopic);

        setTopics(reorderedTopics);

        // Save new order to backend
        const reorderedData = reorderedTopics.map((topic, index) => ({
            id: topic.id,
            order: index + 1,
        }));

        TrainingTopic.UpdateOrder(branchId, { topics: reorderedData }, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Training Topic order updated successfully.',
            });
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to update training topic order.',
            });
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            {branch &&
                <DefaultContainer>
                    <h2 className="text-2xl font-bold mb-4">Training Topics for {branch ? branch.name : 'Loading...'}</h2>
                    <Link to={`/dashboard/hr/setup/training-topics/${branch.id}/create`}>
                        <Button type="primary" className="mb-5">Add Training Topic</Button>
                    </Link>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="topics">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {topics.map((topic, index) => (
                                        <Draggable key={topic.id} draggableId={String(topic.id)} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="bg-white p-2 px-4 rounded-lg shadow-md mb-2 flex justify-between items-center border border-gray-200"
                                                >
                                                    <div className="flex flex-col sm:flex-row sm:items-center">
                                                        <span className="font-semibold text-lg mr-4">{topic.name}</span>
                                                        <span className="text-gray-500">Group: {topic.group_name || 'None'}</span>
                                                    </div>
                                                    <div className="flex space-x-4">
                                                        <Link to={`/dashboard/hr/setup/training-topic/${branch.id}/${topic.id}`}>
                                                            <Button type="link">Edit</Button>
                                                        </Link>
                                                        <Popconfirm
                                                            title="Are you sure you want to delete this training topic?"
                                                            onConfirm={() => handleDelete(topic.id)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type="link" danger>Delete</Button>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DefaultContainer>
            }
        </SkeletonWrapper>
    );
};

export default ListTrainingTopics;