import React, {useEffect, useState} from 'react';
import T from "../../../../../components/T";
import {motion} from "framer-motion";
import Division from "../../../../../http/Division";
import {notification, Progress} from "antd";
import Irani from "../../../../../http/Irani";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import { Radio } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import GoogleImage from "./GoogleImage";
import DubaiValidate from "./DubaiValidate";

const DubaiStandardBrands = () => {
    return (
        <DubaiValidate approvedType={'standard'} />
    );
};

export default DubaiStandardBrands;