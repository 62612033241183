import actions from './actions';

const {
    CHECKOUT_RESET,

    CHECKOUT_STORE_RESET,
    CHECKOUT_STORE_BEGIN,
    CHECKOUT_STORE_SUCCESS,
    CHECKOUT_STORE_ERROR,

    APPLY_COUPON_BEGIN,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_ERROR,

    CLEAR_COUPON_BEGIN,
    CLEAR_COUPON_SUCCESS,
    CLEAR_COUPON_ERROR,

    CREATE_PAYMENT_BEGIN,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_ERROR,

    CHECKOUT_VALIDATE_RESET,
    CHECKOUT_VALIDATE_BEGIN,
    CHECKOUT_VALIDATE_SUCCESS,
    CHECKOUT_VALIDATE_ERROR,

    RESET_ACTIVE_ORDERS,
    ACTIVE_ORDERS_INDEX_BEGIN,
    ACTIVE_ORDERS_INDEX_SUCCESS,
    ACTIVE_ORDERS_INDEX_ERR,

    RESET_OFFLINE_ORDERS,
    OFFLINE_ORDERS_INDEX_BEGIN,
    OFFLINE_ORDERS_INDEX_SUCCESS,
    OFFLINE_ORDERS_INDEX_ERR,

} = actions;

const initState = {
    store_loading: false,
    store_done: false,
    store_error: null,
    store_checkout: null,

    apply_coupon_done: false,
    apply_coupon_loading: false,
    apply_coupon_fields: false,
    apply_coupon_error: false,
    apply_coupon_message: null,

    clear_coupon_done: false,
    clear_coupon_loading: false,
    clear_coupon_fields: false,
    clear_coupon_error: false,
    clear_coupon_message: null,

    create_payment_loading: false,
    create_payment_done: false,
    create_payment_error: null,
    create_payment_fields: null,
    create_payment_checkout: null,

    validate_loading: false,
    validate_done: false,
    validate_error: null,
    validate_checkout: null,
    validate_message: null,

    total_active_orders: 0,

    orders_loading: false,
    orders_error: null,
    orders_success: null,
    active_orders: null,

    offline_orders_loading: false,
    offline_orders_error: null,
    offline_orders_success: null,
    offline_orders: null,
    offline_total_pending_points: 0,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CheckoutReducer = (state = initState, action) => {
    const {type, checkout, errors, message, total_active_orders, error, fields, active_orders, offline_orders, total_pending_points} = action;
    switch (type) {
        case CHECKOUT_RESET:
            return {
                ...state,
                store_loading: false,
                store_done: false,
                store_error: null,
                store_checkout: null,

                create_payment_loading: false,
                create_payment_done: false,
                create_payment_error: null,
                create_payment_fields: null,
                create_payment_checkout: null,

                apply_coupon_done: false,
                apply_coupon_loading: false,
                apply_coupon_fields: false,
                apply_coupon_error: false,
                apply_coupon_message: null,
                clear_coupon_done: false,
                clear_coupon_loading: false,
                clear_coupon_fields: false,
                clear_coupon_error: false,
                clear_coupon_message: null,

            };

        case CHECKOUT_STORE_RESET:
            return {
                ...state,
                store_loading: false,
                store_done: false,
                store_error: null,
            };

        case CHECKOUT_STORE_BEGIN:
            return {
                ...state,
                store_loading: true,
                store_done: false,
                store_checkout: null,
                store_error: null,
            };
        case CHECKOUT_STORE_SUCCESS:
            return {
                ...state,
                store_loading: false,
                store_done: true,
                store_checkout: checkout
            };
        case CHECKOUT_STORE_ERROR:
            return {
                ...state,
                store_loading: false,
                store_done: false,
                store_error: errors,
            };


        case APPLY_COUPON_BEGIN:
            return {
                ...state,
                clear_coupon_message: null,
                clear_coupon_done: false,
                apply_coupon_done: false,
                apply_coupon_loading: true,
                apply_coupon_error: null,
                apply_coupon_message: null,
                apply_coupon_fields: null,
            };
        case APPLY_COUPON_SUCCESS:
            return {
                ...state,
                apply_coupon_done: true,
                apply_coupon_loading: false,
                apply_coupon_message: message,
                apply_coupon_error: null,
                apply_coupon_fields: null,
                store_checkout: checkout,
            };
        case APPLY_COUPON_ERROR:
            return {
                ...state,
                apply_coupon_done: false,
                apply_coupon_loading: false,
                apply_coupon_error: message,
                apply_coupon_fields: errors,
            };


        case CLEAR_COUPON_BEGIN:
            return {
                ...state,
                clear_coupon_done: false,
                clear_coupon_loading: true,
                clear_coupon_error: null,
                clear_coupon_message: null,
                clear_coupon_fields: null,
            };
        case CLEAR_COUPON_SUCCESS:
            return {
                ...state,
                clear_coupon_done: true,
                clear_coupon_loading: false,
                clear_coupon_message: message,
                clear_coupon_error: null,
                store_checkout: checkout,
                clear_coupon_fields: null,
                apply_coupon_done: false,
                apply_coupon_message: null,
            };
        case CLEAR_COUPON_ERROR:
            return {
                ...state,
                clear_coupon_done: false,
                clear_coupon_loading: false,
                clear_coupon_error: message,
                clear_coupon_fields: errors,
            };



        case CREATE_PAYMENT_BEGIN:
            return {
                ...state,
                create_payment_loading: true,
                create_payment_done: false,
                create_payment_checkout: null,
                create_payment_error: null,
            };
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                create_payment_loading: false,
                create_payment_done: true,
                create_payment_checkout: checkout
            };
        case CREATE_PAYMENT_ERROR:
            return {
                ...state,
                create_payment_loading: false,
                create_payment_done: false,
                create_payment_error: message,
                create_payment_fields: errors,
            };

        case CHECKOUT_VALIDATE_RESET:
            return {
                ...state,
                validate_checkout: null,
                validate_loading: true,
                validate_done: false,
                validate_error: null,
                validate_fields: null,
                validate_message: null,
            };

        case CHECKOUT_VALIDATE_BEGIN:
            return {
                ...state,
                validate_loading: true,
                validate_done: false,
                validate_error: null,
                validate_fields: null,
                validate_message: null,
            };
        case CHECKOUT_VALIDATE_SUCCESS:
            return {
                ...state,
                validate_loading: false,
                validate_done: true,
                validate_checkout: checkout,
                validate_message: message,
                total_active_orders: total_active_orders,
            };
        case CHECKOUT_VALIDATE_ERROR:
            return {
                ...state,
                validate_loading: false,
                validate_done: false,
                validate_error: error,
                validate_fields: fields,
                validate_checkout: null,
            };

        case RESET_ACTIVE_ORDERS:
            return {
                ...state,
                active_orders: null
            };

        case ACTIVE_ORDERS_INDEX_BEGIN:
            //console.log("orders_loading: true");
            return {
                ...state,
                orders_loading: true,
                orders_success: false,
                orders_error: null,
            };
        case ACTIVE_ORDERS_INDEX_SUCCESS:
            //console.log("orders_loading: false and orders are loaded!");
            return {
                ...state,
                orders_loading: false,
                orders_success: true,
                active_orders,
                total_active_orders: active_orders?active_orders.length:0,
            };
        case ACTIVE_ORDERS_INDEX_ERR:
            return {
                ...state,
                orders_loading: false,
                orders_success: false,
                orders_error: error,
                active_orders: null,
            };


        case RESET_OFFLINE_ORDERS:
            return {
                ...state,
                offline_orders: null
            };
        case OFFLINE_ORDERS_INDEX_BEGIN:
            return {
                ...state,
                offline_orders_loading: true,
                offline_orders_success: false,
                offline_orders_error: null,
                offline_total_pending_points: 0,
            };
        case OFFLINE_ORDERS_INDEX_SUCCESS:
            return {
                ...state,
                offline_orders_loading: false,
                offline_orders_success: true,
                offline_orders,
                total_offline_orders: offline_orders?offline_orders.length:0,
                offline_total_pending_points: total_pending_points,
            };
        case OFFLINE_ORDERS_INDEX_ERR:
            return {
                ...state,
                offline_orders_loading: false,
                offline_orders_success: false,
                offline_orders_error: error,
                offline_orders: null,
                offline_total_pending_points: 0,
            };

        default:
            return state;
    }
};
export default CheckoutReducer;
