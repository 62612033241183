import actions from './actions';

const {
  MENU_INDEX_BEGIN,
  MENU_INDEX_SUCCESS,
  MENU_INDEX_ERR,
  UPDATE_MENU,

} = actions;

const initState = {
  index_loading: true,
  index_menu: null,
  index_options: null,
  index_images: null,
  index_error: null,
  index_categories: null,
  index_translations: null,
  index_items: null,
  index_branches: null,
  public_path: null,
};

const MenuReducer = (state = initState, action) => {
  const { type, branches, menu, options, items, images, translations, error, categories, public_path } = action;
  switch (type) {

    case MENU_INDEX_BEGIN:
      return {
        ...state,
        index_loading: true,
        index_error: null,
        index_menu: null,
        index_categories: null,
        index_options: null,
        index_items: null,
        index_branches: null,
      };

    case MENU_INDEX_SUCCESS:
      let foods = [];
      menu.map(category => {
        category.foods.map(food => {
          foods.push(food);
        });
      })
      return {
        ...state,
        index_loading: false,
        index_branches: branches,
        index_menu: foods,
        index_images: images,
        index_categories: menu,
        index_translations: translations,
        public_path: public_path,
        index_options: options,
        index_items: items,

      };

    case MENU_INDEX_ERR:
      return {
        ...state,
        index_loading: true,
        index_error: error,
      };


    case UPDATE_MENU:

      //console.log("inside UPDATE_MENU");
      if(!state.index_options)
        return state;

      //console.log("inside UPDATE_MENU after if");

      let anyOptionUpdated = false;
      const updatedOptions = state.index_options.map(option => {
        const updatedOption = action.options.find(f => f.id === option.id);
        let optionUpdated = false;
        if (updatedOption && updatedOption.in_stock !== option.in_stock) {option.in_stock = updatedOption.in_stock; optionUpdated = true;}
        if (updatedOption && updatedOption.status !== option.status) {option.status = updatedOption.status; optionUpdated = true;}

        if(optionUpdated) {
          anyOptionUpdated = true;
          return {...option};
        }
        else
          return option;
      });


      //console.log("Action:", action);

      let anyItemUpdated = false;
      const updatedItems = state.index_items.map(item => {
        const updatedItem = action.items.find(f => f.id === item.id);
        let itemUpdated = false;
        if (updatedItem && updatedItem.in_stock !== item.in_stock) {item.in_stock = updatedItem.in_stock; itemUpdated = true;}
        if (updatedItem && updatedItem.status !== item.status) {item.status = updatedItem.status; itemUpdated = true;}

        if(itemUpdated) {
          anyItemUpdated = true;
          return {...item};
        }
        else
          return item;
      });


      let anyFoodUpdated = false;
      const updatedFoods = state.index_menu.map(food => {
        const updatedFood = action.foods.find(f => f.id === food.id);
        let foodUpdated = false;

        if (updatedFood && updatedFood.status !== food.status) {food.status = updatedFood.status; foodUpdated = true; }
        if (updatedFood && updatedFood.in_stock !== food.in_stock) {food.in_stock = updatedFood.in_stock; foodUpdated = true;}
        if (updatedFood && updatedFood.can_customize !== food.can_customize) {food.can_customize = updatedFood.can_customize; foodUpdated = true;}
        if (updatedFood && updatedFood.use_inventory !== food.use_inventory) {food.use_inventory = updatedFood.use_inventory; foodUpdated = true;}
        if (updatedFood && updatedFood.inventory !== food.inventory) {food.inventory = updatedFood.inventory; foodUpdated = true;}
        if (updatedFood && updatedFood.show_inventory !== food.show_inventory) {food.show_inventory = updatedFood.show_inventory; foodUpdated = true;}
        if (updatedFood && updatedFood.permanent !== food.permanent) {food.permanent = updatedFood.permanent; foodUpdated = true;}

        if(foodUpdated) {
          anyFoodUpdated = true;
          return {...food};
        }
        else
          return food;
      });


      let anyCategoryUpdated = false;
      const updatedCategories = state.index_categories.map(category => {
        const updatedCategory = action.categories.find(f => f.id === category.id);

        let categoryUpdated = false;

        if (updatedCategory && updatedCategory.status !== category.status) {category.status = updatedCategory.status; categoryUpdated = true; }
        if (updatedCategory && updatedCategory.explode !== category.explode) {category.explode = updatedCategory.explode; categoryUpdated = true;}

        if(anyFoodUpdated) {
          categoryUpdated = true;
          const categoryFoods = category.foods.map(food => {
            const updatedFood = updatedFoods.find(f => f.id === food.id);
            return {...updatedFood};
          });
          category = {...category, foods: categoryFoods};
        }

        if(categoryUpdated) {
          anyCategoryUpdated = true;
          return {...category};
        }
        else
          return category;
      });


      return {
        ...state,
        index_categories: anyCategoryUpdated?updatedCategories:state.index_categories,
        index_menu: anyFoodUpdated?updatedFoods:state.index_menu,
        index_options: anyOptionUpdated?updatedOptions:state.index_options,
        index_items: anyItemUpdated?updatedItems:state.index_items
      };


    default:
      return state;
  }
};
export default MenuReducer;
