import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEye, faPlus, faStop, faUserClock} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import Bloggers from "../../../../http/Bloggers";
import Table from "ant-responsive-table";
import {Button, Pagination} from "antd";
import {SpinnerCircular} from "spinners-react";
import InviteActions from "./InviteActions";

const InviteesBloggers = ({blogger}) => {
    const dispatch = useDispatch();

    return (
        <div className="flex flex-col">
            {blogger.viewed_at?
                <div className="flex flex-row items-center whitespace-nowrap">
                    <FontAwesomeIcon icon={faEye} className="mr-2 text-3xl" aria-hidden="true"/>
                    <div className=" flex flex-col whitespace-nowrap items-start">
                        <div>Viewed at: </div>
                        <span>
                        {blogger.viewed_at.split(" ")[0]}
                    </span>
                    </div>
                </div>
            :<div className="font-bold text-base uppercase">Not seen</div>}


            {blogger.accepted_at?
                <div className="flex flex-row items-center whitespace-nowrap border-t border-gray-200 mt-2 pt-2">
                    <FontAwesomeIcon icon={faCheckCircle} className=" text-green-800 mr-2 text-3xl" aria-hidden="true"/>
                    <div className=" flex flex-col whitespace-nowrap items-start">
                        <div>Accepted at: </div>
                        <span>
                        {blogger.accepted_at.split(" ")[0]}
                    </span>
                    </div>
                </div>
                :null}

            {blogger.will_attend_at?
                <div className="flex flex-row items-center whitespace-nowrap border-t border-gray-200 mt-2 pt-2">
                    <FontAwesomeIcon icon={faUserClock} className=" text-blue-800 mr-2 text-3xl" aria-hidden="true"/>
                    <div className=" flex flex-col whitespace-nowrap items-start">
                        <div>Will attend at: </div>
                        <span>
                        {blogger.will_attend_at.split(" ")[0]}
                    </span>
                    </div>
                </div>
                :null}

        </div>
    );
};
export default InviteesBloggers;
