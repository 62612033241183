const actions = {
  PROFILES_EDIT_RESET: 'PROFILES_EDIT_RESET',
  PROFILES_EDIT_BEGIN: 'PROFILES_EDIT_BEGIN',
  PROFILES_EDIT_SUCCESS: 'PROFILES_EDIT_SUCCESS',
  PROFILES_EDIT_ERR: 'PROFILES_EDIT_ERR',

  PROFILES_UPDATE_BEGIN: 'PROFILES_UPDATE_BEGIN',
  PROFILES_UPDATE_SUCCESS: 'PROFILES_UPDATE_SUCCESS',
  PROFILES_UPDATE_ERR: 'PROFILES_UPDATE_ERR',

  PASSWORD_EDIT_RESET: 'PASSWORD_EDIT_RESET',
  PASSWORD_UPDATE_BEGIN: 'PASSWORD_UPDATE_BEGIN',
  PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
  PASSWORD_UPDATE_ERR: 'PASSWORD_UPDATE_ERR',

  ORDERS_INDEX_BEGIN: 'ORDERS_INDEX_BEGIN',
  ORDERS_INDEX_SUCCESS: 'ORDERS_INDEX_SUCCESS',
  ORDERS_INDEX_ERR: 'ORDERS_INDEX_ERR',

  REQUESTS_INDEX_BEGIN: 'REQUESTS_INDEX_BEGIN',
  REQUESTS_INDEX_SUCCESS: 'REQUESTS_INDEX_SUCCESS',
  REQUESTS_INDEX_ERR: 'REQUESTS_INDEX_ERR',

  OFFLINE_ORDERS_INDEX_BEGIN: 'OFFLINE_ORDERS_INDEX_BEGIN',
  OFFLINE_ORDERS_INDEX_SUCCESS: 'OFFLINE_ORDERS_INDEX_SUCCESS',
  OFFLINE_ORDERS_INDEX_ERR: 'OFFLINE_ORDERS_INDEX_ERR',

  profilesEditBegin: () => {
    return {
      type: actions.PROFILES_EDIT_BEGIN,
    };
  },

  profilesEditSuccess: (profile, categories) => {
    return {
      type: actions.PROFILES_EDIT_SUCCESS,
      profile,
      categories
    };
  },

  profilesEditErr: (error) => {
    return {
      type: actions.PROFILES_EDIT_ERR,
      error,
    };
  },


  profilesEditReset: () => {
    return {
      type: actions.PROFILES_EDIT_RESET,
    };
  },

  profilesUpdateBegin: () => {
    return {
      type: actions.PROFILES_UPDATE_BEGIN,
    };
  },

  profilesUpdateSuccess: (profile) => {
    return {
      type: actions.PROFILES_UPDATE_SUCCESS,
      profile,
    };
  },

  profilesUpdateErr: (error, fields) => {
    return {
      type: actions.PROFILES_UPDATE_ERR,
      error,
      fields,
    };
  },

  passwordEditReset: () => {
    return {
      type: actions.PASSWORD_EDIT_RESET,
    };
  },

  passwordUpdateBegin: () => {
    return {
      type: actions.PASSWORD_UPDATE_BEGIN,
    };
  },

  passwordUpdateSuccess: () => {
    return {
      type: actions.PASSWORD_UPDATE_SUCCESS,
    };
  },

  passwordUpdateErr: (error, fields) => {
    return {
      type: actions.PASSWORD_UPDATE_ERR,
      error,
      fields,
    };
  },


  ordersIndexBegin: () => {
    return {
      type: actions.ORDERS_INDEX_BEGIN,
    };
  },

  ordersIndexSuccess: (orders) => {
    return {
      type: actions.ORDERS_INDEX_SUCCESS,
      orders
    };
  },

  ordersIndexErr: (error, fields) => {
    return {
      type: actions.ORDERS_INDEX_ERR,
      error,
      fields,
    };
  },


  requestsIndexBegin: () => {
    return {
      type: actions.REQUESTS_INDEX_BEGIN,
    };
  },

  requestsIndexSuccess: (requests) => {
    return {
      type: actions.REQUESTS_INDEX_SUCCESS,
      requests
    };
  },

  requestsIndexErr: (error, fields) => {
    return {
      type: actions.REQUESTS_INDEX_ERR,
      error,
      fields,
    };
  },

};

export default actions;
