import React from 'react';
import {useSelector} from "react-redux";

export const Translate = (text, fr=null) => {
    const frs = useSelector(state => state.menu.index_translations);
    const lang = useSelector(state => state.settings.lang);

    if(lang == "fr" && fr) return fr;

    if(!frs) return text;

    // handle exceptions
    if(text.startsWith("Sauce (Default")) {
        if(lang == "en") return "Sauce (Default)";
        if(lang == "fr") return "Sauce (Par Défaut)";
    }

    if(text.includes("(Triple ")) {
        text = text.replace("(Triple ", "(");
    }

    if(lang == "en") return text;
    if(lang == "fr") {
        if (text in frs) {
            return frs[text];
        }
        text = text.toLowerCase();
        if (text in frs) {
            return frs[text];
        }
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
}
