import React, {useEffect} from 'react';
import StatusMessages, {useMessages} from "../../components/Stripe/StatusMessages";
import push from "../../history";
import {SpinnerCircular} from "spinners-react";

const MyComponent = () => {

    const [messages, addMessage] = useMessages();

    const RefreshCrashed = () => {
        const crashedURL = localStorage.getItem("crashedURL");
        if(crashedURL && crashedURL != "/server-down") {
            addMessage("redirecting: " + crashedURL);
            window.location.href = crashedURL;
            window.sendMessageToSwift("redirecting: " + crashedURL);
        }
        else {
            addMessage("redirecting: " + "/menu");
            window.sendMessageToSwift("redirecting: /menu");
            window.location.href = "/menu";
        }
    }

    useEffect(() => {
        addMessage("crashedURL: " + localStorage.getItem("crashedURL"))
        setTimeout(RefreshCrashed, 5000);
    },[]);

    const refresh = () => {
        const url = localStorage.getItem("crashedURL");
        if(url) {
            localStorage.removeItem("crashedURL");
            if(url == "/server-down") {
                window.location.href = "/menu";
            }
            window.location.href = url;
        }
        else {
            window.location.href = "/menu";
        }
    }

    return (
        window.location.href == "/hiring-form/"?
            <div></div>
        :
        <div className="flex items-center text-center flex-col">
            <div className="text-center py-16 text-xl">
                <div className="py-24 w-full flex flex-col items-center justify-center opacity-70">
                    <SpinnerCircular size={200} thickness={100} speed={80} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)"/>
                    <br/>
                    There was a problem with your request.
                    <br/>
                    <b>Please wait...</b>
                </div>

                {/*
                <StatusMessages messages={messages}/>
                */}

            </div>
        </div>
    );
};

export default MyComponent;
