import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const ModelPerformance = {
    getModelPerformances: (successCb, failCb) => {
        axios.get('/model-performances', getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    retrainModel: (branchId, data, successCb, failCb) => {
        axios.post(`/model-performances/retrain/${branchId}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    retrainAllModels: (discardDays, successCb, failCb) => {
        axios.post('/model-performances/retrain-all', { discard_days: discardDays }, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default ModelPerformance;