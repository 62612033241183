import React from 'react';
import {withGoogleMap, GoogleMap, Polygon, Marker, OverlayView} from 'react-google-maps';
import yellowMarkerIcon from './icons/google-maps-yellow-marker.png'; // Replace with the path to your yellow marker icon
import mcdonaldsIcon from './icons/mcdonalds.png';
import starbucksIcon from './icons/starbucks.png';
import shakeshackIcon from './icons/shakeshack.png';
import fiveguysIcon from './icons/fiveguys.png';
import burgerkingIcon from './icons/burgerking.png';

const GoogleMapComponent = withGoogleMap(({clusters, zoom, center, selectedBoundary, mcdonalds, shakeshacks, starbucks, fiveguys, burgerkings, showmcdonalds, showstarbucks, showshakeshacks, showfiveguys, showburgerkings}) => (
    <GoogleMap
        defaultZoom={10}
        defaultCenter={{lat: 45.5591827, lng: -73.7118733}} // Set the initial center of the map
        zoom={zoom} // Use the passed zoom prop
        center={center} // Use the passed center prop
    >
        {clusters.map((cluster, index) => (
            <React.Fragment key={index}>
                <Polygon
                    key={index}
                    path={cluster.shape.map(coord => ({lng: coord[0], lat: coord[1]}))}
                    options={{
                        fillColor: cluster.shape == selectedBoundary ? '#002bff' : '#FF0000',
                        fillOpacity: .5,
                        strokeColor: cluster.shape == selectedBoundary ? '#002bff' : '#FF0000',
                        strokeOpacity: 10,
                        strokeWeight: 2,
                    }}
                />

                {/*
                <OverlayView
                    position={{lat: cluster.center[1], lng: cluster.center[0]}}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div style={{ fontWeight: 'bold', color: 'black', display: "flex", justifyContent: "center", alignItems:"center", width: "20px", height: "20px", backgroundColor: "yellow", borderRadius: 100 }}>{cluster.rank.toString()}</div>
                </OverlayView>
                */}


                <Marker
                    key={`marker-${index}`}
                    position={{lat: cluster.center[1], lng: cluster.center[0]}}
                    label={cluster.rank.toString()}
                    labelStyle={{fontWeight: "bold"}}
                    icon={{
                        url: yellowMarkerIcon, // Path to your yellow marker icon
                        scaledSize: new window.google.maps.Size(30, 60), // Adjust the size as needed
                    }}
                />
            </React.Fragment>
        ))}

        {showmcdonalds && mcdonalds.map((loc, index) => (
            <Marker
                key={`mcdonalds-${index}`}
                position={loc}
                icon={{
                    url: mcdonaldsIcon, // Path to your yellow marker icon
                    scaledSize: new window.google.maps.Size(30, 50), // Adjust the size as needed
                }}
            />
        ))}

        {showstarbucks && starbucks.map((loc, index) => (
            <Marker
                key={`starbucks-${index}`}
                position={loc}
                icon={{
                    url: starbucksIcon, // Path to your yellow marker icon
                    scaledSize: new window.google.maps.Size(30, 50), // Adjust the size as needed
                }}
            />
        ))}

        {showshakeshacks && shakeshacks.map((loc, index) => (
            <Marker
                key={`shakeshacks-${index}`}
                position={loc}
                icon={{
                    url: shakeshackIcon, // Path to your yellow marker icon
                    scaledSize: new window.google.maps.Size(30, 50), // Adjust the size as needed
                }}
            />
        ))}

        {showfiveguys && fiveguys.map((loc, index) => (
            <Marker
                key={`fiveguys-${index}`}
                position={loc}
                icon={{
                    url: fiveguysIcon, // Path to your yellow marker icon
                    scaledSize: new window.google.maps.Size(30, 50), // Adjust the size as needed
                }}
            />
        ))}

        {showburgerkings && burgerkings.map((loc, index) => (
            <Marker
                key={`burgerkings-${index}`}
                position={{lat: loc.lat, lng: loc.lng}}
                icon={{
                    url: burgerkingIcon, // Path to your yellow marker icon
                    scaledSize: new window.google.maps.Size(30, 50), // Adjust the size as needed
                }}
            >
                <OverlayView
                    key={`burgerkings-${index}`}
                    position={{ lat: loc.lat, lng: loc.lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div style={{ position: 'absolute', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                        <div style={{ backgroundColor: 'white', padding: '0px 4px', borderRadius: '5px', border: "1px solid black" }}>
                            <span>${loc.sales}</span>
                        </div>
                    </div>
                </OverlayView>
            </Marker>
        ))}

    </GoogleMap>
));

export default GoogleMapComponent;
