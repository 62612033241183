import React from 'react';
import { Form } from 'antd';

function FormItem({ name, rules, initialValue, label, disabled, errors, children, hidden, className, valuePropName, style}) {
  return (
    <Form.Item
      className={className}
      style={style}
      valuePropName={valuePropName}
      hidden={hidden}
      name={name}
      rules={rules}
      initialValue={initialValue}
      label={label}
      disabled={disabled}
      validateStatus={errors && errors[name] ? 'error' : undefined}
      help={errors && errors[name] ? errors[name][errors[name].length - 1] : undefined}
    >
      {children}
    </Form.Item>
  );
}

export default FormItem;