import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart } from './Headers';

const FoodApi = {

    Edit: (id, successCb, failCb) => {
        axios.get(`/food/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Update: (id, data, successCb, failCb) => {
        axios.post(`/food/${id}`, data, getAuthenticationHeadersMultiPart())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    UpdateOptionOrder: (id, data, successCb, failCb) => {
        axios.post(`/food/${id}/options/reorder`, {"options": data}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default FoodApi;
