import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, InputNumber, notification } from 'antd';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';
import Forecast from "../../../../http/Forecast";

const BranchForecasts = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [branch, setBranch] = useState(null);
    const [forecasts, setForecasts] = useState([]);
    const [adjustedForecasts, setAdjustedForecasts] = useState({});
    const [adjustmentCoefficient, setAdjustmentCoefficient] = useState(1.15);
    const [coefficientChanged, setCoefficientChanged] = useState(false);
    const [saving, setSaving] = useState({});
    const [resetting, setResetting] = useState({});

    useEffect(() => {
        if (branchId) {
            fetchOrderPageData(branchId);
        }
    }, [branchId]);

    const fetchOrderPageData = (branchId) => {
        setLoading(true);
        Forecast.getForecasts(branchId,
            response => {
                const data = response.data.data;
                setForecasts(data.forecasts);
                setBranch(data.branch);
                setAdjustmentCoefficient(parseFloat(data.adjustment_coefficient));
                setLoading(false);
            },
            error => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load order page data',
                });
                setLoading(false);
            });
    };

    const handleForecastChange = (forecastId, value) => {
        setAdjustedForecasts(prevState => ({
            ...prevState,
            [forecastId]: value,
        }));
    };

    const saveForecast = (forecastId) => {
        setSaving(prevState => ({ ...prevState, [forecastId]: true }));
        const adjustedValue = adjustedForecasts[forecastId];
        Forecast.updateForecast(forecastId, {
            predicted_burgers: adjustedValue,
            adjusted: 1,
        }, (response) => {
            notification.success({
                message: 'Success',
                description: 'Forecast updated successfully',
            });

            setForecasts(prevForecasts => prevForecasts.map(f =>
                f.id === forecastId ? { ...f, predicted_burgers: adjustedValue, adjusted: 1 } : f
            ));
            setSaving(prevState => ({ ...prevState, [forecastId]: false }));
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to update forecast',
            });
            setSaving(prevState => ({ ...prevState, [forecastId]: false }));
        });
    };

    const resetForecast = (forecastId) => {
        setResetting(prevState => ({ ...prevState, [forecastId]: true }));
        const forecast = forecasts.find(f => f.id === forecastId);
        Forecast.updateForecast(forecastId, {
            predicted_burgers: forecast.model_prediction,
            adjusted: 0,
        }, (response) => {
            notification.success({
                message: 'Success',
                description: 'Forecast reset successfully',
            });

            setForecasts(prevForecasts => prevForecasts.map(f =>
                f.id === forecastId ? { ...f, predicted_burgers: forecast.model_prediction, adjusted: 0 } : f
            ));
            setAdjustedForecasts(prevState => ({
                ...prevState,
                [forecastId]: undefined,  // Reset adjusted forecast after reset
            }));
            setResetting(prevState => ({ ...prevState, [forecastId]: false }));
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to reset forecast',
            });
            setResetting(prevState => ({ ...prevState, [forecastId]: false }));
        });
    };

    const saveCoefficient = () => {
        Forecast.updateAdjustmentCoefficient(
            { adjustment_coefficient: adjustmentCoefficient },
            response => {
                notification.success({
                    message: 'Success',
                    description: 'Adjustment coefficient updated successfully',
                });
                setCoefficientChanged(false);
            },
            err => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to update adjustment coefficient',
                });
            }
        );
    };

    return (
        <DefaultContainer>
            <div className="patty text-2xl font-bold mb-4">Burger Sales - Future Forecasts - {branch && <span>{branch.name}</span>}</div>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <div className="bg-blue-50 px-2 py-2 border-gray-300 mb-2 border rounded-md">

                    <div className="mt-2 flex items-center mb-3">
                        <span className="font-bold ml-2 mr-2 uppercase text-base">Adjustment Coefficient:</span>
                        <InputNumber
                            min={1}
                            step={0.01}
                            value={adjustmentCoefficient}
                            onChange={value => {
                                setAdjustmentCoefficient(value);
                                setCoefficientChanged(true);
                            }}
                            style={{ width: "70px" }}
                        />
                        {coefficientChanged && (
                            <Button
                                type="primary"
                                size="small"
                                onClick={saveCoefficient}
                                className="ml-2"
                            >
                                Save Coefficient
                            </Button>
                        )}
                    </div>


                    <div className="flex flex-col">
                        {forecasts.map((forecast) => {
                            const adjustedValue = adjustedForecasts[forecast.id] ?? forecast.predicted_burgers;
                            const adjustedValueWithCoefficient = adjustedValue * adjustmentCoefficient;
                            const isAdjusted = forecast.model_prediction !== forecast.predicted_burgers || adjustedForecasts[forecast.id] !== undefined;
                            const isSaving = saving[forecast.id] || false;
                            const isResetting = resetting[forecast.id] || false;

                            return (
                                <div key={forecast.id} className="bg-gray-100 px-3 py-2 border border-gray-300 shadow-sm flex flex-col mb-1">
                                    <div className="flex items-center">
                                        <span className="font-bold mr-2">{forecast.forecast_date}:</span>
                                        <InputNumber
                                            min={0}
                                            value={adjustedValue}
                                            onChange={(value) => handleForecastChange(forecast.id, value)}
                                            style={{ width: "100px" }}
                                            disabled={isSaving || isResetting}
                                        />
                                        <div className="text-xs ml-2 text-right mt-1">
                                            Adjusted: <b>{adjustedValueWithCoefficient.toFixed(0)}</b>
                                        </div>
                                    </div>
                                    {isAdjusted && (
                                        <div className="flex items-center mt-2">
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() => saveForecast(forecast.id)}
                                                loading={isSaving}
                                                disabled={isSaving || isResetting}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                type="default"
                                                size="small"
                                                onClick={() => resetForecast(forecast.id)}
                                                loading={isResetting}
                                                disabled={isSaving || isResetting}
                                                className="ml-2"
                                            >
                                                Reset
                                            </Button>
                                            <div className="text-xs text-red-500 pl-3">
                                                Adjusted from actual model prediction: {forecast.model_prediction} ({((adjustedValue - forecast.model_prediction) / forecast.model_prediction * 100).toFixed(1)}%)
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                </div>
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default BranchForecasts;
