import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import ShiftTemperatureReport from '../../../../../http/ShiftTemperatureReport';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';

const CreateShiftTemperatureReport = () => {
    const [form] = Form.useForm();
    const { branchId } = useParams();
    const [zones, setZones] = useState([]);
    const [branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    useEffect(() => {
        ShiftTemperatureReport.Create(branchId, (response) => {
            setZones(response.data.data.zones);
            setBranch(response.data.data.branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load temperature zones.',
            });
            setLoading(false);
        });
    }, [branchId]);

    const onFinish = (values) => {
        setSaving(true);
        const temperatures = zones.map(zone => ({
            zone_id: zone.id,
            temperature: values[`temperature_${zone.id}`],
        }));

        ShiftTemperatureReport.Store(branchId, { temperatures }, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Shift Temperature Report created successfully.',
            });
            form.resetFields();
            setSaving(false);
            history.push(`/dashboard/operations/daily-logs/end-of-shift/temperature-reports/${branch.id}/manage`)
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to create shift temperature report.',
            });
            setSaving(false);
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2>Create Shift Temperature Report for {branch ? branch.name : 'Loading...'}</h2>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    {zones.map(zone => (
                        <Form.Item
                            key={zone.id}
                            name={`temperature_${zone.id}`}
                            label={`Temperature for ${zone.name}`}
                            rules={[{ required: true, message: `Please enter the temperature for ${zone.name}` }]}
                        >
                            <Input type="number" step="0.1" addonAfter="°C" />
                        </Form.Item>
                    ))}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={saving}>Submit Report</Button>
                    </Form.Item>
                </Form>
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default CreateShiftTemperatureReport;