import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const BranchInventoryGroupContainer = {
    Index: (successCb, failCb) => {
        axios.get(`/branch_inventory_group_containers`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Create: (successCb, failCb) => {
        axios.get(`/branch_inventory_group_containers/create`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/branch_inventory_group_containers`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/branch_inventory_group_containers/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.put(`/branch_inventory_group_containers/${id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (id, successCb, failCb) => {
        axios.delete(`/branch_inventory_group_containers/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default BranchInventoryGroupContainer;
