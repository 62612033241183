import actions from './actions';

const {
  resetFoodAction,
  setFoodAction,
  selectItemAction,
} = actions;

const resetFood = (food) => {
  return dispatch => {
    dispatch(resetFoodAction(food));
  };
};

const setFood = (food, selectedItems=null) => {
  return dispatch => {
    dispatch(setFoodAction(food, selectedItems));
  };
};

const selectItem = (food, selectedItems, item_id) => {
  return dispatch => {
    dispatch(selectItemAction(food, selectedItems, item_id));
  };
};

export { setFood, selectItem, resetFood };
