import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {SpinnerCircular} from "spinners-react";

const DarkGenericSkeleton = () => {
    return (
        <>
            <div className="py-6 w-full flex flex-col items-center justify-center opacity-90">
                <SpinnerCircular size={100} thickness={50} speed={40} color="rgba(255, 0, 0, .8)" secondaryColor="rgba(255, 255, 255, 0.74)"/>
            </div>
        </>
    )

};

export default DarkGenericSkeleton;
