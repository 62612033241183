import React, { useEffect, useState } from 'react';
import { Table, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import BranchInventory from "../http/BranchInventory";
import SkeletonWrapper from "../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../components/skeletons/GenericSkeleton";
import DefaultContainer from "../components/DefaultContainer";

const BranchSelector = ({ title, redirectUrl, customComponent }) => {
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState([]);
    const history = useHistory();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        setLoading(true);
        BranchInventory.FetchInitialData((response) => {
            setLoading(false);
            setBranches(response.data.data.branches);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branches',
            });
        });
    };

    const handleBranchClick = (branchId) => {
        history.push(`${redirectUrl}/${branchId}/manage`);
    };

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <a onClick={() => handleBranchClick(record.id)}>{text}</a>
            ),
        },
    ];

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className={"patty text-2xl font-bold"}>{title}</div>
            {customComponent? customComponent : null}
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    dataSource={branches}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                />
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default BranchSelector;