// AbilityItem.js

import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Button, Popconfirm, Input, message } from 'antd';
import { DeleteOutlined, UpOutlined, DownOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import Abilities from '../../../../http/Abilities';
import {RiClipboardLine} from "@remixicon/react";
import copy from "copy-to-clipboard";

const ItemTypes = {
    ABILITY: 'ability',
};

const AbilityItem = ({ ability, groupId, moveAbilityUp, moveAbilityDown, isFirst, isLast, fetchAbilityGroups }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ABILITY,
        item: { id: ability.id, groupId, type: 'ability' },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [isEditing, setIsEditing] = useState(false);
    const [newAbilityName, setNewAbilityName] = useState(ability.label);
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setLoading(true);
        Abilities.Update(ability.id, { label: newAbilityName }, (response) => {
            message.success('Ability updated successfully');
            setIsEditing(false);
            fetchAbilityGroups(); // Refresh the abilities list
            setLoading(false);
        }, (err) => {
            message.error('Failed to update ability');
            setLoading(false);
        });
    };

    const handleDelete = () => {
        setLoading(true);

        Abilities.Delete(ability.id, (response) => {
            message.success('Ability deleted successfully');
            fetchAbilityGroups(); // Refresh the abilities list
            setLoading(false);
        }, (err) => {
            message.error('Failed to delete ability');
            console.log("err", err);
            setLoading(false);
        });
    };

    return (
        <div
            ref={drag}
            className={`ability-item px-2 mb-2 bg-gray-100 rounded flex justify-between items-center ${isDragging ? 'opacity-50' : ''}`}
        >
            <div className="flex items-center">
                <Button type="link" icon={<UpOutlined />} onClick={moveAbilityUp} disabled={isFirst} />
                <Button type="link" icon={<DownOutlined />} onClick={moveAbilityDown} disabled={isLast} />
                {isEditing ? (
                    <Input
                        value={newAbilityName}
                        onChange={(e) => setNewAbilityName(e.target.value)}
                        size="small"
                        style={{ width: '400px', marginLeft: '10px' }}
                        disabled={loading}
                    />
                ) : (
                    <span className="ml-2 flex flex-row">
                        {ability.label} ({ability.name}) <RiClipboardLine onClick={() => copy(ability.name)} className={"ml-2 cursor-pointer"} />
                    </span>
                )}
            </div>
            <div>
                {isEditing ? (
                    <>
                        <Button
                            type="link"
                            icon={<SaveOutlined />}
                            onClick={handleSave}
                            loading={loading}
                        />
                        <Button
                            type="link"
                            icon={<CloseOutlined />}
                            onClick={() => setIsEditing(false)}
                        />
                    </>
                ) : (
                    <>
                        <Button type="link" icon={<EditOutlined />} onClick={() => setIsEditing(true)} />
                        <Popconfirm
                            title="Are you sure to delete this ability?"
                            onConfirm={handleDelete}
                            okText="Yes"
                            cancelText="No"
                            placement={"right"}
                        >
                            <Button type="link" icon={<DeleteOutlined />} danger />
                        </Popconfirm>
                    </>
                )}
            </div>
        </div>
    );
};

export default AbilityItem;
