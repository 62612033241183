const actions = {
  CART_STORE_CLOSE_BUBBLE: 'CART_STORE_CLOSE_BUBBLE',
  CART_STORE_BEGIN: 'CART_STORE_BEGIN',
  CART_STORE_SUCCESS: 'CART_STORE_SUCCESS',

  CART_UPDATE_CLOSE_BUBBLE: 'CART_UPDATE_CLOSE_BUBBLE',
  CART_UPDATE_BEGIN: 'CART_UPDATE_BEGIN',
  CART_UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
  CART_UPDATE_ERROR: 'CART_UPDATE_ERR',

  CART_DESTROY_CLOSE_BUBBLE: 'CART_DESTROY_CLOSE_BUBBLE',
  CART_DESTROY_BEGIN: 'CART_DESTROY_BEGIN',
  CART_DESTROY_SUCCESS: 'CART_DESTROY_SUCCESS',
  CART_DESTROY_ERROR: 'CART_DESTROY_ERR',

  EMPTY_CART: 'EMPTY_CART',

  emptyCart: () => {
    return {
      type: actions.EMPTY_CART,
    };
  },

  cartStoreCloseBubble: () => {
    return {
      type: actions.CART_STORE_CLOSE_BUBBLE,
    };
  },

  cartStoreBegin: () => {
    return {
      type: actions.CART_STORE_BEGIN,
    };
  },

  cartStoreSuccess: (cart_items, added_item) => {
    return {
      type: actions.CART_STORE_SUCCESS,
      cart_items,
      store_cart_item: added_item,
    };
  },

  cartUpdateCloseBubble: () => {
    return {
      type: actions.CART_UPDATE_CLOSE_BUBBLE,
    };
  },

  cartUpdateBegin: () => {
    return {
      type: actions.CART_UPDATE_BEGIN,
    };
  },

  cartUpdateSuccess: (cart_items, update_cart_item, show_bubble) => {
    return {
      type: actions.CART_UPDATE_SUCCESS,
      cart_items,
      update_cart_item,
      show_bubble
    };
  },

  cartUpdateError: (error) => {
    return {
      type: actions.CART_UPDATE_ERROR,
      error
    };
  },

  cartDestroyCloseBubble: () => {
    return {
      type: actions.CART_DESTROY_CLOSE_BUBBLE,
    };
  },

  cartDestroyBegin: () => {
    return {
      type: actions.CART_DESTROY_BEGIN,
    };
  },

  cartDestroySuccess: (cart_items, show_bubble) => {
    return {
      type: actions.CART_DESTROY_SUCCESS,
      cart_items,
      show_bubble
    };
  },

  cartDestroyError: (error) => {
    return {
      type: actions.CART_DESTROY_ERROR,
      error,
    };
  },

};

export default actions;
