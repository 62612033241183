import actions from './actions';
import Cart from '../../http/Cart';

const {
    cartStoreCloseBubble,
    cartStoreBegin,
    cartStoreSuccess,

    cartUpdateCloseBubble,
    cartUpdateBegin,
    cartUpdateSuccess,
    cartUpdateError,
    emptyCart,

    cartDestroyCloseBubble,
    cartDestroyBegin,
    cartDestroySuccess,

} = actions;

const closeStoreCartBubble = () => {
    return async dispatch => {
        dispatch(cartStoreCloseBubble());
    }
}

const closeUpdateCartBubble = () => {
    return async dispatch => {
        dispatch(cartUpdateCloseBubble());
    }
}

const closeDestroyCartBubble = () => {
    return async dispatch => {
        dispatch(cartDestroyCloseBubble());
    }
}

const addToCart = (food, selectedItems, quantity) => {
    return async dispatch => {
        dispatch(cartStoreBegin());
        const [cartItems, added_item] = Cart.Store(food, selectedItems, quantity);
        dispatch(cartStoreSuccess(cartItems, added_item));dispatch(cartStoreSuccess(cartItems, added_item));
    };
};

const updateCart = (cart_items, key, updated_item, show_bubble=true) => {
    return async dispatch => {
        dispatch(cartUpdateBegin());
        const cartItems = Cart.Update(cart_items, key, updated_item);
        dispatch(cartUpdateSuccess(cartItems, updated_item, show_bubble));
    };
};

const removeFromCart = (cart_items, key, show_bubble=true) => {
    return async dispatch => {
        dispatch(cartDestroyBegin());
        const new_cart_items = Cart.Destroy(cart_items, key);
        dispatch(cartDestroySuccess(new_cart_items, show_bubble));
    };
};

const EmptyCart = () => {
    return async dispatch => {
        localStorage.removeItem("name");
        localStorage.removeItem("cart_items");
        localStorage.removeItem("edit_checkout_id");
        localStorage.removeItem("edit_checkout_name");
        dispatch(emptyCart());
    }
}

export {closeStoreCartBubble, closeUpdateCartBubble, closeDestroyCartBubble, addToCart, removeFromCart, updateCart, EmptyCart};
