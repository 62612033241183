import React, {useEffect, useState} from 'react';
import {Button, Table, notification, Popconfirm, Select} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {EditOutlined, DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';
import BranchInventory from "../../../../../http/BranchInventory";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

const {Option} = Select;

const ManageBranchInventories = () => {
    const [loading, setLoading] = useState(true);
    const [branchInventories, setBranchInventories] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('all');
    const history = useHistory();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        BranchInventory.FetchInitialData((response) => {
            const {branches} = response.data.data;
            setBranches(branches);
            fetchBranchInventories();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load initial data',
            });
        });
    };

    const fetchBranchInventories = () => {
        setLoading(true);
        BranchInventory.Index((response) => {
            setBranchInventories(response.data.data.branch_inventories);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branch inventories',
            });
        });
    };

    const handleDelete = (id) => {
        setLoading(true);
        BranchInventory.Delete(id, (response) => {
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            fetchBranchInventories();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to delete branch inventory',
            });
        });
    };

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
    };

    const filteredBranchInventories = selectedBranch === 'all' ? branchInventories : branchInventories.filter(bi => bi.branch.id === selectedBranch);

    const columns = [
        {
            title: 'Branch',
            dataIndex: ['branch', 'name'],
            key: 'branch',
        },
        {
            title: 'Inventory',
            dataIndex: ['inventory', 'name'],
            key: 'inventory',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Link to={`/dashboard/operations/setup/inventory/branch/${record.id}/edit`}>
                        <Button icon={<EditOutlined/>}/>
                    </Link>
                    <Popconfirm
                        title="Are you sure to delete this inventory?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined/>} className="ml-2"/>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const onBranchInventoryCreate = () => {
        history.push("/dashboard/operations/setup/inventory/branch/new");
    }

    return (
        <DefaultContainer>
            <div className="flex flex-col pt-3 w-full">

                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row w-full">
                    <div className={"flex flex-row items-center justify-between w-full"}>
                        <span className={"uppercase"}>Manage Branch Inventories</span>
                        <Button onClick={onBranchInventoryCreate} style={{borderRadius: "5px"}} type={"primary"}>
                            <div className={"flex flex-row items-center"}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-lg cursor-pointer" aria-hidden="true"/>
                                Add Branch Inventory
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="mb-4 pl-1 text-gray-600">
                    <p>
                        In this section you can specify which inventories at each branch are supplied.
                    </p>
                </div>

                <div className="mb-4">
                    <Select placeholder="Filter by Branch" onChange={handleBranchChange} className="w-full">
                        <Option value="all">All Branches</Option>
                        {branches.map(branch => (
                            <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                        ))}
                    </Select>
                </div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Table
                        dataSource={filteredBranchInventories}
                        columns={columns}
                        rowKey="id"
                        pagination={{pageSize: 10}}
                    />
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageBranchInventories;
