import {isItemSelected, removeFromSelectedItems, getDefaultItemsAsSelected, selectItem, getOption, calculatePrice} from "../../services/food.js";

const actions = {

  RESET_FOOD: 'RESET_FOOD',
  SET_FOOD: 'SET_FOOD',
  SELECT_ITEM: 'SELECT_ITEM',

  resetFoodAction: (food) => {
    return {
      type: actions.RESET_FOOD
    };
  },

  setFoodAction: (food, editSelectedItems) => {
    console.log("This is my food:", food);

    let selectedItems;
    if(editSelectedItems)
      selectedItems = [...editSelectedItems];
    else
      selectedItems = getDefaultItemsAsSelected(food);

    console.log("default items:", selectedItems);
    const price = calculatePrice(food, selectedItems);
    return {
      type: actions.SET_FOOD,
      food,
      selectedItems,
      price
    };
  },

  selectItemAction: (food, _selectedItems, item_id) => {
    const selectedItems = [...selectItem(food, _selectedItems, item_id)];
    const price = calculatePrice(food, selectedItems);
    return {
      type: actions.SELECT_ITEM,
      selectedItems,
      price
    };
  },


};

export default actions;
