import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const usePermission = (abilities = null, roles = null) => {
    const userAbilitiesNames = useSelector(state => state.auth.user.abilities_names);
    const userRolesNames = useSelector(state => state.auth.user.roles_names);

    const hasPermission = useMemo(() => {
        const hasAbility = abilities ? abilities.some(ability => userAbilitiesNames.includes(ability)) : false;
        const hasRole = roles ? roles.some(role => userRolesNames.includes(role)) : false;

        return hasAbility || hasRole;
    }, [abilities, roles, userAbilitiesNames, userRolesNames]);

    return hasPermission;
};

export default usePermission;