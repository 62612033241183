import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, notification } from 'antd';
import BranchInventoryGroupContainer from '../../../../../http/BranchInventoryGroupContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import BranchInventoryGroup from '../../../../../http/BranchInventoryGroup';
import Container from '../../../../../http/Container';

const { Option } = Select;

const CreateBranchInventoryGroupContainer = () => {
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState([]);
    const [containers, setContainers] = useState([]);
    const [inventoryGroups, setInventoryGroups] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        BranchInventoryGroupContainer.Create((response) => {
            const { branches, inventory_groups, containers } = response.data.data;
            setBranches(branches);
            setInventoryGroups(inventory_groups);
            setContainers(containers);
            if (id) {
                fetchBranchInventoryGroupContainer(id);
            } else {
                setLoading(false);
            }
        }, (err) => {
            setBranches([]);
            setInventoryGroups([]);
            setContainers([]);
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load initial data',
            });
        });
    };

    const fetchBranchInventoryGroupContainer = (id) => {
        BranchInventoryGroupContainer.Edit(id, (response) => {
            const branchInventoryGroupContainer = response.data.data.branch_inventory_group_container;
            form.setFieldsValue(branchInventoryGroupContainer);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branch inventory group container',
            });
            history.push("/dashboard/operations/setup/inventory/branch-containers/manage");
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);
        if (id) {
            BranchInventoryGroupContainer.Update(id, values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch-containers/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            BranchInventoryGroupContainer.Store(values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch-containers/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" style={{ backgroundColor: "#FAFAFA" }}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Branch Inventory Group Container' : 'Add New Branch Inventory Group Container'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Branch Inventory Group"
                            name="branch_inventory_group_id"
                            rules={[{ required: true, message: 'Please select the branch inventory group' }]}
                        >
                            <Select placeholder="Select branch inventory group" disabled={submitting}>
                                {inventoryGroups.map(group => (
                                    <Option key={group.id} value={group.id}>{group.branch.name} - {group.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Container"
                            name="container_id"
                            rules={[{ required: true, message: 'Please select the container' }]}
                        >
                            <Select placeholder="Select container" disabled={submitting}>
                                {containers.map(container => (
                                    <Option key={container.id} value={container.id}>{container.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Full Container Weight"
                            name="full_container_weight"
                            rules={[{ required: true, message: 'Please enter the full container weight' }]}
                        >
                            <Input type="number" disabled={submitting} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full">
                                {id ? 'Update Branch Inventory Group Container' : 'Add Branch Inventory Group Container'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateBranchInventoryGroupContainer;
