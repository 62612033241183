import React, {useEffect, useRef, useState} from 'react';
import {notification} from "antd";
import Division from "../../../../http/Division";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';

const DivisionIndex = () => {
    const [divisions, setDivisions] = useState(null);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {

        Division.VisibleIndex((response) => {
            setDivisions(response.data.data.divisions);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }, []);

    const borderRadiusVariants = {
        initial: {scale: 1}, // Initial border-radius (e.g., a circle)
        hovered: {scale: 1.01}, // Border-radius when hovered (e.g., a rounded rectangle)
    };

    return (
        <div className={"flex flex-col w-full h-screen"}>
            <div className={"w-full flex flex-row items-center justify-center text-4xl font-bold uppercase patty bg-white"}>
                All Divisions
            </div>
            <SkeletonWrapper loading={!divisions} skeleton={GenericSkeleton}>
                {divisions &&
                    <div className={"flex flex-col p-1 py-2"}>
                        {divisions.map(division => {
                            return (
                                <Link to={`/dashboard/division/${division.id}`}>
                                    <motion.div
                                        initial="initial"
                                        exit="initial"
                                        whileHover="hovered"
                                        variants={borderRadiusVariants}
                                        key={`division-${division.id}`}
                                        className={`rounded-lg hover:text-black border-2 border-black shadow hover:border-black px-4 py-4 text-black text-base  m-1 flex flex-col cursor-pointer
                                                ${division.users.find(u => u.id === user.id && u.pivot.role === "accountable") ? 'bg-green-300' : (division.users.some(u => u.id === user.id) ? 'bg-green-100' : 'bg-gray-200')}
                                            `}
                                    >
                                        <div className={"font-bold text-4xl uppercase patty border-b border-gray-500 mb-2 pb-2"}>{division.name}</div>
                                        <div className={"flex flex-wrap"}>
                                            {division.users.map(u => {
                                                return (
                                                    <div className={`px-2 py-0.5 bg-gray-100 border border-gray-400 rounded-md text-sm m-0.5 ${u.pivot.role == "accountable" ? "font-bold" : "text-gray-600"} ${u.id == user.id ? "" : ""} uppercase`}>
                                                        {u.pivot.role == "accountable" &&
                                                            <FontAwesomeIcon icon={faCrown} className="text-sm mr-1" aria-hidden="true"/>
                                                        }
                                                        {u.name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </motion.div>
                                </Link>
                            )
                        })}
                    </div>
                }
            </SkeletonWrapper>
        </div>
    );
};

export default DivisionIndex;