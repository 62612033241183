import React, {useState} from 'react';
import {Button, Divider, notification} from "antd";
import Profile from "../../../../http/Profile";
import push from "../../../../history";
import {ListRequests} from "../../../../redux/profile/actionCreator";
import {useDispatch} from "react-redux";

const RequestRow = ({request}) => {

    const dispatch = useDispatch();
    const [onApproving, setOnApproving] = useState(false);
    const [onRejecting, setOnRejecting] = useState(false);

    const approve = () => {
        setOnApproving(true);
        Profile.ApproveRequest(request.id, (response) => {
            dispatch(ListRequests());
            setOnApproving(false);
            notification['success']({
                message: 'Request Approved.',
                description:response.data.message
            });
        }, (err) => {
            setOnApproving(false);
            dispatch(ListRequests());
            notification['error']({
                message: 'Error',
                description:err?.response?.data?.message
            });

        });
    }

    const reject = () => {
        setOnRejecting(true);
        Profile.RejectRequest(request.id, (response) => {
            dispatch(ListRequests());
            setOnRejecting(false);
            notification['success']({
                message: 'Request Rejected.',
                description:response.data.message
            });
        }, (err) => {
            dispatch(ListRequests());
            setOnRejecting(false);
            notification['error']({
                message: 'Error',
                description:err?.response?.data?.message
            });
        });
    }

    return (
        <div className={`shadow-lg flex flex-col border border-gray-300 bg-gray-50 w-full text-lg rounded-xl mb-2 p-2`}>
            <div className="flex flex-col p-2">
                <div className="text-sm">
                    <div>
                        <b>{request.checkout.issuer.name}</b> has requested <b className={`uppercase ${request.type == "edit"?"text-green-600":"text-red-600"}`}>{request.type}</b> on checkout belong to <b>{request.checkout.first_name}</b> with the amount of <b>${request.checkout.total}</b>.
                    </div>

                    <div className="text-gray-600 my-3 text-xs">
                        Branch: <i>{request.checkout.branch.address}</i>
                    </div>
                    <div>
                        Currently this order {request.checkout.broadcast_status == "broadcasted"? "has been broadcasted to the KDS and being prepared." : <>has <b>NOT</b> been broadcasted to the KDS yet.</>}
                    </div>
                    <Divider style={{padding: "2px", margin: "10px"}} />
                    <div className="flex flex-row justify-between">
                        <Button type={"primary"} onClick={approve} loading={onApproving} disabled={onRejecting}>Approve</Button>
                        <Button danger onClick={reject} loading={onRejecting} disabled={onApproving}>Reject</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestRow;
