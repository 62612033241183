import React, { useEffect, useState } from 'react';
import { Button, notification, Popconfirm, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import BranchInventoryGroup from '../../../../../http/BranchInventoryGroup';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCrown, faPlusCircle, faSort} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Option } = Select;

const ManageBranchInventoryGroups = () => {
    const [loading, setLoading] = useState(true);
    const [branchInventoryGroups, setBranchInventoryGroups] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('all');
    const history = useHistory();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        BranchInventoryGroup.FetchInitialData(
            (response) => {
                const { branches } = response.data.data;
                setBranches(branches);
                fetchBranchInventoryGroups();
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to load initial data',
                });
            }
        );
    };

    const fetchBranchInventoryGroups = () => {
        setLoading(true);
        BranchInventoryGroup.Index(
            (response) => {
                setBranchInventoryGroups(response.data.data.branch_inventory_groups);
                setLoading(false);
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to load branch inventory groups',
                });
            }
        );
    };

    const handleDelete = (id) => {
        setLoading(true);
        BranchInventoryGroup.Delete(
            id,
            (response) => {
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
                fetchBranchInventoryGroups();
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to delete branch inventory group',
                });
            }
        );
    };

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
    };

    const filteredBranchInventoryGroups =
        selectedBranch === 'all'
            ? branchInventoryGroups
            : branchInventoryGroups.filter((bi) => bi.branch.id === selectedBranch);

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedGroups = Array.from(filteredBranchInventoryGroups);
        const [movedItem] = reorderedGroups.splice(result.source.index, 1);
        reorderedGroups.splice(result.destination.index, 0, movedItem);

        setBranchInventoryGroups(reorderedGroups);

        // Update the order in the backend
        const reorderedData = reorderedGroups.map((group, index) => ({
            id: group.id,
            order: index + 1,
        }));

        BranchInventoryGroup.Sort(
            reorderedData,
            (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'Inventory group order updated successfully.',
                });
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to update inventory group order.',
                });
            }
        );
    };

    const onBranchInventoryGroupCreate = () => {
        history.push('/dashboard/operations/setup/inventory/branch-group/new');
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full">
                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row w-full">
                    <div className={"flex flex-row items-center justify-between w-full"}>
                        <span className={""}>Manage Branch Inventory Groups</span>
                        <Button
                            onClick={onBranchInventoryGroupCreate}
                            style={{ borderRadius: '5px' }}
                            type={"primary"}
                            className={"primary-button"}
                        >
                            <div className={"flex flex-row items-center"}>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="mr-2 text-lg cursor-pointer"
                                    aria-hidden="true"
                                />
                                Add Branch Inventory Group
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="mb-4 pl-1 text-gray-600">
                    <p>In this section you can specify which inventories at each branch are supplied.</p>
                </div>
                <div className="mb-4">
                    <Select
                        placeholder="Filter by Branch"
                        onChange={handleBranchChange}
                        className="w-full"
                    >
                        <Option value="all">All Branches</Option>
                        {branches.map((branch) => (
                            <Option key={branch.id} value={branch.id}>
                                {branch.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="branchInventoryGroups">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {filteredBranchInventoryGroups.map((group, index) => (
                                        <Draggable key={group.id} draggableId={String(group.id)} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="bg-white p-1 px-2 mb-2 rounded-md shadow-sm border border-gray-200 flex justify-between items-center"
                                                >
                                                    <div className={"flex flex-row items-center gap-1"}>
                                                        <div className="text-lg font-semibold"><FontAwesomeIcon icon={faSort} className="text-sm mr-1" aria-hidden="true"/></div>
                                                        <div className="text-lg font-semibold">{group.name}</div>
                                                        <div className="text-gray-500">({group.unit_type})</div>

                                                        {group.description || group.image_url ? (
                                                            <Tooltip
                                                                title={
                                                                    <div>
                                                                        {group.description && <p>{group.description}</p>}
                                                                        {group.image_url && (
                                                                            <img
                                                                                src={group.image_url}
                                                                                alt="Inventory Group"
                                                                                style={{ maxWidth: '100px'}}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                }
                                                            >
                                                                <InfoCircleOutlined
                                                                    style={{ marginLeft: '3px', cursor: 'pointer', color: '#1890ff' }}
                                                                />
                                                            </Tooltip>
                                                        ) : null}

                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <Link to={`/dashboard/operations/setup/inventory/branch-group/${group.id}/edit`}>
                                                            <Button icon={<EditOutlined />} />
                                                        </Link>
                                                        <Popconfirm
                                                            title="Are you sure to delete this group?"
                                                            onConfirm={() => handleDelete(group.id)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button icon={<DeleteOutlined />} danger />
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageBranchInventoryGroups;