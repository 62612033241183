import React, {useEffect} from 'react';
import CartItem from "../../Cart/CartItem";
import UserMenu from "./UserMenu";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ListOrders} from "../../../redux/profile/actionCreator";
import FoodSkeleton from "../../../components/skeletons/FoodSkeleton";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import {Pagination} from "antd";
import OrderRow from "./OrderRow";
import {motion} from "framer-motion";
import T from "../../../components/T";

const Orders = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.profile.orders_loading);
    const error = useSelector(state => state.profile.orders_error);
    const success = useSelector(state => state.profile.orders_success);
    const orders = useSelector(state => state.profile.orders);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            console.log("listing orders...");
            dispatch(ListOrders());
        }
    }, []);

    const onChange = (current, pageSize) => {
        dispatch(ListOrders(current));
    };


    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="row"
        >
            <div className="md:container flex flex-col mx-auto pb-10 pt-5 px-4">
                <h1 className="text-4xl font-bold text-black"><T upper>MY ORDERS</T></h1>
                <div className="w-full flex flex-col lg:flex-row">

                    <UserMenu menu={"orders"}/>

                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-2 flex flex-col items-center justify-center">
                        <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                            {orders ?
                                <>
                                    {orders.data.length == 0 ?
                                        <div className="text-sm py-8 font-normal"><T cap>You do not have any orders.</T></div>
                                    :
                                        <>
                                            <div className="w-full">
                                                {orders.data.map(order => {
                                                    return (<OrderRow order={order}/>);
                                                })}
                                            </div>
                                            <div className="mt-4 ml-4">
                                                <Pagination
                                                    total={orders.total}
                                                    showSizeChanger={false}
                                                    showTotal={total => `Total ${total} items`}
                                                    defaultPageSize={orders.per_page}
                                                    current={orders.current_page}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </>
                                    }

                                </>
                            : null}
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Orders;
