import React, {useEffect, useRef, useState} from 'react';
import {motion} from "framer-motion";

const ManageTraining = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full`}
        >
            Salam
        </motion.div>

    );
};
export default ManageTraining;
