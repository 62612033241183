import React, { useState, useEffect } from 'react';
import { Checkbox, Button, message } from 'antd';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import Roles from '../../../../http/Roles';
import DefaultContainer from "../../../../components/DefaultContainer";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import { useParams } from 'react-router-dom';
import copy from "copy-to-clipboard";
import {RiClipboardLine} from "@remixicon/react";

const EditRoleAbilities = () => {
    const { roleId } = useParams();
    const [abilityGroups, setAbilityGroups] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [expandedGroups, setExpandedGroups] = useState({});

    useEffect(() => {
        fetchRoleAbilities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId]);

    const fetchRoleAbilities = () => {
        setEditLoading(true);
        Roles.GetAbilities(roleId, (response) => {
            const groups = response.data.data.ability_groups;
            setAbilityGroups(groups);
            setRoleName(response.data.data.role.label);

            // Initialize expanded groups based on selected abilities
            const expanded = {};
            initializeExpandedGroups(groups, expanded);
            setExpandedGroups(expanded);

            setEditLoading(false);
        }, (err) => {
            message.error('Failed to load role abilities');
            setEditLoading(false);
        });
    };

    const initializeExpandedGroups = (groups, expanded) => {
        groups.forEach(group => {
            const shouldExpand = shouldExpandGroup(group);
            expanded[group.id] = shouldExpand;
            if (group.children && group.children.length > 0) {
                initializeExpandedGroups(group.children, expanded);
            }
        });
    };

    const shouldExpandGroup = (group) => {
        // Check if any abilities are selected in the group or its children
        if (group.abilities && group.abilities.some(a => a.checked)) {
            return true;
        }
        if (group.children) {
            return group.children.some(child => shouldExpandGroup(child));
        }
        return false;
    };

    const handleAbilityToggle = (abilityId) => {
        setAbilityGroups(prevGroups => toggleAbilityInGroups(prevGroups, abilityId));
    };

    const toggleAbilityInGroups = (groups, abilityId) => {
        return groups.map(group => {
            if (group.abilities) {
                group.abilities = group.abilities.map(ability => {
                    if (ability.id === abilityId) {
                        return { ...ability, checked: !ability.checked };
                    }
                    return ability;
                });
            }
            if (group.children) {
                group.children = toggleAbilityInGroups(group.children, abilityId);
            }
            return group;
        });
    };

    const handleGroupToggle = (groupId) => {
        setAbilityGroups(prevGroups => toggleGroup(prevGroups, groupId));
    };

    const toggleGroup = (groups, groupId) => {
        return groups.map(group => {
            if (group.id === groupId) {
                const { allChecked } = getGroupCheckedStatus(group);
                const newChecked = !allChecked;
                group = setGroupChecked(group, newChecked);
            } else if (group.children) {
                group.children = toggleGroup(group.children, groupId);
            }
            return group;
        });
    };

    const setGroupChecked = (group, checked) => {
        if (group.abilities) {
            group.abilities = group.abilities.map(ability => ({
                ...ability,
                checked: checked,
            }));
        }
        if (group.children) {
            group.children = group.children.map(child => setGroupChecked(child, checked));
        }
        return group;
    };

    const getGroupCheckedStatus = (group) => {
        let allChecked = true;
        let someChecked = false;

        if (group.abilities) {
            group.abilities.forEach(ability => {
                if (ability.checked) {
                    someChecked = true;
                } else {
                    allChecked = false;
                }
            });
        }

        if (group.children) {
            group.children.forEach(child => {
                const childStatus = getGroupCheckedStatus(child);
                if (childStatus.someChecked) {
                    someChecked = true;
                }
                if (!childStatus.allChecked) {
                    allChecked = false;
                }
            });
        }

        return { allChecked, someChecked };
    };

    const saveRoleAbilities = () => {
        setLoading(true);

        const selectedAbilities = collectCheckedAbilities(abilityGroups);

        Roles.UpdateAbilities(roleId, selectedAbilities, (response) => {
            message.success('Role abilities updated');
            setLoading(false);
        }, (err) => {
            message.error('Failed to update role abilities');
            setLoading(false);
        });
    };

    const collectCheckedAbilities = (groups) => {
        let abilities = [];
        groups.forEach(group => {
            if (group.abilities) {
                abilities = abilities.concat(
                    group.abilities.filter(a => a.checked).map(a => a.id)
                );
            }
            if (group.children) {
                abilities = abilities.concat(collectCheckedAbilities(group.children));
            }
        });
        return abilities;
    };

    const toggleGroupExpand = (groupId) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [groupId]: !prevState[groupId],
        }));
    };

    const renderAbilityGroup = (group, level = 0) => {
        const { allChecked, someChecked } = getGroupCheckedStatus(group);

        // Compute background and border color based on level
        const baseLightness = 100; // Starting lightness percentage for background
        const lightnessStep = 4;   // Amount to decrease per level
        const lightness = Math.max(0, baseLightness - level * lightnessStep);
        const bgColor = `hsl(0, 20%, ${lightness}%)`;

        // Border color slightly darker than background
        const borderLightness = Math.max(0, lightness - 10);
        const borderColor = `hsl(0, 0%, ${borderLightness}%)`;

        const isExpanded = expandedGroups[group.id] || false;

        // Determine if group has children (abilities or child groups)
        const hasContent = (group.abilities && group.abilities.length > 0) || (group.children && group.children.length > 0);

        return (
            <div
                key={group.id}
                className="mb-2 p-1 pb-1 rounded "
                style={{ backgroundColor: bgColor, border: `1px solid ${borderColor}` }}
            >
                <div className="flex items-center text-black">
                    {hasContent && (
                        <Button
                            type="link"
                            icon={isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                            onClick={() => toggleGroupExpand(group.id)}
                            style={{ marginRight: '8px' }}
                        />
                    )}
                    {!hasContent && (
                        <span style={{ width: '24px', display: 'inline-block' }}></span>
                    )}
                    <Checkbox
                        onChange={() => handleGroupToggle(group.id)}
                        checked={allChecked}
                        indeterminate={!allChecked && someChecked}
                        className="flex flex-row items-center font-bold"
                    >
                        {group.name}
                    </Checkbox>
                </div>
                {isExpanded && hasContent && (
                    <div className="ml">
                        {group.abilities && group.abilities.length > 0 && (
                            <div className="flex flex-wrap ml-12 flex-col">
                                {group.abilities.map(ability => (
                                    <div key={ability.id} className="px-1 m-1 bg-gray-100 border ">
                                        <Checkbox
                                            onChange={() => handleAbilityToggle(ability.id)}
                                            checked={ability.checked}
                                            className={""}
                                        >
                                            <div className={"flex flex-row"}>
                                                {ability.label} ({ability.name}) <RiClipboardLine onClick={(e) => {copy(ability.name); e.preventDefault()}} className={"ml-2 cursor-pointer"} />
                                            </div>
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        )}
                        {group.children && group.children.length > 0 && (
                            <div className="ml-4">
                                {group.children.map(childGroup => renderAbilityGroup(childGroup, level + 1))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <DefaultContainer className="edit-role-abilities mx-auto max-w-6xl">
            <div className="text-xl font-bold text-black mb-2 py-2">
                Manage Abilities for Role: <span className="text-primary">{roleName}</span>
            </div>
            <SkeletonWrapper loading={editLoading} skeleton={GenericSkeleton}>
                <div className="role-abilities">
                    {abilityGroups.map(group => renderAbilityGroup(group))}
                </div>
                <div className="flex items-center justify-end mt-4">
                    <Button
                        type="primary"
                        onClick={saveRoleAbilities}
                        loading={loading}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>
                </div>
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default EditRoleAbilities;
