import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Select, notification, Spin, Tag } from 'antd';
import UserTraining from '../../../../../http/UserTraining';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import usePermission from "../../../../../layouts/usePermission";

const { Option } = Select;

const UserTrainings = () => {
    const { branchId } = useParams();
    const [trainings, setTrainings] = useState([]);
    const [branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(true);
    const [assignLoading, setAssignLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [status, setStatus] = useState('all');

    const hr_add_new_trainees = usePermission(["hr_add_new_trainees"], null);
    const hr_access_training_evaluations = usePermission(["hr_access_training_evaluations"], null);
    const hr_add_training_report = usePermission(["hr_add_training_report"], null);

    useEffect(() => {
        loadUserTrainings();
    }, [branchId, status]);

    const loadUserTrainings = () => {
        if (!employees) setLoading(true);
        UserTraining.Index(branchId, { status }, (response) => {
            setTrainings(response.data.data.user_trainings);
            setBranch(response.data.data.branch);
            setEmployees(response.data.data.employees);
            setFilteredEmployees(response.data.data.employees);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    };

    const handleSearch = (value) => {
        if (!value) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter((employee) =>
                employee.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };

    const handleAssignUser = (userId) => {
        setAssignLoading(true);
        UserTraining.AssignUser(branchId, userId, (response) => {
            notification.success({
                message: 'Success!',
                description: 'User assigned to training successfully.',
            });
            loadUserTrainings();
            setAssignLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setAssignLoading(false);
        });
    };

    const handleStatusChange = (value) => {
        setStatusLoading(true);
        setStatus(value);
        setTimeout(() => setStatusLoading(false), 500); // Smooth status change without hiding entire page
    };

    return (
        <DefaultContainer>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                {branch &&
                    <>
                        <h2 className="text-2xl font-bold mb-4">User Trainings for {branch ? branch.name : 'Loading...'}</h2>
                        <div className="flex justify-between items-center mb-5">
                            <div className="flex items-center">
                                <Spin spinning={statusLoading}>
                                    <Select defaultValue={status} onChange={handleStatusChange} style={{ width: "130px" }}>
                                        <Option value="all">All</Option>
                                        <Option value="pending">Pending</Option>
                                        <Option value="completed">Completed</Option>
                                    </Select>
                                </Spin>
                            </div>
                            {hr_add_new_trainees &&
                                <div className="flex items-center ml-4">
                                    <Select
                                        showSearch
                                        placeholder="Search employees"
                                        onSearch={handleSearch}
                                        style={{width: 300, marginRight: '10px'}}
                                        loading={assignLoading}
                                        filterOption={false}
                                        notFoundContent={assignLoading ? <Spin size="small"/> : null}
                                    >
                                        {filteredEmployees.map(user => (
                                            <Option key={user.id} value={user.id}>
                                                <div className="flex justify-between items-center">
                                                    <span>{user.name}</span>
                                                    <Button type="primary" size="small" onClick={() => handleAssignUser(user.id)}>Assign</Button>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            }
                        </div>
                        <div>
                            {trainings.length > 0 ? (
                                trainings.map(training => (
                                    <div key={training.id} className="bg-white p-2 mb-3 px-4 rounded-lg shadow-md border border-gray-200">
                                        <div className={"flex flex-row justify-between items-center"}>
                                            <div className="text-lg font-semibold">{training.user.name}</div>
                                            <div className={"flex flex-row items-center"}>
                                                <Link to={`/dashboard/hr/training-evaluation/${training.id}/topics`}>
                                                    <Button type="link">View Training Topics</Button>
                                                </Link>
                                                {training.can_evaluate && (
                                                    <Link to={`/dashboard/hr/training-evaluation/${training.id}/create`}>
                                                        <Button type="link">Evaluate</Button>
                                                    </Link>
                                                )}
                                                {training.has_evaluations && hr_access_training_evaluations && (
                                                    <Link to={`/dashboard/hr/training-evaluation/${training.id}/evaluations`}>
                                                        <Button type="link">View Evaluations</Button>
                                                    </Link>
                                                )}
                                                <div>
                                                    {training.status === "pending" ? <Tag color={"gold"}>TRAINING</Tag> : <Tag color={"green"}>COMPLETED</Tag>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No user trainings found.</p>
                            )}
                        </div>
                    </>
                }

            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default UserTrainings;
