import React, {useEffect, useRef, useState} from 'react';
import DashBoardMenu from "./DashBoardMenu";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";
import {useLocation} from "react-router-dom";
import {HideHeader} from "../../../redux/settings/actionCreator";

const AdminLayout = ({openMenuKey, menuKey, children}) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const user = useSelector(state => state.auth.user);
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [smallDevice, setSmallDevice] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setSmallDevice(false);
                setCollapsed(false);
                dispatch(HideHeader());
            } else {
                setSmallDevice(true);
                setCollapsed(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the route changes
        window.scrollTo(0, 0);
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
    }, [location.pathname]);

    return (
        isLoggedIn && (user.type == 'employee' || user.type == 'admin') ?
            <motion.div
                initial={{opacity: 0, scale: 1, x: 200}}
                animate={{opacity: 1, scale: 1, x: 0}}
                className="w-full brand-h-screen flex flex-col overflow-hidden dashboard"
            >
                <div className="flex flex-row relative flex-1 w-full brand-h-screen overflow-hidden ">
                    <div className={`${!collapsed && smallDevice?'fixed':''}`} style={{zIndex: 1000}}>
                        <DashBoardMenu defaultOpenMenuKey={openMenuKey} defaultMenuKey={menuKey} collapsed={collapsed} setCollapsed={setCollapsed} />
                    </div>
                    <div ref={ref} className="w-full brand-h-screen overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 ml-0" >
                        <div className="w-full p-1 pt-2 md:p-2 md:py-3">
                            {children}
                        </div>
                    </div>
                </div>
            </motion.div>
            :
            <div className="flex flex-col items-center justify-center w-full mt-2 p-5">
                <div className="flex flex-col items-center justify-center w-full p-5 bg-white rounded-2xl">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-600" style={{fontSize: "40px"}} aria-hidden="true"/>
                    <div className="mt-3">
                        You don't have access to this page.
                    </div>
                </div>
            </div>
    );
};

export default AdminLayout;