import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Training = {

    Index: (successCb, failCb) => {
        axios.get(`/google/index`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    BaseReviews: (google_id, successCb, failCb) => {
        axios.get(`/google/${google_id}/base_reviews`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    OurReviews: (google_id, successCb, failCb) => {
        axios.get(`/google/${google_id}/our_reviews`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Generate: (google_id, total_reviews, generate_text, datetime, rating, successCb, failCb) => {
        axios.post(`/google/${google_id}/generate`, {total_reviews, generate_text, datetime, rating}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (place_id, successCb, failCb) => {
        axios.post(`/google`, {place_id}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Destroy: (google_id, successCb, failCb) => {
        axios.delete(`/google/${google_id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    DestroyOurReview: (our_review_id, successCb, failCb) => {
        axios.delete(`/google/our_reviews/${our_review_id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    PlayOurReview: (our_review_id, successCb, failCb) => {
        axios.post(`/google/our_reviews/${our_review_id}/play`, null, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    AutocompleteOurReview: (our_review_id, successCb, failCb) => {
        axios.post(`/google/our_reviews/${our_review_id}/autocomplete`, null, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


    UpdateOurReview: (our_review_id, review_text, successCb, failCb) => {
        axios.post(`/google/our_reviews/${our_review_id}`, {review_text}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Training;