import React, {useEffect} from 'react';
import {Button, Form, Input} from 'antd';
import FormItem from '../../components/Form/FormItem';
import {useDispatch, useSelector} from 'react-redux';
import {ResetAuthErrors, resetPassword, resetPasswordReset} from '../../redux/authentication/actionCreator';
import {Link, useParams} from 'react-router-dom';
import FormError from '../../components/Form/FormError';
import {ResetActiveOrders} from "../../redux/checkout/actionCreator";

const ResetPassword = () => {

    const dispatch = useDispatch();
    const {token, email} = useParams();
    const [form] = Form.useForm();

    const isLoading = useSelector(state => state.auth.loading);
    const resetPasswordError = useSelector(state => state.auth.error);
    const resetPasswordDone = useSelector(state => state.auth.done);
    const resetPasswordValidationFields = useSelector(state => state.auth.fields);

    const handleSubmit = () => {
        dispatch(resetPassword(form.getFieldsValue()));
    };

    const getDecoded = (txt) => {
        return decodeURIComponent(txt || '');
    };

    useEffect(() => {
        dispatch(resetPasswordReset());
        dispatch(ResetActiveOrders());
        dispatch(ResetAuthErrors());
    }, []);

    return (
        <div className="auth-contents container mx-auto bg-white mt-4 py-2 px-4 lg:py-4 lg:px-8">
            {resetPasswordDone ?
                <>
                    <div>
                        <h1>Password Updated</h1>
                        You can login with your new password, <Link to="/login">Sign in</Link>.
                    </div>
                </>
                :

                <>
                    <Form name='resetPassword' form={form} onFinish={handleSubmit} layout='vertical'>
                        <div className="text-2xl font-bold">Reset Password</div>

                        <FormError error={resetPasswordError}/>


                        <FormItem name='token' hidden={true}
                                  initialValue={token}
                        >
                            <Input type="hidden"/>
                        </FormItem>

                        <FormItem name='email'
                                  label='Email'
                                  errors={resetPasswordValidationFields}
                                  disabled={true}
                                  initialValue={getDecoded(email)}
                        >
                            <Input placeholder='name@example.com' disabled={true}/>
                        </FormItem>

                        <FormItem name='password'
                                  label='Password'
                                  errors={resetPasswordValidationFields}
                                  disabled={isLoading}
                                  initialValue=""
                        >
                            <Input.Password placeholder='' disabled={isLoading}/>
                        </FormItem>


                        <FormItem name='password_confirmation'
                                  label='Confirm Password'
                                  errors={resetPasswordValidationFields}
                                  disabled={isLoading}
                                  initialValue=""
                        >
                            <Input.Password placeholder='' disabled={isLoading}/>
                        </FormItem>


                        <Button htmlType='submit' size='large' type='primary' key='submit' loading={isLoading}>
                            Reset Password
                        </Button>

                        <p className="return-text mt-2 text-base uppercase">
                            Return to <Link to="/login" className="uppercase">Sign In</Link>
                        </p>
                    </Form>
                </>
            }
        </div>
    );
};

export default ResetPassword;
