import actions from './actions';

const {
  SET_REDIRECT_SOURCE,
  REDIRECT_SUCCESS,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  PROFILE_UPDATE_BEGIN,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
  SENDFORGOTPASSWORD_RESET,
  SENDFORGOTPASSWORD_BEGIN,
  SENDFORGOTPASSWORD_SUCCESS,
  SENDFORGOTPASSWORD_ERR,
  RESETPASSWORD_RESET,
  RESETPASSWORD_BEGIN,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_ERR,
  RESET_AUTH_ERRORS,
  UPDATE_TOTAL_UNAPPROVED_COUPONS,
} = actions;

const initState = {
  loading: false,
  isLoggedIn: !!localStorage.getItem('isLoggedIn'),
  user: JSON.parse(localStorage.getItem('user')),
  token: localStorage.getItem('token'),
  error: null,
  fields: null,
  redirected: false,
  redirect_source: null,
  user_updating: false,
  user_error: false,
};

const AuthReducer = (state = initState, action) => {
  const { type, user, source, error, fields, token, total_unapproved_coupons} = action;
  switch (type) {

    case SET_REDIRECT_SOURCE:
      return {
        ...state,
        redirect_source: source,
      };

    case REDIRECT_SUCCESS:
      return {
        ...state,
        redirected: true,
      };

    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        fields: null,
        user: null,
        token: null,
        isLoggedIn: false,
        redirected: false
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user,
        token
      };

    case LOGIN_ERR:
      return {
        ...state,
        loading: false,
        error,
        fields,
      };

    case REGISTER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        fields: null,
        user: null,
        token: null,
        isLoggedIn: false,
        redirected: false
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user,
        token
      };

    case REGISTER_ERR:
      return {
        ...state,
        loading: false,
        error,
        fields,
      };

    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        token: null,
        user: null,
      };

    case LOGOUT_ERR:
      return {
        ...state,
        loading: false,
        error,
      };

    case PROFILE_UPDATE_BEGIN:
      return {
        ...state,
        user_updating: true,
        user_error: null
      }

    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user,
        user_updating: false,
        user_error: null,
      };

    case PROFILE_UPDATE_ERROR:
      return {
        ...state,
        user_updating: false,
        user_error: error
      };

    case SENDFORGOTPASSWORD_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        fields: null,
        done: false
      };

    case SENDFORGOTPASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        fields: null,
        done: false
      };
    case SENDFORGOTPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true
      };
    case SENDFORGOTPASSWORD_ERR:
      return {
        ...state,
        loading: false,
        done: false,
        error,
        fields,
      };


    case RESETPASSWORD_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        fields: null,
        done: false
      };
    case RESETPASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        fields: null,
        done: false
      };
    case RESETPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
      };
    case RESETPASSWORD_ERR:
      return {
        ...state,
        loading: false,
        done: false,
        error,
        fields,
      };

    case RESET_AUTH_ERRORS:
      return {
        ...state,
        error: null,
      };

    case UPDATE_TOTAL_UNAPPROVED_COUPONS:
      return {
        ...state,
        user: {...state.user, total_unapproved_coupons},
      };

    default:
      return state;
  }
};
export default AuthReducer;
