import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const ShiftTemperatureReport = {
    Index: (branchId, config, successCb, failCb) => {
        axios.get(`/branch/${branchId}/shift-temperature-reports`, { ...getAuthenticationHeaders(), ...config })
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Create: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/shift-temperature-reports/create`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Store: (branchId, data, successCb, failCb) => {
        axios.post(`/branch/${branchId}/shift-temperature-reports`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default ShiftTemperatureReport;