import axios from "axios";
import {getAuthenticationHeaders, getDeviceHeaders} from "./Headers";

const Auth = {
    login: (data, successCb, failCb) => {
        axios.post('/login', data, getDeviceHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    register: (data, successCb, failCb) => {
        axios.post('/register', data, getDeviceHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    logout: (successCb, failCb) => {
        axios.get('/logout', getAuthenticationHeaders())
            .then(response => {
                localStorage.clear();
                successCb(response);
            }).catch(err => {
            failCb(err);
            //alert(err?.response?.data?.message ?? "No internet connection!");
        });
    },
    checkAuth: (successCb, failCb) => {
        axios.get('/check-auth', getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            failCb(err);
        });
    },
    sendForgotPassword: (data, successCb, failCb) => {
        axios.post('/forgot-password', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    ResetPassword: (data, successCb, failCb) => {
        axios.post('/reset-password', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    VerifyEmail: (data, successCb, failCb) => {
        console.log("sending request to: ", '/verify/'+data);
        axios.get('/verify/'+data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    ResendVerificationEmail: (successCb, failCb) => {
        axios.get('/resend-verification', getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            failCb(err);
        });
    },


};

export default Auth;
