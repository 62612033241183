import React, { useState, useEffect, useRef } from 'react';
import EmployeeCouponReports from "./Reports/EmployeeCouponReports";

const EmployeeReports = ({ employee }) => {
    return (
        <div>
            <EmployeeCouponReports employee={employee} />
        </div>
    );
};

export default EmployeeReports;
