import React, {useState} from 'react';
import {Button, Modal, notification} from "antd";
import Profile from "../../../http/Profile";
import {useDispatch} from "react-redux";
import {logOut} from "../../../redux/authentication/actionCreator";

const DeleteAccount = () => {

    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [password, setPassword] = useState("");

    const onContinue = () => {
        setIsModalOpen(true);
    }

    const handleDelete = () => {
        setLoading(true);
        Profile.Delete({password, reason},
            (response) => {
                dispatch(logOut());
                notification['success']({
                    message: 'Account Deleted!',
                    description:
                        `Your Account has successfully been deleted.`,
                });
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description:
                        `Your password is wrong!`,
                });
            }
        );
    }

    const handleCancel = () => {
        if(!loading) {
            setIsModalOpen(false);
        }
    }

    return (
        <div>

            <Modal title="Delete Confirmation" open={isModalOpen} onOk={handleDelete} onCancel={handleCancel} okText={"DELETE"} okButtonProps={{danger: true, size: "large", loading}} cancelButtonProps={{size: "large", disabled: loading}}>
                <div className="text-sm font-normal">Are you sure you want to delete your account? This action is irreversible.</div>

                <div className="pt-3">
                    <div className="pb-2 font-bold">Please Enter your password:</div>
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder={"Password..."} className="border border-gray-800 w-full py-2 px-4" autoFocus={true}/>
                </div>

            </Modal>

            <div className="text-2xl py-3 font-bold">
                Delete Account
            </div>
            <div>
                Can you please share to us what was not working? We are fixing bugs as soon as we spot them. If something slipped through our fingers, we'd be so grateful to be aware of it and fix it.
                <div className="my-3">
                    <textarea rows={5} placeholder={"Your explanation is entirely optional..."} onChange={(e) => setReason(e.target.value)} value={reason} className="border border-gray-800 w-full py-2 px-2" />
                </div>
                <Button htmlType='submit' size='large' type='primary' key='submit' className='w-full rounded-full bg-black' style={{background: "black", border: "black"}} onClick={onContinue}>
                    Continue...
                </Button>
            </div>
        </div>
    );
};

export default DeleteAccount;