import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuildingFlag, faCheckCircle, faLocationPin, faPlusCircle, faTrash, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {Button, notification, Popconfirm, Tag} from "antd";
import BigBang from "../../../../http/BigBang";
import Parameter from "./Parameter";
import BigBangIndex from "./BigBangIndex";

const BigBangReport = ({setting, mcdonalds, shakeshacks, starbucks, fiveguys, burgerkings, edit, reload}) => {

    const [deleting, setDeleting] = useState(false);
    const [generatingScores, setGeneratingScores] = useState(false);
    const [generatingClusters, setGeneratingClusters] = useState(false);
    const [zscore, set_zscore] = useState(setting.zscore_margin??2);
    const [min_distance, set_min_distance] = useState(setting.min_distance??150);
    const [min_sample, set_min_sample] = useState(setting.min_sample??5);
    const [viewStatic, setViewStatic] = useState(false);
    const [viewDynamic, setViewDynamic] = useState(false);

    const onEdit = () => {
        edit(setting);
    }

    const onDelete = () => {
        setDeleting(true);

        BigBang.ReportSettingDelete(setting.id, (response) => {
            setDeleting(false);
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            setDeleting(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }

    const onGenerate = () => {
        setGeneratingScores(true);

        BigBang.ReportSettingGenerateScores(setting.id, (response) => {
            setGeneratingScores(false);
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            setGeneratingScores(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }

    const onGenerateClusters = () => {
        setGeneratingClusters(true);

        BigBang.ReportSettingGenerateClusters(setting.id, {zscore_margin: zscore, min_distance, min_sample}, (response) => {
            setGeneratingClusters(false);
            reload(response.data.data);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
        }, (err) => {
            setGeneratingClusters(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    }



    return (
        <div className={"flex flex-col border border-black p-2 w-full mb-2 shadow bg-blue-50 pl-3 rounded-md shadow-lg"}>
            <div className={"flex flex-col w-full"}>
                <div className={"mb-1"}>
                Report #{setting.id}
                </div>

                <div className={"flex flex-row w-full"}>
                    <div className={"flex flex-col w-full flex-1 justify-center"}>
                        {setting.locations.map(location => {
                            return (
                                <div className={"text-sm font-bold"}>
                                    <FontAwesomeIcon icon={faLocationPin} className="mr-1"/>
                                    {location.country} {location.state ? <> - {location.state}</> : null} {location.city ? <> - {location.city}</> : null}
                                </div>
                            )
                        })}

                        <div className={"flex flex-wrap mt-1"}>
                            {Object.entries(setting.score_parameters).map(([key, value]) => (
                                <div key={key} className={"m-0.5 bg-purple-100 text-black rounded-md border border-purple-300 px-2"}>
                                    <span>{key}:</span> <strong>{value.toString()}</strong>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div>
                        {!!!setting.zscores &&
                            <Button disabled={deleting || generatingClusters} loading={generatingScores} type={'primary'} onClick={onGenerate}>GENERATE SCORES</Button>
                        }
                        <Button disabled={deleting || generatingScores || generatingClusters} className={"ml-2"} onClick={onEdit}>EDIT</Button>
                        <Popconfirm
                            title="Delete the setting"
                            description="Are you sure to delete this setting?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onDelete}
                            disabled={generatingClusters || generatingScores}
                        >
                            <Button disabled={generatingScores} loading={deleting} className={"ml-2"}>
                                <FontAwesomeIcon icon={faTrash} className="text-sm"/>
                            </Button>
                        </Popconfirm>

                    </div>
                </div>

                {!!setting.zscores &&
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex flex-col w-full border-t-2 mt-2 border-gray-400 pt-2"}>
                            <Parameter label={"min distance (meters)"} maxValue={2000} minValue={1} value={min_distance} onSet={set_min_distance}/>
                            <Parameter label={"min cluster nodes"} maxValue={50} minValue={1} value={min_sample} onSet={set_min_sample}/>
                            <div className={"border-t border-gray-200 my-2 w-full"}/>
                            <Parameter label={"ZSCORE MARGIN"} maxValue={10} minValue={-10} step={.5} value={zscore} onSet={set_zscore}/>
                            <div className={"pl-56 flex flex-col w-full"}>
                                <div className={"p-1 flex flex-row items-center"}>
                                    Population: <b className={"px-1"}>{setting.zscores[zscore.toFixed(1)]}</b> out of {setting.zscores["all"]}
                                    <div className={"pl-2 font-bold text-lg"}>
                                        ({((setting.zscores[zscore.toFixed(1)] / setting.zscores["all"]) * 100).toFixed(0)}%)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col w-full border-t-2 mt-2 border-gray-400 pt-2"}>
                            <Button disabled={deleting || generatingScores} loading={generatingClusters} type={'primary'} onClick={onGenerateClusters}>
                                {setting.static_report?'RE':null}
                                GENERATE CLUSTERS
                            </Button>
                        </div>

                        {setting.dynamic_report && !generatingClusters &&
                            <div className={"flex flex-row w-full border-t-2 mt-2 border-gray-400 pt-2 items-center"}>
                                Clustering resulted in <b className={"px-1"}>{setting.dynamic_report.length}</b> clusters.
                                <Button type={'primary'} className={"mx-2"} onClick={() => setViewStatic(true)}>VIEW OVERALL</Button>
                                {viewStatic &&
                                    <div>
                                        <iframe
                                            className={"fixed w-full h-screen bg-white border-4 border-black left-0 top-0"}
                                            style={{zIndex: 1000000}}
                                            srcDoc={setting.static_report}
                                        >
                                        </iframe>
                                        <div
                                            className={"cursor-pointer fixed right-2 top-2 bg-black rounded-full flex flex-row items-center justify-center text-white font-bold w-8 h-8 text-lg"}
                                            onClick={()=>setViewStatic(false)}
                                            style={{zIndex: 1000001}}
                                        >
                                            X
                                        </div>
                                    </div>
                                }

                                <Button type={'primary'} onClick={() => setViewDynamic(true)}>VIEW RANKINGS</Button>
                                {viewDynamic &&
                                    <BigBangIndex mcdonalds={mcdonalds} starbucks={starbucks} shakeshacks={shakeshacks} fiveguys={fiveguys} burgerkings={burgerkings} clusters={setting.dynamic_report} setViewDynamic={setViewDynamic} />
                                }
                            </div>
                        }

                    </div>
                }

            </div>

        </div>
    );
};

export default BigBangReport;