import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const FormHandler = {
    getForms: (successCb, failCb) => {
        axios.get('/forms', getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    createForm: (data, successCb, failCb) => {
        axios.post(`/forms`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    updateForm: (id, data, successCb, failCb) => {
        axios.post(`/forms/${id}`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getForm: (id, successCb, failCb) => {
        axios.get(`/forms/${id}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    deleteForm: (id, successCb, failCb) => {
        axios.delete(`/forms/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    submitForm: (id, data, successCb, failCb) => {
        axios.post(`/forms/${id}/submit`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getFormSubmissions: (id, successCb, failCb) => {
        axios.get(`/forms/${id}/submissions`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getFormSubmission: (formId, submissionId, successCb, failCb) => {
        axios.get(`forms/${formId}/submissions/${submissionId}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

};

export default FormHandler;
