import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const InventoryVendor = {
    Index: (successCb, failCb) => {
        axios.get(`/inventory_vendors`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Create: (id, successCb, failCb) => {
        axios.get(`/inventory_vendors/create`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/inventory_vendors/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/inventory_vendors/store`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.post(`/inventory_vendors/${id}/update`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (id, successCb, failCb) => {
        axios.delete(`/inventory_vendors/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default InventoryVendor;
