import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Table, Button, Popconfirm, notification} from 'antd';
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import Container from '../../../../../http/Container';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import {motion} from "framer-motion";
import DefaultContainer from "../../../../../components/DefaultContainer";

const ManageContainers = () => {
    const [loading, setLoading] = useState(true);
    const [containers, setContainers] = useState([]);

    const reload = () => {
        setLoading(true);
        Container.Index((response) => {
            setLoading(false);
            setContainers(response.data.data.containers);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load containers',
            });
        });
    };

    useEffect(() => {
        reload();
    }, []);

    const handleDelete = (id) => {
        setLoading(true);
        Container.Delete(id, (response) => {
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            reload();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Empty Weight (gr)',
            dataIndex: 'empty_weight',
            key: 'empty_weight',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text, record) => (
                record.image ? <img src={`${record.image_url}`} alt={record.name} style={{width: '80px'}}/> : 'No Image'
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Link to={`/dashboard/operations/setup/inventory/containers/${record.id}/edit`}>
                        <Button icon={<EditOutlined/>}/>
                    </Link>
                    <Popconfirm
                        title="Are you sure you want to delete this container?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined/>} className="ml-2"/>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-row justify-between items-start mb-4">
                <div>
                    <h1 className="text-xl font-bold">Manage Containers</h1>
                    <div className="mb-4 text-gray-600">
                        This section allows you to efficiently organize and manage different storage buckets used for storing each inventory item
                    </div>
                </div>
                <Link to="/dashboard/operations/setup/inventory/containers/new">
                    <Button type="primary" icon={<PlusCircleOutlined/>} className={"primary-button"}>
                        Add Container
                    </Button>
                </Link>
            </div>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table dataSource={containers} columns={columns} rowKey="id"/>
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default ManageContainers;
