import axios from 'axios';
import {getAuthenticationHeaders, getAuthenticationHeadersMultiPart} from './Headers';
import {CreateCustomFormData} from './CustomFormData';
import uuid from "react-uuid";

const Cart = {
    Store: (food, selectedItems, quantity) => {
        let cart_items = JSON.parse(localStorage.getItem('cart_items'));
        if (cart_items === null) {
            cart_items = [];
        }

        let found = false;
        let item = null;

        selectedItems.sort();

        //console.log("selectedItems:", selectedItems);
        //console.log("cart_items before add:", cart_items);

        cart_items = cart_items.map(cart_item => {
            if(cart_item[0] == food.id && cart_item[1].join() == selectedItems.join()) {
                cart_item[2] += quantity;
                item = cart_item;
                found = true;
            }
            return cart_item;
        });

        //console.log("cart_items after add:", cart_items);

        if(!found) {
            item = [food.id, selectedItems, quantity, uuid()];
            cart_items.push(item);
        }
        else {
            item = [...item];
            //item[2] = quantity;
        }

        localStorage.setItem('cart_items', JSON.stringify(cart_items));
        return [cart_items, item];
    },
    Edit: (option_id, id, successCb, failCb) => {
        axios.get(`/option/${option_id}/cart/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    Update: (cart_items, key, updated_item) => {
        const new_cart_items = cart_items.map((item) => {
                return item[3] == key ? updated_item : item;
            }
        )
        localStorage.setItem('cart_items', JSON.stringify(new_cart_items));
        return new_cart_items;
    },
    Destroy: (cart_items, key) => {
        const new_cart_items = cart_items.filter((item) => {
                return item[3] != key;
            }
        )
        localStorage.setItem('cart_items', JSON.stringify(new_cart_items));
        return new_cart_items;
    },
};

export default Cart;
