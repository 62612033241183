import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart} from './Headers';

const SevenShiftApi = {

    // Fetch all categories
    syncUsers: (successCb, failCb) => {
        axios.post(`/seven-shifts/sync`, {}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
}

export default SevenShiftApi;