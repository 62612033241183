import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import FormItem from '../../components/Form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { ResetAuthErrors, resetPassword, resetPasswordReset } from '../../redux/authentication/actionCreator';
import { Link, useParams } from 'react-router-dom';
import FormError from '../../components/Form/FormError';
import { ResetActiveOrders } from "../../redux/checkout/actionCreator";

const AcceptInvitation = () => {

    const dispatch = useDispatch();
    const { token, email } = useParams();
    const [form] = Form.useForm();

    const isLoading = useSelector(state => state.auth.loading);
    const resetPasswordError = useSelector(state => state.auth.error);
    const resetPasswordDone = useSelector(state => state.auth.done);
    const resetPasswordValidationFields = useSelector(state => state.auth.fields);

    const handleSubmit = () => {
        dispatch(resetPassword(form.getFieldsValue()));
    };

    const getDecoded = (txt) => {
        return decodeURIComponent(txt || '');
    };

    useEffect(() => {
        dispatch(resetPasswordReset());
        dispatch(ResetActiveOrders());
        dispatch(ResetAuthErrors());
    }, [dispatch]);

    return (
        <div className="auth-contents container mx-auto bg-white mt-4 py-2 px-4 lg:py-4 lg:px-8">
            {resetPasswordDone ? (
                <div>
                    <div className="text-2xl font-bold mb-4">Registration Complete</div>
                    <p>You can now log in with your new password. <Link to="/login">Sign in</Link>.</p>
                </div>
            ) : (
                <>
                    <Form name="resetPassword" form={form} onFinish={handleSubmit} layout="vertical">
                        <div className="text-2xl font-bold mb-4">Accept Your Invitation</div>

                        <FormError error={resetPasswordError} />

                        <FormItem name="token" hidden={true} initialValue={token}>
                            <Input type="hidden" />
                        </FormItem>

                        <FormItem name="email" hidden={true} initialValue={getDecoded(email)} >
                            <Input type="hidden" />
                        </FormItem>

                        <div className="mb-6">
                            <p>Hello <b>{getDecoded(email)}</b>,</p>
                            <p>You have been invited to join Pattyslaps. Please set your password to complete your registration.</p>
                        </div>

                        <FormItem
                            name="password"
                            label="Password"
                            errors={resetPasswordValidationFields}
                            disabled={isLoading}
                            initialValue=""
                        >
                            <Input.Password placeholder="Enter your new password" disabled={isLoading} />
                        </FormItem>

                        <FormItem
                            name="password_confirmation"
                            label="Confirm Password"
                            errors={resetPasswordValidationFields}
                            disabled={isLoading}
                            initialValue=""
                        >
                            <Input.Password placeholder="Confirm your new password" disabled={isLoading} />
                        </FormItem>

                        <Button htmlType="submit" size="large" type="primary" key="submit" loading={isLoading}>
                            Accept Invitation
                        </Button>
                    </Form>
                </>
            )}
        </div>
    );
};

export default AcceptInvitation;
