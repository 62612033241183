import React from 'react';
import UserWithAvatar from "../../UserWithAvatar";
import UpdatedColumn from "./UpdatedColumn";
import moment from "moment";
import UpdatedAction from "./UpdatedAction";

const TaskLogItem = ({item}) => {

    return (
        <div className={"flex flex-col md:flex-row items-center w-full"}>
            <div className={"flex-1 md:ml-3 mt-2 md:mt-0"}>
                <UserWithAvatar name={item.user.name} avatar={item.user.avatar.url} noborder/>
                {item.action != null ?
                    <div className={"flex flex-col items-center md:items-start justify-center flex-1"}>
                        {item.type && <UpdatedColumn column={"type"} value={item.type}/>}
                        {item.description && <UpdatedColumn column={"description"} value={item.description}/>}
                        {item.more_info && <UpdatedColumn column={"more_info"} value={item.more_info}/>}
                        {item.priority && <UpdatedColumn column={"priority"} value={item.priority}/>}
                        {item.status && <UpdatedColumn column={"status"} value={item.status}/>}
                    </div>
                :
                    <div className={"flex flex-col items-center md:items-start justify-center flex-1"}>
                        {item.goal && <UpdatedColumn column={"goal"} value={item.goal}/>}
                        {item.result && <UpdatedColumn column={"result"} value={item.result}/>}
                    </div>
                }
            </div>

            <div className={"px-2 rounded-md bg-gray-100 px-2 py-1 mt-2 md:mt-0 "}>
                {moment(item.created_at).fromNow()}
                <UpdatedAction action={item.action} />
                <UpdatedAction action={item.deliverable_action} deliverable />
            </div>

        </div>
    );
};

export default TaskLogItem;