import React from 'react';
import {Link} from "react-router-dom";
import T from "../components/T";

const BubbleViewBagButton = ({cartRoute, cart_items}) => {

    return (
        <>
            {!cartRoute && cart_items.length > 0 ?
                <div className="flex mt-3">
                    <Link to="/cart" className="flex-1 rounded-3xl bg-gray-800 text-white text-center py-1 uppercase">
                        <T>VIEW BAG</T>
                    </Link>
                </div>
            : null}
        </>
    );
};

export default BubbleViewBagButton;
