import push from '../history';

export function getAuthenticationHeaders() {
  return { headers: { Authorization:
          'Bearer ' + localStorage.getItem('token'),
          device_token: localStorage.getItem('device_token'),
          device_uuid: localStorage.getItem('device_uuid'),
          device_type: localStorage.getItem('device_type'),
          web: true
      }};
}

export function getDeviceHeaders() {
    return { headers: {
            device_token: localStorage.getItem('device_token'),
            device_uuid: localStorage.getItem('device_uuid'),
            device_type: localStorage.getItem('device_type')
        }};
}


export function getAuthenticationHeadersMultiPart() {
  return { headers: { Authorization:
          'Bearer ' + localStorage.getItem('token'),
          "Content-Type": "multipart/form-data",
          device_token: localStorage.getItem('device_token'),
          device_uuid: localStorage.getItem('device_uuid'),
          device_type: localStorage.getItem('device_type')
  }};
}

export function checkLoggedIn(err) {

  if(err?.response?.data?.message == "Not logged in!") {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('isLoggedIn');
    push('/', true);
  }
    if(err?.response?.data?.message == "Please verify your email address.") {
        push('/resend-verification-email');
    }
}
