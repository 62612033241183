import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullseye, faFeatherPointed} from "@fortawesome/free-solid-svg-icons";

const UpdatedAction = ({action, deliverable}) => {
    return (
        action &&
        <div className={`px-1 rounded-md ${action=='update'?'bg-green-200':'bg-blue-200'} text-center`}>
            {deliverable?<FontAwesomeIcon icon={faBullseye} aria-hidden="true" className={"mr-1"}/>:null}
            <b>{action == "update"?'UPDATE':'CREATED'}</b>
        </div>
    );
};

export default UpdatedAction;