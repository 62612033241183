import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Form, Input, Button, Select, notification} from 'antd';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';

const {Option} = Select;

const CreateBranchInventory = () => {
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [unitType, setUnitType] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const {id} = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {
        console.log("unitType", unitType);
    }, [unitType]);

    const fetchInitialData = () => {
        BranchInventory.FetchInitialData((response) => {
            const {branches, inventories} = response.data.data;
            setBranches(branches);
            setInventories(inventories);
            if (id) {
                fetchBranchInventory(id);
            } else {
                setLoading(false);
            }
        }, (err) => {
            setBranches([]);
            setInventories([]);
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load initial data',
            });
        });
    };

    const fetchBranchInventory = (id) => {
        BranchInventory.Edit(id, (response) => {
            const branchInventory = response.data.data.branch_inventory;
            form.setFieldsValue(branchInventory);
            setLoading(false);
            setUnitType(branchInventory?.inventory?.unit_type);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branch inventory',
            });
            history.push("/dashboard/operations/setup/inventory/branch/manage");
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);
        if (id) {
            BranchInventory.Update(id, values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            BranchInventory.Store(values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/branch/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }
    };

    const handleInventoryChange = (value) => {
        const selectedInventory = inventories.find(inventory => inventory.id === value);
        if (selectedInventory) {
            setUnitType(selectedInventory.unit_type);
        } else {
            setUnitType('');
        }
    };

    return (
        <DefaultContainer>
            <div className="flex flex-col pt-3 w-full" style={{backgroundColor: "#FAFAFA"}}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Branch Inventory' : 'Add New Branch Inventory'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Branch"
                            name="branch_id"
                            rules={[{required: true, message: 'Please select the branch'}]}
                        >
                            <Select placeholder="Select branch" disabled={submitting}>
                                {branches.map(branch => (
                                    <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Inventory"
                            name="inventory_id"
                            rules={[{required: true, message: 'Please select the inventory'}]}
                        >
                            <Select placeholder="Select inventory" onChange={handleInventoryChange} disabled={submitting}>
                                {inventories.map(inventory => (
                                    <Option key={inventory.id} value={inventory.id}>{inventory.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div className={"hidden"}>
                            <Form.Item
                                label="Order Batch Size"
                                name="order_batch_size"
                                rules={[{required: false, message: 'Please enter the order batch size'}]}
                            >
                                <Input addonAfter={unitType} disabled={submitting}/>
                            </Form.Item>
                        </div>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full">
                                {id ? 'Update Branch Inventory' : 'Add Branch Inventory'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateBranchInventory;
