import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ListActiveOrders} from "../../../redux/checkout/actionCreator";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import ActiveOrderRow from "./ActiveOrderRow";
import {SpinnerCircular} from "spinners-react";
import Pullable from "react-pullable";
import {SetRedirectSource} from "../../../redux/authentication/actionCreator";
import {motion} from 'framer-motion';

const ActiveOrders = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.checkout.orders_loading);
    const error = useSelector(state => state.checkout.orders_error);
    const success = useSelector(state => state.checkout.orders_success);
    const active_orders = useSelector(state => state.checkout.active_orders);

    const handleRefresh = () => {
        dispatch(ListActiveOrders());
    }

    useEffect(() => {
        dispatch(ListActiveOrders());
        dispatch(SetRedirectSource("/orders/active"))
    }, []);

    return (
        <Pullable
            onRefresh={handleRefresh}
            spinnerSize={34}
            refreshDuration={500}
        >
            <motion.div className=""
                        initial={{opacity: 0, scale: 1, y: -100}}
                        animate={{opacity: 1, scale: 1, y: 0}}
            >
                <div className="row">
                    <div className="md:container  flex flex-col mx-auto pb-10 pt-5 px-2">
                        <h1 className="text-xl font-bold text-black pl-3 flex flex-row mb-4">
                            ACTIVE ORDERS
                            {loading && active_orders ?
                                <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="ml-2  h-5"/>
                                : null}

                        </h1>
                        <div className="w-full flex flex-col lg:flex-row">
                            <div className="rounded-2xl flex-1 border shadow-xl bg-white p-2 flex flex-col items-center justify-center">
                                <SkeletonWrapper loading={loading && !active_orders} skeleton={MenuSkeleton}>
                                    {active_orders ?
                                        <>
                                            {active_orders.length == 0 ?
                                                <>
                                                    <div className="text-sm text-gray-600 font-normal py-8">You do not have any active orders.</div>
                                                </>

                                                :
                                                <>
                                                    <div className="w-full">
                                                        {active_orders.map(order => {
                                                            return (<ActiveOrderRow order={order} loading={loading}/>);
                                                        })}
                                                    </div>
                                                </>
                                            }

                                        </>
                                        : null}
                                </SkeletonWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </Pullable>
    );
};

export default ActiveOrders;
