import React from 'react';

const SkeletonWrapper = ({loading, skeleton, children}) => {
  const CustomSkeleton = skeleton;
  return (
    <>
      {loading ? <><CustomSkeleton key="custom"/></> : (children)}
    </>
  )

}

export default SkeletonWrapper;