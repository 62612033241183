import React, { useEffect, useState } from 'react';
import { Table, Button, Input, notification } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import Coefficients from '../../../../http/Coefficients';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const { Search } = Input;

const BranchCoefficientsComponent = () => {
    const [coefficients, setCoefficients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [recalculating, setRecalculating] = useState(false);
    const [branch, setBranch] = useState(null);
    const [searchText, setSearchText] = useState('');
    const { branchId } = useParams();
    const history = useHistory();

    useEffect(() => {
        fetchCoefficients();
    }, [branchId]);

    const fetchCoefficients = () => {
        setLoading(true);
        Coefficients.getCoefficients(branchId, (response) => {
            setCoefficients(response.data.data.coefficients);
            setBranch(response.data.data.branch);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load coefficients',
            });
            setLoading(false);
        });
    };

    const handleRecalculate = () => {
        setRecalculating(true);
        Coefficients.recalculateCoefficients(branchId, (response) => {
            notification.success({
                message: 'Success',
                description: response.data?.data?.message,
            });
            fetchCoefficients(); // Refresh the coefficients
            setRecalculating(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to recalculate coefficients',
            });
            setRecalculating(false);
        });
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredCoefficients = coefficients.filter(coefficient =>
        coefficient.entity_name.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        {
            title: 'Entity Name',
            dataIndex: 'entity_name',
            key: 'entity_name',
            sorter: (a, b) => a.entity_name.localeCompare(b.entity_name),
        },
        {
            title: 'Entity Type',
            dataIndex: 'entity_type',
            key: 'entity_type',
            sorter: (a, b) => a.entity_type.localeCompare(b.entity_type),
            render: (text) => text === 'item_type' ? 'Item Type' : 'Food',
        },
        {
            title: 'Coefficient',
            dataIndex: 'coef',
            key: 'coef',
            render: (value) => value.toFixed(4),
            sorter: (a, b) => a.coef - b.coef,
        },
    ];

    return (
        <DefaultContainer>
            <div className={"patty text-2xl font-bold mb-4"}>
                Branch Coefficients {branch && <>- Branch {branch.name}</>}
            </div>
            <Button type="primary" onClick={handleRecalculate} loading={recalculating}>
                Recalculate All Coefficients
            </Button>
            <Search
                placeholder="Search by entity name"
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 200, margin: '20px 0 0 0' }}
            />
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={filteredCoefficients}
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 20 }}
                />
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default BranchCoefficientsComponent;
