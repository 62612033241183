import React, { useState } from 'react';
import DefaultContainer from "../../../../components/DefaultContainer";
import { Button, Typography, notification } from "antd";
import SevenShiftApi from "../../../../http/SevenShiftApi";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom"; // Adjust path if necessary

const { Text } = Typography;

const SevenShifts = () => {
    const [loading, setLoading] = useState(false);
    const [syncResult, setSyncResult] = useState(null);

    const handleSync = () => {
        setLoading(true);
        setSyncResult(null); // Clear previous results
        SevenShiftApi.syncUsers(
            (response) => {
                setLoading(false);
                setSyncResult(response.data.data);
                notification.success({ message: "Sync completed" });
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Sync failed", description: error.message });
            }
        );
    };

    return (
        <DefaultContainer>
            <Text style={{ marginBottom: 16 }}>
                Sync punch codes and manage users with 7shifts. We will create and invite users from 7shifts who are not yet registered here.
            </Text>
            <Button type="primary" loading={loading} onClick={handleSync}>
                Sync
            </Button>

            {syncResult && (
                <div style={{ marginTop: 24 }}>
                    <Typography.Title level={4}>Sync Report</Typography.Title>

                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text strong>Total Users Imported:</Typography.Text> {syncResult.total_users_imported}
                        {syncResult.imported_users.length > 0 && (
                            <ul style={{ paddingLeft: 20 }}>
                                {syncResult.imported_users.map((user, index) => (
                                    <li key={index} className={`${user.status == 0?'opacity-20':null}`}>
                                        {user.status == 0?<FontAwesomeIcon icon={faTriangleExclamation} className="text-md text-red-700" aria-hidden="true"/>:null} <b><Link to={`/dashboard/hr/employees/${user.id}/edit`}>{user.name}</Link></b> ({user.email})
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text strong>Punch IDs Updated:</Typography.Text> {syncResult.punch_ids_updated}
                        {syncResult.updated_punch_ids.length > 0 && (
                            <ul style={{ paddingLeft: 20 }}>
                                {syncResult.updated_punch_ids.map((user, index) => (
                                    <li key={index} className={`${user.status == 0?'opacity-50':null}`}><b>{user.name}</b> ({user.email})</li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text strong>Users Disabled:</Typography.Text> {syncResult.users_disabled}
                        {syncResult.disabled_users.length > 0 && (
                            <ul style={{ paddingLeft: 20 }}>
                                {syncResult.disabled_users.map((user, index) => (
                                    <li key={index}><b>{user.name}</b> ({user.email})</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </DefaultContainer>
    );
};

export default SevenShifts;
