import React from 'react';

const Amount = ({min, max, sign='$', unlimited_text = 'unlimited'}) => {

    return (
        min < max?
            <>
                {sign}{min} - {sign}{max}
            </>
        :
            (min > 0?<>{sign}{min}</>:` ${unlimited_text}`)

    );
};

export default Amount;