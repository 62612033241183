import React, {useEffect, useRef, useState} from 'react';
import {Switch, notification, Modal, Select, Input, Button, Form, List} from "antd";
import Division from "../../../../http/Division";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBatteryEmpty, faBeerMugEmpty, faCirclePlus, faCrown, faEdit, faEye, faHistory, faMartiniGlassEmpty, faTimes, faTrash, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {motion} from 'framer-motion';
import Avatar from "../../../../components/Avatar";
import Table from "ant-responsive-table";
import {SpinnerCircular} from "spinners-react";
import PriorityTag from "../../../../components/PriorityTag";
import StatusTag from "../../../../components/StatusTag";
import {Translate} from "../../../../helpers/Translate";
import FormItem from "../../../../components/Form/FormItem";
import UserWithAvatar from "../../../../UserWithAvatar";
import Task from "../../../../http/Task";
import Deliverables from "./TaskGroup/Tasks/Deliverables/Deliverables";
import TaskGroup from "../../../../http/TaskGroup";
import TypeTag from "../../../../components/TypeTag";
import TaskLogItem from "../../../../components/log/TaskLogItem";

const DivisionDetails = () => {
    const {id} = useParams();

    const user = useSelector(state => state.auth.user);
    const [division, setDivision] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [userIsParticipant, setUserIsParticipant] = useState(false);
    const [completed, setCompleted] = useState(true);
    const [loading, setLoading] = useState(false);
    const [accountableDivisions, setAccountableDivisions] = useState(null);
    const [columns, setColumns] = useState([

        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: 'type',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'PRIORITY',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority.props.priority.localeCompare(b.priority.props.priority),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.props.status.localeCompare(b.status.props.status),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            showOnResponse: true,
            showOnDesktop: true
        },

    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [editingTaskGroup, setEditingTask] = useState(null);
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();
    const [validationFields, setValidateFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const history = useHistory();
    const [logTaskGroup, setLogTaskGroup] = useState(null);
    const [showLog, setShowLog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deletingTaskGroup, setDeletingTaskGroup] = useState(null);
    const [deletePerforming, setDeletePerforming] = useState(false);

    const load = (data) => {
        const d = data.division;
        setCompleted(data.completed);
        setAccountableDivisions(data.accountable_divisions);

        setDivision(d);

        const u = d.users.find(u => u.id == user.id);
        setUserIsParticipant(!!u);
        //if (!u) setColumns([...columns.filter((column) => column.key !== 'actions')]);

        const TasksDataSource = [];
        d.task_groups.map(task_group => {
            return TasksDataSource.push({
                key: task_group.id,
                accountable: task_group.accountable_division.name,
                type: <TypeTag type={task_group.type}/>,
                description: task_group.accountable_division_id == 1?task_group.description:<Link to={`/dashboard/task_group/${task_group.id}`}>{task_group.description}</Link>,
                priority: <PriorityTag priority={task_group.priority}/>,
                status: <StatusTag status={task_group.status}/>,
                actions: (
                    <div className='table-actions flex flex-row items-center justify-start xl:justify-center'>
                        {(u || task_group.accountable_division_id == 1) &&
                            <div className={"flex flex-row items-center justify-center"}>
                                <div className={"xl:hidden"}>
                                    <Button onClick={() => onEdit(task_group)} className={"xl:hidden"}>
                                        <div className="flex flex-row items-center justify-start xl:justify-center">
                                            <FontAwesomeIcon icon={faEdit} className="text-sm"/>
                                            <div className={"ml-2"}>Edit</div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="hidden xl:flex mx-1 cursor-pointer" onClick={() => onEdit(task_group)}>
                                    <FontAwesomeIcon icon={faEdit} className="text-sm"/>
                                </div>
                                {task_group.logs.some(log => log.user_id === user.id && log.action === 'create') &&
                                    <div className="hidden xl:flex ml-2 cursor-pointer" onClick={() => onDelete(task_group)}>
                                        <FontAwesomeIcon icon={faTrash} className="text-sm"/>
                                    </div>
                                }
                            </div>
                        }

                        {task_group.accountable_division_id > 1 &&
                            <div className={"ml-2"}>
                                <div className={"xl:hidden"}>
                                    <Button onClick={() => onView(task_group)} className={"xl:hidden"}>
                                        <div className="flex flex-row items-center justify-start xl:justify-center">
                                            <FontAwesomeIcon icon={faEye} className="text-sm"/>
                                            <div className={"ml-2"}>View</div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="hidden xl:flex mx-1 cursor-pointer" onClick={() => onView(task_group)}>
                                    <FontAwesomeIcon icon={faEye} className="text-sm"/>
                                </div>
                            </div>
                        }

                        <div className={"ml-2"}>
                            <div className="flex mx-1 cursor-pointer" onClick={() => onLog(task_group)}>
                                <FontAwesomeIcon icon={faHistory} className="text-sm"/>
                            </div>
                        </div>
                    </div>
                ),
            });
        });

        setDataSource(TasksDataSource);
    }

    const onView = (task_group) => {
        history.push(`/dashboard/task_group/${task_group.id}`);
    }

    const reload = () => {
        setLoading(true);
        Division.Show(id ? id : 'open_subjects', completed, (response) => {
            load(response.data.data);
            setLoading(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    }

    useEffect(() => {
        if (completed != null) {
            reload();
        }
    }, [completed]);

    useEffect(() => {
        reload();
    }, [id]);

    const onCompleteChange = (c) => {
        if (loading) return;
        setCompleted(c);
        console.log("completed", c);
    }

    const onCancel = () => {
        if (saving) return;
        setCreating(false);
        setEditing(false);
        setEditingTask(null);
        setValidateFields(null);
    }

    const onCreateTask = () => {
        form.resetFields();
        setEditingTask(null);
        setCreating(true);
    }

    const onEdit = (task) => {
        form.resetFields();
        setValidateFields(null);
        setEditing(true);
        setEditingTask(task);
    }


    const handleSubmit = () => {
        setSaving(true);
        const data = {'accountable_division_id': division.id, ...form.getFieldsValue()};
        if (creating) {
            TaskGroup.Store(data, completed, (response) => {
                load(response.data.data);
                setLoading(false);
                setSaving(false);
                setCreating(false);
                notification['success']({
                    description: 'Project created successfully.'
                });
            }, (err) => {

                if (!err.response?.data?.errors) {
                    notification['error']({
                        message: 'Error!',
                        description: err.response?.data.message,
                    });
                }

                setValidateFields(err.response.data.errors)
                setSaving(false);
            });
        } else {
            TaskGroup.Update(editingTaskGroup.id, data, completed, (response) => {
                load(response.data.data);
                setLoading(false);
                setSaving(false);
                setEditing(false);
                notification['success']({
                    description: 'Project updated successfully.'
                });

            }, (err) => {

                if (!err.response?.data?.errors) {
                    notification['error']({
                        message: 'Error!',
                        description: err.response?.data.message,
                    });
                }

                setValidateFields(err.response.data.errors)
                setSaving(false);
            });
        }
    }


    const onLogCancel = () => {
        setShowLog(false)
    }

    const onLog = (task_group) => {
        setLogTaskGroup(task_group);
        setShowLog(true);
    }


    const onDelete = (task) => {
        setDeleting(true);
        setDeletingTaskGroup(task);
    }

    const onDeleteCancel = () => {
        setDeleting(false);
        setDeletingTaskGroup(null);
    }

    const onDeleteOk = () => {
        setDeletePerforming(true);
        TaskGroup.Delete(deletingTaskGroup.id, (response) => {
            load(response.data.data);
            setDeleting(false);
            setDeletePerforming(false);
            setDeletingTaskGroup(null);
            notification['success']({
                description: 'Task deleted successfully.'
            });

        }, (err) => {
            setDeletePerforming(false);
            if (!err.response?.data?.errors) {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            }
        });
    }

    console.log("editingTaskGroup", editingTaskGroup);

    return (

        <div className={"flex flex-col w-full h-screen division"}>

            {deletingTaskGroup &&
                <Modal title={<div>DELETE TASK GROUP <b>{deletingTaskGroup.description.toUpperCase()}</b></div>}
                       onCancel={onDeleteCancel}
                       onOk={onDeleteOk}
                       open={deleting}
                       width={"90%"}
                       style={{maxWidth: "800px"}}
                       okText={"DELETE"}
                       okButtonProps={{danger: true, size: "large", loading: deletePerforming}}
                       cancelButtonProps={{size: "large", disabled: deletePerforming}} className={"task"}
                >
                    Are you sure you want to delete this task group? This operation is irreversible.
                </Modal>
            }


            {logTaskGroup &&
                <Modal title={<div>LOGS FOR <b>{logTaskGroup.description.toUpperCase()}</b></div>}
                       onCancel={onLogCancel}
                       onOk={onLogCancel}
                       open={showLog}
                       width={"90%"}
                       style={{maxWidth: "800px"}}
                       cancelButtonProps={{style: {display: 'none'}}}
                >
                    <List
                        dataSource={logTaskGroup.logs}
                        renderItem={(item) => (
                            <List.Item>
                                <TaskLogItem item={item}/>
                            </List.Item>
                        )}
                    />
                </Modal>
            }

            {division && (creating || (editing && editingTaskGroup)) &&
                <Modal title={<div>{editing ? 'EDITING' : 'CREATE'} PROJECT FOR <b>DIVISION {division.name.toUpperCase()}</b></div>}
                       onCancel={onCancel}
                       onOk={handleSubmit}
                       open={creating || editing}
                       okText={editing ? 'SAVE' : "CREATE"}
                       okButtonProps={{danger: true, size: "large", loading: saving}}
                       cancelButtonProps={{size: "large", disabled: saving}} className={"task"}
                >
                    <Form form={form} layout='vertical'>
                        <div className="flex flex-col">
                            <div className={"flex flex-row"}>
                                <div className="w-36 text-right font-bold mr-2">ACCOUNTABLE:</div>
                                <div className={"w-64"}>

                                    {editingTaskGroup && editingTaskGroup.accountable_division_id == 1 ?
                                        <FormItem
                                            name="accountable_division_id"
                                            initialValue={"1"}
                                        >
                                            <Select
                                                className={"w-full"}
                                                showSearch={true}
                                                style={{width: "100% important"}}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={Object.keys(accountableDivisions).map((key) => {
                                                    return ({value: key, label: accountableDivisions[key]})
                                                })}
                                            />
                                        </FormItem>
                                        :
                                        <div>
                                            DIVISION {division.name}
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-36 text-right font-bold mr-2 mt-1">TYPE:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="type"
                                        errors={validationFields}
                                        initialValue={editingTaskGroup ? editingTaskGroup.type : null}
                                    >
                                        <Select
                                            className={"w-full"}
                                            options={['idea', 'issue'].map(type => {
                                                return ({value: type, label: type})
                                            })}
                                        />
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-36 text-right font-bold mr-2 mt-1">DESCRIPTION:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="description"
                                        errors={validationFields}
                                        initialValue={editingTaskGroup?.description}
                                    >
                                        <Input placeholder="" disabled={isLoading}/>
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-36 text-right font-bold mr-2 mt-1">PRIORITY:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="priority"
                                        errors={validationFields}
                                        initialValue={editingTaskGroup ? editingTaskGroup.priority : null}
                                    >
                                        <Select
                                            className={"w-full"}
                                            options={['low', 'medium', 'high'].map(priority => {
                                                return ({value: priority, label: priority})
                                            })}
                                        />
                                    </FormItem>
                                </div>
                            </div>


                        </div>
                    </Form>
                </Modal>
            }

            <SkeletonWrapper loading={!division} skeleton={GenericSkeleton}>
                {division &&
                    <div className={"flex flex-col p-0 py-1"}>

                        <div className={"w-full flex flex-col lg:flex-row items-center justify-between text-3xl font-bold uppercase bg-white mb-1 px-2 py-1 border-b-2 border-black"}>
                            <div className={" flex flex-col sm:flex-row items-center text-black text-3xl lg:text-4xl"}>
                                <div className={"patty"} style={{letterSpacing: "-2px"}}>
                                    Division {division.name}
                                </div>
                                {(userIsParticipant || division.id == 1) &&
                                    <div className={"px-2 pl-1 py-1 rounded-2xl flex flex-row ml-3 bg-blue-100 items-center justify-center text-base border border-gray-500 cursor-pointer mb-2 lg:mb-0"} onClick={onCreateTask}>
                                        <FontAwesomeIcon icon={faCirclePlus} className="text-2xl mr-1 text-black " aria-hidden="true"/>
                                        Create a Project
                                    </div>
                                }
                                {loading && <SpinnerCircular size={25} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"-mt-1 ml-2"}/>}
                            </div>

                            <div className={"flex flex-row mb-1 lg:mb-0"}>

                                {division.users.map(u => {
                                    return (
                                        <div className={`px-2 py-0.5 bg-gray-100 border border-gray-400 rounded-md m-0.5 text-xs md:text-sm ${u.pivot.role == "accountable" ? "font-bold" : "text-gray-600 hidden"} ${u.id == user.id ? "" : ""}`}>
                                            {u.pivot.role == "accountable" &&
                                                <FontAwesomeIcon icon={faCrown} className="text-xs md:text-sm mr-1" aria-hidden="true"/>
                                            }
                                            <span className={"uppercase"}>
                                                {u.name}
                                            </span>
                                        </div>
                                    )
                                })}

                                {division.id > 1 &&
                                    <div className={"px-1 pl-3 rounded-2xl flex flex-row ml-1 bg-blue-100 items-center justify-center text-xs border border-gray-500"}>
                                        <div className={"uppercase mr-2 font-bold"}>Completed</div>
                                        <Switch checked={completed} size={"default"} onChange={onCompleteChange}/>
                                    </div>
                                }


                            </div>
                        </div>

                        {division.task_groups.length == 0 ?
                            <div className={`flex flex-row items-center justify-center bg-gray-50 py-3 px-3 text-gray-500 shadow-md text-xs`}>
                                <FontAwesomeIcon icon={faMartiniGlassEmpty} className="text-sm mr-2" style={{marginTop: "2px"}} aria-hidden="true"/>
                                There are no active projects in this division.
                            </div>
                            :
                            <div className={`flex flex-col `}>
                                <div className={"text-2xl font-bold patty pl-1 py-2"}>
                                    Projects
                                </div>
                                <div className={"border border-gray-300 rounded-lg shadow-lg overflow-hidden"}>
                                    <Table antTableProps={{dataSource: dataSource, columns: columns, pagination: false}} mobileBreakPoint={1200}/>
                                </div>
                            </div>
                        }

                    </div>
                }
            </SkeletonWrapper>
        </div>
    );
};

export default DivisionDetails;