import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Abilities = {

    // Fetch all ability groups with their abilities
    Index: (successCb, failCb) => {
        axios.get(`/employees/abilities`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new ability group
    StoreGroup: (data, successCb, failCb) => {
        axios.post(`/employees/abilities/groups`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new ability
    Store: (data, successCb, failCb) => {
        axios.post(`/employees/abilities`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing ability group
    UpdateGroup: (id, data, successCb, failCb) => {
        axios.put(`/employees/abilities/groups/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing ability
    Update: (id, data, successCb, failCb) => {
        axios.put(`/employees/abilities/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete an ability group
    DeleteGroup: (id, successCb, failCb) => {
        axios.delete(`/employees/abilities/groups/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete an ability
    Delete: (id, successCb, failCb) => {
        axios.delete(`/employees/abilities/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update the order of abilities within a group
    UpdateOrder: (data, successCb, failCb) => {
        axios.post(`/employees/abilities/order`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update the order of ability groups
    UpdateGroupOrder: (data, successCb, failCb) => {
        axios.post(`/employees/abilities/groups/order`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default Abilities;
