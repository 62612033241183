import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Inventory = {
    Index: (successCb, failCb) => {
        axios.get(`/inventories`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/inventories/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/inventories`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.put(`/inventories/${id}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (id, successCb, failCb) => {
        axios.delete(`/inventories/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    FetchEntities: (successCb, failCb) => {
        axios.get(`/inventory/entities`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default Inventory;
