import React, {useEffect, useRef, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faArrowLeft, faArrowRightLong, faBroom, faExclamationTriangle, faExpand, faMailBulk, faMapPin, faPhone, faThermometer1, faUser, faX} from "@fortawesome/free-solid-svg-icons";
import Settings from "../../../../http/Settings";
import Survey from "../../../../http/Survey";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";

import {FrownOutlined, MehOutlined, SmileOutlined} from '@ant-design/icons';
import {Button, Divider, Input, notification, Rate} from 'antd';
import {useSelector} from "react-redux";
import {SpinnerCircular} from "spinners-react";
import {useParams} from "react-router-dom";

const customIcons = {
    1: <FrownOutlined style={{fontSize: "25px"}}/>,
    2: <FrownOutlined style={{fontSize: "25px"}}/>,
    3: <MehOutlined style={{fontSize: "25px"}}/>,
    4: <SmileOutlined style={{fontSize: "25px"}}/>,
    5: <SmileOutlined style={{fontSize: "25px"}}/>,
};

function ErrorMessages({errors}) {
    return (
        <div>
            {Object.keys(errors).map(key => (
                <div key={key} className="flex flex-row items-center bg-red-700 text-white border border-red-800 px-2 py-1 rounded-md mb-2">
                    <FontAwesomeIcon icon={faExclamationTriangle} aria-hidden="true" className={"mr-1"}/>
                    {errors[key].map((error, index) => (
                        <div key={index}>{error}</div>
                    ))}
                </div>
            ))}
        </div>
    );
}

const SurveyIndex = () => {

    const user = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const { branchId } = useParams();
    const [branch, setBranch] = useState(null);

    const ref = useRef();
    const [fullscreen, setFullscreen] = useState(false);
    const [errors, setErrors] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [answers, setAnswers] = useState({});
    const [comments, setComments] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [searching, setSearching] = useState(false);
    const [corrCheckout, setCorrCheckout] = useState(0);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);

    const handleFullscreen = () => {
        setFullscreen(!fullscreen);
    }

    useEffect(() => {
        setLoading(true);
        Survey.GetQuestions(branchId, (response) => {
            setLoading(false);
            console.log("settings:", response);
            setQuestions(response.data.data.questions);
            setBranch(response.data.data.branch);
        }, (err) => {
            setQuestions([]);
            setLoading(false);
        });
    }, []);

    const handleSetAnswer = (question, answer) => {
        answers[question.id] = answer;
        setAnswers({...answers});
    }

    const handleChangeComment = (question, e) => {
        comments[question.id] = e.target.value;
        setComments({...comments});
    }

    const handleSubmit = () => {
        setSubmitting(true);
        Survey.Submit(branchId, answers, comments, phone, email, corrCheckout, (response) => {
            notification['success']({
                message: 'Success!',
                placement: fullscreen ? "topRight" : "bottomRight",
                description: `Survey submitted successfully!`,
            });
            setSubmitting(false);
            resetSurvey();
        }, (err) => {
            setErrors(err.response.data.message.errors);
            setSubmitting(false);
        });
    }

    const resetSurvey = () => {
        setComments({});
        setAnswers({});
        setErrors(null);
        setCorrCheckout(0);
        setEmail(null);
        setPhone(null);
        setSearchResult(null);
        ref.current.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    const handleCustomerSearch = (e) => {
        const keyword = e.target.value;
        setSearching(true);
        Survey.Search(branchId, keyword, (response) => {
            console.log("Customers:", response.data.data);
            setSearchResult(response.data.data.checkouts);
            setSearching(false);
        }, (err) => {
            setSearching(false);
        });
    }

    const onHandlePhoneChange = (e) => {
        setPhone(e.target.value);
    }

    const onHandleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const setCheckout = (checkoutId) => {
        setCorrCheckout(checkoutId);
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full ${fullscreen ? 'fullscreen' : null}`}
            key={'kpi page'}
            ref={ref}
        >
            <div className="flex flex-col mx-auto w-full">
                {!fullscreen ?
                    <div className="text-xl font-bold text-black pl-1 flex flex-row mb-2 justify-between">
                        <T>PattySlaps Survey </T>{branch?<> - {branch.name}</>:null}
                        <div>
                            <FontAwesomeIcon icon={faExpand} aria-hidden="true" className={"mr-2 cursor-pointer"} onClick={handleFullscreen}/>
                        </div>
                    </div>
                    :
                    <div className="text-lg font-bold text-black p-2 px-4 flex flex-row mb-2 bg-red-brand justify-between items-center">
                        <div className={"flex flex-row items-center uppercase"}>
                            <img src={process.env.PUBLIC_URL + '/images/logo-small.png'} className="h-10 mr-4"/>
                            <FontAwesomeIcon icon={faArrowRightLong} aria-hidden="true" className={"mr-1"}/>

                            <div className="px-3 py-1 text-base text-white text-with-border">
                                Survey
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faExpand} aria-hidden="true" className={"mr-2 cursor-pointer"} onClick={handleFullscreen}/>
                        </div>
                    </div>
                }
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center py-3 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {questions && questions.length == 0 ?
                                <div>There are no questions.</div>
                                :
                                questions ?
                                    <div className='flex flex-col w-full'>
                                        <>
                                            <div className="flex flex-col">

                                                <div className="flex flex-col w-full  py-2 px-2 bg-gray-100 border border-gray-200 shadow-lg rounded-lg mb-2">
                                                    <div className="flex flex-row items-center w-full">
                                                        <div className={"mr-2 w-24 text-right"}>
                                                            <b>Phone:</b>
                                                        </div>
                                                        <div className={"mb-1 w-full"}>
                                                            <Input prefix={<FontAwesomeIcon icon={faPhone} className="text-base"/>} value={phone} placeholder='Customer Phone' onChange={onHandlePhoneChange} style={{width: "100%"}}/>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row items-center w-full">
                                                        <div className={"mr-2 w-24 text-right"}>
                                                            <b>Email:</b>
                                                        </div>
                                                        <div className={"mb-1 w-full"}>
                                                            <Input prefix={<FontAwesomeIcon icon={faMailBulk} className="text-base"/>} value={email} placeholder='Customer Email' onChange={onHandleEmailChange} style={{width: "100%"}}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full  py-2 px-2 bg-yellow-100 border border-gray-200 shadow-lg rounded-lg mb-2">

                                                    {corrCheckout == 0 ?
                                                        <>
                                                            <div className={"mt-1"}>
                                                                <b>Customer Search:</b>
                                                            </div>
                                                            <div className={"mb-1"}>
                                                                <Input prefix={searching ? <SpinnerCircular size={14} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.14)" thickness={235} className=""/> : <FontAwesomeIcon icon={faUser} className="text-base"/>} placeholder='Customer Name' onChange={handleCustomerSearch}/>
                                                            </div>
                                                        </>
                                                        : null}

                                                    {corrCheckout > 0 && searchResult && searchResult.hasOwnProperty(corrCheckout) ?
                                                        <>
                                                            <div key={corrCheckout} className={"rounded-lg bg-green-200 shadow-md border border-gray-300 flex flex-row my-1 items-center cursor-pointer"} onClick={() => setCheckout(0)}>
                                                                {searchResult[corrCheckout].length > 0 && (
                                                                    <div className="uppercase ml-2 font-bold">{searchResult[corrCheckout][0].first_name}</div>
                                                                )}
                                                                <div className="flex flex-wrap items-center px-1 py-0.5 m-0.5 flex-1">
                                                                    {searchResult[corrCheckout].map(item => (
                                                                        <div key={item.checkout_id} className="flex flex-row items-center m-1 bg-white px-1">
                                                                            <div className="font-bold mr-2 text-xs">{item.quantity}X</div>
                                                                            {item.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <FontAwesomeIcon icon={faX} aria-hidden="true" className={"mr-4 text-base font-bold"}/>

                                                            </div>
                                                        </>
                                                        :

                                                        searchResult ?
                                                            <div className="flex flex-col">
                                                                {Object.keys(searchResult).map(checkoutId => (
                                                                    <div key={checkoutId} className={"rounded-lg bg-blue-100 shadow-md border border-gray-300 flex flex-row my-1 items-center hover:bg-blue-200 cursor-pointer"} onClick={() => setCheckout(checkoutId)}>
                                                                        {searchResult[checkoutId].length > 0 && (
                                                                            <div className="uppercase ml-2 font-bold">{searchResult[checkoutId][0].first_name}</div>
                                                                        )}
                                                                        <div className="flex flex-wrap items-center px-1 py-0.5 m-0.5">
                                                                            {searchResult[checkoutId].map(item => (
                                                                                <div key={item.checkout_id} className="flex flex-row items-center m-1 bg-white px-1">
                                                                                    <div className="font-bold mr-2 text-xs">{item.quantity}X</div>
                                                                                    {item.name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>


                                            {questions.map(question => {
                                                return (
                                                    <div className="flex flex-row bg-white px-2 py-2 rounded-lg border-gray-300 border mb-2 shadow-md w-full">

                                                        <div className="flex flex-col w-full">
                                                            <div>
                                                                {question.question}
                                                            </div>
                                                            <div className="pt-2">
                                                                <Rate defaultValue={0}
                                                                      value={answers.hasOwnProperty(question.id) ? answers[question.id] : 0}
                                                                      onChange={(v) => handleSetAnswer(question, v)}
                                                                      character={({index}) => customIcons[index + 1]}
                                                                />
                                                            </div>
                                                            <div className="mt-2 w-full">
                                                                <Input.TextArea
                                                                    onChange={(e) => handleChangeComment(question, e)}
                                                                    value={comments.hasOwnProperty(question.id) ? comments[question.id] : ""}
                                                                    prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>}
                                                                    placeholder={"Comment..."}
                                                                    style={{width: "100%"}}
                                                                    rows={1}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {errors && <ErrorMessages errors={errors}/>}


                                            <div className="pt-2">
                                                <Button type={"primary"} onClick={handleSubmit} loading={submitting}>
                                                    Submit
                                                </Button>
                                            </div>

                                        </>

                                    </div>
                                    : null
                            }
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>

    );
};
export default SurveyIndex;
