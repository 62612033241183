import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Button, notification, Card, Pagination } from 'antd';
import ShiftTemperatureReport from '../../../../../http/ShiftTemperatureReport';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import moment from 'moment';

const ListShiftTemperatureReports = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [branch, setBranch] = useState(null);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

    useEffect(() => {
        loadReports(true); // Show big spinner for initial load
    }, [branchId, pagination.current, pagination.pageSize]);

    const loadReports = (initialLoad = false) => {
        if (initialLoad) {
            setLoading(true);
        }

        ShiftTemperatureReport.Index(
            branchId,
            {
                params: {
                    per_page: pagination.pageSize,
                    page: pagination.current,
                },
            },
            (response) => {
                if (response && response.data && response.data.data) {
                    const { data } = response.data;
                    setReports(data.reports.data);
                    setBranch(data.branch);
                    setPagination({
                        ...pagination,
                        total: data.reports.total,
                    });
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Unexpected response format while loading shift temperature reports.',
                    });
                }
                setLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load shift temperature reports.',
                });
                setLoading(false);
            }
        );
    };

    const handleTableChange = (paginationConfig) => {
        const updatedPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total,
        };

        setPagination(updatedPagination);
    };

    // Group reports by recorded_at
    const groupedReports = reports.reduce((acc, report) => {
        const recordedAt = moment(report.recorded_at).format('DD/MM/YYYY HH:mm');
        if (!acc[recordedAt]) {
            acc[recordedAt] = [];
        }
        acc[recordedAt].push(report);
        return acc;
    }, {});

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2>Shift Temperature Reports for {branch ? branch.name : 'Loading...'}</h2>
                <Link to={`/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create/${branchId}/manage`}>
                    <Button type="primary" style={{ marginBottom: '20px' }}>Add Temperature Report</Button>
                </Link>
                {Object.entries(groupedReports).map(([recordedAt, reportsGroup]) => (
                    <Card key={recordedAt} title={`Recorded At: ${recordedAt}`} style={{ marginBottom: '20px' }}>
                        <Table
                            columns={[
                                {
                                    title: 'Recorded By',
                                    dataIndex: ['user', 'name'],
                                    key: 'user',
                                },
                                {
                                    title: 'Zone',
                                    dataIndex: ['temperature_zone', 'name'],
                                    key: 'zone',
                                },
                                {
                                    title: 'Temperature',
                                    dataIndex: 'temperature',
                                    key: 'temperature',
                                    render: (temp) => `${temp} °C`,
                                },
                                {
                                    title: 'Recorded At',
                                    dataIndex: 'recorded_at',
                                    key: 'recorded_at',
                                    render: (recorded_at) => moment(recorded_at).format('DD/MM/YYYY HH:mm'),
                                },
                            ]}
                            dataSource={reportsGroup}
                            rowKey="id"
                            pagination={false}
                        />
                    </Card>
                ))}
                <Pagination
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    showSizeChanger
                    onChange={(page, pageSize) =>
                        handleTableChange({ current: page, pageSize, total: pagination.total })
                    }
                />
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default ListShiftTemperatureReports;