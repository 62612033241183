import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, notification, Tag, Spin } from 'antd';
import UserTrainingTopic from '../../../../../http/UserTrainingTopic';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import moment from 'moment';

const UserTrainingTopics = () => {
    const { trainingId } = useParams();
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [completing, setCompleting] = useState(null);
    const [userTraining, setUserTraining] = useState(null);

    useEffect(() => {
        loadTopics();
    }, [trainingId]);

    const loadTopics = () => {
        setLoading(true);
        UserTrainingTopic.Index(trainingId, (response) => {
            setTopics(response.data.data.topics);
            setUserTraining(response.data.data.user_training);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load training topics.',
            });
            setLoading(false);
        });
    };

    const handleCompleteTopic = (topicId) => {
        setCompleting(topicId);
        UserTrainingTopic.CompleteTopic(trainingId, topicId, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Training topic marked as complete.',
            });
            loadTopics();
            setCompleting(null);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: err.response?.data.message || 'Failed to mark topic as complete.',
            });
            setCompleting(null);
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                {userTraining?
                    <h2 className="text-2xl font-bold mb-4">Training Topics - {userTraining.user.name}</h2>
                :null}
                {topics.length > 0 ? (
                    topics.map(topic => (
                        <div key={topic.id} className="bg-white p-2 mb-3 px-4 rounded-lg shadow-md border border-gray-200">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col">
                                    <span className="font-semibold text-lg">{topic.training_topic.name}</span>
                                    {topic.status === 'completed' && (
                                        <span className="text-sm text-gray-500">
                                            Completed by: {topic.completed_by?.name} on {moment(topic.completed_at).format('DD/MM/YYYY HH:mm')}
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-center">
                                    {topic.status === 'pending' ? (
                                        <Button
                                            type="primary"
                                            onClick={() => handleCompleteTopic(topic.id)}
                                            loading={completing === topic.id}
                                        >
                                            Complete
                                        </Button>
                                    ) : (
                                        <Tag color="green">COMPLETED</Tag>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No training topics found.</p>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default UserTrainingTopics;