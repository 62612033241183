import React, {useEffect, useState} from 'react';
import Table from "ant-responsive-table";
import {Link} from "react-router-dom";
import router from "react-named-routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown, faEdit, faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Input, Pagination} from "antd";

const ContextsTable = ({contexts}) => {

    const [data, setData] = useState(null);
    const [search, setSearch] = useState(null);

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank_formatted',
            key: 'rank_formatted',
            sorter: (a, b) => a.rank - b.rank,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Occurrence',
            dataIndex: 'occurrence_formatted',
            key: 'occurrence_formatted',
            sorter: (a, b) => a.occurrence - b.occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Positive',
            dataIndex: 'positive_occurrence_formatted',
            key: 'positive_occurrence_formatted',
            sorter: (a, b) => a.positive_occurrence - b.positive_occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Iranian Occurrence',
            dataIndex: 'Iranian_occurrence_formatted',
            key: 'Iranian_occurrence_formatted',
            sorter: (a, b) => a.Iranian_occurrence - b.Iranian_occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Iranian Positive',
            dataIndex: 'Iranian_positive_occurrence_formatted',
            key: 'Iranian_positive_occurrence_formatted',
            sorter: (a, b) => a.Iranian_positive_occurrence - b.Iranian_positive_occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Whites Occurrence',
            dataIndex: 'Whites_occurrence_formatted',
            key: 'Whites_occurrence_formatted',
            sorter: (a, b) => a.Whites_occurrence - b.Whites_occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Whites Positive',
            dataIndex: 'Whites_positive_occurrence_formatted',
            key: 'Whites_positive_occurrence_formatted',
            sorter: (a, b) => a.Whites_positive_occurrence - b.Whites_positive_occurrence,
            showOnResponse: true,
            showOnDesktop: true
        },
    ];

    useEffect(() => {
        const ContextsDataSource = [];
        let oc = 0;
        let ioc = 0;
        let woc = 0;
        contexts.map((context) => {oc += context.occurrence; if(context.Iranian_occurrence) ioc += context.Iranian_occurrence; if(context.Whites_occurrence) woc += context.Whites_occurrence});
        console.log(oc, ioc, woc);

        contexts.map((context, index) => {
            const {id, category_full_name, occurrence, positive_occurrence, Iranian_occurrence, Iranian_positive_occurrence, Whites_occurrence, Whites_positive_occurrence, } = context;

            if(search && !category_full_name.toLowerCase().includes(search)) return;

            return ContextsDataSource.push({
                key: id,
                rank: index + 1,
                rank_formatted:
                    <div className={"patty text-lg flex flex-row items-center ml-2"} style={{lineHeight: 1}}>
                        {index+1 <= 30 &&
                            <FontAwesomeIcon icon={faCrown} className="text-xs mr-1" aria-hidden="true"/>
                        }

                        {index+1}
                    </div>,
                name: category_full_name,
                occurrence,
                occurrence_formatted: <div>{occurrence} (<b>%{(occurrence / oc * 100).toFixed(2)}</b>)</div>,
                positive_occurrence: positive_occurrence / occurrence,
                positive_occurrence_formatted: <div><b>%{(positive_occurrence / occurrence * 100).toFixed(1)}</b></div>,
                Iranian_occurrence,
                Iranian_occurrence_formatted: <div>{Iranian_occurrence} (<b>%{(Iranian_occurrence / ioc * 100).toFixed(2)}</b>)</div>,
                Iranian_positive_occurrence: !Iranian_occurrence?0:(Iranian_positive_occurrence / Iranian_occurrence),
                Iranian_positive_occurrence_formatted: <div><b>%{!Iranian_occurrence?"0.0":(Iranian_positive_occurrence / Iranian_occurrence * 100).toFixed(1)}</b></div>,
                Whites_occurrence,
                Whites_occurrence_formatted: <div>{Whites_occurrence} (<b>%{(Whites_occurrence / woc * 100).toFixed(2)}</b>)</div>,
                Whites_positive_occurrence: Whites_occurrence==0?0:(Whites_positive_occurrence / Whites_occurrence),
                Whites_positive_occurrence_formatted: <div><b>%{!Whites_occurrence?"0.0":(Whites_positive_occurrence / Whites_occurrence * 100).toFixed(1)}</b></div>,
                actions: (
                    <div className="flex flex-row items-center justify-center">
                        <div className={"px-2 text-sm font-bold rounded-sm cursor-pointer border border-gray-200 bg-gray-100"}>REVIEWS</div>
                    </div>
                ),
            });
        });
        setData(ContextsDataSource);
    }, [search]);


    const onChange = (e) => {
        setSearch(e.target.value);
    }

    return (
        <div className={"m-1 "}>
            <Input
                addonBefore="Search"
                placeholder="input search text"
                style={{
                    width: 300,
                }}
                onChange={onChange}
                allowClear
            />

            <div className={"context-report border-gray-300 rounded-lg overflow-hidden border shadow mt-2"}>
                <Table antTableProps={{dataSource: data, columns: columns,
                    pagination: {
                        defaultPageSize: 20,
                        pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200', '1000'], // Optional: Customize the available page sizes
                        showSizeChanger: true, // Optional: Show the page size changer
                    }
                }} mobileBreakPoint={1200}/>
            </div>
        </div>

    );
};

export default ContextsTable;