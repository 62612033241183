import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import FoodSkeleton from "./FoodSkeleton";
import {SpinnerCircular} from "spinners-react";

const MenuSkeleton = () => {
    return (
        <>
            <div className="py-24 w-full flex flex-col items-center justify-center opacity-70">
                <SpinnerCircular size={200} thickness={100} speed={80} color="rgba(255, 0, 0, .4)" secondaryColor="rgba(0, 0, 0, 0.14)"/>
            </div>
        </>
    )

};

export default MenuSkeleton;
