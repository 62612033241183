import React from 'react';
import DashBoardMenu from "../DashBoardMenu";
import AdminLayout from "../AdminLayout";
import {motion} from "framer-motion";
import T from "../../../../components/T";
import Can from "../../../../layouts/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const Announcements = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="w-full"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-3 items-center pr-2">
                    <div><T upper>Announcements</T></div>
                    <Can roles={["branch_manager"]}>
                        <FontAwesomeIcon icon={faPlus} className="rounded bg-black text-xl text-white px-2.5 py-2"/>
                    </Can>
                </div>
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center py-6">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab animi deserunt id reiciendis! Accusamus accusantium debitis dicta expedita iusto minus praesentium repellat vel velit. Alias aut distinctio dolorem fugit tenetur.
                    </div>
                </div>
            </div>
        </motion.div>
    );
};
export default Announcements;
