import {Button, Col, DatePicker, Divider, Form, Input, notification, Row, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import FormError from "../../../../components/Form/FormError";
import T from "../../../../components/T";
import Settings from "../../../../http/Settings";
import Bloggers from "../../../../http/Bloggers";
import {Translate} from "../../../../helpers/Translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft} from "@fortawesome/free-solid-svg-icons";
import FormItem from "../../../../components/Form/FormItem";
import {updateProfile} from "../../../../redux/profile/actionCreator";
import {SpinnerCircular} from "spinners-react";
import moment from "moment";

const {Option} = Select;
const {TextArea} = Input;

const ProfileForm = ({names}) => {

    const [validationFields, setValidationFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [settings, setSettings] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setSettings(null);
        setLoading(true);

        if(names && names.length > 0) {
            Settings.GetSettings(names,(response) => {
                setLoading(false);
                console.log("settings:", response);
                setSettings(response.data.data.settings);
            }, (err) => {
                setSettings(null);
                setLoading(false);
            });
        }

        return () => {
            setSettings(null);
        }
    }, [names]);

    const handleSubmit = () =>
    {
        console.log({...form.getFieldsValue(true)});
        setSubmitting(true);
        Settings.SaveSettings({...form.getFieldsValue(true)}, (response) => {
            setSubmitting(false);
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `Settings has been updated!`,
            });

        }, (err) => {
            setSubmitting(false);
        });
    }

    return (
        <>
            {loading ?
            <div className="p-10 flex flex-row items-center text-base uppercase justify-center w-full">
                <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="h-5 mr-2"/>
                Loading
            </div>
            :null}

            <Form form={form} onFinish={handleSubmit} layout='vertical'>

                <FormError error={error}/>

                <Row gutter='20'>

                    {settings ?
                    <Col lg={24} xs={24} className=''>
                        {settings.map(setting => {
                            if(setting.type == "string") {
                                return (<FormItem name={setting.name}
                                                  label={setting.title}
                                                  errors={validationFields}
                                                  disabled={false}
                                                  initialValue={setting.value}
                                >
                                    <Input prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={setting.title} disabled={submitting}/>
                                </FormItem>);
                            }

                            if(setting.type == "multiline") {
                                return (<FormItem name={setting.name}
                                                  label={setting.title}
                                                  errors={validationFields}
                                                  disabled={false}
                                                  initialValue={setting.value}
                                >
                                    <Input.TextArea prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={setting.title} disabled={submitting} rows={20}/>
                                </FormItem>);
                            }

                            if(setting.type == "date") {
                                return (<FormItem name={setting.name}
                                                  label={setting.title}
                                                  errors={validationFields}
                                                  disabled={false}
                                                  initialValue={moment(setting.value)}
                                >
                                    <DatePicker prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={setting.title} disabled={submitting} rows={20}/>
                                </FormItem>);
                            }
                        })}
                    </Col>
                    :null}


                    <Col xs={24} className='pt-2 flex'>
                        <div className="flex flex-row justify-between">
                            <Button htmlType='submit' size='default' type='primary' key='submit' loading={submitting} className=''>
                                <T>Update Settings</T>
                            </Button>
                        </div>
                    </Col>

                </Row>
            </Form>
        </>
    );
};

export default ProfileForm;