// src/http/BranchSettings.js

import axios from 'axios';
import {getAuthenticationHeaders} from "./Headers";

const BranchSettings = {
    getBranchSettings: (successCallback, errorCallback) => {
        axios.get('/branch-settings', getAuthenticationHeaders())
            .then(successCallback)
            .catch(errorCallback);
    },
    updateBranchSettings: (data, successCallback, errorCallback) => {
        axios.post('/branch-settings/update', { data }, getAuthenticationHeaders())
            .then(successCallback)
            .catch(errorCallback);
    },
};

export default BranchSettings;
