import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Checkbox, Button, Switch} from 'antd';
import FormItem from "../../../../../components/Form/FormItem";

const DiscountPresetModal = ({ visible, onSave, onCancel, editDiscountPreset, validationFields, saving, form }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [switchState, setSwitchState] = useState(editDiscountPreset?.status ?? false);
    const switchLabel = switchState ? "Enabled" : "Disabled";

    useEffect(() => {
        if (editDiscountPreset) {
            setIsEdit(true);
            console.log("form.setFieldsValue(editDiscountPreset);")
            form.setFieldsValue(editDiscountPreset);
        } else {
            setIsEdit(false);
            console.log("form.resetFields();")
            form.resetFields();
        }
    }, [editDiscountPreset, form]);


    const onSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        form
            .validateFields()
            .then(values => {
                onSave(values, isEdit);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title={isEdit ? "Edit DiscountPreset" : "Create DiscountPreset"}
            okText={isEdit ? "Save" : "Create"}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={onSubmit}
            confirmLoading={saving}
            key={editDiscountPreset ? `edit-${editDiscountPreset.id}` : 'create'}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                key={editDiscountPreset ? `form-edit-${editDiscountPreset.id}` : 'form-create'}
            >

                <FormItem
                    name="name"
                    label="Discount Preset Name"
                    errors={validationFields}
                    initialValue={editDiscountPreset?.name}
                    rules={[{ required: true, message: 'Please input the full name!' }]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="percents"
                    label="Percents"
                    errors={validationFields}
                    initialValue={editDiscountPreset?.email}
                    rules={[{ required: true, message: 'Please input the percents!' }]}
                >
                    <Input min={0} />
                </FormItem>
                <FormItem
                    name="max_total_price"
                    label="Max Total Price"
                    errors={validationFields}
                    rules={[{ required: !isEdit, message: 'Please input the password!' }]}
                    initialValue={""}
                >
                    <Input type="text" autoComplete={null} />
                </FormItem>

                <FormItem
                    name="max_coupon_price"
                    label="Max Coupon Price"
                    errors={validationFields}
                    rules={[{ required: !isEdit, message: 'Please input the password!' }]}
                    initialValue={""}
                >
                    <Input min={0} autoComplete={null} />
                </FormItem>

                <FormItem
                    name="one_item"
                    valuePropName="checked"
                    initialValue={editDiscountPreset?.one_item ?? false}
                >
                    <div className="flex flex-row items-center">
                        <Switch
                            defaultChecked={editDiscountPreset?.one_item ?? false}
                            onChange={(checked) => form.setFieldsValue({ one_item: checked })}
                        />
                        <label htmlFor="one_item" className="ml-3">One Item</label>
                    </div>
                </FormItem>

                <FormItem
                    name="status"
                    valuePropName="checked"
                    initialValue={editDiscountPreset?.status ?? false}
                >
                    <div className="flex flex-row items-center">
                        <Switch
                            defaultChecked={editDiscountPreset?.status ?? false}
                            onChange={(checked) => form.setFieldsValue({ status: checked })}
                        />
                        <label htmlFor="status" className="ml-3">Status</label>
                    </div>
                </FormItem>

            </Form>
        </Modal>
    );
};

export default DiscountPresetModal;