import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Deliverable = {

    Request: (data, successCb, failCb) => {
        axios.post(`/deliverable/request`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateRequest: (deliverable_id, data, successCb, failCb) => {
        axios.post(`/deliverable/${deliverable_id}/request`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Respond: (deliverable_id, data, successCb, failCb) => {
        axios.post(`/deliverable/${deliverable_id}/respond`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Deliverable;
