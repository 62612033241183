import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import actions from "../../redux/checkout/actions";
import {useHistory} from "react-router";
import {Button, Collapse, Divider, Form} from 'antd';
import {roundToTwo} from "../../services/global";
import {SetRedirectSource} from "../../redux/authentication/actionCreator";
import StripeContainer from "./StripeContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAppleAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import ApplePayContainer from "./ApplePayContainer";
import {motion} from "framer-motion";
import T from "../../components/T";
import StripeContainer2 from "./StripeContainer2";

const {Panel} = Collapse;

const {
    checkoutStoreReset,
} = actions;

export default function Payment() {
    const dispatch = useDispatch();
    const history = useHistory();
    const checkout = useSelector(state => state.checkout.create_payment_checkout);
    const user = useSelector(state => state.auth.user);
    const [form] = Form.useForm();

    const [applePayInProgress, setApplePayInProgress] = useState(false);
    const [cardPayInProgress, setCardPayInProgress] = useState(false);
    const [applePaySupported, setApplePaySupported] = useState(false);


    const handleIncomingMessages = (message) => {
        let data = message.data;
        if (data && typeof data === "string") {
            if (data == "cancel-applepay") {
                setApplePayInProgress(false);
            }
        }
    }

    useEffect(() => {

        window.addEventListener("message", handleIncomingMessages);

        if (localStorage.getItem("applepay") == "true")
            setApplePaySupported(true);
        else
            setApplePaySupported(false);

        if (!checkout) {
            history.push("/cart");
        } else {
            window.sendMessageToSwift("checkout:true")
        }

        return () => {
            window.sendMessageToSwift("checkout:false");
            window.removeEventListener("message", handleIncomingMessages);
        }
    }, []);

    const login = () => {
        dispatch(SetRedirectSource("/checkout"));
        history.push("/login");
    }

    const onPay = () => {
        //console.log("createPayment params:", checkout.id, {...form.getFieldsValue(true)});
        //dispatch(CreatePayment(checkout.id, {...form.getFieldsValue(true)}));
        setApplePayInProgress(true);
        if (checkout) {
            window.sendMessageToSwift(`applepay:${checkout.uuid},${checkout.due_amount}`)
        }
    }

    return (
        <>
            {checkout ?
                <div className="mt-4 mx-2">
                    <div className="row">
                        <div className="container max-w-lg flex flex-col mx-auto p-5 rounded-xl border shadow-md bg-white flex flex-col">
                            <div className="w-full py-4 border-b pb-2 bg-gray-100 rounded-lg">
                                <div className="row flex flex-col mb-2 items-center">
                                    <span className="flex-1 uppercase"><T>Order Total</T></span>
                                    <span className="text-3xl font-extrabold">${roundToTwo(checkout.due_amount)}</span>
                                </div>
                            </div>

                            {/*
                            <ApplePayContainer />
                            */}

                            {applePaySupported && false ?
                                <div className="">
                                    <Button
                                        type="primary" shape="round" size="large" className="text-white text-lg font-bold py-4 px-4 rounded-xl focus:outline-none focus:shadow-outline mt-4 flex flex-row flex-1 items-center block w-full justify-center"
                                        style={{display: "flex", backgroundColor: "black", border: "black", justifyContent: "center"}}
                                        onClick={onPay}
                                        loading={applePayInProgress}
                                        disabled={cardPayInProgress}
                                    >
                                        <div className="text-white hover:text-white flex-1 text-center flex flex-row items-center justify-center w-full text-base">
                                            <span className="text-base">Check out with</span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 64 64"
                                                style={{fill: "#FFFFFF"}}
                                                className="mx-1"
                                            >
                                                <path d="M54.166,19.783c-0.258,0.162-6.401,3.571-6.401,11.13c0.29,8.621,7.752,11.644,7.88,11.644	c-0.128,0.162-1.127,4.119-4.085,8.267C49.213,54.398,46.607,58,42.65,58c-3.764,0-5.115-2.381-9.458-2.381	c-4.664,0-5.984,2.381-9.555,2.381c-3.957,0-6.756-3.795-9.232-7.335c-3.216-4.633-5.95-11.903-6.047-18.883	c-0.065-3.699,0.644-7.335,2.444-10.423c2.541-4.312,7.077-7.238,12.031-7.335c3.795-0.128,7.173,2.606,9.49,2.606	c2.22,0,6.37-2.606,11.065-2.606C45.415,14.026,50.82,14.636,54.166,19.783z M32.002,13.285c-0.676-3.378,1.19-6.756,2.927-8.911	C37.149,1.769,40.655,0,43.678,0c0.193,3.378-1.03,6.691-3.216,9.104C38.5,11.71,35.122,13.671,32.002,13.285z"/>
                                            </svg>
                                            <span className="text-xl"><T>Pay</T></span>
                                        </div>
                                    </Button>
                                    <Divider>OR</Divider>
                                </div>
                                : null}

                            <div className="row flex flex-wrap flex-col mt-1 bg-gray-100 p-5 rounded-lg border-b mt-4">
                                <StripeContainer applePayInProgress={applePayInProgress} cardPayInProgress={cardPayInProgress} setCardPayInProgress={setCardPayInProgress}/>
                            </div>

                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}