import React, {useEffect, useState} from 'react';

const StatsInfo = ({title, data, level=0, isPercent=false}) => {

    const [bg, setBg] = useState('bg-white')
    const [textColor, setTextColor] = useState('text-black')

    useEffect(() => {
        if(isPercent) {
            const p = parseFloat(data.replace('%', ''));
            if(p > 10) {setBg('bg-green-100'); setTextColor('text-black')}
            if(p > 20) {setBg('bg-green-200'); setTextColor('text-black')}
            if(p > 30) {setBg('bg-green-300'); setTextColor('text-black')}
            if(p > 40) {setBg('bg-green-400'); setTextColor('text-black')}
            if(p > 50) {setBg('bg-green-500'); setTextColor('text-black')}
            if(p > 60) {setBg('bg-green-600'); setTextColor('text-white')}
            if(p > 70) {setBg('bg-green-700'); setTextColor('text-white')}
            if(p > 80) {setBg('bg-green-800'); setTextColor('text-white')}
            if(p > 90) {setBg('bg-green-900'); setTextColor('text-white')}
        }
    }, [data, level]);

    return (
        <div className={`flex flex-col items-center justify-center ${bg} ${textColor} m-1 rounded-xl p-1 px-3 border-black border-2 text-base 2xl:text-lg flex-1`}>
            <div className="font-bold whitespace-nowrap">
                {title}
            </div>
            <div className={"w-full flex flex-row items-center justify-center"}>
                {data}
            </div>
        </div>
    );
};

export default StatsInfo;