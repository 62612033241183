import React, {useEffect, useState} from 'react';
import {Form, Pagination, Select} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {editProfile, resetEditProfile, updateProfile} from '../../../redux/profile/actionCreator';
import SkeletonWrapper from '../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../components/skeletons/GenericSkeleton';
import ProfileForm from './Form';
import FormError from '../../../components/Form/FormError';
import UserMenu from "./UserMenu";
import MenuSkeleton from "../../../components/skeletons/MenuSkeleton";
import OrderRow from "./OrderRow";
import {motion} from "framer-motion";
import T from "../../../components/T";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMedal} from "@fortawesome/free-solid-svg-icons";

const {Option} = Select;

const levels = {1: 'Bronze', 2: 'Silver', 3: 'Gold', 4: 'Platinum', 5: 'Diamond'}
const CustomerClub = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(state => state.auth.user);

    const convertLevel = (level) => {
        if(levels.hasOwnProperty(level)) return levels[level];
        else return levels[1];
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className="row"
        >
            <div className="md:container flex flex-col mx-auto pb-10 pt-5 px-4">
                <h1 className="text-4xl font-bold text-black"><T upper>Club</T></h1>
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-2xl flex-1 border shadow-xl bg-white p-6 flex flex-col text-center text-2xl">
                    {user.points_balance > 0 ?
                        <div>
                            <div className={"text-6xl font-bold mb-2 flex flex-row items-center justify-center"}>
                                <FontAwesomeIcon icon={faMedal} className="block text-5xl mr-1" aria-hidden="true"/>
                                {user.points_balance}
                            </div> POINTS EARNED
                        </div>
                    :
                        <div className="text-sm md:text-lg">YOU HAVE NO POINTS.</div>
                    }

                    <div className="hidden px-3 py-2 uppercase mt-5 rounded-lg bg-black text-white text-3xl" style={{letterSpacing: "-.1vw", fontFamily: "PattySlaps"}}>
                        Your level: {convertLevel(user.level)}
                    </div>

                    </div>
                </div>
            </div>
        </motion.div>

    );
};

export default CustomerClub;
