import React, {useEffect, useRef, useState} from 'react';
import {motion} from "framer-motion";
import UserModal from "../Employees/UserModal";
import T from "../../../../components/T";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import BranchSelector from "../BranchSelector";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import {Button, Tag} from "antd";
import {useSelector} from "react-redux";
import Employees from "../../../../http/Employees";
import Training from "../../../../http/Training";

const TrainingNodeCreate = ({node, reload}) => {
    const [creating, setCreating] = useState(false);
    const [name, setName] = useState(null);
    const [storing, setStoring] = useState(false);

    const onStore = () => {
        setStoring(true);
        Training.Store(node?.id || 0, {name}, (response) => {
            reload();
            setCreating(false);
            setStoring(false);
            setName("");
        }, (err) => {
            reload();
            setCreating(false);
            setStoring(false);
            setName("");
        });
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full flex flex-col`}
        >
            <div className={"flex flex-row items-center px-2 rounded-lg"}>
                {!creating?
                    <div onClick={() => {setCreating(true)}}  className="text-sm cursor-pointer flex flex-row items-center">
                        <FontAwesomeIcon icon={faPlusCircle} className={"text-green-800"} />
                        {!node && <span className={"patty text-xl pl-2"}>Create a new root</span>}
                    </div>
                :
                    <div className={`ml-2 flex flex-row items-center px-2 bg-gray-300 py-2 whitespace-nowrap`}>
                        <div className={"mr-2 font-bold"}>Create a training: </div>
                        <input type={"text"} name={'name'}  value={name} className={"patty text-lg py-1 w-64 border border-gray-400 px-2"} placeholder={"Training Name..."} onChange={(e) => setName(e.target.value)} />
                        <Button type={"primary"} loading={storing} className={"ml-2 bg-gray-800 text-white px-5 py-0 rounded-sm "} onClick={onStore} >Create</Button>
                    </div>
                }
            </div>
        </motion.div>


    );
};
export default TrainingNodeCreate;
