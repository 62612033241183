import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, notification, Button } from 'antd';
import FormHandler from '../../../../http/FormHandler';

const FormSubmissions = () => {
    const { id } = useParams();
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchSubmissions();
    }, [id]);

    const fetchSubmissions = () => {
        FormHandler.getFormSubmissions(id, (response) => {
            setSubmissions(response.data.data);
            setLoading(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '',
            key: 'first_field',
            render: (_, record) => {
                const data = record.data;
                const firstField = Object.keys(data)[0];
                return `${data[firstField]}`;
            },
        },
        {
            title: '',
            key: 'second_field',
            render: (_, record) => {
                const data = record.data;
                const secondField = Object.keys(data)[1];
                return `${data[secondField]}`;
            },
        },
        {
            title: 'Submitted At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Link to={`/dashboard/forms/${id}/submissions/${record.id}`}>
                    <Button type="primary">View</Button>
                </Link>
            ),
        },
    ];

    return (
        <div className="p-4">
            <h2 className="text-2xl mb-4">Form Submissions</h2>
            <Table
                columns={columns}
                dataSource={submissions}
                rowKey="id"
                loading={loading}
                pagination={false}
            />
        </div>
    );
};

export default FormSubmissions;
