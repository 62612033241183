import React, { useEffect, useState } from 'react';
import { Table, Button, Switch, Select, notification } from 'antd';
import BranchSettings from '../../../../http/BranchSettings';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const { Option } = Select;

const BranchSettingsComponent = () => {
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [branchSettings, setBranchSettings] = useState([]);

    useEffect(() => {
        fetchBranchSettings();
    }, []);

    const fetchBranchSettings = () => {
        setLoading(true);
        BranchSettings.getBranchSettings((response) => {
            setBranches(response.data.data.branches);
            const settings = response.data.data.branches.map(branch => ({
                id: branch.id,
                name: branch.name,
                status: branch.status,
                coef_branch_id: branch.coef_branch_id || 0,
                model_branch_id: branch.model_branch_id || 0,
            }));
            setBranchSettings(settings);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load branch settings',
            });
            setLoading(false);
        });
    };

    const handleChange = (branchId, field, value) => {
        const updatedSettings = branchSettings.map(branch =>
            branch.id === branchId ? { ...branch, [field]: value } : branch
        );
        setBranchSettings(updatedSettings);
    };

    const saveChanges = () => {
        setSaving(true);
        const changes = branchSettings.map(branch => ({
            id: branch.id,
            coef_branch_id: branch.coef_branch_id,
            model_branch_id: branch.model_branch_id,
            status: branch.status,
        }));
        BranchSettings.updateBranchSettings(changes, (response) => {
            notification.success({
                message: 'Success',
                description: 'Branch settings updated successfully.',
            });
            setSaving(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to update branch settings',
            });
            setSaving(false);
        });
    };

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Switch
                    checked={record.status === 1}
                    onChange={(checked) => handleChange(record.id, 'status', checked ? 1 : 0)}
                />
            ),
        },
        {
            title: 'Coefficient Branch',
            dataIndex: 'coef_branch_id',
            key: 'coef_branch_id',
            render: (text, record) => (
                <Select
                    value={record.coef_branch_id === 0 ? 'Not Selected' : record.coef_branch_id}
                    onChange={(value) => handleChange(record.id, 'coef_branch_id', value)}
                    style={{ width: '100%' }}
                >
                    <Option value={0}>Not Selected</Option>
                    {branches.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Model Branch',
            dataIndex: 'model_branch_id',
            key: 'model_branch_id',
            render: (text, record) => (
                <Select
                    value={record.model_branch_id === 0 ? 'Not Selected' : record.model_branch_id}
                    onChange={(value) => handleChange(record.id, 'model_branch_id', value)}
                    style={{ width: '100%' }}
                >
                    <Option value={0}>Not Selected</Option>
                    {branches.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                    ))}
                </Select>
            ),
        },
    ];

    return (
        <DefaultContainer>
            <div className={"patty text-2xl font-bold mb-4"}>Branch Settings</div>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={branchSettings}
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                />
                <Button type="primary" onClick={saveChanges} loading={saving} style={{ marginTop: '20px' }}>
                    Save Changes
                </Button>
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default BranchSettingsComponent;
