import React from 'react';
import Option from "./Option";

const Options = ({options}) => {
    return (
        <div className="w-full flex flex-wrap" style={{marginBottom: "150px", marginTop: "5px"}} >
            {options.map((option, index) => {
                return (
                    <Option option={option} index={index}/>
                )
            })}
        </div>
    );
};

export default Options;
