import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Button, notification, Rate } from 'antd';
import TrainingEvaluation from '../../../../../http/TrainingEvaluation';
import DefaultContainer from '../../../../../components/DefaultContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';

const CreateTrainingEvaluation = () => {
    const { userTrainingId } = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [trainee, setTrainee] = useState(null);
    const [trainedTopics, setTrainedTopics] = useState([]);

    useEffect(() => {
        TrainingEvaluation.Create(userTrainingId, (response) => {
            const { user_training, trained_topics } = response.data.data;
            setTrainee(user_training);
            setTrainedTopics(trained_topics);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to load evaluation details.',
            });
            setLoading(false);
        });
    }, [userTrainingId]);

    const onFinish = (values) => {
        setSaving(true);
        TrainingEvaluation.Store(userTrainingId, values, (response) => {
            notification.success({
                message: 'Success!',
                description: 'Evaluation submitted successfully.',
            });
            history.push(`/dashboard/hr/training-evaluation/${userTrainingId}/topics`);
            setSaving(false);
        }, (err) => {
            notification.error({
                message: 'Error!',
                description: 'Failed to submit evaluation.',
            });
            setSaving(false);
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2 className="text-2xl font-bold mb-4">Training Evaluation for {trainee ? trainee.name : 'Loading...'}</h2>
                {trainedTopics.length > 0 && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold">Trained Topics:</h3>
                        <ul className="list-disc ml-5">
                            {trainedTopics.map((topic) => (
                                <li key={topic}>{topic}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="punctuality"
                        label="Punctuality (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for punctuality' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="understanding_of_role"
                        label="Understanding of Role (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for understanding of role' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="task_execution_efficiency"
                        label="Task Execution Efficiency (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for task execution efficiency' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="hygiene"
                        label="Hygiene (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for hygiene' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="motivation_self_improvement"
                        label="Motivation and Self-Improvement (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for motivation and self-improvement' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="overall_performance"
                        label="Overall Performance (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for overall performance' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="readiness"
                        label="Readiness to Fulfill Role (Score out of 5)"
                        rules={[{ required: true, message: 'Please enter the score for readiness' }]}
                    >
                        <Rate count={5} />
                    </Form.Item>
                    <Form.Item
                        name="comments"
                        label="Additional Comments"
                    >
                        <Input.TextArea rows={4} placeholder="Enter any additional comments about the trainee" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={saving}>Submit Evaluation</Button>
                    </Form.Item>
                </Form>
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default CreateTrainingEvaluation;