import actions from './actions';

const {

  PROFILES_EDIT_RESET,
  PROFILES_EDIT_BEGIN,
  PROFILES_EDIT_SUCCESS,
  PROFILES_EDIT_ERR,

  PROFILES_UPDATE_BEGIN,
  PROFILES_UPDATE_SUCCESS,
  PROFILES_UPDATE_ERR,

  PASSWORD_EDIT_RESET,
  PASSWORD_UPDATE_BEGIN,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_ERR,

  ORDERS_INDEX_BEGIN,
  ORDERS_INDEX_SUCCESS,
  ORDERS_INDEX_ERR,

  REQUESTS_INDEX_BEGIN,
  REQUESTS_INDEX_SUCCESS,
  REQUESTS_INDEX_ERR,

  OFFLINE_ORDERS_INDEX_BEGIN,
  OFFLINE_ORDERS_INDEX_SUCCESS,
  OFFLINE_ORDERS_INDEX_ERR,

} = actions;

const initState = {
  edit_loading: false,
  edit_profile: null,
  edit_categories: null,
  edit_error: null,

  update_loading: false,
  update_profile: null,
  update_fields: null,
  update_error: null,

  update_password_loading: false,
  update_password_fields: null,
  update_password_error: null,

  orders_loading: false,
  orders_error: null,
  orders_success: null,
  orders: null,

  requests_loading: false,
  requests_error: null,
  requests_success: null,
  requests: null,


};

/**
 *
 * @todo impure state mutation/explaination
 */
const ProfileReducer = (state = initState, action) => {
  const { type, profile, error, fields, orders, requests } = action;
  switch (type) {

    case PROFILES_EDIT_BEGIN:
      return {
        ...state,
        edit_loading: true,
        edit_error: null
      };
    case PROFILES_EDIT_SUCCESS:
      return {
        ...state,
        edit_loading: false,
        edit_profile: profile
      };
    case PROFILES_EDIT_ERR:
      return {
        ...state,
        edit_loading: false,
        edit_error: error,
      };

    case PROFILES_EDIT_RESET:
      return {
        ...state,
        edit_loading: false,
        edit_profile: null,
        edit_error: null,
        update_loading: false,
        update_profile: null,
        update_fields: null,
        update_error: null,
      };

      case PROFILES_UPDATE_BEGIN:
      return {
        ...state,
        update_loading: true,
        update_error: null,
        update_fields: null
      };
    case PROFILES_UPDATE_SUCCESS:
      return {
        ...state,
        update_loading: false,
        update_profile: profile,
      };
    case PROFILES_UPDATE_ERR:
      return {
        ...state,
        update_loading: false,
        update_error: error,
        update_fields: fields,
      };

    case PASSWORD_EDIT_RESET:
      return {
        ...state,
        update_password_loading: false,
        update_password_error: null,
        update_password_fields: null,
      };

    case PASSWORD_UPDATE_BEGIN:
      return {
        ...state,
        update_password_loading: true,
        update_password_error: null,
        update_password_fields: null
      };
    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        update_password_loading: false,
      };
    case PASSWORD_UPDATE_ERR:
      return {
        ...state,
        update_password_loading: false,
        update_password_error: error,
        update_password_fields: fields,
      };

    case ORDERS_INDEX_BEGIN:
      return {
        ...state,
        orders_loading: true,
        orders_success: false,
        orders_error: null,
        orders: null,
      };
    case ORDERS_INDEX_SUCCESS:
      return {
        ...state,
        orders_loading: false,
        orders_success: true,
        orders,
      };
    case ORDERS_INDEX_ERR:
      return {
        ...state,
        orders_loading: false,
        orders_success: false,
        orders_error: error,
      };


    case REQUESTS_INDEX_BEGIN:
      return {
        ...state,
        requests_loading: true,
        requests_success: false,
        requests_error: null,
        requests: null,
      };
    case REQUESTS_INDEX_SUCCESS:
      return {
        ...state,
        requests_loading: false,
        requests_success: true,
        requests,
      };
    case REQUESTS_INDEX_ERR:
      return {
        ...state,
        requests_loading: false,
        requests_success: false,
        requests_error: error,
      };


    default:
      return state;
  }
};
export default ProfileReducer;
