import React from 'react';
import {Link} from "react-router-dom";

const ConditionalLink = ({children, to, enabled, className}) => {
    return (
        enabled?
            <Link to={to} className={className}>
                {children}
            </Link>
        :
            children
    );
};

export default ConditionalLink;