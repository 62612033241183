import React, {useEffect, useState} from 'react';
import { Input } from 'antd';

const ItemComponent = ({ id, title, description, onUpdate }) => {
    const [editTitle, setEditTitle] = useState(title);
    const [editDescription, setEditDescription] = useState(description);


    // Update parent when editing is done
    const handleUpdate = () => {
        onUpdate(id, editTitle, editDescription);
    };

    console.log(`re-render of item ${id}`)

    return (
        <div className="border p-4 mb-4">
            <Input
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                onBlur={handleUpdate} // Update when input is blurred (focus lost)
                placeholder="Title"
            />
            <Input.TextArea
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                onBlur={handleUpdate} // Update when textarea is blurred (focus lost)
                placeholder="Description"
                className="mt-2"
            />
        </div>
    );
};

export default ItemComponent;
