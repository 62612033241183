import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Coefficients = {
    getCoefficients: (branchId, successCb, failCb) => {
        axios.get(`/coefficients/${branchId}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    recalculateCoefficients: (branchId, successCb, failCb) => {
        axios.post(`/coefficients/recalculate/${branchId}`, {}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    }
};

export default Coefficients;
