import React, { createContext, useState } from 'react';

// Create the global context
export const GlobalContext = createContext();

// Create a provider component that will wrap your app
export const GlobalProvider = ({ children }) => {
    // Define the global state variable
    const [globalValue, setGlobalValue] = useState(false);

    // Define a function to update the global value
    const updateGlobalValue = (newValue) => {
        setGlobalValue(newValue);
    };

    // Provide the global state and update function to the child components
    return (
        <GlobalContext.Provider value={{ globalValue, updateGlobalValue }}>
            {children}
        </GlobalContext.Provider>
    );
};