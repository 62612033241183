const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  REGISTER_BEGIN: 'REGISTER_BEGIN',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERR: 'REGISTER_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  REDIRECT_SUCCESS: 'REDIRECT_SUCCESS',
  SET_REDIRECT_SOURCE: 'SET_REDIRECT_SOURCE',

  PROFILE_UPDATE_BEGIN: 'PROFILE_UPDATE_BEGIN',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',

  SENDFORGOTPASSWORD_RESET: 'SENDFORGOTPASSWORD_RESET',
  SENDFORGOTPASSWORD_BEGIN: 'SENDFORGOTPASSWORD_BEGIN',
  SENDFORGOTPASSWORD_SUCCESS: 'SENDFORGOTPASSWORD_SUCCESS',
  SENDFORGOTPASSWORD_ERR: 'SENDFORGOTPASSWORD_ERR',

  RESETPASSWORD_RESET: 'RESETPASSWORD_RESET',
  RESETPASSWORD_BEGIN: 'RESETPASSWORD_BEGIN',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
  RESETPASSWORD_ERR: 'RESETPASSWORD_ERR',

  RESET_AUTH_ERRORS: 'RESET_AUTH_ERRORS',
  UPDATE_TOTAL_UNAPPROVED_COUPONS: 'UPDATE_TOTAL_UNAPPROVED_COUPONS',

  setRedirectSource: (source) => {
    return {
      type: actions.SET_REDIRECT_SOURCE,
      source,
    };
  },

  redirectSuccess: () => {
    return {
      type: actions.REDIRECT_SUCCESS,
    };
  },

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (user, token) => {
    return {
      type: actions.LOGIN_SUCCESS,
      user,
      token,
    };
  },

  loginErr: (error, fields) => {
    return {
      type: actions.LOGIN_ERR,
      error,
      fields,
    };
  },

  registerBegin: () => {
    return {
      type: actions.REGISTER_BEGIN,
    };
  },

  registerSuccess: (user, token) => {
    return {
      type: actions.REGISTER_SUCCESS,
      user,
      token,
    };
  },

  registerErr: (error, fields) => {
    return {
      type: actions.REGISTER_ERR,
      error,
      fields,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: () => {
    return {
      type: actions.LOGOUT_SUCCESS,
    };
  },

  logoutErr: error => {
    return {
      type: actions.LOGOUT_ERR,
      error,
    };
  },

  updateAuthProfileBegin: () => {
    return {
      type: actions.PROFILE_UPDATE_BEGIN
    };
  },

  updateAuthProfileSuccess: (user) => {
    return {
      type: actions.PROFILE_UPDATE_SUCCESS,
      user
    };
  },

  updateAuthProfileError: (error) => {
    return {
      type: actions.PROFILE_UPDATE_ERROR,
      error
    };
  },


  sendForgotPasswordRst: () => {
    return {
      type: actions.SENDFORGOTPASSWORD_RESET,
    };
  },

  sendForgotPasswordBegin: () => {
    return {
      type: actions.SENDFORGOTPASSWORD_BEGIN,
    };
  },

  sendForgotPasswordSuccess: () => {
    return {
      type: actions.SENDFORGOTPASSWORD_SUCCESS,
    };
  },

  sendForgotPasswordErr: (error, fields) => {
    return {
      type: actions.SENDFORGOTPASSWORD_ERR,
      error,
      fields,
    };
  },


  resetPasswordRst: () => {
    return {
      type: actions.RESETPASSWORD_RESET,
    };
  },
  resetPasswordBegin: () => {
    return {
      type: actions.RESETPASSWORD_BEGIN,
    };
  },

  resetPasswordSuccess: () => {
    return {
      type: actions.RESETPASSWORD_SUCCESS,
    };
  },

  resetPasswordErr: (error, fields) => {
    return {
      type: actions.RESETPASSWORD_ERR,
      error,
      fields,
    };
  },

  resetAuthErrors: () => {
    return {
      type: actions.RESET_AUTH_ERRORS,
    }
  },

  updateTotalUnapprovedCoupons: (total_unapproved_coupons) => {
    return {
      type: actions.UPDATE_TOTAL_UNAPPROVED_COUPONS,
      total_unapproved_coupons
    }
  }

};

export default actions;
