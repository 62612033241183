import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SpinnerCircular} from "spinners-react";
import {useHistory} from "react-router-dom";
import {EmptyCart} from "../../redux/cart/actionCreator";
import {motion} from "framer-motion";


const BranchesIndex = () => {
    const index_branches = useSelector(state => state.menu.index_branches);
    const history = useHistory();
    const dispatch = useDispatch();
    let branch_guid = localStorage.getItem('branch_guid');


    console.log("index_branches", index_branches);

    useEffect(() => {
        //localStorage.removeItem("branch_guid");
        //TODO: remove this to make the branches available for multibranch system
        if(index_branches) {
            const branch = index_branches.find(branch => branch.id == 2);
            moveToBranch(branch);
        }
    }, [index_branches]);

    const moveToBranch = (branch) => {
        if (branch.coming_soon) return;
        if (branch_guid != branch.guid) {
            dispatch(EmptyCart());
        }
        localStorage.setItem("branch_guid", branch.guid);
        setTimeout(() => {
            history.push(`/menu`);
        }, 100);
    }

    return null;

    return (

        <section className="bg-white">
            <motion.div
                initial={{opacity: 1, y: -200}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 1, y: -500}}
                style={{zIndex: 10001}}
            >
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md flex flex-col items-center justify-center">
                    <h2 className="mb-5 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white uppercase patty">
                        SELECT YOUR BRANCH
                    </h2>

                    {index_branches ?
                        <>
                            {index_branches.map(branch => {
                                return (
                                    <div className={`w-64 border-4 border-black rounded-md bg-gray-100 mb-5 flex flex-col items-center justify-center py-5 patty ${branch.coming_soon ? 'opacity-60' : 'cursor-pointer'} `} onClick={() => moveToBranch(branch)}>
                                        <div className={"text-3xl text-center font-bold text-black"}>
                                            {branch.display_name}
                                        </div>
                                        {branch.coming_soon ?
                                            <div className={"text-2xl text-red-800 font-bold"}>
                                                COMING SOON
                                            </div>
                                            : null}
                                        <div className={"text-center"}>
                                            {branch.address}
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        : <SpinnerCircular size={100} thickness={50} speed={40} color="rgba(255, 0, 0, .8)" secondaryColor="rgba(255, 255, 255, 0.74)"/>}
                </div>
            </motion.div>
        </section>
    );
};

export default BranchesIndex;