import React, {useState} from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useElements,
    useStripe, AddressElement,
} from '@stripe/react-stripe-js';

import {logEvent} from '../../components/Stripe/util';
import '../../components/Stripe/styles/common.css';

import {Alert, Button, Divider} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation, faUserTie} from "@fortawesome/free-solid-svg-icons";
import Checkout from "../../http/Checkout";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {EmptyCart} from "../../redux/cart/actionCreator";
import T from "../../components/T";

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '18px',
            color: '#424770',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

const CheckoutForm = ({applePayInProgress, cardPayInProgress, setCardPayInProgress}) => {
    const elements = useElements();
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const checkout = useSelector(state => state.checkout.create_payment_checkout);
    const history = useHistory();
    const dispatch = useDispatch();

    const setAsLoading = (isLoading, card=null) => {
        if(isLoading) {
            if(card) card.update({disabled: true});
            setCardPayInProgress(true);
        }
        else {
            if(card) card.update({disabled: false});
            setCardPayInProgress(false);
        }
    }

    const handleSubmit = async (event) => {

        setAsLoading(true);
        setErrorMessage(null);

        event.preventDefault();

        if (!stripe || !elements) {
            setAsLoading(false);
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const addressElement = elements.getElement(AddressElement);
        const {complete, value} = await addressElement.getValue();

        if(!complete) {
            setAsLoading(false);
            return;
        }

        setAsLoading(true);

        if (card == null) {
            setAsLoading(false, card);
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: value
        });

        if (payload.error) {
            console.log('[error]', payload.error);
            setErrorMessage(payload.error.message);
            setPaymentMethod(null);
            setAsLoading(false, card);
        } else {
            console.log('[PaymentMethod]', payload.paymentMethod);

            if(!checkout) {
                history.push("/cart");
            }

            Checkout.WebStripePay(checkout.uuid, payload.paymentMethod.id,(response) => {
                history.push("/order/" + checkout.uuid);
                dispatch(EmptyCart());
            }, (err) => {
                setErrorMessage(err?.response?.data?.message ?? "No internet connection!");
                setAsLoading(false, card);
            });

        }
    };

    return (
        <form onSubmit={handleSubmit}>

            {errorMessage ?
                <div className="mb-3">
                    <Alert
                        className='mb-5 font-bold'
                        showIcon
                        icon={<FontAwesomeIcon icon={faTriangleExclamation} className="text-2xl" aria-hidden="true"/>}
                        message={errorMessage}
                        description=''
                        type='error'
                    />

                </div>
            : null}

            <label htmlFor="cardNumber" className="font-bold">Card Number</label>
            <CardNumberElement
                id="cardNumber"
                onBlur={logEvent('blur')}
                onChange={logEvent('change')}
                onFocus={logEvent('focus')}
                onReady={logEvent('ready')}
                options={ELEMENT_OPTIONS}
            />
            <label htmlFor="expiry" className="font-bold">Card Expiration</label>
            <CardExpiryElement
                id="expiry"
                onBlur={logEvent('blur')}
                onChange={logEvent('change')}
                onFocus={logEvent('focus')}
                onReady={logEvent('ready')}
                options={ELEMENT_OPTIONS}
            />
            <label htmlFor="cvc" className="font-bold">CVC</label>
            <CardCvcElement
                id="cvc"
                onBlur={logEvent('blur')}
                onChange={logEvent('change')}
                onFocus={logEvent('focus')}
                onReady={logEvent('ready')}
                options={ELEMENT_OPTIONS}
            />


            <label htmlFor="address" className="font-bold">Billing Address</label>
            <AddressElement
                id="address"
                onBlur={logEvent('blur')}
                onChange={logEvent('change')}
                onFocus={logEvent('focus')}
                onReady={logEvent('ready')}
                options={{mode: "billing",allowedCountries:["canada"]}}
            />

            <Button
                type="primary" shape="round" size="large" className="text-white text-xl font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-2 flex flex-row flex-1 items-center block w-full"
                style={{display: "flex", backgroundColor: "black", border: "black"}}
                onClick={handleSubmit}
                loading={cardPayInProgress}
                disabled={applePayInProgress}
            >
                <div className="text-white hover:text-white flex-1 flex w-full justify-center items-center"><T upper>Pay</T></div>
            </Button>

        </form>
    );
};

const StripeContainer = ({applePayInProgress, cardPayInProgress, setCardPayInProgress}) => {
    return (
        <CheckoutForm
            applePayInProgress={applePayInProgress}
            cardPayInProgress={cardPayInProgress}
            setCardPayInProgress={setCardPayInProgress}
        />
    );
};

export default StripeContainer;