import actions from './actions';
import Checkout from '../../http/Checkout';
import push from '../../history';
import Profile from "../../http/Profile";
import {logOut, partiallyUpdateAuthProfile} from "../authentication/actionCreator";
import store from "../store";

const {
    checkoutReset,
    checkoutStoreReset,
    checkoutStoreBegin,
    checkoutStoreSuccess,
    checkoutStoreError,

    applyCouponBegin,
    applyCouponSuccess,
    applyCouponError,

    clearCouponBegin,
    clearCouponSuccess,
    clearCouponError,

    createPaymentBegin,
    createPaymentSuccess,
    createPaymentError,

    checkoutValidateReset,
    checkoutValidateBegin,
    checkoutValidateSuccess,
    checkoutValidateError,

    resetActiveOrders,
    activeOrdersIndexBegin,
    activeOrdersIndexSuccess,
    activeOrdersIndexErr,

    resetOfflineOrders,
    offlineOrdersIndexBegin,
    offlineOrdersIndexSuccess,
    offlineOrdersIndexErr,

} = actions;

const checkout = () => {
    return async dispatch => {
        dispatch(checkoutStoreBegin());
        Checkout.Store((response) => {
            dispatch(checkoutStoreSuccess(response.data.data))
        }, (err) => {
            if(!err.response) {
                push('/server-down', true)
            }
            else {
                dispatch(checkoutStoreError(err?.response?.data?.message ?? "No internet connection!"))
            }
        });
    };
};

const CheckoutReset = () => {
    return async dispatch => {
        dispatch(checkoutReset());
    };
};

const CheckoutStoreReset = () => {
    return async dispatch => {
        dispatch(checkoutStoreReset());
    };
};

const CreatePayment = (checkout_uuid, {name, email, phone, order_type}) => {
    return async dispatch => {
        dispatch(createPaymentBegin());

        Checkout.CreatePayment(checkout_uuid, {name, email, phone:phone?(phone.replace(/\D/g,'')):null, order_type}, (response) => {
            dispatch(createPaymentSuccess(response.data.data))
        }, (err) => {
            if(!err.response) {
                push('/server-down', true)
            }
            else {
                dispatch(createPaymentError(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
            }
        });
    };
};


const ApplyCoupon = (checkout_uuid, code) => {
    return async dispatch => {
        dispatch(applyCouponBegin());
        Checkout.ApplyCoupon(checkout_uuid, code, (response) => {
            dispatch(applyCouponSuccess(response.data.message, response.data.data.checkout))
        }, (err) => {
            if(!err.response) {
                push('/server-down', true)
            }
            else {
                dispatch(applyCouponError(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
            }
        });
    };
};


const ClearCoupon = (checkout_uuid) => {
    return async dispatch => {
        dispatch(clearCouponBegin());
        Checkout.ClearCoupon(checkout_uuid, (response) => {
            dispatch(clearCouponSuccess(response.data.message, response.data.data.checkout))
        }, (err) => {
            if(!err.response) {
                push('/server-down', true)
            }
            else {
                dispatch(clearCouponError(err?.response?.data?.message ?? "No internet connection!", err?.response?.data?.errors))
            }
        });
    };
};


const ValidateCheckoutReset = () => {
    return async dispatch => {
        dispatch(checkoutValidateReset());
    };
};


const ValidateCheckout = (uuid) => {
    return async dispatch => {
        dispatch(checkoutValidateBegin());
        Checkout.Validate( uuid, (response) => {
            const user = response.data.data.user;
            if(user && user != null) {
                dispatch(partiallyUpdateAuthProfile(user));
            }
            dispatch(checkoutValidateSuccess(response.data.data.checkout, response.data.data.total_active_orders, response.data.message))
        }, (err) => {
            if(!err.response) {
                push('/server-down', true)
            }
            else {
                dispatch(checkoutValidateError(err?.response?.data?.message ?? "No internet connection!"))
            }
        });
    };
};


const ResetActiveOrders = () => {
    return async dispatch => {
        dispatch(resetActiveOrders());
    };
};

const ListActiveOrders = () => {
    return async dispatch => {
        dispatch(activeOrdersIndexBegin());
        Profile.ActiveOrders((response) => {
            dispatch(activeOrdersIndexSuccess(response.data.data.active_orders))
        }, (err) => {
            dispatch(activeOrdersIndexErr(err?.response?.data?.message ?? "No internet connection!"))
        });
    };
};

const ListOfflineOrders = () => {
    return async dispatch => {
        dispatch(offlineOrdersIndexBegin());
        Profile.OfflineOrders((response) => {
            dispatch(offlineOrdersIndexSuccess(response.data.data.offline_orders, response.data.data.total_pending_points))
            localStorage.setItem('order_uids', JSON.stringify(response.data.data.offline_uuids));
        }, (err) => {
            if(store.getState().auth.isLoggedIn) {
                push('/orders', true)
            }
            dispatch(offlineOrdersIndexErr(err?.response?.data?.message ?? "No internet connection!"))
        });
    };
};

export {checkout, CheckoutReset, CheckoutStoreReset, CreatePayment, ValidateCheckout, ValidateCheckoutReset, ListActiveOrders, ResetActiveOrders, ListOfflineOrders, ApplyCoupon, ClearCoupon};
