import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Tag, Pagination, Input, Modal, notification} from 'antd';
import Inventory from "../../../../../http/Inventory";
import {Link, useHistory} from "react-router-dom";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";

const ManageInventories = () => {
    const [loading, setLoading] = useState(true);
    const [inventories, setInventories] = useState([]);
    const [filteredInventories, setFilteredInventories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState({
        search: ''
    });
    const [searchInput, setSearchInput] = useState('');
    const history = useHistory();

    const reload = (forceShowLoading = false) => {
        if (!inventories || forceShowLoading)
            setLoading(true);
        Inventory.Index((response) => {
            setLoading(false);
            setInventories(response.data.data.inventories);
            setFilteredInventories(response.data.data.inventories);
        }, (err) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        reload();
    }, []);

    const onInventoryCreate = () => {
        history.push("/dashboard/operations/setup/inventory/new");
    }

    const applyFilters = () => {
        let filtered = [...inventories];

        if (filters.search) {
            const search = filters.search.toLowerCase();
            filtered = filtered.filter(inv => inv.name.toLowerCase().includes(search));
        }

        setFilteredInventories(filtered);
        setCurrentPage(1); // Reset to first page
    };

    const resetFilters = () => {
        setFilters({
            search: ''
        });
        setSearchInput(''); // Reset the search input field
    }

    const handleFilterChange = (value, filterType) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: value
        }));
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        handleFilterChange(value, 'search');
    }

    useEffect(() => {
        applyFilters();
    }, [filters]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    const handleDelete = (inventoryId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this inventory?',
            content: 'This action cannot be undone.',
            okText: 'Yes, Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                Inventory.Delete(inventoryId, () => {
                    notification['success']({
                        message: 'Success!',
                        description: 'Inventory deleted successfully.',
                    });
                    reload();
                }, (err) => {
                    notification['error']({
                        message: 'Error!',
                        description: err.response?.data?.message || 'Failed to delete inventory.',
                    });
                });
            }
        });
    };

    const paginatedInventories = filteredInventories && filteredInventories.length > 0 ? filteredInventories.slice((currentPage - 1) * pageSize, currentPage * pageSize) : [];

    return (
        <DefaultContainer className="mx-auto max-w-8xl">
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row w-full">
                    <div className={"flex flex-row items-center justify-between w-full"}>
                        <span>Manage Inventories</span>
                        <Button onClick={onInventoryCreate} style={{borderRadius: "5px"}} type={"primary"} className={"primary-button"}>
                            <div className={"flex flex-row items-center"}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-lg cursor-pointer" aria-hidden="true"/>
                                Add Inventory
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="mb-4 pl-1 text-gray-600">
                    <p>
                        In this section you manage the core ingredients and items that are essential for the operation of Patty Slaps.
                        These inventories can include various food items and item types that are fundamental in preparing our menu offerings.
                    </p>
                </div>

                <div className="flex flex-row p-2 items-center">
                    <div className={"mr-2"}>
                        <Input.Search placeholder="Search for an inventory" value={searchInput} onChange={handleSearch}/>
                    </div>
                    <div>
                        <Button type={"link"} className={"text-xs text-blue-400"} onClick={resetFilters}>Reset filters</Button>
                    </div>
                </div>

                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {paginatedInventories && paginatedInventories.length > 0 ?
                                <div className={"w-full"}>
                                    {paginatedInventories.map(inventory => {
                                        return (
                                            <div className={"mb-1 border border-gray-300 w-full rounded-sm px-2 py-2 bg-gray-50 flex flex-col items-center md:flex-row"} key={inventory.id}>
                                                <div className={"w-full"}>
                                                    <div className={"flex flex-col md:flex-row items-center w-full"}>
                                                        <span className={"uppercase font-bold whitespace-nowrap mr-2 patty text-xl"}>
                                                            <Link to={`/dashboard/operations/setup/inventory/${inventory.id}/edit`} className={"text-black"}>
                                                                {inventory.name}
                                                            </Link>
                                                        </span>

                                                        <span className={"text-gray-400 whitespace-nowrap"}>
                                                            {inventory.unit_type} ({inventory.unit_size})
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={"w-full flex flex-row items-center md:justify-end justify-center mt-2 md:mt-0"}>
                                                    <FontAwesomeIcon icon={faTrash} className="block text-lg text-red-500 cursor-pointer mr-4" aria-hidden="true" onClick={() => handleDelete(inventory.id)} />
                                                    <Link to={`/dashboard/operations/setup/inventory/${inventory.id}/edit`} className={"text-black"}>
                                                        <FontAwesomeIcon icon={faEdit} className="block text-lg cursor-pointer" aria-hidden="true"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className={"w-full text-center text-gray-400 pb-1"}>There are no inventories available.</div>
                            }
                        </SkeletonWrapper>
                    </div>
                </div>

                <div className="mt-4 flex justify-center">
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={filteredInventories ? filteredInventories.length : 0}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </DefaultContainer>
    );
};

export default ManageInventories;
