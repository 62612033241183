import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {useSelector} from "react-redux";

const CartBadge = () => {

    const cart_items = useSelector(state => state.cart.cart_items);
    const menu = useSelector(state => state.menu.index_menu);

    let [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        if (menu && cart_items) {
            let tq = 0;
            for (const key in cart_items) {
                const storageCartItem = cart_items[key];
                const quantity = storageCartItem[2];
                tq += quantity;
            }
            setTotalQuantity(tq);
        }
    }, [menu, cart_items])

    return (
        totalQuantity?
            <motion.div
                initial={{opacity: 0, scale: 5}}
                animate={{opacity: 1, scale: 1, transition: {delay: .4}}}
                key={`added-food-name`}
                className="absolute" style={{right: "-4px", top: "-4px"}}
            >
                <div className="bg-red-800 text-white flex flex-row items-center justify-center rounded-full shadow-xl" style={{fontSize: "14px", width: "22px", height: "22px", letterSpacing: "0vw"}}>
                    <div style={{marginTop: "0vw"}}>{totalQuantity}</div>
                </div>
            </motion.div>
        :null
    );
};

export default CartBadge;
