import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const UserTrainingTopic = {
    Index: (userTrainingId, successCb, failCb) => {
        axios.get(`/user-training/${userTrainingId}/topics`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    CompleteTopic: (userTrainingId, topicId, successCb, failCb) => {
        axios.post(`/user-training/${userTrainingId}/topics/${topicId}/complete`, {}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default UserTrainingTopic;