import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {Form, Input, Button, Upload, notification} from 'antd';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {RiImageLine} from "@remixicon/react";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import CategoryApi from "../../../../../http/CategoryApi";
import DefaultContainer from "../../../../../components/DefaultContainer";  // Adjust path as needed

const EditCategory = ({match}) => {
    const [form] = Form.useForm();
    const [category, setCategory] = useState(null);
    const [foods, setFoods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);

    const categoryId = match.params.id;

    const loadCategory = () => {
        setLoading(true);
        CategoryApi.Edit(categoryId,
            (response) => {
                setLoading(false);
                const {category} = response.data.data;
                setCategory(category);
                setFoods(category.foods);  // Load foods inside the category

                form.setFieldsValue({
                    name: category.name,
                    name_fr: category.name_fr
                });
            },
            (err) => {
                notification.error({message: 'Error!', description: err.response?.data.message});
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        loadCategory();
    }, []);

    const handleFoodReorder = (result) => {
        if (!result.destination) return;
        const reorderedFoods = Array.from(foods);
        const [movedFood] = reorderedFoods.splice(result.source.index, 1);
        reorderedFoods.splice(result.destination.index, 0, movedFood);
        setFoods(reorderedFoods);

        // Automatically save the reordered foods
        const reorderedData = reorderedFoods.map((food, index) => ({
            id: food.id,
            order: index
        }));

        CategoryApi.UpdateFoodOrder(categoryId, reorderedData,
            () => {
                notification.success({message: 'Success!', description: 'Food order updated successfully!'});
            },
            (err) => {
                notification.error({message: 'Error!', description: 'Failed to update food order.'});
            }
        );
    };

    const saveCategory = (values) => {
        setSaving(true);
        const data = new FormData();
        data.append('name', values.name);
        data.append('name_fr', values.name_fr);

        if (values.image) {
            data.append('image', values.image.file);  // Append the image file to FormData
        }

        CategoryApi.Update(categoryId, data,
            () => {
                notification.success({message: 'Success!', description: 'Category updated successfully!'});
                setSaving(false);
            },
            (err) => {
                notification.error({message: 'Error!', description: err.response?.data.message});
                setSaving(false);
            }
        );
    };


    const handleImageUpload = (file) => {
        form.setFieldsValue({image: file});  // Set the image file into the form
    };


    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <h1 className="text-xl font-bold">Edit Category</h1>

                    <DefaultContainer>
                        <Form
                            form={form}
                            onFinish={saveCategory}
                            className="mt-4 !pt-3"
                            labelCol={{span: 6}}  // Adjust the label width
                            wrapperCol={{span: 18}}  // Adjust the input width
                        >
                            <Form.Item
                                name="name"
                                label="Category Name"
                                rules={[{required: true, message: 'Please enter the category name'}]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                name="name_fr"
                                label="Category Name (French)"
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                name="image"
                                label="Category Image"
                            >
                                <Upload
                                    listType="picture-card"
                                    beforeUpload={(file) => {
                                        handleImageUpload(file);  // Handle image upload and store it in form state
                                        return false;  // Prevent automatic upload by Ant Design
                                    }}
                                    showUploadList={true}
                                    maxCount={1}
                                    onRemove={() => form.setFieldsValue({image: null})}  // Clear image from form state when removed
                                >
                                    {category && category.image ? (
                                        <img src={category.image} alt="category" style={{width: '100%'}}/>
                                    ) : (
                                        <RiImageLine/>
                                    )}
                                </Upload>
                            </Form.Item>

                            <Form.Item wrapperCol={{offset: 6, span: 18}}> {/* Align the button */}
                                <Button type="primary" htmlType="submit" loading={saving}>
                                    Save Category
                                </Button>
                            </Form.Item>
                        </Form>
                    </DefaultContainer>

                    {foods && foods?.length > 0 &&
                        <>
                            <h2 className="text-lg font-bold mt-8">Reorder Foods in this Category</h2>
                            <DragDropContext onDragEnd={handleFoodReorder}>
                                <Droppable droppableId="foods">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {foods.map((food, index) => (
                                                <Draggable key={food.id} draggableId={String(food.id)} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="food-item p-4 mb-2 bg-white shadow-md border border-gray-200 rounded-lg flex items-center"
                                                        >
                                                            <div className="w-12 h-12 bg-gray-200 flex-shrink-0">
                                                                {food.image ? (
                                                                    <img src={food.image} alt={food.name} className="w-full h-full object-cover"/>
                                                                ) : (
                                                                    <RiImageLine className="w-full h-full"/>
                                                                )}
                                                            </div>
                                                            <span className="ml-4">{food.name}</span>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>
                    }
            </SkeletonWrapper>
        </motion.div>
    );
};

export default EditCategory;
