import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart } from './Headers';
import { CreateCustomFormData } from './CustomFormData';

const Bloggers = {
  FetchFreeBloggers: (successCb, failCb) => {
    axios.get(`/blogger/free`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  Invite: (pk, successCb, failCb) => {
    axios.post(`/blogger/${pk}/invite`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  Accept: (pk, successCb, failCb) => {
    axios.post(`/blogger/${pk}/accept`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  Reject: (pk, successCb, failCb) => {
    axios.post(`/blogger/${pk}/reject`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  FetchAcceptedBloggers: (page, perpage, successCb, failCb) => {
    axios.get(`/blogger/accepted/index?page=${page}&per_page=${perpage}`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  FetchInvitedBloggers: (page, perpage, successCb, failCb) => {
    axios.get(`/blogger/invited/index?page=${page}&per_page=${perpage}`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  FetchAttendingBloggers: (successCb, failCb) => {
    axios.get(`/blogger/attending/index`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  GetInvitation: (token, successCb, failCb) => {
    axios.get(`/blogger/invitation/${token}`, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  AcceptInvitation: (token, data, successCb, failCb) => {
    axios.post(`/blogger/invitation/${token}/accept`, data, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  LockAttendanceDate: (date, successCb, failCb) => {
    axios.post(`/blogger/attendance/${date}/disable`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },

  UnlockAttendanceDate: (date, successCb, failCb) => {
    axios.post(`/blogger/attendance/${date}/enable`, null, getAuthenticationHeaders()).then(response => {
      successCb(response);
    }).catch(err => {
      checkLoggedIn(err);
      failCb(err);
    });
  },


};

export default Bloggers;
