import axios from 'axios';
import { getAuthenticationHeaders, checkLoggedIn } from './Headers';

const Device = {
    Index: (page, perPage, successCb, failCb) => {
        axios.get(`/device?page=${page}&per_page=${perPage}&pagination=True`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    Show: (id, successCb, failCb) => {
        axios.get(`/device/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    Create: (successCb, failCb) => {
        axios.get('/device/create', getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            }).catch(err => {
            failCb(err);
        });
    },
    Edit: (id, successCb, failCb) => {
        axios.get(`/device/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
    Store: ({data}, successCb, failCb) => {
        axios.post('/device', data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    Update: ({id, data}, successCb, failCb) => {
        axios.put(`/device/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
    Destroy: (id, successCb, failCb) => {
        axios.delete(`/device/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    GetDeviceCode: (deviceId, successCb, failCb) => {
        axios.post(`/square/${deviceId}/device_code`, {}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    GetDeviceId: (deviceCodeId, successCb, failCb) => {
        axios.post(`/square/device_id`, { device_code_id: deviceCodeId }, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default Device;
