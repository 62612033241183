import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import TrainingNode from "../../Training/TrainingNode";
import Training from "../../../../../http/Training";
import GooglePlaces from "../../../../../http/GooglePlaces";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, Input, Modal, notification} from "antd";
import FormItem from "../../../../../components/Form/FormItem";
import Employees from "../../../../../http/Employees";
import Place from "./Place";

const Places = () => {

    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState(null);
    const [creating, setCreating] = useState(false);
    const [saving, setSaving] = useState(false);
    const [place_id, set_place_id] = useState('');
    const [validationFields, setValidationFields] = useState(null);
    const [form] = Form.useForm();

    const reload = () => {
        if(!places) setLoading(true);
        GooglePlaces.Index((response) => {
            setLoading(false);
            setPlaces(response.data.data.places);
        }, (err) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        reload();
    }, []);

    const onCancel = () => {
        if(!saving)
            setCreating(false);
    }

    const onOpen = () => {
        form.resetFields();
        setValidationFields(null);
        set_place_id(null);
        setCreating(true);
    }

    const handleSubmit = () => {
        setSaving(true);
        GooglePlaces.Store(place_id, (response) => {
            const google = response.data.data;
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `Google place ${google.place_id} has been created!`,
            });
            setSaving(false);
            setCreating(false);
            reload();
        }, (err) => {
            setValidationFields(err.response.data.errors);
            setSaving(false);
        });
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full`}
        >

            <Modal title={<div className={""}>Create Google Place</div>}
                   onCancel={onCancel}
                   onOk={handleSubmit}
                   open={creating}
                   okText={"CREATE"}
                   okButtonProps={{danger: true, size: "large", loading: saving}}
                   cancelButtonProps={{size: "large", disabled: saving}}
            >
                <Form form={form}>
                    <FormItem
                        label="Place ID"
                        errors={validationFields}
                        name="place_id"
                        rules={[{ required: true, message: 'Please input the Place ID!', len: 27 }]}
                    >
                        <Input
                            placeholder="Enter 27 character Place ID"
                            value={place_id}
                            onChange={e => set_place_id(e.target.value)}
                        />
                    </FormItem>
                </Form>

            </Modal>

            <div className="flex flex-col mx-auto w-full">
                <div className="text-3xl font-bold text-black pl-1 flex flex-row mb-2 items-center patty uppercase">
                    Google Places
                    <FontAwesomeIcon icon={faPlusCircle} className=" ml-2 cursor-pointer text-2xl" aria-hidden="true" onClick={onOpen}/>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {places &&
                                <div className={"w-full flex flex-col items-start"}>
                                    {places.length == 0 ?
                                        <div className={"text-gray-400"}>
                                            There are no google places.
                                        </div>
                                    :
                                        <>
                                            {places.map(place => {return (<Place place={place} reload={reload} />)})}
                                        </>
                                    }
                                </div>
                            }


                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>

    );
};

export default Places;