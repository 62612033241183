import React from 'react';
import {Badge} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShop, faStar, faStarOfLife} from "@fortawesome/free-solid-svg-icons";

const BaseReview = ({review}) => {
    return (
        <div className={`border border-gray-200 px-1 py-1 mb-1 rounded-md shadow-md ${review.status == 'failed'?'bg-red-200':''} ${review.our_review? (review.our_review.status=='processed' && review.status=='processed'?'bg-green-300':'bg-red-300'):'bg-gray-50'}`}>
            <div className={"flex flex-row items-start"}>
                <div>
                    <img src={"https://pattykiosk.com/api/image-proxy/" + review.author_image} className="rounded-sm" width={70} height={70}/>
                </div>
                <div className={"flex-1 flex flex-col pl-2 "}>
                    <div className={"uppercase font-bold text-sm flex flex-row items-center pb-1 pt-1"}>
                        {review.our_review &&
                            <div className={"mr-1"}>
                                <FontAwesomeIcon icon={faStar} className="block text-xl" aria-hidden="true"/>
                            </div>
                        }

                        <div className={`mr-1 rounded-full ${review.review_rating >= 4?'bg-green-800':''} ${review.review_rating == 3?'bg-yellow-400':''} ${review.review_rating <= 2?'bg-red-700':''} flex items-center justify-center w-5 h-5 font-normal text-sm text-white`}>{review.review_rating}</div>
                        <div>{review.author_name} <span className={"text-xs text-gray-600 font-normal"}>({review.author_reviews_count})</span></div>

                    </div>
                    <div className={"text-xs font-bold"}>
                        {review.review_at}
                    </div>
                    <div className={"text-xs"}>
                        {review.is_review ?
                            <>
                                {review.review_text}
                            </>
                            :
                            <span className={"text-gray-300"}>...</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaseReview;