import React from 'react';
import T from "../../components/T";

const CustomizeButton = ({add, total_price, onClick, backgroundColor = "#bb1523"}) => {
    return (
        <button className="hover:bg-red-700 bg-red-600 text-white font-bold py-3 px-5 rounded-lg focus:outline-none flex-1 shadow-2xl add-to-bag-button "
                onClick={onClick}
                type="button"
        >
            <div className="flex justify-between text-base md:text-xl 2xl:text-3xl">
                <span className="capitalize"><T>{add ? "Add to Bag" : "Update Item"}</T></span>
                <span>${total_price}</span>
            </div>
        </button>
    );
};

export default CustomizeButton;
