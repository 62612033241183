import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Division = {

    Index: (successCb, failCb) => {
        axios.get('/division', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    VisibleIndex: (successCb, failCb) => {
        axios.get('/division/visible_index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    TimelineIndex: (successCb, failCb) => {
        axios.get('/division/timeline_index', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Show: (id, completed, successCb, failCb) => {
        axios.get(`/division/${id}${completed?'/completed':''}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    MyTasks: (completed, successCb, failCb) => {
        axios.get(`/division/my_tasks${completed?'/completed':''}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Division;
