import React from 'react';
import { motion } from "framer-motion";

const DefaultContainer = ({children, className, append=false, bg=false}) => {
    const cls = "mx-auto rounded-lg border shadow-xl bg-white p-4 flex flex-col py-3 w-full";
    return (
        <div className={"mt-2"}>
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className={`${className?className:cls} ${append?cls:null}`}
        >
            {children}
        </motion.div>
        </div>
    );
};

export default DefaultContainer;