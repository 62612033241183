import actions from './actions';

const {
    EMPTY_CART,

    CART_STORE_BEGIN,
    CART_STORE_SUCCESS,
    CART_STORE_ERR,
    CART_STORE_CLOSE_BUBBLE,

    CART_UPDATE_BEGIN,
    CART_UPDATE_SUCCESS,
    CART_UPDATE_ERR,
    CART_UPDATE_CLOSE_BUBBLE,

    CART_DESTROY_BEGIN,
    CART_DESTROY_SUCCESS,
    CART_DESTROY_ERR,
    CART_DESTROY_CLOSE_BUBBLE,

} = actions;

const initState = {
    cart_items: JSON.parse(localStorage.getItem("cart_items")),

    store_loading: false,
    store_done: false,
    store_error: null,
    store_cart_item: null,
    store_bubble: 0,

    update_loading: false,
    update_done: false,
    update_error: false,
    update_cart_item: null,
    update_bubble: 0,

    destroy_loading: false,
    destroy_done: false,
    destroy_error: null,
    destroy_bubble: 0,

};

/**
 *
 * @todo impure state mutation/explaination
 */
const CartReducer = (state = initState, action) => {
    const {type, cart_items, store_cart_item, error, update_cart_item, show_bubble} = action;
    switch (type) {
        case EMPTY_CART:
            return {
                ...state,
                cart_items: [],
            }

        case CART_STORE_CLOSE_BUBBLE:
            return {
                ...state,
                store_bubble: state.store_bubble > 0 ? state.store_bubble - 1 : 0
            }

        case CART_UPDATE_CLOSE_BUBBLE:
            return {
                ...state,
                update_bubble: state.update_bubble > 0 ? state.update_bubble - 1 : 0
        }

        case CART_DESTROY_CLOSE_BUBBLE:
            return {
                ...state,
                destroy_bubble: state.destroy_bubble > 0 ? state.destroy_bubble - 1 : 0
            }

        case CART_STORE_BEGIN:
            return {
                ...state,
                store_loading: true,
                store_done: false,
                store_cart_item: null,
                store_bubble: 0,
            };
        case CART_STORE_SUCCESS:
            return {
                ...state,
                store_loading: false,
                store_done: true,
                cart_items,
                store_bubble: 2,
                store_cart_item
            };
        case CART_STORE_ERR:
            return {
                ...state,
                store_loading: false,
                store_done: false,
                store_error: error,
            };


        case CART_UPDATE_BEGIN:
            return {
                ...state,
                update_loading: true,
                update_done: false,
                update_cart_item: null,
                update_bubble: 0,
            };
        case CART_UPDATE_SUCCESS:
            return {
                ...state,
                update_loading: false,
                update_done: true,
                cart_items,
                update_bubble: show_bubble ? 2 : 0,
                update_cart_item
            };
        case CART_UPDATE_ERR:
            return {
                ...state,
                update_loading: false,
                update_done: false,
                update_error: error,
            };


        case CART_DESTROY_BEGIN:
            return {
                ...state,
                destroy_loading: true,
                destroy_done: false,
                destroy_bubble: 0,
            };
        case CART_DESTROY_SUCCESS:
            return {
                ...state,
                destroy_loading: false,
                destroy_done: true,
                destroy_bubble: show_bubble ? 1 : 0,
                cart_items
            };
        case CART_DESTROY_ERR:
            return {
                ...state,
                destroy_error: error,
            };


        default:
            return state;
    }
};
export default CartReducer;
