import React, {useState} from 'react';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, notification, Tag} from "antd";
import { Modal } from 'antd';
import GooglePlaces from "../../../../../http/GooglePlaces";

const Place = ({place, reload}) => {

    const [deleting, setDeleting] = useState(false);

    const convertStatusToColor = (status) => {
        if(status == "not processed")
            return "yellow";
        if(status == "processed")
            return "green";
        if(status == "failed")
            return "red";
        return "yellow";
    }

    const onDelete = (google_id) => {
        setDeleting(true);
        GooglePlaces.Destroy(google_id, (response) => {
            const google = response.data.data;
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `Google place ${place.place_id} has been deleted!`,
            });
            setDeleting(false);
            reload();
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });

            setDeleting(false);
            reload();

        });
    }

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Are you sure delete place with place_id ${place.place_id}?`,
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                onDelete(place.id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <div className={"mb-2 border border-gray-400 rounded-md px-2 py-1 w-full bg-blue-50 shadow-md"}>

            {place.status == "processed" ?
                <div className={"uppercase flex flex-row justify-between"}>
                    <div className={"flex flex-row items-center"}>Name: <b className={"px-2 text-base"}>{place.name}</b> <span className={"text-gray-500"}>(Total Reviews: {<span className={"font-bold"}>{place.total_reviews}</span>})</span></div>
                    <div>FETCHED AT: <b>{place.fetched_at}</b></div>
                </div>
                :null}

            <div className={"font-bold flex flex-row items-center justify-between border-t border-gray-300 mt-1 pt-1 "}>
                <div><a target={"_blank"} href={"https://www.google.com/maps/place/?q=place_id:"+place.place_id}>{place.place_id}</a></div>
                <div>
                    <Button danger type={"primary"} size={"small"} loading={deleting} onClick={showDeleteConfirm}>Delete</Button>
                </div>
            </div>

            <div className={"mt-1 pt-1 border-t border-gray-300 uppercase"}>
                Status: <Tag color={convertStatusToColor(place.status)}>{place.status}</Tag>
            </div>

        </div>
    );
};

export default Place;