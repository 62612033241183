import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart} from './Headers';

const CategoryApi = {

    // Fetch all categories
    Index: (successCb, failCb) => {
        axios.get(`/categories`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new category
    Store: (data, successCb, failCb) => {
        axios.post(`/categories`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing category
    Edit: (id, successCb, failCb) => {
        axios.get(`/categories/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                //checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing category
    Update: (id, data, successCb, failCb) => {
        axios.post(`/categories/${id}`, data, getAuthenticationHeadersMultiPart())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete a category
    Delete: (id, successCb, failCb) => {
        axios.delete(`/categories/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update the order of categories
    UpdateOrder: (data, successCb, failCb) => {
        axios.post(`/categories/reorder`, {'categories': data}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update the order of foods within a category
    UpdateFoodOrder: (categoryId, data, successCb, failCb) => {
        axios.post(`/categories/${categoryId}/foods/reorder`, {'foods': data}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

};

export default CategoryApi;
