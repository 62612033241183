import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Space, notification } from 'antd';
import FormHandler from '../../../../http/FormHandler';
import { useHistory } from 'react-router-dom';
import DefaultContainer from "../../../../components/DefaultContainer";

const FormManagement = () => {
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        fetchForms();
    }, []);

    const fetchForms = () => {
        FormHandler.getForms((response) => {
            setForms(response.data.data);
            setLoading(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    };

    const handleEdit = (id) => {
        history.push(`/dashboard/forms/manage/${id}/edit`);
    };

    const handleView = (id) => {
        history.push(`/dashboard/forms/manage/${id}/render`);
    };

    const handleSubmissions = (id) => {
        history.push(`/dashboard/forms/manage/${id}/submissions`);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => handleEdit(record.id)}>Edit</Button>
                    <Button type="default" onClick={() => handleView(record.id)}>View</Button>
                    <Button type="default" onClick={() => handleSubmissions(record.id)}>Submissions</Button>
                </Space>
            ),
        },
    ];

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <h2 className="text-2xl mb-2 patty">Form Management</h2>

            <Table
                columns={columns}
                dataSource={forms}
                rowKey="id"
                loading={loading}
                pagination={false}
                className={"border border-gray-300"}
            />

        </DefaultContainer>
    );
};

export default FormManagement;
