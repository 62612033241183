const actions = {
  SET_BRANCH: 'SET_BRANCH',

  setBranch: (branch) => {
    return {
      type: actions.SET_BRANCH,
      branch
    };
  },


};

export default actions;
