import React, { useEffect, useState } from 'react';
import { Table, Button, notification, InputNumber, Modal, Form } from 'antd';
import OrderService from '../../../../../http/OrderService';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { ReloadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye, faCheckCircle, faTasks, faTruck, faClipboardCheck, faPlay } from "@fortawesome/free-solid-svg-icons";

const VendorOrders = () => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState([]); // Track loading state for each order
    const [form] = Form.useForm();

    useEffect(() => {
        fetchVendorOrders();
    }, []);

    const fetchVendorOrders = () => {
        setRefreshing(true);
        if (!orders) setLoading(true);
        OrderService.getVendorOrders(
            response => {
                const data = response.data.data;
                setOrders(data.orders);
                setLoading(false);
                setRefreshing(false);
            },
            error => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load orders',
                });
                setLoading(false);
                setRefreshing(false);
            }
        );
    };

    const handleStatusChange = (order, status) => {
        if (status === 'preparing') {
            setLoadingOrders(prev => [...prev, order.id]); // Set loading for the current order
            // Directly update the status to 'preparing' without opening the modal
            OrderService.updateVendorOrderStatus(order.id, { status: 'preparing' },
                response => {
                    notification.success({
                        message: 'Success',
                        description: 'Order marked as preparing successfully',
                    });
                    fetchVendorOrders(); // Refresh the orders list
                    setLoadingOrders(prev => prev.filter(id => id !== order.id)); // Remove loading state
                },
                error => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update order status',
                    });
                    setLoadingOrders(prev => prev.filter(id => id !== order.id)); // Remove loading state
                }
            );
        } else if (status === 'shipped') {
            // Open the modal when marking as shipped to set the sent amount
            setSelectedOrder(order);
            form.setFieldsValue({ sent_amount: order.ordered_amount }); // Set the form's value based on the selected order
            setIsModalVisible(true);
        }
    };

    const handleModalOk = () => {
        form.validateFields().then(values => {
            OrderService.updateVendorOrderStatus(selectedOrder.id, { status: 'shipped', sent_amount: values.sent_amount },
                response => {
                    notification.success({
                        message: 'Success',
                        description: 'Order status updated successfully',
                    });
                    fetchVendorOrders(); // Refresh the orders list
                    setIsModalVisible(false);
                    form.resetFields();
                },
                error => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update order status',
                    });
                });
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const statusColorMap = {
        pending: '#ececec',
        seen: '#e4f9ff',
        confirmed: '#90EE90',
        preparing: '#FFDAB9',
        shipped: '#fee0ff',
        received: 'rgba(174,255,171,0.28)'
    };

    const statusIconMap = {
        pending: faClock,
        seen: faEye,
        confirmed: faCheckCircle,
        preparing: faTasks,
        shipped: faTruck,
        received: faClipboardCheck
    };

    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'group_name',
            render: (text, record) => (
                <div className={`patty text-2xl ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}
                </div>
            )
        },
        {
            title: 'Ordered Amount',
            dataIndex: 'ordered_amount',
            key: 'ordered_amount',
            render: (text, record) => (
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''} ${record.status === 'preparing' ? 'text-green-700' : ''}`}>
                    {record.status === 'preparing' ?
                        <FontAwesomeIcon icon={faPlay} className="text-sm cursor-pointer mr-2" />
                        : ''}
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Ordered At',
            dataIndex: 'ordered_at',
            key: 'ordered_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Branch',
            dataIndex: ['branch_inventory_group', 'branch', 'name'],
            key: 'branch_name',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent At',
            dataIndex: 'sent_at',
            key: 'sent_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent Amount',
            dataIndex: 'sent_amount',
            key: 'sent_amount',
            render: (text, record) => (
                text &&
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Received At',
            dataIndex: 'received_at',
            key: 'received_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
            render: (text, record) => (
                text &&
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div className="flex flex-col items-start">
                    <div style={{ backgroundColor: statusColorMap[text] }} className={`w-[150px] uppercase text-sm font-bold py-1 text-left pl-3 flex flex-row items-center justify-start ${record.status === 'cancelled' ? '' : ''}`}>
                        <FontAwesomeIcon icon={statusIconMap[text]} className="mr-2" />
                        {text === "received" ? "done" : text}
                    </div>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    {record.status === 'seen' && (
                        <Button
                            style={{ width: "170px", backgroundColor: "#002569", color: "white" }}
                            onClick={() => handleStatusChange(record, 'preparing')}
                            loading={loadingOrders.includes(record.id)} // Set button to loading if the order is being processed
                        >
                            {loadingOrders.includes(record.id) ? 'Processing...' : 'Mark as Preparing'}
                        </Button>
                    )}
                    {record.status === 'preparing' && (
                        <Button
                            style={{ width: "150px", backgroundColor: "#383838", color: "white" }}
                            onClick={() => handleStatusChange(record, 'shipped')}
                        >
                            Mark as Sent
                        </Button>
                    )}
                </div>
            )
        }
    ];

    return (
        <DefaultContainer>
            <div className="patty text-2xl font-bold mb-4 flex justify-between items-center">
                <span>Vendor Orders</span>
                <Button icon={<ReloadOutlined />} loading={refreshing} onClick={fetchVendorOrders}>Refresh</Button>
            </div>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={orders}
                    pagination={false}
                    rowKey="id"
                    rowClassName={(record) => record.status === 'cancelled' ? 'line-through' : ''}
                />
            </SkeletonWrapper>
            <Modal
                title="Send Order"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form}>
                    <Form.Item
                        name="sent_amount"
                        label="Sent Amount"
                        rules={[{ required: true, message: 'Please input the sent amount!' }]}
                    >
                        {selectedOrder &&
                            <InputNumber min={0} style={{ width: '130px' }} addonAfter={selectedOrder.branch_inventory_group.unit_type} />
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultContainer>
    );
};

export default VendorOrders;
