import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Form, Input, Button, InputNumber, Upload, notification} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import Container from "../../../../../http/Container";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import FormItem from "../../../../../components/Form/FormItem";
import DefaultContainer from "../../../../../components/DefaultContainer";

const CreateOrEditContainer = () => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const {id} = useParams();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState(null);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (id) {
            fetchContainer(id);
        } else {
            setLoading(false);
        }
    }, [id]);

    const fetchContainer = (id) => {
        Container.Edit(id, (response) => {
            const container = response.data.data.container;
            form.setFieldsValue(container);
            setImageUrl(container.image_url);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load container',
            });
            history.push("/dashboard/operations/setup/inventory/containers/manage");
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        const file = values.image?.file;
        if (file) {
            formData.append('image', file);
        }

        if (id) {
            Container.Update(id, formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/containers/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setErrors(err.response?.data?.errors);
            });
        } else {
            Container.Store(formData, (response) => {
                setSubmitting(false);
                history.push("/dashboard/operations/setup/inventory/containers/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setErrors(err.response?.data?.errors);
            });
        }
    };

    const handleUploadChange = ({file}) => {
        if (file.status === 'done' || file.status === 'removed') {
            setImageUrl(file.thumbUrl);
        }
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" style={{backgroundColor: "#FAFAFA"}}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Container' : 'Add New Container'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish} encType="multipart/form-data">
                        <FormItem
                            label="Name"
                            name="name"
                            rules={[{required: true, message: 'Please input the container name'}]}
                            errors={errors}
                        >
                            <Input placeholder="Enter container name" disabled={submitting}/>
                        </FormItem>
                        <FormItem
                            label="Empty Weight (gr)"
                            name="empty_weight"
                            rules={[{required: true, message: 'Please input the empty weight'}]}
                            errors={errors}
                        >
                            <InputNumber min={0} step={0.01} className="w-full" placeholder="Enter empty weight" disabled={submitting}/>
                        </FormItem>
                        <FormItem
                            label="Image"
                            name="image"
                            valuePropName="file"
                            getValueFromEvent={(e) => e.file}
                            errors={errors}
                        >
                            <Upload
                                name="image"
                                listType="picture"
                                maxCount={1}
                                showUploadList={true}
                                onChange={handleUploadChange}
                                beforeUpload={() => false} // Prevent auto-upload
                                disabled={submitting}
                            >
                                <Button icon={<UploadOutlined/>} disabled={submitting}>Click to upload</Button>
                            </Upload>
                        </FormItem>
                        {imageUrl && (
                            <FormItem>
                                <img src={imageUrl} alt="Container" className={"max-w-64"}/>
                            </FormItem>
                        )}
                        <FormItem>
                            <Button type="primary" htmlType="submit" loading={submitting}  className="w-full primary-button">
                                {id ? 'Update Container' : 'Add Container'}
                            </Button>
                        </FormItem>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateOrEditContainer;
