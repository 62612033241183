import React from 'react';

const ReviewRow = ({review, type}) => {
    return (
        <div className={`flex flex-col px-2 py-2 m-1 border border-gray-200 ${type == 'positive'?'bg-green-100':'bg-red-100'}`}>
            <div className={"flex flex-row items-center"}>
                <div className={"mr-2 px-2 bg-red-brand text-white patty text-lg"}>
                    {review.author_country} / {review.author_cuisine}
                </div>
                <div className={"font-bold flex flex-row items-center"}>
                    <div>
                        {review.venue_name}
                    </div>
                    <div className={"ml-2 rounded-lg bg-white border-gray-200 px-3 py-0.5"}>
                        {review.review_rating}
                    </div>
                </div>
            </div>
            <div className={`${type}-review px-1 pt-1 border-t border-gray-300 mt-2`} dangerouslySetInnerHTML={{__html: review.review_text}} />
        </div>
    );
};

export default ReviewRow;