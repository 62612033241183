import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function UnderConstructions() {

    return (

        <div className="w-full h-screen brand-background-red flex flex-col items-center justify-center " style={{overflow: "hidden"}}>
            <div className="p-5">
                <img src="/images/hello.png"/>
            </div>
            <div className="text-4xl -mt-6 uppercase text-black text-center font-bold" style={{transform: "rotate(-14deg)"}}>
                Under <br/> Constructions
            </div>
        </div>

    )
}