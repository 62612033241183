import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPenSquare, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import SkeletonWrapper from "../../components/skeletons/SkeletonWrapper";
import FoodSkeleton from "../../components/skeletons/FoodSkeleton";
import Options from "./Options";
import {setFood} from "../../redux/food/actionCreator";
import {addToCart, updateCart} from "../../redux/cart/actionCreator";
import {convertCartItemToFood, getCartItemByKey, hasAllOptionsSelected} from "../../services/food";
import AddToBagButton from "./AddToBagButton";
import {abort} from "../../history";
import {motion} from "framer-motion";
import T from "../../components/T";
import Back from "../../components/Buttons/Back";
import {notification} from "antd";
import MenuSkeleton from "../../components/skeletons/MenuSkeleton";


export default function FoodCustomize() {
    const {slug} = useParams();
    const {uid} = useParams();

    const [quantity, setQuantity] = useState(1);
    const menu = useSelector(state => state.menu.index_menu);
    const loading = useSelector(state => state.menu.index_loading);
    const error = useSelector(state => state.menu.index_error);
    const food = useSelector(state => state.food.food);
    const price = useSelector(state => state.food.price);
    const selectedItems = useSelector(state => state.food.selectedItems);
    const options = useSelector(state => state.menu.index_options);
    const cart_items = useSelector(state => state.cart.cart_items);

    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        if (menu) {
            if (uid) {
                const cart_item = getCartItemByKey(cart_items, uid);
                if (!cart_item) {
                    return abort(history);
                }
                const _food = convertCartItemToFood(menu, cart_item);
                setQuantity(cart_item[2]);
                dispatch(setFood(_food, cart_item[1]));
            } else {
                dispatch(setFood(menu.filter((item) => item.slug == slug)[0]));
            }
        }

    }, [menu]);

    const handleAddToCart = () => {
        if (!!slug) {
            console.log("Adding food: ", food, " with selected items: ", selectedItems);

            const o = hasAllOptionsSelected(food, options, selectedItems)
            if(o !== true) {
                notification['error']({
                    message: 'Error!',
                    description:
                        `You must select an item for ${o}!`,
                });
                return false;
            }

            dispatch(addToCart(food, selectedItems, quantity));
            history.push('/menu');
        } else {
            let cart_item = getCartItemByKey(cart_items, uid);
            const new_item = [...cart_item];
            new_item[1] = selectedItems;
            new_item[2] = quantity;
            console.log("Updating cart item: ", cart_item, " with new item", new_item);
            console.log("history state:", history.location.state?.pathname);
            dispatch(updateCart(cart_items, uid, new_item))
            history.push((history.location.state?.pathname) ? history.location.state.pathname : '/cart');
        }
    }

    const incrementQuantity = () => {
        setQuantity(quantity + 1);
    }

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const total_price = (price * quantity).toFixed(2);

    return (
        <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
            {food ?
                <div className="w-full min-h-screen bg-gray-200 overflow-hidden container mx-auto" style={{backgroundColor: "rgb(231,231,231)", maxWidth: "1000px"}}>
                    <motion.div className="flex flex-row w-full items-start bg-gray-100 relative"
                                initial={{opacity: .4, scale: 1.2}}
                                animate={{opacity: 1, scale: 1}}
                                style={{padding: "2vw 1vw", backgroundColor: "rgb(231,231,231)"}}
                    >
                        <div className="flex relative w-full">
                            <div className="flex flex-col w-full items-center justify-center relative">
                                <motion.div className="flex flex-row items-start justify-start absolute" style={{top: "10px", left: "40px"}}
                                            initial={{opacity: 0, x: -400}}
                                            animate={{opacity: 1, x: 0, transition: {delay: .5}}}
                                >
                                    <Back />
                                </motion.div>

                                <div className="w-full bg-white flex flex-col items-center justify-center rounded-lg p-5">
                                    <img src={food.image} style={{maxHeight: "300px"}}/>

                                    <div className="text-center uppercase drop-shadow-lg font-bold text-4xl xxs:text-4xl xs:text-4xl sm:text-5xl md:text-6xl -mt-6" style={{letterSpacing: "-.1vw", fontFamily: "PattySlaps"}}>
                                        {food.name}
                                    </div>

                                </div>
                                <Options options={food.modified_options}/>
                            </div>
                        </div>

                    </motion.div>

                    <div className="fixed block bottom-5 w-full container mx-auto" style={{bottom: "2.4vw", maxWidth: "1000px"}}>
                        <div className="flex mt-8 mx-2 flex-col items-end " style={{marginRight: "0 2.2vw"}}>
                            <motion.div initial={{opacity: 0, y: 50}}
                                        animate={{opacity: 1, y: 0, transition: {duration: .2, delay: .5}}}
                                        className="flex justify-between pt-3 bg-black text-white rounded-t-lg text-base md:text-xl px-5 py-2 mr-5"
                            >
                                <div className="flex flex-row justify-center items-center">
                                    <div className="font-bold uppercase" style={{paddingRight: "2vw"}}><T>QUANTITY</T></div>
                                    <button onClick={decrementQuantity}>
                                        <FontAwesomeIcon icon={faMinusCircle} className=" mr-2 text-2xl" aria-hidden="true"/>
                                    </button>
                                    <span className="font-bold">{quantity}</span>
                                    <button onClick={incrementQuantity}>
                                        <FontAwesomeIcon icon={faPlusCircle} className=" ml-2  text-2xl" aria-hidden="true"/>
                                    </button>
                                </div>
                            </motion.div>
                            <motion.div initial={{opacity: 0, marginBottom: -30}}
                                        animate={{opacity: 1, marginBottom: 0, transition: {duration: .4, delay: .3}}}
                                        className="w-full flex "
                            >
                                <AddToBagButton total_price={total_price} add={!!slug} onClick={handleAddToCart}/>
                            </motion.div>
                        </div>
                    </div>
                </div>
                : null
            }
        </SkeletonWrapper>
    )
}