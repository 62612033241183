import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const TemperatureZone = {
    Index: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/temperature-zones`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Create: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/temperature-zones/create`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Store: (branchId, data, successCb, failCb) => {
        axios.post(`/branch/${branchId}/temperature-zones`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Edit: (branchId, zoneId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/temperature-zones/${zoneId}/edit`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Update: (branchId, zoneId, data, successCb, failCb) => {
        axios.put(`/branch/${branchId}/temperature-zones/${zoneId}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    Destroy: (branchId, zoneId, successCb, failCb) => {
        axios.delete(`/branch/${branchId}/temperature-zones/${zoneId}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default TemperatureZone;