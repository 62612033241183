import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {Form, Input, Button, Select, Upload, notification} from 'antd';
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import FoodApi from "../../../../../http/FoodApi";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {RiEdit2Line, RiImageLine} from "@remixicon/react";
import DefaultContainer from "../../../../../components/DefaultContainer";

const {Option} = Select;

const EditFood = ({match, history}) => {
    const [form] = Form.useForm();
    const [food, setFood] = useState(null);
    const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [image, setImage] = useState(null);  // For handling the image upload

    const foodId = match.params.id;

    // Load the food details and categories
    const loadFoodDetails = () => {
        setLoading(true);

        FoodApi.Edit(foodId, (response) => {
            const {food, categories} = response.data.data;
            setFood(food);
            setOptions(food.options);
            setCategories(categories);

            form.setFieldsValue({
                name: food.name,
                name_fr: food.name_fr,
                category_id: food.category_id,
                price: food.price,
                delivery_price: food.delivery_price,
                type: food.type,
            });

            setLoading(false);
        }, (err) => {
            notification.error({message: 'Error!', description: err.response?.data.message});
            setLoading(false);
        });
    };

    useEffect(() => {
        loadFoodDetails();
    }, []);

    const saveFood = (values) => {
        setSaving(true);
        const data = new FormData();
        data.append('name', values.name);
        data.append('name_fr', values.name_fr);
        data.append('category_id', values.category_id);
        data.append('price', values.price);
        data.append('delivery_price', values.delivery_price);
        data.append('type', values.type);

        if (values.image) {
            data.append('image', values.image.file);  // Append the image file to FormData
        }

        FoodApi.Update(foodId, data,
            () => {
                notification.success({message: 'Success!', description: 'Food updated successfully!'});
                setSaving(false);
            },
            (err) => {
                notification.error({message: 'Error!', description: err.response?.data.message});
                setSaving(false);
            }
        );
    };

    const handleImageUpload = (file) => {
        setImage(file);  // Store the uploaded image in the state
        return false;  // Prevent automatic upload by Ant Design
    };

    const handleOptionReorder = (result) => {
        if (!result.destination) return;
        const reorderedOptions = Array.from(options);
        const [movedOption] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, movedOption);
        setOptions(reorderedOptions);

        // Automatically save the reordered options (you can implement FoodApi.UpdateOptionOrder)
        const reorderedData = reorderedOptions.map((option, index) => ({
            id: option.id,
            order: index
        }));

        FoodApi.UpdateOptionOrder(foodId, reorderedData,
            () => {
                notification.success({message: 'Success!', description: 'Option order updated successfully!'});
            },
            (err) => {
                notification.error({message: 'Error!', description: 'Failed to update option order.'});
            }
        );
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full mx-auto max-w-8xl mt-2`}
        >
            <h1 className="text-xl font-bold">Edit Food</h1>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <Form
                        form={form}
                        onFinish={saveFood}
                        className="mt-4"
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                    >
                        <Form.Item
                            name="name"
                            label="Food Name"
                            rules={[{required: true, message: 'Please enter the food name'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="name_fr"
                            label="Food Name (French)"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="category_id"
                            label="Category"
                            rules={[{required: true, message: 'Please select the category'}]}
                        >
                            <Select placeholder="Select Category" disabled={true}>
                                {categories.map((category) => (
                                    <Option key={category.id} value={category.id}>
                                        {category.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="price"
                            label="Price"
                            rules={[{required: true, message: 'Please enter the price'}]}
                        >
                            <Input type="number" min={0} step="0.01"/>
                        </Form.Item>

                        <Form.Item
                            name="delivery_price"
                            label="Delivery Price"
                        >
                            <Input type="number" min={0} step="0.01"/>
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[{required: true, message: 'Please select the food type'}]}
                        >
                            <Select placeholder="Select Type">
                                <Option value="fries">Fries</Option>
                                <Option value="drinks">Drinks</Option>
                                <Option value="shakes">Shakes</Option>
                                <Option value="beer">Beer</Option>
                                <Option value="side">Side</Option>
                                <Option value="dip">Dip</Option>
                                <Option value="can-beer">Can Beer</Option>
                                <Option value="burger">Burger</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="image"
                            label="Food Image"
                        >
                            <Upload
                                listType="picture-card"
                                beforeUpload={handleImageUpload}
                                showUploadList={true}
                                maxCount={1}  // Limit to one image upload
                                onRemove={() => setImage(null)}  // Clear image from the state
                            >
                                {food && food.image ? (
                                    <img src={food.image} alt="food" style={{width: '100%'}}/>
                                ) : (
                                    <RiImageLine/>
                                )}
                            </Upload>
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 6, span: 18}}>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save Food
                            </Button>
                        </Form.Item>
                    </Form>
                </DefaultContainer>

                {options && options?.length > 0 &&
                    <>
                        <h2 className="text-lg font-bold mt-8">Reorder and Edit Food Options</h2>
                        <DragDropContext onDragEnd={handleOptionReorder}>
                            <Droppable droppableId="options">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {options.map((option, index) => (
                                            <Draggable key={option.id} draggableId={String(option.id)} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="food-item p-2 mb-2 bg-white shadow-md border border-gray-200 rounded-lg flex items-center justify-between"
                                                    >
                                                        <div className="flex items-center">
                                                            <span className="ml-2">{option.name}</span>
                                                        </div>
                                                        <Button
                                                            type="link"
                                                            onClick={() => history.push(`/dashboard/storefront/foods/${food.id}/options/${option.id}/edit`)}
                                                        >
                                                            <RiEdit2Line/>
                                                        </Button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                }
            </SkeletonWrapper>
        </motion.div>
    );
};

export default EditFood;
