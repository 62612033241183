import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Roles = {
    Index: (successCb, failCb) => {
        axios.get(`/employees/roles`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    GetAbilities: (roleId, successCb, failCb) => {
        axios.get(`/employees/roles/${roleId}/abilities`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateRole: (roleId, data, successCb, failCb) => {
        axios.put(`/employees/roles/${roleId}`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateAbilities: (roleId, abilities, successCb, failCb) => {
        axios.post(`/employees/roles/${roleId}/abilities`, { abilities }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Duplicate: (roleId, roleName, successCb, failCb) => {
        axios.post(`/employees/roles/${roleId}/duplicate`, { name: roleName }, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    }
};

export default Roles;
