import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {Button, Input, Popconfirm, message} from 'antd';
import {PlusOutlined, DeleteOutlined, EditOutlined, UpOutlined, DownOutlined} from '@ant-design/icons';
import AbilityItem from './AbilityItem';
import Abilities from '../../../../http/Abilities';

const ItemTypes = {
    GROUP: 'group',
    ABILITY: 'ability',
};

const AbilityGroup = ({
                          group,
                          index,
                          setAbilityGroups,
                          addNestedGroup,
                          addNewAbility,
                          deleteAbilityGroup,
                          moveGroupUp,
                          moveGroupDown,
                          isFirst,
                          isLast,
                          moveGroupInParent,
                          parentGroupId,
                          handleDropAbility,
                          fetchAbilityGroups
                      }) => {
    const moveAbility = (group, fromIndex, toIndex) => {
        if (toIndex < 0 || toIndex >= group.abilities.length) return;
        const updatedAbilities = [...group.abilities];
        const [movedAbility] = updatedAbilities.splice(fromIndex, 1);
        updatedAbilities.splice(toIndex, 0, movedAbility);
        group.abilities = updatedAbilities;
        setAbilityGroups(prevGroups => [...prevGroups]);

        Abilities.UpdateOrder({
            group_id: group.id,
            abilities: updatedAbilities.map((ability, i) => ({id: ability.id, order: i}))
        }, (response) => {
            message.success('Ability order updated');
        }, (err) => {
            message.error('Failed to update ability order');
        });
    };

    const [isEditing, setIsEditing] = useState(false);
    const [newGroupName, setNewGroupName] = useState(group.name);
    const [loading, setLoading] = useState(false);
    const [newAbilityName, setNewAbilityName] = useState('');
    const [newNestedGroupName, setNewNestedGroupName] = useState('');

    const [, drop] = useDrop({
        accept: [ItemTypes.ABILITY],
        drop: (item, monitor) => {
            if (item.type === 'ability') {
                handleDropAbility(item, group.id);
            }
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.GROUP,
        item: {id: group.id, type: 'group'},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const handleSave = () => {
        setLoading(true);
        Abilities.UpdateGroup(group.id, {name: newGroupName}, (response) => {
            message.success('Ability group updated');
            setLoading(false);
            setIsEditing(false);
        }, (err) => {
            message.error('Failed to update ability group');
            setLoading(false);
        });
    };

    const handleAddAbility = () => {
        if (!newAbilityName.trim()) return;
        addNewAbility(group.id, newAbilityName);
        setNewAbilityName('');
    };

    const handleAddNestedGroup = () => {
        if (!newNestedGroupName.trim()) return;
        addNestedGroup(group.id, newNestedGroupName);
        setNewNestedGroupName('');
    };

    return (
        <div ref={node => drag(drop(node))} className={`group mb-4 mr-2 rounded box ${isDragging ? 'opacity-50' : ''}`}>
            <div className="group-header font-bold mb-1 flex justify-between items-center rounded-t px-3 border-b border-gray-200 pt-1 bg-gray-50">
                {isEditing ? (
                    <div className="flex items-center py-2">
                        <Input
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                            size="large"
                            style={{width: '300px', marginRight: '10px'}}
                            disabled={loading}
                        />
                        <Button
                            type="primary"
                            onClick={handleSave}
                            loading={loading}
                            style={{marginRight: '5px'}}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <span>{group.name}</span>
                )}
                {!isEditing && (
                    <div>
                        <Button type="link" icon={<UpOutlined/>} onClick={moveGroupUp} disabled={isFirst}/>
                        <Button type="link" icon={<DownOutlined/>} onClick={moveGroupDown} disabled={isLast}/>
                        <Button type="link" icon={<EditOutlined/>} onClick={() => setIsEditing(true)}/>
                        <Popconfirm
                            title="Are you sure to delete this group?"
                            onConfirm={() => deleteAbilityGroup(group.id)}
                            okText="Yes"
                            cancelText="No"
                            disabled={group.abilities && group.abilities.length > 0}
                            placement={"right"}
                        >
                            <Button type="link" icon={<DeleteOutlined/>} danger disabled={group.abilities && group.abilities.length > 0}/>
                        </Popconfirm>
                    </div>
                )}
            </div>
            <div>
                {group.abilities && group.abilities.map((ability, i) => (
                    <AbilityItem
                        key={ability.id}
                        ability={ability}
                        groupId={group.id}
                        moveAbilityUp={() => moveAbility(group, i, i - 1)}
                        moveAbilityDown={() => moveAbility(group, i, i + 1)}
                        isFirst={i === 0}
                        isLast={i === group.abilities.length - 1}
                        fetchAbilityGroups={fetchAbilityGroups}
                    />
                ))}
            </div>
            <div className="nested-groups ml-4">
                {group.children && group.children.map((childGroup, i) => (
                    <AbilityGroup
                        key={childGroup.id}
                        group={childGroup}
                        index={i}
                        setAbilityGroups={setAbilityGroups}
                        addNestedGroup={addNestedGroup}
                        addNewAbility={addNewAbility}
                        deleteAbilityGroup={deleteAbilityGroup}
                        moveGroupUp={() => moveGroupInParent(group.id, i, i - 1)}
                        moveGroupDown={() => moveGroupInParent(group.id, i, i + 1)}
                        isFirst={i === 0}
                        isLast={i === group.children.length - 1}
                        moveGroupInParent={moveGroupInParent}
                        parentGroupId={group.id}
                        handleDropAbility={handleDropAbility}
                        fetchAbilityGroups={fetchAbilityGroups}

                    />
                ))}
            </div>
            <div className={"flex flex-row justify-between"}>
                <div className="m-2">
                    <Input
                        placeholder="New Ability Name"
                        value={newAbilityName}
                        onChange={(e) => setNewAbilityName(e.target.value)}
                        style={{width: '300px', marginRight: '10px'}}
                    />
                    <Button type="primary" onClick={handleAddAbility}>
                        <PlusOutlined/> Add Ability
                    </Button>
                </div>
                <div className="m-2">
                    <Input
                        placeholder="New Nested Group Name"
                        value={newNestedGroupName}
                        onChange={(e) => setNewNestedGroupName(e.target.value)}
                        style={{width: '300px', marginRight: '10px'}}
                    />
                    <Button type="primary" onClick={handleAddNestedGroup}>
                        <PlusOutlined/> Add Nested Group
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AbilityGroup;
