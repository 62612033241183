import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullseye, faEdit, faFloppyDisk, faListCheck} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {SpinnerCircular} from "spinners-react";
import Deliverable from "../../../../../../../../http/Deliverable";
import {notification} from "antd";

const ResultComponent = ({deliverable, reload, canEdit}) => {

    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [result, setResult] = useState(null);

    const editResult = () => {
        setResult(deliverable.result);
        setEditing(true);
    }

    const save = () => {
        setSaving(true);
        Deliverable.Respond(deliverable.id, {result}, (r) => {
            reload();
            setSaving(false);
            setEditing(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.request?.data.message,
            });
            setSaving(false);
            setEditing(false);
        });
        reload();
    }

    return (
        editing?
            <div className={"flex flex-row items-center mt-0.5 px-1 py-0.5"}>
                <input name={"response"} className={"flex-1 px-1 py-0.5 border border-gray-300"} value={result} onChange={(e) => setResult(e.target.value)} disabled={saving} />
                {saving?
                    <SpinnerCircular size={18} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"ml-1"}/>
                    :
                    <FontAwesomeIcon icon={faFloppyDisk} aria-hidden="true" className={"ml-1 text-base cursor-pointer"} onClick={() => save(deliverable)}/>
                }
            </div>
        :
            <div>
                <div className={"bg-green-100 rounded-md border-gray-200 border mt-0.5 px-1 py-0.5"}>
                    <FontAwesomeIcon icon={faListCheck} aria-hidden="true" className={"mr-1"}/>
                    <b>{result?result:deliverable.result}</b>
                    {canEdit && <FontAwesomeIcon icon={faEdit} aria-hidden="true" className={"ml-1 text-xs cursor-pointer"} onClick={() => editResult(deliverable)}/> }
                    <i>(By <b>{deliverable.result_by.name}</b> {moment(deliverable.result_at).fromNow()})</i>
                </div>
            </div>
    );
};

export default ResultComponent;