import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const KPI = {

    Index: (successCb, failCb) => {
        axios.get('/KPI', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default KPI;
