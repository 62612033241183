import actions from './actions';
import push from "../../history";
import Profile from "../../http/Profile";
import Settings from "../../http/Settings";
import {notification} from "antd";
import store from "../store";
import {logOut, partiallyUpdateAuthProfile} from "../authentication/actionCreator";
import {EmptyCart} from "../cart/actionCreator";
import {UpdateMenu} from "../menu/actionCreator";

const {
    setLanguage,
    hideHeader,
    showHeader,
    hideDashboardMenu,
    showDashboardMenu,
    setUnderConstruction,
    dashboardSwitchVisibility
} = actions;

const SetLanguage = (lang) => {
    return async dispatch => {
        dispatch(setLanguage(lang));
    };
};

const HideHeader = () => {
    return async dispatch => {
        console.log("dispatching hideHeader()");
        dispatch(hideHeader());
    };
};

const ShowHeader = (fixed = false) => {
    return async dispatch => {
        dispatch(showHeader(fixed));
    };
};

const HideDashboardMenu = () => {
    return async dispatch => {
        dispatch(hideDashboardMenu());
    };
};

const ShowDashboardMenu = () => {
    return async dispatch => {
        console.log("dispatching hideHeader()");
        dispatch(showDashboardMenu());
    };
};

const DashboardSwitchVisibility = () => {
    return async dispatch => {
        dispatch(dashboardSwitchVisibility());
    };
};

const CheckVersion = () => {
    return async dispatch => {

        if(window.location.pathname === "/hiring-form/" || window.location.pathname.startsWith("/profile"))
            return false;

        Settings.Version( (response) => {
            const apiVersion = response.data.data.version;
            const user = response.data.data.user;

            const categories = response.data.data.categories;
            const foods = response.data.data.foods;
            const options = response.data.data.options;
            const items = response.data.data.items;

            //console.log("UpdateMenu Ran!!");

            dispatch(UpdateMenu(categories, foods, options, items));

            if(user && user!=null) {
                dispatch(partiallyUpdateAuthProfile(user));
            }
            else {
                if(store.getState().auth.isLoggedIn) {
                    dispatch(logOut());
                }
            }

            dispatch(setUnderConstruction(response.data.data.under_construction, response.data.data.be_back_string));

            localStorage.setItem("under_construction", response.data.data.under_construction);
            const currentVersion = localStorage.getItem('version');
            if(!currentVersion) {
                dispatch(EmptyCart());
                localStorage.setItem('version', apiVersion);
            }
            else {
                if(currentVersion != apiVersion) {
                    dispatch(EmptyCart());
                    const newLocation = window.location.pathname + "?version=" + apiVersion;
                    console.log("new location: " + newLocation);
                    window.location.href = newLocation;
                    localStorage.setItem('version', apiVersion);
                    localStorage.setItem('version_update', new Date().toISOString());
                }
            }
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description:
                    `Internal Error (version)!`,
            });
        });
    };
}


export {SetLanguage, HideHeader, ShowHeader, CheckVersion, DashboardSwitchVisibility, HideDashboardMenu, ShowDashboardMenu};
