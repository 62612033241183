import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { notification, Pagination, Spin } from 'antd';
import Table from 'ant-responsive-table';
import QCReports from '../../../../http/QCReports'; // Adjust the path as needed
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';
import moment from 'moment';
import Styled from 'styled-components';

const ContainerWrapper = Styled.div`
    .ant-card-body div {
        white-space: nowrap;
        width: auto !important;
    }
    
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li {
        margin-bottom: 4px;
    }
    
    /* Additional styles if needed */
`;

const QCReportsIndex = () => {
    const { branchId } = useParams();
    const location = useLocation();
    const history = useHistory();

    const getQueryParams = useCallback(() => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get('page'), 10) || 1,
            pageSize: parseInt(params.get('pageSize'), 10) || 10,
            sortField: params.get('sortField') || 'payed_at',
            sortOrder: params.get('sortOrder') || 'desc',
        };
    }, [location.search]);

    const initialParams = getQueryParams();

    const [pagination, setPagination] = useState({
        current: initialParams.page,
        pageSize: initialParams.pageSize,
        total: 0,
    });

    const [sorter, setSorter] = useState({
        field: initialParams.sortField,
        order: initialParams.sortOrder,
    });

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const { page, pageSize, sortField, sortOrder } = getQueryParams();

        const isDifferent =
            page !== pagination.current ||
            pageSize !== pagination.pageSize ||
            sortField !== sorter.field ||
            sortOrder !== sorter.order;

        if (isDifferent) {
            setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
            }));
            setSorter({
                field: sortField,
                order: sortOrder,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        loadReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, pagination.pageSize, sorter.field, sorter.order, branchId]);

    const loadReports = () => {
        const isInitialLoad =
            pagination.current === initialParams.page &&
            pagination.pageSize === initialParams.pageSize &&
            sorter.field === initialParams.sortField &&
            sorter.order === initialParams.sortOrder;

        if(!reports)
            setLoading(isInitialLoad);
        setTableLoading(true);

        const mappedSortOrder =
            sorter.order === 'ascend' ? 'asc' :
                sorter.order === 'descend' ? 'desc' :
                    'desc';

        if (!['asc', 'desc'].includes(mappedSortOrder)) {
            notification.error({
                message: 'Error!',
                description: 'Invalid sort direction.',
            });
            setTableLoading(false);
            return;
        }

        const validSortFields = ['id', 'payed_at', 'qc_punched.name'];
        if (!validSortFields.includes(sorter.field)) {
            notification.error({
                message: 'Error!',
                description: 'Invalid sort field.',
            });
            setLoading(false);
            setTableLoading(false);
            return;
        }

        QCReports.Index(
            branchId,
            {
                params: {
                    page: pagination.current,
                    per_page: pagination.pageSize,
                    sort_by: sorter.field,
                    sort_direction: mappedSortOrder,
                },
            },
            (response) => {
                if (response.data && response.data.data && response.data.data.reports) {
                    const { reports } = response.data.data;

                    const processedReports = reports.data.map((record) => ({
                        ...record,
                        payedAtFormatted: record.payed_at ? moment(record.payed_at).format('DD/MM/YYYY HH:mm') : '-',
                        qcPunchedName: record.qc_punched ? record.qc_punched.name : '-',
                        checkoutItems: record.items ? record.items.map(item => ({
                            ...item,
                            isFaulty: item.qc_status === 'not correct',
                        })) : [],
                    }));
                    setReports(processedReports);
                    setPagination((prev) => ({
                        ...prev,
                        total: reports.total,
                    }));
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Unexpected response format while loading QC reports.',
                    });
                }
                setLoading(false);
                setTableLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: err.response?.data?.message || 'Failed to load QC reports.',
                });
                setLoading(false);
                setTableLoading(false);
            }
        );
    };

    const updateURL = (newParams) => {
        const params = new URLSearchParams(location.search);

        Object.keys(newParams).forEach((key) => {
            if (newParams[key] !== undefined && newParams[key] !== null) {
                params.set(key, newParams[key]);
            } else {
                params.delete(key);
            }
        });

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleTableChange = (paginationConfig, filters, sorterConfig) => {
        const newPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total,
        };

        const newSorter = {
            field: sorterConfig.field || sorter.field,
            order: sorterConfig.order || sorter.order,
        };

        setPagination(newPagination);
        setSorter(newSorter);

        updateURL({
            page: newPagination.current,
            pageSize: newPagination.pageSize,
            sortField: newSorter.field,
            sortOrder: newSorter.order,
        });
    };

    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize: pageSize,
        }));

        updateURL({
            page: page,
            pageSize: pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    const handlePerPageChange = (current, size) => {
        setPagination((prev) => ({
            ...prev,
            pageSize: size,
            current: 1,
        }));

        updateURL({
            page: 1,
            pageSize: size,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    const columns = useMemo(() => [
        {
            title: 'Checkout ID',
            dataIndex: 'id',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid At',
            dataIndex: 'payedAtFormatted',
            key: 'payedAtFormatted',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'QC Punched By',
            dataIndex: 'qcPunchedName',
            key: 'qcPunchedName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Items',
            dataIndex: 'checkoutItems',
            key: 'checkoutItems',
            render: (checkoutItems) => (
                <ul>
                    {checkoutItems.map(item => (
                        <li
                            key={item.id}
                            className={item.isFaulty ? 'faulty-item' : 'normal-item'}
                        >
                            {item.name} {item.isFaulty && '(Faulty)'}
                        </li>
                    ))}
                </ul>
            ),
            showOnResponse: true,
            showOnDesktop: true,
        },
    ], [sorter.field, sorter.order]);

    return (
        <ContainerWrapper>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <h2>QC Reports - Not Correct Checkouts</h2>
                    <Table
                        antTableProps={{
                            dataSource: reports,
                            columns: columns,
                            pagination: false,
                            rowKey: 'id',
                            onChange: handleTableChange,
                            loading: tableLoading || loading
                        }}
                        columns={columns}
                        dataSource={reports}
                        rowKey="id"
                        pagination={false}
                        mobileBreakPoint={1200}
                    />

                    <div className="mt-4 ml-4">
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePerPageChange}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50']}
                            showTotal={(total) => `Total ${total} items`}
                        />
                    </div>
                </DefaultContainer>
            </SkeletonWrapper>
        </ContainerWrapper>
    );
};

export default QCReportsIndex;
