import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const QCReports = {
    Index: (branchId, config, successCb, failCb) => {
        axios.get(`/branch/${branchId}/qc-reports`, {...getAuthenticationHeaders(), ...config})
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

}

export default QCReports;