import React, {useEffect} from 'react';
import {Button, Form, Input} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {ResetAuthErrors, sendForgotPassword, sendForgotPasswordReset} from '../../redux/authentication/actionCreator';
import {Link} from 'react-router-dom';
import FormError from "../../components/Form/FormError";
import FormItem from '../../components/Form/FormItem';

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const isLoading = useSelector(state => state.auth.loading);
    const forgotPasswordError = useSelector(state => state.auth.error);
    const forgotPasswordDone = useSelector(state => state.auth.done);
    const forgotPasswordValidationFields = useSelector(state => state.auth.fields);

    const handleSubmit = () => {
        dispatch(sendForgotPassword(form.getFieldsValue()));
    };



    useEffect(() => {
        dispatch(sendForgotPasswordReset());
        dispatch(ResetAuthErrors());
    }, []);

    return (

        <div className="auth-contents flex flex-col items-center justify-center mt-4 py-2 px-4 lg:py-4 lg:px-8 uppercase">
            <div className="max-w-xl w-full">
                {forgotPasswordDone ? <>
                        <div className="flex flex-col">
                            <div>
                                <h1 className="text-2xl font-bold">Check your Inbox</h1>
                                We have e-mailed your password reset link! Check your spam folder to make sure it didn't end up there.
                            </div>
                            <Link to="/login" className="text-lg mt-3">Sign in</Link>
                        </div>
                    </>
                    :
                    <Form name='forgotPassword' form={form} onFinish={handleSubmit} layout='vertical'>
                        <div className="text-2xl font-bold">Forgot Password?</div>

                        <FormError error={forgotPasswordError}/>

                        <p className="forgot-text mt-2">
                            Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                        </p>
                        <FormItem name='email'
                                  label='Email'
                                  errors={forgotPasswordValidationFields}
                                  disabled={isLoading}
                                  initialValue=""
                        >
                            <Input placeholder='name@example.com' disabled={isLoading} className="h-10"/>
                        </FormItem>

                        <Button htmlType='submit' size='large' type='primary' key='submit' loading={isLoading} className="mt-3 uppercase">
                            Send Reset Instructions
                        </Button>

                        <div className="return-text mt-5 text-right uppercase">
                            Return to <Link to="/login" className="underline">Sign In</Link>
                        </div>
                    </Form>}
            </div>
        </div>
    );
};

export default ForgotPassword;
